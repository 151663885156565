import styled from 'styled-components'
import { ImagesType } from '../enums/ImagesType'
import { Texts } from '../figma/helpers/TextRepository'
import arrowDown from '../figma/images/arrowDown'
import arrowUp from '../figma/images/arrowUp'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { FILTER_BUTTONS_WRAPPER_MAX_WIDTH } from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import { TextType } from '../moesia/interfaces/FigmaTypes'

type Props = {
  filterType: ImagesType | null
  setFilterType: React.Dispatch<React.SetStateAction<ImagesType | null>>
  filterOptions: {
    label: TextType
    value: ImagesType
  }[]
  showFilterOptions: boolean
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>
}

const ImagesFilterDesktop: React.FC<Props> = ({
  filterType,
  setFilterType,
  filterOptions,
  showFilterOptions,
  setShowFilterOptions
}) => (
  <Wrapper fullWidth>
    <FilterOptionsButton
      direction="row"
      align="center"
      alignSelf="flex-end"
      fullPadding
      spacing={Spacings.minimum}
      onClick={() => setShowFilterOptions(!showFilterOptions)}
      $activeEffect={showFilterOptions}
    >
      <TextContainer textKey={Texts.FilterImageCategoies} />
      <StyledSVGContainer SVG={showFilterOptions ? arrowUp : arrowDown} />
    </FilterOptionsButton>
    {!!filterOptions?.length && showFilterOptions && (
      <FilterButtonsWrapper
        position="absolute"
        direction="row"
        gap={Spacings.min}
        alignSelf="flex-end"
        justify="flex-end"
        fullPadding
        spacing={Spacings.min}
      >
        <StyledFilterButton
          fullWidth
          alignText="center"
          justify="center"
          fullPadding
          spacing={Spacings.minimum}
          onClick={() => setFilterType(null)}
          $buttonValue={null}
          $filterType={filterType}
        >
          <TextContainer textKey={Texts.AllImagesLabel} />
        </StyledFilterButton>
        {filterOptions.map(({ label, value }) => (
          <StyledFilterButton
            key={value}
            fullWidth
            alignText="center"
            justify="center"
            fullPadding
            spacing={Spacings.minimum}
            onClick={() => setFilterType(value)}
            $buttonValue={value}
            $filterType={filterType}
          >
            <TextContainer textKey={label} />
          </StyledFilterButton>
        ))}
      </FilterButtonsWrapper>
    )}
  </Wrapper>
)

export default ImagesFilterDesktop

const Wrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
`

const FilterOptionsButton = styled(StyledBox)<{ $activeEffect: boolean }>`
  display: flex;
  margin-top: -${Spacings.big};
  border-radius: ${({ $activeEffect }) => !$activeEffect && `0 0 ${BorderRadius.soft} ${BorderRadius.soft}`};
  cursor: pointer;
  background-color: ${({ $activeEffect }) =>
    $activeEffect ? FigmaColors.primaryButtonHoverColor : FigmaColors.bhBluePrimaryButton};
`

const StyledSVGContainer = styled(SVGContainer)`
  svg path {
    fill: ${FigmaColors.white};
  }
`

const FilterButtonsWrapper = styled(StyledBox)`
  display: flex;
  flex-wrap: wrap;
  margin-top: -${Spacings.small};
  max-width: ${FILTER_BUTTONS_WRAPPER_MAX_WIDTH};
  background-color: ${FigmaColors.white};
  border-radius: 0 0 ${BorderRadius.soft} ${BorderRadius.soft};
`

const StyledFilterButton = styled(StyledBox)<{ $buttonValue: string | null; $filterType: ImagesType | null }>`
  cursor: pointer;
  background-color: ${({ $buttonValue, $filterType }) =>
    $buttonValue === $filterType ? FigmaColors.bhBluePrimaryButton : FigmaColors.bhBlueLight};
  border-radius: ${BorderRadius.soft};

  &:hover {
    background-color: ${FigmaColors.bhBluePrimaryButton};
  }
`
