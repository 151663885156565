import { ReactNode, useMemo, useState } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { getIsItemContentNotEmpty } from 'src/helpers/MobileDetailsBoxConditionHelper'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import arrowDown from '../figma/images/arrowDown'
import arrowUp from '../figma/images/arrowUp'
import { MOBILE_DETAILS_BOX_MIN_HEIGHT, MOBILE_DETAILS_INFO_BOX_MAX_HEIGHT } from '../moesia/StrongHardCodedSizes'
import { Line } from './Line'

type Props = {
  itemIcon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  itemTitle: TextType
  itemInfo: JSX.Element | string
  itemButton?: ReactNode
}

const MobileDetailsBox: React.FC<Props> = ({ itemIcon, itemTitle, itemInfo, itemButton }) => {
  const [shouldShowInfo, setShouldShowInfo] = useState(false)
  const [isArrowClicked, setIsArrowClicked] = useState(false)
  const isItemContentNotEmpty = useMemo(() => getIsItemContentNotEmpty(itemInfo), [itemInfo])

  return (
    <>
      {!!itemInfo && !!isItemContentNotEmpty && (
        <Wrapper fullWidth fullPadding spacing={Spacings.small} align="center" justify="center">
          <StyledBox fullWidth direction="row" gap={Spacings.min} justify="space-between">
            <StyledBox direction="row" align="center" gap={Spacings.small}>
              <SVGContainer SVG={itemIcon} />
              <TextContainer textKey={itemTitle} />
            </StyledBox>
            <StyledBox
              onClick={() => {
                setShouldShowInfo(!shouldShowInfo)
                setIsArrowClicked(!isArrowClicked)
              }}
              align="center"
            >
              <SVGContainer SVG={isArrowClicked ? arrowUp : arrowDown} />
            </StyledBox>
          </StyledBox>
          {shouldShowInfo && !!itemInfo && (
            <>
              <StyledBox fullWidth top bottom>
                <Line fullWidth />
              </StyledBox>
              <InfoBox
                fullWidth
                fullPadding
                spacing={Spacings.min}
                direction="row"
                justify="space-between"
                align="center"
              >
                {typeof itemInfo === 'string' ? (
                  <TextContainer textKey={Texts.EmployeesDetails} text={itemInfo} />
                ) : (
                  itemInfo
                )}
                {itemButton}
              </InfoBox>
            </>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default MobileDetailsBox

const Wrapper = styled(StyledBox)`
  min-height: ${MOBILE_DETAILS_BOX_MIN_HEIGHT};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};
`

const InfoBox = styled(StyledBox)`
  max-height: ${MOBILE_DETAILS_INFO_BOX_MAX_HEIGHT};
  overflow: auto;
`
