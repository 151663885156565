import React, { useMemo } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE, SIDEBAR_ELEMENTS_BORDER } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import ZIndex from '../figma/tokens/ZIndex'
import { getInfoIcon, isNotIconTypeNone } from '../helpers/UserInformationIconTypeHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useAppState } from './AuthProvider'

type Props = {
  menuWithoutIcon?: boolean
  menuWithOptionsIcon?: boolean
  menuWithLogOutIcon?: boolean
  iconType?: UserInformationType
  onClick?: () => void
}

export enum UserInformationType {
  OPTIONS = 'Options',
  LOG_OUT = 'LogOut',
  NONE = 'None'
}

const MenuUserInformation: React.FC<Props> = ({ iconType, onClick }) => {
  const {
    state: { user }
  } = useAppState()

  const showUserInformation = useMemo(() => isNotIconTypeNone(iconType), [iconType])
  const isMobile = useIsMobile()

  return (
    <>
      <UserInformation
        fullPadding
        fullWidth
        direction="row"
        spacing={Spacings.tiny}
        gap={Spacings.min}
        align="center"
        justify="space-between"
        $isMobile={isMobile}
      >
        <StyledBox fullWidth direction="row" gap={Spacings.min} align="center">
          {!showUserInformation && <UserPhoto imageKey={Images.userPhoto} />}
          {showUserInformation && <ImageContainer imageKey={Images.userPhoto} />}
          <CredentialsWrapper fullWidth align="flex-start" justify="center">
            <TextContainer
              textKey={Texts.UserName}
              text={user?.name}
              style={{ fontSize: '14px', lineHeight: 'auto' }}
            />
            <Email textKey={Texts.UserEmail} text={user?.email} style={{ fontSize: '12px', lineHeight: 'auto' }} />
          </CredentialsWrapper>
          {getInfoIcon(iconType, onClick)}
        </StyledBox>
      </UserInformation>
    </>
  )
}

export default MenuUserInformation

const UserInformation = styled(StyledBox)<{ $isMobile: boolean }>`
  border-bottom: ${SIDEBAR_ELEMENTS_BORDER};
  border-top: ${SIDEBAR_ELEMENTS_BORDER};
  position: ${({ $isMobile }) => ($isMobile ? 'fixed' : 'sticky')};
  bottom: 0;
  z-index: ${ZIndex.heaven};
  background-color: white;
`
const UserPhoto = styled(ImageContainer)`
  img {
    min-width: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
    max-height: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
    max-width: ${SIDEBAR_ACCOUNT_MENU_PHOTO_SIZE} !important;
  }
`
const CredentialsWrapper = styled(StyledBox)`
  overflow: hidden;
`
const Email = styled(TextContainer)`
  span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`
