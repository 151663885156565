import { useMemo } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { mapLabelsToLanguage } from '../helpers/Common'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { getImageTypes } from '../helpers/ImageTypesHelper'
import useLanguage from '../hooks/useLanguage'
import { Document } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import { AutoCompleteController } from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import GenericPageModal from './GenericPageModal'
import UploadFileContainer from './UploadFileContainer'

type Props = {
  open: boolean
  onClose: () => void
  control: Control<FieldValues, any>
  onImageUpload: () => Promise<void>
  files: Document[]
  uploadingProgress: UploadingProgress
  imageType: string
}

const AddImageFormMobileView: React.FC<Props> = ({
  open,
  onClose,
  control,
  onImageUpload,
  files,
  uploadingProgress,
  imageType
}) => {
  const language = useLanguage()
  const imageTypes = useMemo(() => getImageTypes(), [])
  const imagesTypesOptions = useMemo(() => mapLabelsToLanguage(imageTypes, language), [imageTypes, language])

  return (
    <GenericPageModal open={open} onClose={onClose}>
      <StyledBox fullWidth align="center" top={Spacings.large}>
        <ImageContainer imageKey={Images.addTemplateImage} />
      </StyledBox>
      <StyledBox fullWidth fullPadding spacing={Spacings.medium}>
        <form>
          <StyledBox fullWidth fullPadding spacing={Spacings.medium} gap={Spacings.medium}>
            <AutoCompleteController
              name="type"
              control={control}
              options={imagesTypesOptions}
              labelTextKey={Texts.SelectImageTypeMenu}
            />
            {!!imageType && (
              <UploadImages fullWidth fullPadding spacing={Spacings.medium} align="center" gap={Spacings.tiny}>
                <UploadFileContainer
                  fullWidth
                  onChange={onImageUpload}
                  files={files}
                  uploadingProgress={uploadingProgress}
                  fileType="image"
                />
              </UploadImages>
            )}
          </StyledBox>
        </form>
      </StyledBox>
    </GenericPageModal>
  )
}

export default AddImageFormMobileView

const UploadImages = styled(StyledBox)`
  border: 1px solid ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
`
