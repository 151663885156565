import { memo } from 'react'
import styled from 'styled-components'
import { TervelImage } from '../../interfaces/Dto'
import { openLinkInNewTab, trackGAEvent } from '../helpers/Common'
import NewsCardBackComponent from './NewsCardBackComponent'
import { CardWrapper } from './StyleComponents'
import StyledBox from './StyledBox'

type Props = {
  textKey: string
  image: TervelImage
  backTextLink?: string
  googleAnalyticsEvent?: string
}

const NewsCardBack = ({ textKey, image, backTextLink, googleAnalyticsEvent }: Props) => (
  <CardWrapper direction="row" align="center" justify="space-between" transform="rotateY(180deg)">
    <InnerWrapper
      onClick={() => {
        openLinkInNewTab(backTextLink)
        trackGAEvent(googleAnalyticsEvent)
      }}
      $clickable={!!backTextLink}
    >
      <NewsCardBackComponent textKey={textKey} image={image} />
    </InnerWrapper>
  </CardWrapper>
)

export default memo(NewsCardBack)

const InnerWrapper = styled(StyledBox)<{ $clickable?: boolean }>`
  cursor: ${({ $clickable }) => $clickable && 'pointer'};
`
