import toString from 'lodash/toString'
import { Controller } from 'react-hook-form'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import useLanguage from 'src/hooks/useLanguage'
import { StyledTextField, createAdornmentWithLoading, getLabelText } from 'src/moesia/components/ReactHookComponents'
import { TextFieldControllerType } from 'src/moesia/components/TextFieldController'

function LoginPageTextFieldController<T extends Record<string, unknown>>({
  name,
  control,
  labelTextKey,
  labelText,
  multiline,
  adornment,
  onBlur,
  onChange: _onChange,
  type,
  disabled,
  rows,
  loading
}: TextFieldControllerType<T>) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <StyledTextField
          rows={rows}
          disabled={disabled}
          fullWidth
          value={value}
          onBlur={onBlur}
          onChange={(event) => {
            if (type === 'number') {
              event.target.value = isNaN(Number(event.target.dataset.valueAsNumber))
                ? event.target.value
                : toString(event.target.dataset.valueAsNumber)
              onChange(event)
              return _onChange?.(event)
            }
            onChange(event)
            return _onChange?.(event)
          }}
          label={getLabelText(language, labelTextKey, labelText)}
          error={Boolean(errors[name]?.message)}
          helperText={errors[name]?.message as React.ReactNode}
          multiline={multiline}
          type={type}
          InputProps={adornment ? { endAdornment: createAdornmentWithLoading(adornment, loading) } : {}}
          containerstyles={{ width: '100%', borderRadius: `${BorderRadius.verySoft}` }}
          inputstyles={{ backgroundColor: FigmaColors.surfaceOnWhite }}
          autoComplete="new-password"
        />
      )}
      control={control}
      name={name}
    />
  )
}
export default LoginPageTextFieldController
