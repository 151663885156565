import { memo } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import { createKeyIndex } from '../helpers/Common'
import { ContactPerson, User } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AvatarInitials from './AvatarInitials'

type Props = {
  employees?: User[]
  contacts?: ContactPerson[]
}

const CompanyDetailsPeopleComponent: React.FC<Props> = ({ employees, contacts }) => {
  const people = !!employees ? employees : contacts
  const isMobile = useIsMobile()

  const isEmployee = (person: ContactPerson | User): person is User => (person as User).role !== undefined

  return (
    <StyledBox fullWidth gap={Spacings.tiny}>
      {!!people &&
        people.map((person: ContactPerson | User, idx: number) => (
          <Wrapper
            fullWidth
            key={createKeyIndex(idx, person.name)}
            direction="row"
            gap={Spacings.min}
            justify="flex-start"
            align="center"
          >
            <AvatarInitials name={person.name} size={isMobile ? '32' : '36'} />
            <StyledBox align="flex-start">
              {isEmployee(person) && (
                <>
                  <TextContainer textKey={Texts.CompanyDetailsEmployerName} text={person.name} />
                  <TextContainer textKey={Texts.CompanyDetailsEmployerPosition} text={person.position} />
                </>
              )}
              {!isEmployee(person) && (
                <>
                  <TextContainer
                    textKey={Texts.CompanyDetailsEmployerName}
                    text={`${person.name}, ${person.position}`}
                  />
                  <TextContainer textKey={Texts.CompanyDetailsEmployerPosition} text={person.email} />
                </>
              )}
            </StyledBox>
          </Wrapper>
        ))}
    </StyledBox>
  )
}

export default memo(CompanyDetailsPeopleComponent)

const Wrapper = styled(StyledBox)`
  .avatarWrapper {
    width: ${Spacings.large};
    height: ${Spacings.large};
  }
`
