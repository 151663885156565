import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import { formatImageData } from '../helpers/ImageActionsHelper'
import { PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useUploadTervelImage } from '../helpers/QueryHooks'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { useNotifications } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddImageFormDesktopView from './AddImageFormDesktopView'
import AddImageFormMobileView from './AddImageFormMobileView'

type Props = {
  open: boolean
  onClose: () => void
}

const AddImageFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const isMobile = useIsMobile()
  const uploadImageMutation = useUploadTervelImage()
  const [uploadingProgress, setUploadingProgress] = useState<UploadingProgress>(UploadingProgress.LOADING)
  const { setSubstate } = useContext(PageSubstateContext)
  const language = useLanguage()
  const { successNotification } = useNotifications()
  const defaultValues = useMemo(
    () => ({
      type: ''
    }),
    []
  )
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: createRequiredStringYupValidation(getText(Texts.AddImageTypeIsRequired, language))
      }),
    [language]
  )

  const { control, watch, setError } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const { files, type } = watch()

  const handleUploadImage = (imageFilesArray: unknown[] = []) => {
    const formData = formatImageData(imageFilesArray, type)

    return uploadImageMutation
      .mutateAsync(formData)
      .then(() => {
        setUploadingProgress(UploadingProgress.SUCCESS)
        successNotification(Texts.NotificationAddImageSuccessful)
        onClose()
      })
      .catch((err: Error) => {
        setUploadingProgress(UploadingProgress.ERROR)
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  return (
    <>
      {!isMobile && (
        <AddImageFormDesktopView
          open={open}
          onClose={onClose}
          control={control}
          files={files}
          onImageUpload={handleUploadImage}
          uploadingProgress={uploadingProgress}
          imageType={type}
        />
      )}
      {isMobile && (
        <AddImageFormMobileView
          open={open}
          onClose={onClose}
          control={control}
          files={files}
          onImageUpload={handleUploadImage}
          uploadingProgress={uploadingProgress}
          imageType={type}
        />
      )}
    </>
  )
}

export default AddImageFormContainer
