import { FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Texts } from '../figma/helpers/TextRepository'
import applyChangesIcon from '../figma/images/applyChangesIcon'
import buttonAddIcon from '../figma/images/buttonAddIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { handleApplyContactChanges } from '../helpers/CompanyContactsHelper'
import { ContactPerson } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import useIsMobile from '../moesia/hooks/useIsMobile'
import MobileSubmitButton from './MobileSubmitButton'

type Props = {
  onAddContact: (
    contactsContainer: ContactPerson[] | [],
    setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
  ) => void
  selectedContactEmail: string | null
  isContactInfoFilled: boolean
  contactsContainer: ContactPerson[] | []
  watch: UseFormWatch<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  setSelectedContactEmail: React.Dispatch<React.SetStateAction<string | null>>
  setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>>
}

const CompanyContactsSubmitButton: React.FC<Props> = ({
  onAddContact,
  selectedContactEmail,
  isContactInfoFilled,
  contactsContainer,
  watch,
  setError,
  clearErrors,
  setValue,
  setSelectedContactEmail,
  setContactsContainer
}) => {
  const isMobile = useIsMobile()

  const handleClick = () => {
    isContactInfoFilled && !selectedContactEmail && onAddContact(contactsContainer, setContactsContainer)
    isContactInfoFilled &&
      selectedContactEmail &&
      handleApplyContactChanges(
        selectedContactEmail,
        contactsContainer,
        watch,
        setError,
        clearErrors,
        setValue,
        setSelectedContactEmail,
        setContactsContainer
      )
  }

  return (
    <>
      {!!isMobile && (
        <StyledBox fullWidth align="flex-end" top bottom spacing={Spacings.min}>
          <MobileSubmitButton
            textKey={!!selectedContactEmail ? Texts.ApplyChangesButtonTextMobile : Texts.AddButtonText}
            buttonIcon={!!selectedContactEmail ? applyChangesIcon : buttonAddIcon}
            textBackgroundColor={isContactInfoFilled ? FigmaColors.bhBluePrimaryButton : FigmaColors.grayLight}
            iconBackgroundColor={isContactInfoFilled ? FigmaColors.bhBlueMedium : FigmaColors.lightStone}
            onClick={handleClick}
          />
        </StyledBox>
      )}
      {!isMobile && (
        <SubmitButton
          variant="primary"
          textKey={!!selectedContactEmail ? Texts.ApplyChangesButtonText : Texts.AddContactButtonText}
          onClick={handleClick}
          disabled={!isContactInfoFilled}
        />
      )}
    </>
  )
}
export default CompanyContactsSubmitButton
