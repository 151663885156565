import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import documentOptionsIconDesktop from '../figma/images/documentOptionsIconDesktop'
import smallCloseIcon from '../figma/images/smallCloseIcon'
import templatesDocumentImage from '../figma/images/templatesDocumentImage'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { Template } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from '../moesia/Constants'
import useIsMobile from '../moesia/hooks/useIsMobile'
import {
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT,
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT_DESKTOP,
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH,
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH_DESKTOP
} from '../moesia/StrongHardCodedSizes'
import TemplateContainerOptions from './TemplateContainerOptions'

type Props = {
  onClick?: () => void
  template: Template
  hasRightsToDelete: boolean
  onDeleteTemplate?: (templateId: string) => void
  setSelectedTemplate?: React.Dispatch<React.SetStateAction<Template | null>>
}

const TemplateContainer: React.FC<Props> = ({
  onClick,
  hasRightsToDelete,
  template,
  onDeleteTemplate,
  setSelectedTemplate
}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const isMobile = useIsMobile()

  return (
    <Wrapper
      $isMobile={isMobile}
      position="relative"
      onClick={onClick}
      fullPadding
      spacing={Spacings.tiny}
      isMenuOpen={isOptionsMenuOpen}
      align="center"
      justify="center"
    >
      <StyledBox alignText="center" align="center" gap={Spacings.tiny}>
        <SVGContainer SVG={templatesDocumentImage} />
        <FileName textKey={Texts.TemplateName} text={template.name} />
      </StyledBox>
      <OptionsMenu fullWidth position="absolute" isMenuOpen={isOptionsMenuOpen} align="center" justify="center">
        <OptionsIconsWrapper
          onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}
          align="center"
          justify="center"
          position="absolute"
          isCloseIcon={isOptionsMenuOpen}
          pointer
        >
          {!isOptionsMenuOpen && <SVGContainer SVG={documentOptionsIconDesktop} />}
          {isOptionsMenuOpen && <SVGContainer SVG={smallCloseIcon} />}
        </OptionsIconsWrapper>
        {isOptionsMenuOpen && (
          <TemplateContainerOptions
            hasRightsToDelete={hasRightsToDelete}
            onDeleteTemplate={onDeleteTemplate}
            template={template}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
      </OptionsMenu>
    </Wrapper>
  )
}

export default TemplateContainer

const Wrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  border-radius: ${BorderRadius.soft};
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseHeavy};
  height: ${({ $isMobile }) =>
    $isMobile ? TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT : TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT_DESKTOP};
  width: ${({ $isMobile }) =>
    $isMobile ? TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH : TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH_DESKTOP};
  overflow: hidden;
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${({ $isMobile }) => ($isMobile ? 'none' : REGULAR_TRANSFORM)};
  }
`

const OptionsIconsWrapper = styled(StyledBox)<{ isCloseIcon: boolean }>`
  z-index: 2;
  right: ${({ isCloseIcon }) => (isCloseIcon ? Spacings.minimum : '0')};
  top: ${({ isCloseIcon }) => (isCloseIcon ? Spacings.min : Spacings.minimum)};
`
const FileName = styled(TextContainer)`
  span {
    //styles to truncate file title
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
`
const OptionsMenu = styled(StyledBox)<{ isMenuOpen: boolean }>`
  height: 100%;
  z-index: 2;
  background-color: ${({ isMenuOpen }) => (isMenuOpen ? FigmaColors.grayLight : 'transparent')};
`
