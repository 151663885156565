import React from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'

export type ModalButtonsProps = {
  primaryButton?: React.ReactNode
  secondaryButton?: React.ReactNode
  tertiaryButton?: React.ReactNode
  fullPadding?: boolean
  spacing?: string
}

const ModalButtons: React.FC<ModalButtonsProps> = ({
  primaryButton,
  secondaryButton,
  tertiaryButton,
  fullPadding,
  spacing
}) => (
  <StyledBox
    fullWidth
    direction="row"
    justify={tertiaryButton ? 'space-between' : 'flex-end'}
    fullPadding={fullPadding}
    spacing={fullPadding && spacing}
  >
    {tertiaryButton}
    <StyledBox direction="row" gap={Spacings.large}>
      {secondaryButton}
      {primaryButton}
    </StyledBox>
  </StyledBox>
)

export default ModalButtons
