import styled from 'styled-components'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from './StyledBox'
import { TERVEL_NEWS_CARD_HEIGHT, TERVEL_NEWS_CARD_WIDTH } from '../StrongHardCodedSizes'
import BorderRadius from '../../figma/tokens/BorderRadius'
import Shadows from '../../figma/tokens/Shadows'

const FlexboxStyles = `
  display: flex;
  flex-direction: column;
`
export const Flexbox = styled.div<{ id?: string; align?: string }>`
  ${FlexboxStyles}
`

export const FlexboxWithPadding = styled.div<{
  left?: boolean
  right?: boolean
  top?: boolean
  bottom?: boolean
  spacing?: string
  fullWidth?: boolean
}>`
  ${FlexboxStyles}
  padding-left: ${({ left, spacing }) => (left ? spacing || Spacings.small : 0)};
  padding-right: ${({ right, spacing }) => (right ? spacing || Spacings.small : 0)};
  padding-top: ${({ top, spacing }) => (top ? spacing || Spacings.small : 0)};
  padding-bottom: ${({ bottom, spacing }) => (bottom ? spacing || Spacings.small : 0)};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`

export const WideFlexbox = styled(Flexbox)`
  width: 100%;
`

export const CenterFlexbox = styled(Flexbox)`
  width: 100%;
  align-items: center;
`

export const RowFlexbox = styled(WideFlexbox)`
  flex-direction: row;
  align-items: ${({ align }) => (align ? align : 'center')};
`

export const LeftPaddingFlexbox = styled(Flexbox)`
  padding-left: ${Spacings.small};
`

export const RightPaddingFlexbox = styled(Flexbox)`
  padding-right: ${Spacings.small};
`

export const TopPaddingFlexbox = styled(Flexbox)`
  padding-top: ${Spacings.small};
`

export const BottomPaddingFlexbox = styled(Flexbox)`
  padding-bottom: ${Spacings.small};
`

export const WithPaddinglexbox = styled(Flexbox)`
  padding: ${Spacings.small};
`

export const ColoredContainer = styled(StyledBox)<{ containerColor: string }>`
  background-color: ${({ containerColor }) => containerColor};
`

export const WhiteBox = styled(StyledBox)<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${Spacings.tiny};
  background-color: ${FigmaColors.white};
`

export const CardWrapper = styled(StyledBox)<{
  width?: string
  height?: string
  backgroundColor?: string
  transform?: string
  position?: string
  borderRadius?: string
  justify?: string
}>`
  width: ${({ width }) => (!!width ? width : TERVEL_NEWS_CARD_WIDTH)};
  justify-content: ${({ justify }) => !!justify && justify};
  height: ${({ height }) => (!!height ? height : TERVEL_NEWS_CARD_HEIGHT)};
  border-radius: ${({ borderRadius }) => (!!borderRadius ? borderRadius : BorderRadius.soft)};
  box-shadow: ${Shadows.baseHeavy};
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: ${({ position }) => !!position && position};
  background-color: ${({ backgroundColor }) => (!!backgroundColor ? backgroundColor : FigmaColors.bhBlue)};
  transform: ${({ transform }) => !!transform && transform};
`
