import React from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import { redirectHomePage } from '../helpers/Common'
import SubmitButton from '../moesia/components/SubmitButton'
import useIsMobile from '../moesia/hooks/useIsMobile'

const NoAccessPage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth align="center" justify="center" gap={Spacings.large} left right $isMobile={isMobile}>
      <ImageContainer fullWidth imageKey={Images.noAccessImage} minHeight={!isMobile} />
      <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.SorryNoAccessHeading} />
        <TextContainer textKey={Texts.SorryNoAccessSubHeading} />
        <SubmitButton onClick={redirectHomePage} textKey={Texts.HomePageButton} variant="primary" />
      </StyledBox>
    </Wrapper>
  )
}

export default React.memo(NoAccessPage)

const Wrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => !$isMobile && '100vh'};
  margin-top: ${({ $isMobile }) => $isMobile && `-${Spacings.max}`};
`
