import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import deleteImageIcon from '../figma/images/deleteImageIcon'
import documentOptionsIconDesktop from '../figma/images/documentOptionsIconDesktop'
import eyeDisplay from '../figma/images/eyeDisplay'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { handleDeleteConfirmation, handleDeleteImage, handleExpandImage } from '../helpers/ImageActionsHelper'
import { useDeleteImage } from '../helpers/QueryHooks'
import { TervelImage } from '../interfaces/Dto'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from '../moesia/Constants'
import {
  IMAGE_ITEM_FIGMA_HEIGHT_MOBILE,
  IMAGE_ITEM_FIGMA_SIZE_DESKTOP,
  IMAGE_ITEM_FIGMA_WIDTH_MOBILE
} from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import ExpandedImageModal from './ExpandedImageModal'
import GenericConfirmationModal from './GenericConfirmationModal'

type Props = {
  image: TervelImage
}

const ImageItem: React.FC<Props> = ({ image }) => {
  const deleteImageMutation = useDeleteImage()
  const isMobile = useIsMobile()
  const { _id, name, signedUrl } = image
  const [showImageOptions, setShowImageOptions] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [isExpandedImage, setIsExpandedImage] = useState(false)

  return (
    <>
      <StyledBox position="relative">
        <ImageView key={_id} $isMobile={isMobile}>
          <ImageElement src={signedUrl} />
        </ImageView>
        <OptionsButton
          position="absolute"
          top={Spacings.minimum}
          right={Spacings.smallest}
          onClick={() => setShowImageOptions(!showImageOptions)}
        >
          <SVGContainer SVG={documentOptionsIconDesktop} />
        </OptionsButton>
        {showImageOptions && (
          <ImageOptions
            fullWidth
            direction="row"
            position="absolute"
            justify="center"
            align="center"
            gap={Spacings.tiny}
          >
            <SVGWrapper
              top={Spacings.smallest}
              title="View"
              onClick={() => handleExpandImage(isExpandedImage, setIsExpandedImage)}
              justify="center"
              align="center"
              $isMobile={isMobile}
            >
              <SVGContainer SVG={eyeDisplay} />
            </SVGWrapper>
            <SVGWrapper
              title="Delete"
              onClick={() => handleDeleteConfirmation(showDeleteConfirmation, setShowDeleteConfirmation)}
              justify="center"
              align="center"
              $isMobile={isMobile}
            >
              <SVGContainer SVG={deleteImageIcon} />
            </SVGWrapper>
          </ImageOptions>
        )}
      </StyledBox>
      {isExpandedImage && (
        <ExpandedImageModal
          isOpen
          onClose={() => handleExpandImage(isExpandedImage, setIsExpandedImage)}
          imageName={name}
          imageUrl={signedUrl}
          downloadButton
        />
      )}
      {showDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={() => handleDeleteConfirmation(showDeleteConfirmation, setShowDeleteConfirmation)}
          onConfirm={() => handleDeleteImage(deleteImageMutation, _id)}
          confirmationTitle={Texts.ConfirmDeleteImageTitle}
          confirmationQuestion={Texts.ConfirmDeleteImageQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize={isMobile}
        />
      )}
    </>
  )
}

export default ImageItem

const ImageView = styled(StyledBox)<{ $isMobile: boolean; $showImageOptions: boolean }>`
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseHeavy};
  height: ${({ $isMobile }) => ($isMobile ? IMAGE_ITEM_FIGMA_HEIGHT_MOBILE : IMAGE_ITEM_FIGMA_SIZE_DESKTOP)};
  width: ${({ $isMobile }) => ($isMobile ? IMAGE_ITEM_FIGMA_WIDTH_MOBILE : IMAGE_ITEM_FIGMA_SIZE_DESKTOP)};
  transition: ${REGULAR_TRANSITION};

  img {
    border-radius: ${BorderRadius.soft};
    object-fit: cover;
    height: 100%;
  }

  &:hover {
    transform: ${({ $isMobile }) => !$isMobile && REGULAR_TRANSFORM};
  }
`

const ImageElement = styled.img``

const ImageOptions = styled(StyledBox)`
  background-color: ${FigmaColors.blackDarkenedBackground};
  border-radius: ${BorderRadius.soft};
  inset: 0;
`

const SVGWrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => !$isMobile && Spacings.big};
  width: ${({ $isMobile }) => !$isMobile && Spacings.big};
  cursor: ${({ $isMobile }) => !$isMobile && 'pointer'};
  border-radius: ${({ $isMobile }) => !$isMobile && BorderRadius.circle};

  &:hover {
    background-color: ${({ $isMobile }) => !$isMobile && FigmaColors.gray};
  }
`

const OptionsButton = styled(StyledBox)`
  z-index: ${ZIndex.minimum};
  right: 0;
  cursor: pointer;
  background-color: ${FigmaColors.white};
  border-radius: 0 ${BorderRadius.soft};
`
