/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import MUIRichTextEditor from 'mui-rte'
import { Control, Controller, Path, PathValue } from 'react-hook-form'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import MediaQueries from 'src/figma/tokens/MediaQueries'
import Spacings from 'src/figma/tokens/Spacings'
import { getText } from '../../figma/helpers/TextRepository'
import ZIndex from '../../figma/tokens/ZIndex'
import useLanguage from '../../hooks/useLanguage'
import { ADD_EMPLOYEE_FORM_MOBILE_TEXTAREAS_HEIGHT } from '../StrongHardCodedSizes'
import { TextType } from '../interfaces/FigmaTypes'
import StyledBox from './StyledBox'

function EditorController<T extends Record<string, unknown>>({
  name,
  control,
  labelTextKey,
  defaultValue,
  customHeight
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  disabled?: boolean
  loading?: boolean
  labelText?: string
  defaultValue?: PathValue<T, Path<T>>
  customHeight?: string
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value } }: any) => (
        <EditorWrapper fullWidth fullPadding spacing={Spacings.min} $customHeight={customHeight}>
          <MUIRichTextEditor
            onChange={onChange}
            defaultValue={defaultValue}
            {...({ editorState: value } as any)}
            label={labelTextKey ? getText(labelTextKey, language) : ''}
            controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'numberList', 'bulletList', 'quote', 'code', 'link']}
          />
        </EditorWrapper>
      )}
      control={control}
      name={name}
    />
  )
}

const EditorWrapper = styled(StyledBox)<{ $customHeight?: string }>`
  border-radius: ${BorderRadius.soft};
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: ${FigmaColors.white};
  min-height: ${({ $customHeight }) => (!!$customHeight ? $customHeight : ADD_EMPLOYEE_FORM_MOBILE_TEXTAREAS_HEIGHT)};
  max-height: ${({ $customHeight }) => (!!$customHeight ? $customHeight : ADD_EMPLOYEE_FORM_MOBILE_TEXTAREAS_HEIGHT)};
  overflow: auto;

  * {
    position: relative !important;
  }
  .MUIRichTextEditor-container-46 {
    display: flex;
    flex-direction: column;
    gap: ${Spacings.min};
  }
  .MUIRichTextEditor-container-2 {
    margin: 0 !important;
  }
  #mui-rte-toolbar {
    display: flex;
    position: sticky !important;
    top: -${Spacings.min};
    z-index: ${ZIndex.high};
    background-color: ${FigmaColors.white};
    padding-bottom: ${Spacings.minimum} !important;

    button {
      padding: ${Spacings.minimum};
    }
  }
  #mui-rte-container {
    margin: 0 !important;

    .MuiButtonBase-root {
      z-index: 10;
      padding-left: 0;
      padding-bottom: 0;
      svg {
        @media (max-width: ${MediaQueries.xs}px) {
          width: 20px;
        }

        @media (max-width: ${MediaQueries.xxs}px) {
          width: 18px;
        }
      }
    }
  }
`

export default EditorController
