/* eslint-disable no-magic-numbers */
import { MAX_FILE_SIZE_ACCEPTED } from '../Constants'
import { generateUUID, getFileTypes } from '../helpers/Common'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { Document } from '../interfaces/Dto'
import DocumentItem from './DocumentItem'
import DragAndDropZone from './DragAndDropZone'

type Props = {
  fullWidth?: boolean
  onChange: (files: File[]) => void
  files: Document[]
  onDeleteDocument?: (documentId: string) => void
  uploadingProgress?: UploadingProgress
  fileType?: string
}

const UploadFileContainer: React.FC<Props> = ({
  fullWidth,
  onChange,
  files,
  onDeleteDocument,
  uploadingProgress,
  fileType
}) => (
  <>
    <DragAndDropZone
      fullWidth={fullWidth}
      onChange={onChange}
      fileTypes={getFileTypes(fileType)}
      fileSize={MAX_FILE_SIZE_ACCEPTED}
    />
    {!!files?.length &&
      files.map((document) => (
        <DocumentItem
          data-testid="document-container"
          key={generateUUID()}
          onDeleteDocument={onDeleteDocument}
          uploadingProgress={uploadingProgress}
          document={document}
          isUploading
        />
      ))}
  </>
)

export default UploadFileContainer
