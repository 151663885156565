import StyledBox from './StyledBox'
import TextContainer from './TextContainer'
import { TextType } from '../interfaces/FigmaTypes'
import { Texts } from 'src/figma/helpers/TextRepository'

const NotificationDefault = (defaultMessage: TextType) => (
  <StyledBox>
    <TextContainer textKey={Texts.NotificationDefault} />
    <TextContainer textKey={defaultMessage} />
  </StyledBox>
)

export default NotificationDefault
