import { useContext } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import archiveIcon from 'src/figma/images/archiveIcon'
import editIcon from 'src/figma/images/editIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { getEmployeeDetailsItems } from 'src/helpers/EmployeeDetailsHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { UserType } from '../enums/UserType'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import deleteImageIcon from '../figma/images/deleteImageIcon'
import invitationAcceptedIcon from '../figma/images/invitationAcceptedIcon'
import invitationPendingIcon from '../figma/images/invitationPendingIcon'
import remindButtonIcon from '../figma/images/remindButtonIcon'
import ZIndex from '../figma/tokens/ZIndex'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { hasAccess } from '../helpers/UsersHelper'
import { User } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileDetailsBox from './MobileDetailsBox'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  employee: User | null
  handleDeleteButton: () => void
  startingDateText: string
  positionAndCompany: string
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
  currentUserRole: UserType
  isNotRegularUser: boolean
  handleRemindButton: () => void
  isInvitationAccepted: boolean
}

const EmployeeDetailsMobile: React.FC<Props> = ({
  open,
  onClose,
  employee,
  handleDeleteButton,
  startingDateText,
  positionAndCompany,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation,
  currentUserRole,
  isNotRegularUser,
  handleRemindButton,
  isInvitationAccepted
}) => {
  const { setSubstate } = useContext(PageSubstateContext)
  const handleEditButton = () => {
    setSubstate(PageSubstate.EDIT)
  }
  if (!employee) return null
  const { name, archived, invitationStatus } = employee

  const employeeDetailsItems = getEmployeeDetailsItems(employee, positionAndCompany)

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteEmployeeTitle}
          confirmationQuestion={Texts.ConfirmDeleteEmployeeQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateEmployeeTitle : Texts.ConfirmArchiveEmployeeTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateEmplpyeeProfileQuestion : Texts.ConfirmArchiveEmployeeQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={onClose}
        buttons={
          isNotRegularUser && (
            <ModalButtons
              primaryButton={
                <MobileSubmitButton
                  textKey={!!archived ? Texts.ConfirmDeleteButtonText : Texts.EditButtonText}
                  buttonIcon={!!archived ? deleteImageIcon : editIcon}
                  textBackgroundColor={!!archived ? FigmaColors.darkerRed : FigmaColors.bhBluePrimaryButton}
                  iconBackgroundColor={!!archived ? FigmaColors.lighterRed : FigmaColors.bhBlueMedium}
                  onClick={!!archived ? handleShowDeleteConfirmation : handleEditButton}
                />
              }
              tertiaryButton={
                <MobileSubmitButton
                  textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                  buttonIcon={!!archived ? activeIcon : archiveIcon}
                  textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                  iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                />
              }
            />
          )
        }
      >
        <StyledBox fullWidth gap={Spacings.big}>
          <StyledBox fullWidth align="center" position="relative">
            <StyledImageContainer imageKey={Images.employeeDetailsMobileBackground} />
            <BasicInformation align="center" justify="center" position="absolute">
              <AvatarInitials name={employee.name} border />
              <StyledBox align="center" justify="center" top={Spacings.min}>
                <TextContainer textKey={Texts.EmployeeDetailsName} text={name} />
                <StyledBox direction="row" gap={Spacings.minimum} justify="center">
                  <TextContainer textKey={Texts.EmployeeDetailsStartDate} text={startingDateText} />
                </StyledBox>
              </StyledBox>
            </BasicInformation>
          </StyledBox>
          <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.medium}>
            {!!employeeDetailsItems.length &&
              employeeDetailsItems.map(
                ({ itemIcon, itemTitle, itemInfo, permissions }, index) =>
                  (!permissions || hasAccess(permissions, currentUserRole)) && (
                    <MobileDetailsBox
                      key={index}
                      itemIcon={itemIcon}
                      itemTitle={itemTitle}
                      itemInfo={itemInfo as any}
                    />
                  )
              )}
            {!archived && (
              <MobileDetailsBox
                itemIcon={isInvitationAccepted ? invitationAcceptedIcon : invitationPendingIcon}
                itemTitle={Texts.EmployeeDetailsInvitationTitle}
                itemInfo={invitationStatus}
                itemButton={
                  !isInvitationAccepted && (
                    <MobileSubmitButton
                      textKey={Texts.RemindButtonText}
                      buttonIcon={remindButtonIcon}
                      textBackgroundColor={FigmaColors.green1}
                      iconBackgroundColor={FigmaColors.green2}
                      onClick={handleRemindButton}
                    />
                  )
                }
              />
            )}
          </StyledBox>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default EmployeeDetailsMobile

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const BasicInformation = styled(StyledBox)`
  bottom: -30px;
`
