import React, { useState } from 'react'
import navigationMenuIcon from 'src/figma/images/navigationMenuIcon'
import Spacings from 'src/figma/tokens/Spacings'
import { MOBILE_HEADER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'
import ZIndex from '../figma/tokens/ZIndex'
import MobileMenu from './MobileMenu'

const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Wrapper fullWidth $isMenuOpen={isMenuOpen}>
      {!isMenuOpen && (
        <Wrapper fullWidth fullPadding direction="row" spacing={Spacings.small} align="center" justify="flex-end">
          <StyledBox onClick={() => setIsMenuOpen(true)}>
            <SVGContainer SVG={navigationMenuIcon} />
          </StyledBox>
        </Wrapper>
      )}
      {!!isMenuOpen && <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}
    </Wrapper>
  )
}

export default MobileHeader

const Wrapper = styled(StyledBox)<{ $isMenuOpen: boolean }>`
  height: ${MOBILE_HEADER_HEIGHT};
  z-index: ${({ $isMenuOpen }) => ($isMenuOpen ? ZIndex.heaven : ZIndex.modal)};
  background-color: transparent;
`
