import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import RouterPrivateRoutes from './RouterPrivateRoutes'
import RouterPublicRoutes from './RouterPublicRoutes'
import { useAppState, useUser } from './components/AuthProvider'
import NoAccessPage from './components/NoAccessPage'
import routePaths from './config/RoutePaths'
import { UserType } from './enums/UserType'
import { hasAccess } from './helpers/UsersHelper'
import LoginPage from './pages/LoginPage'
import OverviewPage from './pages/OverviewPage'

const Router: React.FC = () => {
  const { isAuthenticated } = useAppState()
  const currentUserRole = useUser().role

  const canAccessRoute = (requiredPermissions: UserType[]) => {
    const isPageRestricted = !!requiredPermissions && !!requiredPermissions.length

    return !isPageRestricted || hasAccess(requiredPermissions, currentUserRole)
  }

  return (
    <AnimatePresence initial={false}>
      <BrowserRouter>
        <Routes>
          <Route path={routePaths.entry} element={isAuthenticated ? <OverviewPage /> : <LoginPage />} />
          {RouterPrivateRoutes.map(({ component: Component, path, permissions, title }) => (
            <Route
              path={path}
              key={path}
              element={
                isAuthenticated ? (
                  <>
                    {canAccessRoute(permissions) && <Component />}
                    {!canAccessRoute(permissions) && <NoAccessPage />}
                    <Helmet title={title} />
                  </>
                ) : (
                  <Navigate to={routePaths.entry} />
                )
              }
            />
          ))}
          {RouterPublicRoutes.map(({ component: Component, path }) => (
            <Route path={path} key={path} element={<Component />} />
          ))}
          <Route path="*" element={!isAuthenticated ? <Navigate to={routePaths.entry} /> : <OverviewPage />} />
        </Routes>
      </BrowserRouter>
    </AnimatePresence>
  )
}

export default React.memo(Router)
