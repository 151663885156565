import { yupResolver } from '@hookform/resolvers/yup'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged } from '../helpers/Common'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useTemplates, useUploadTemplate } from '../helpers/QueryHooks'
import { createTemplateFormData } from '../helpers/TemplatesHelper'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'

import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddTemplateFormDesktop from './AddTemplateFormDesktop'
import AddTemplateFormMobile from './AddTemplateFormMobile'
import DevHarbourLoader from './DevHarbourLoader'

type Props = {
  open: boolean
  onClose: () => void
}

const AddTemplateFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const { isLoading } = useTemplates()
  const uploadTemplate = useUploadTemplate()
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [name, setName] = useState('')
  const [wikiUrl, setWikiUrl] = useState('')
  const [isUploadContainerBorderRed, setIsUploadContainerBorderRed] = useState(false)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)

  const defaultValues = useMemo(
    () => ({
      name: '',
      wikiUrl: '',
      files: []
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddTemplateNameErrorRequired, language)),
        wikiUrl: createRequiredStringYupValidation(getText(Texts.AddTemplateWikiUrlErrorRequired, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isSubmitSuccessful }
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const { files } = watch()

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { files, name, wikiUrl } = data
    if (!files) {
      setName(name)
      setWikiUrl(wikiUrl)
      setIsUploadContainerBorderRed(true)
    }

    if (!!files && !!files.length) {
      const newTemplate = files.shift()
      const fileData = createTemplateFormData({
        name: 'document',
        file: newTemplate,
        customName: name,
        wikiUrl: wikiUrl
      })

      uploadTemplate
        .mutateAsync(fileData)
        .then(() => {
          successNotification(Texts.NotificationAddTemplateSuccessful)
          setSubstate(PageSubstate.DEFAULT)
        })
        .catch((err: Error) => {
          handleErrorMessage(err.message)
        })
    }
  }

  const handleRemoveTemplateFromFiles = (documentTitle: string) => {
    setValue('files', [...files.filter((file: File) => file.name !== documentTitle)])
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ defaultValues })
    }
  }, [defaultValues, isSubmitSuccessful, reset])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  useEffect(() => {
    !!files && setIsUploadContainerBorderRed(false)
    if (!watch('name') && !watch('wikiUrl')) {
      setValue('name', name)
      setValue('wikiUrl', wikiUrl)
    }
  }, [files, watch()])

  return (
    <>
      {isLoading && <DevHarbourLoader />}
      {!isLoading && !isMobile && (
        <AddTemplateFormDesktop
          control={control}
          open={open}
          onClose={onClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          substate={substate}
          setTemplate={(newFiles = []) => setValue('files', [...(files || []), ...newFiles])}
          removeTemplate={(documentTitle) => handleRemoveTemplateFromFiles(documentTitle)}
          files={files}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isUploadContainerBorderRed={isUploadContainerBorderRed}
          isValuesChanged={isValuesChanged}
        />
      )}
      {!isLoading && isMobile && (
        <AddTemplateFormMobile
          control={control}
          open={open}
          onClose={onClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          substate={substate}
          setTemplate={(newFiles = []) => setValue('files', [...(files || []), ...newFiles])}
          removeTemplate={(documentTitle) => handleRemoveTemplateFromFiles(documentTitle)}
          files={files}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isUploadContainerBorderRed={isUploadContainerBorderRed}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddTemplateFormContainer
