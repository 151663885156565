import React, { SVGProps } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import { SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT } from '../moesia/StrongHardCodedSizes'

type Props = {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconMobile: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  arrow?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  url?: string
  onClick?: () => void
  className?: string
  active?: boolean
}

const SidebarAccountMenuItem: React.FC<Props> = ({ icon, iconMobile, textKey, arrow, onClick, className, active }) => {
  const isMobile = useIsMobile()

  return (
    <MenuItem
      onClick={onClick}
      className={className}
      direction="row"
      fullWidth
      fullPadding
      spacing={Spacings.min}
      align="center"
      justify="space-between"
      $active={active}
    >
      <StyledBox direction="row" gap={Spacings.min} align="center">
        <SVGContainer SVG={isMobile ? iconMobile : icon} className="icon" />
        <TextContainer textKey={textKey} />
      </StyledBox>
      <StyledBox align="center">{!!arrow && <SVGContainer SVG={arrow} className="icon" />}</StyledBox>
    </MenuItem>
  )
}

export default SidebarAccountMenuItem

const MenuItem = styled(StyledBox)<{ $active?: boolean }>`
  cursor: pointer;
  border-radius: ${BorderRadius.soft};
  background-color: ${({ $active }) => $active && FigmaColors.surfaceOnWhite};

  span {
    font-weight: ${({ $active }) => $active && SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT};
  }

  svg path {
    fill: ${({ $active }) => $active && `${FigmaColors.grayDark} !important`};
  }

  .icon {
    max-height: 18px !important; //TODO remove when we fix the svg container to not add additional height of the span
  }
`
