import React, { useState } from 'react'
import styled from 'styled-components'
import { getText, Texts } from '../figma/helpers/TextRepository'
import uploadDocumentsIcon from '../figma/images/uploadDocumentsIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { convertFileSize, DocumentFileTypes, ImageFileTypes } from '../helpers/DocumentsHelper'
import useLanguage from '../hooks/useLanguage'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { replaceTextParams } from '../moesia/helpers/Common'
import { errorNotification } from '../moesia/helpers/NotificationsHelper'
import { DROPZONE_WIDTH } from '../moesia/StrongHardCodedSizes'

function testType(files: File[], fileTypes: string[] = []) {
  if (!fileTypes.length) return false
  const acceptedFiles = files.filter((file) => fileTypes.some((fileType) => file.type === fileType))
  return acceptedFiles.length === 0
}

function testSize(files: File[], fileSize = 0) {
  if (!fileSize) return false
  for (const file of files) {
    if (file.size > fileSize) return true
  }
  return false
}

function testLimit(files: File[], fileLimit = 0) {
  if (!fileLimit) return false
  return files.length > fileLimit
}

type StyledDropzoneProps = {
  fullWidth?: boolean
  onChange?: (files: File[]) => void
  fileTypes: string[]
  fileSize: number
  fileLimit?: number
  headerText?: string
  buttonText?: string
}

function DragAndDropZone({ fullWidth, onChange, fileTypes, fileSize, fileLimit }: StyledDropzoneProps) {
  const language = useLanguage()
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [isDragging, setIsDragging] = useState(false)

  const onClickSelect = () => {
    inputRef.current?.click()
  }

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || [])

    if (testType(files, fileTypes)) {
      const keys = Object.values(fileTypes).includes('image/jpeg')
        ? Object.keys(ImageFileTypes)
        : Object.keys(DocumentFileTypes)
      return errorNotification(
        Texts.DocumentTypeNotSupported,
        replaceTextParams(getText(Texts.DocumentTypeNotSupported, language), [keys.join(', ')])
      )
    }
    if (testSize(files, fileSize)) {
      return errorNotification(
        Texts.DocumentSizeExceeded,
        replaceTextParams(getText(Texts.DocumentSizeExceeded, language), [convertFileSize(fileSize)])
      )
    }
    if (testLimit(files, fileLimit)) {
      return errorNotification(
        Texts.DocumentsLimitExceeded,
        replaceTextParams(getText(Texts.DocumentsLimitExceeded, language), [String(fileLimit)])
      )
    }

    event.preventDefault()
    if (onChange) onChange(files)

    if (inputRef.current) inputRef.current.value = ''
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const files = Array.from(event.dataTransfer.files || [])

    if (testType(files, fileTypes)) {
      setIsDragging(false)
      return errorNotification(
        Texts.DocumentTypeNotSupported,
        replaceTextParams(getText(Texts.DocumentTypeNotSupported, language), [
          Object.keys(DocumentFileTypes).join(', ')
        ])
      )
    }

    if (testSize(files, fileSize)) {
      setIsDragging(false)
      return errorNotification(
        Texts.DocumentSizeExceeded,
        replaceTextParams(getText(Texts.DocumentSizeExceeded, language), [convertFileSize(fileSize)])
      )
    }

    if (testLimit(files, fileLimit)) {
      setIsDragging(false)
      return errorNotification(
        Texts.DocumentsLimitExceeded,
        replaceTextParams(getText(Texts.DocumentsLimitExceeded, language), [String(fileLimit)])
      )
    }

    if (onChange) onChange(files)
    setIsDragging(false)
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }
  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }
  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
  }

  return (
    <Wrapper
      $fullWidth={fullWidth}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={isDragging ? 'dragging' : ''}
      fullPadding
      spacing={Spacings.min}
      gap={Spacings.tiny}
      align="center"
      justify="center"
      onClick={onClickSelect}
    >
      <SVGContainer SVG={uploadDocumentsIcon} />
      <StyledBox gap={Spacings.min} align="center" justify="center" alignText="center">
        <TextContainer textKey={Texts.DragAndDropDocuments} />
        <TextContainer textKey={Texts.MaxFileSize} />
        <InvisibleInput onChange={onSelect} ref={inputRef} type="file" />
      </StyledBox>
    </Wrapper>
  )
}

export default React.memo(DragAndDropZone)

const Wrapper = styled(StyledBox)<{ $fullWidth?: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : DROPZONE_WIDTH)};
  background: ${FigmaColors.lightBlueBackground};
  border: 1px dashed ${FigmaColors.bhBlue};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseSoft};
  cursor: pointer;

  &.dragging {
    background: ${FigmaColors.blueHover};
  }
`

const InvisibleInput = styled.input`
  display: none;
`
