import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from 'react-query'

const useDeleteMutation = <TData, TVariables>(
  queryKey: string[],
  fn: MutationFunction<TData, TVariables>,
  invalidateCacheKeyPrefix?: MutationKey[]
) => {
  const queryClient = useQueryClient()
  return _useMutation(fn, {
    onMutate: async (item) => {
      await queryClient.cancelQueries(queryKey)
      // TODO use partial and add ID
      const previous: any[] = queryClient.getQueryData(queryKey) || []

      // If its a single value, just return the new single item
      if (!Array.isArray(previous)) {
        return { previous, data: item }
      }

      const data = previous.filter((row) => {
        // Check if we have an id
        if (row?.id) {
          return row.id !== item
        }

        // Otherwise try match against the values
        return Object.values(row).every((value) => value !== item)
      })

      queryClient.setQueryData(queryKey, data)
      return { previous, data }
    },
    // Benhind the scenes call service
    onError: (err: Error, newValue: TVariables, context: any) => {
      queryClient.setQueryData(queryKey, context?.previous)
    },
    onSettled: (data, err) => {
      if (err) return

      data && queryClient.setQueryData(queryKey, data)

      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries(key))
      }
    }
  })
}

export default useDeleteMutation
