import { Fragment, memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import { SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT, SIDEBAR_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import SVGContainer from 'src/moesia/components/SVGContainer'
import styled from 'styled-components'
import Spacings from '../figma/tokens/Spacings'
import { generateUUID, isButtonActive, isSubButton } from '../helpers/Common'
import { SubButton } from '../helpers/SidebarHelper'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'

type Props = {
  subButtons: SubButton[]
  handleCloseMenu?: () => void
}

const SidebarSubButton = ({ subButtons, handleCloseMenu }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (url?: string) => {
    if (location.pathname === url && !!handleCloseMenu) {
      handleCloseMenu()
      return
    }

    if (!!url) navigate(url)
  }

  return (
    <>
      {subButtons.map(({ icon, textKey, url, type }) => (
        <Fragment key={generateUUID()}>
          {isSubButton(type) && !!url && (
            <ButtonStylesProvider
              fullWidth
              align="center"
              left={Spacings.big}
              onClick={() => handleClick(url)}
              direction="row"
              justify="flex-start"
              gap={Spacings.min}
              className={isButtonActive(url, location.pathname)}
            >
              {!!icon && <SVGContainer SVG={icon} />}
              <TextContainer textKey={textKey} />
            </ButtonStylesProvider>
          )}
        </Fragment>
      ))}
    </>
  )
}

export default memo(SidebarSubButton)

const ButtonStylesProvider = styled(StyledBox)`
  height: ${SIDEBAR_BUTTON_HEIGHT};
  cursor: pointer;
  border-radius: ${BorderRadius.soft};
  &:hover {
    svg path {
      fill: ${FigmaColors.grayDark} !important;
    }
  }
  &.active {
    span {
      font-weight: ${SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT};
    }
    svg path {
      fill: ${FigmaColors.grayDark} !important;
    }
  }
`
