import { memo } from 'react'
import styled from 'styled-components'
import BorderRadius from '../../figma/tokens/BorderRadius'
import FigmaColors from '../../figma/tokens/FigmaColors'
import Shadows from '../../figma/tokens/Shadows'
import Spacings from '../../figma/tokens/Spacings'
import { TervelImage } from '../../interfaces/Dto'
import { TERVEL_NEWS_CARD_MOBILE_HEIGHT, TERVEL_NEWS_CARD_MOBILE_WIDTH } from '../StrongHardCodedSizes'
import NewsCardsMobileDisplayContent from './NewsCardsMobileDisplayContent'
import StyledBox from './StyledBox'
import UploadedImageContainer from './UploadedImageContainer'

type Props = {
  textKey: string
  image: TervelImage
  isBackSide?: boolean
  backTextLink?: string
}

const NewsCardMobileComponent = ({ textKey, image, isBackSide, backTextLink }: Props) => (
  <MobileCard align="center" alignText="center" isBackSide={isBackSide}>
    <StyledBox top={Spacings.large} left right spacing={isBackSide ? Spacings.small : Spacings.large}>
      <NewsCardsMobileDisplayContent textKey={textKey} isBackSide={isBackSide} href={backTextLink} />
    </StyledBox>
    <StyledBox top spacing={isBackSide ? Spacings.small : Spacings.huge}>
      <UploadedImageContainer image={image} />
    </StyledBox>
  </MobileCard>
)

export default memo(NewsCardMobileComponent)

const MobileCard = styled(StyledBox)<{ isBackSide: boolean }>`
  width: ${TERVEL_NEWS_CARD_MOBILE_WIDTH};
  height: ${TERVEL_NEWS_CARD_MOBILE_HEIGHT};
  box-shadow: ${Shadows.baseHeavy};
  border-radius: ${BorderRadius.soft};
  background-color: ${({ isBackSide }) => isBackSide && `${FigmaColors.bhBlue}`};
`
