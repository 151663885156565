import { Control, FieldValues } from 'react-hook-form'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { AutoCompleteController, CheckboxController } from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import ExpandedImageModal from './ExpandedImageModal'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  control: Control<FieldValues, any>
  onAddClick: () => void
  imageName: string | null
  imageUrl: string | null
  handleImageViewClose: () => void
  frontImages: {
    value: string
    label: string
  }[]
  backImages: {
    value: string
    label: string
  }[]
  setImageName: React.Dispatch<React.SetStateAction<string | null>>
  setImageUrl: React.Dispatch<React.SetStateAction<string | null>>
  areImagesLoading: boolean
  handleShowConfirmation: () => void
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddNewsCardFormMobileView: React.FC<Props> = ({
  open,
  onClose,
  control,
  onAddClick,
  imageName,
  imageUrl,
  handleImageViewClose,
  frontImages,
  backImages,
  setImageName,
  setImageUrl,
  areImagesLoading,
  handleShowConfirmation,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        confirmButtonColor={FigmaColors.bhBluePrimaryButton}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    {!!imageUrl && !!imageName && (
      <ExpandedImageModal isOpen onClose={handleImageViewClose} imageName={imageName} imageUrl={imageUrl} />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddButtonText}
              onClick={onAddClick}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <StyledBox fullPadding spacing={Spacings.medium} fullWidth>
        <form>
          <StyledBox fullWidth fullPadding spacing={Spacings.medium} gap={Spacings.medium}>
            <TextFieldController name="frontText" control={control} labelTextKey={Texts.AddFrontTextLabel} />
            <TextFieldController name="backText" control={control} labelTextKey={Texts.AddBackTextLabel} />
            <TextFieldController name="backTextLink" control={control} labelTextKey={Texts.AddLinkLabel} />
            {!areImagesLoading && (
              <AutoCompleteController
                name="frontImage"
                control={control}
                options={frontImages}
                labelTextKey={Texts.AddFrontImageLabel}
                setImageName={setImageName}
                setImageUrl={setImageUrl}
                showViewButton
              />
            )}
            {!areImagesLoading && (
              <AutoCompleteController
                name="backImage"
                control={control}
                options={backImages}
                labelTextKey={Texts.AddBackImageLabel}
                setImageName={setImageName}
                setImageUrl={setImageUrl}
                showViewButton
              />
            )}
            <CheckboxController name="active" control={control} labelTextKey={Texts.NewsCardShowInTervel} />
          </StyledBox>
        </form>
      </StyledBox>
    </GenericPageModal>
  </>
)

export default AddNewsCardFormMobileView
