import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import deleteDocumentIcon from '../figma/images/deleteDocumentIcon'
import editDocumentIcon from '../figma/images/editDocumentIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { handleChangeStatus } from '../helpers/NewsCardsStatusHelper'
import { useUpdateNewsCard } from '../helpers/QueryHooks'
import { NewsCardDto } from '../interfaces/Dto'
import NewsCard from '../moesia/components/NewsCard'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import GenericConfirmationModal from './GenericConfirmationModal'
type Props = {
  newsCard: NewsCardDto
  onDelete: () => void
  onEdit: () => void
}

const NewsCardItem: React.FC<Props> = ({ newsCard, onEdit, onDelete }) => {
  const updateNewsCard = useUpdateNewsCard(newsCard._id)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)
  const [shouldShowStatusConfirmationModal, setShouldShowStatusConfirmationModal] = useState(false)
  const status = newsCard.active ? Texts.ActiveInTervel : Texts.InactiveInTervel

  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)

  const handleShowStatusConfirmation = () => setShouldShowStatusConfirmationModal(!shouldShowStatusConfirmationModal)

  const triggerStatusUpdate = () => handleChangeStatus(newsCard, updateNewsCard, handleShowStatusConfirmation)

  return (
    <>
      {shouldShowDeleteConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={onDelete}
          confirmationTitle={Texts.ConfirmDeleteNewsCardTitle}
          confirmationQuestion={Texts.ConfirmDeleteNewsCardQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
        />
      )}
      {shouldShowStatusConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowStatusConfirmation}
          onConfirm={triggerStatusUpdate}
          confirmationTitle={Texts.ConfirmCardStatus}
          confirmationQuestion={newsCard.active ? Texts.ConfirmNotVisibleToTervel : Texts.ConfirmVisibleToTervel}
          confirmButtonTextKey={Texts.ConfirmChangeStatusButtonText}
        />
      )}
      <Wrapper position="relative" bottom spacing={Spacings.big}>
        <Status position="absolute" fullWidth direction="row" align="center" gap={Spacings.minimum}>
          <StyledBox onClick={handleShowStatusConfirmation}>
            <StatusCircle $active={newsCard.active} />
          </StyledBox>
          <TextContainer textKey={status} />
        </Status>
        <NewsCard newsCard={newsCard} />
        <OptionsWrapper position="absolute" align="center" justify="center">
          <StyledBox direction="row" fullPadding spacing={Spacings.minimum} gap={Spacings.min}>
            <SVGWrapper onClick={handleShowDeleteConfirmation}>
              <StyledSVGContainer SVG={deleteDocumentIcon} />
            </SVGWrapper>
            <SVGWrapper onClick={onEdit}>
              <StyledSVGContainer SVG={editDocumentIcon} />
            </SVGWrapper>
          </StyledBox>
        </OptionsWrapper>
      </Wrapper>
    </>
  )
}
export default NewsCardItem

const Wrapper = styled(StyledBox)`
  background-color: ${FigmaColors.lightStone};
  border-radius: ${BorderRadius.soft} ${BorderRadius.soft} 0 ${BorderRadius.soft};
`

const SVGWrapper = styled(StyledBox)`
  z-index: ${ZIndex.minimum};
  cursor: pointer;
  border-radius: ${BorderRadius.circle};

  :hover {
    background-color: ${FigmaColors.gray2};
  }
`

const StyledSVGContainer = styled(SVGContainer)`
  display: flex;
  align-items: center;
`

const OptionsWrapper = styled(StyledBox)`
  bottom: -${Spacings.large};
  right: 0;
  background-color: ${FigmaColors.lightStone};
  border-radius: 0 0 ${BorderRadius.soft} ${BorderRadius.soft};
`

const Status = styled(StyledBox)`
  top: -${Spacings.medium};
`

const StatusCircle = styled(StyledBox)<{ $active?: boolean }>`
  cursor: pointer;
  width: ${Spacings.min};
  height: ${Spacings.min};
  background-color: ${({ $active }) => ($active ? FigmaColors.baseGreen : FigmaColors.lighterRed)};
  border-radius: ${BorderRadius.circle};
`
