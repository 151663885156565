import React from 'react'
import AddUserFormContainer from 'src/components/AddUserFormContainer'
import BackOfficeLoader from 'src/components/BackOfficeLoader'
import EmployeesTable from 'src/components/EmployeesTable'
import EmptyStatePage from 'src/components/EmptyStatePage'
import SomethingWentWrongPage from 'src/components/SomethingWentWrongPage'
import UsersTable from 'src/components/UsersTable'
import { Texts } from 'src/figma/helpers/TextRepository'
import AddBlogPostContainer from '../components/AddBlogPostContainer'
import AddCompanyFormContainer from '../components/AddCompanyFormContainer'
import AddEmployeeFormContainer from '../components/AddEmployeeFormContainer'
import AddImageFormContainer from '../components/AddImageFormContainer'
import AddNewsCardFormContainer from '../components/AddNewsCardFormContainer'
import AddTemplateFormContainer from '../components/AddTemplateFormContainer'
import BlogPostsSectionContainer from '../components/BlogPostsSectionContainer'
import CompaniesTable from '../components/CompaniesTable'
import ImagesSectionContainer from '../components/ImagesSectionContainer'
import NewsCardsContainer from '../components/NewsCardsContainer'
import TemplatesSectionContainer from '../components/TemplatesSectionContainer'

export enum PageFlowState {
  LOADING = 'loading',
  EMPTY = 'empty',
  ERROR = 'error',
  DATA = 'data'
}

export const getPageCurrentFlow = (data: Array<any>, isError: boolean, isLoading: boolean, isSuccess: boolean) => {
  switch (true) {
    case isLoading:
      return PageFlowState.LOADING
    case isError:
      return PageFlowState.ERROR
    case isSuccess && data.length > 0:
      return PageFlowState.DATA
    case isSuccess && data.length === 0:
      return PageFlowState.EMPTY
    default:
      return PageFlowState.LOADING
  }
}

export const getUsersPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongUsers}
        button
        buttonTextKey={Texts.AddUserButtonText}
        modalComponent={AddUserFormContainer}
      />
    ),
    [PageFlowState.DATA]: <UsersTable users={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.UsersEmptyStateHeading}
        pageSubheading={Texts.UsersEmptyStateSubheading}
        buttonTextKey={Texts.UsersEmptyStateButtonText}
        mobileButtonTextKey={Texts.AddUserMobileButtonText}
        modalComponent={AddUserFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getEmployeesPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongEmployees}
        button
        buttonTextKey={Texts.AddEmployeeButtonText}
        modalComponent={AddEmployeeFormContainer}
      />
    ),
    [PageFlowState.DATA]: <EmployeesTable employees={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.EmployeesEmptyStateHeading}
        pageSubheading={Texts.EmployeesEmptyStateSubheading}
        buttonTextKey={Texts.EmployeesEmptyStateButtonText}
        mobileButtonTextKey={Texts.AddUserMobileButtonText}
        modalComponent={AddEmployeeFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getCompaniesPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongEmployees}
        button
        buttonTextKey={Texts.AddCompanyButtonText}
      />
    ),
    [PageFlowState.DATA]: <CompaniesTable companies={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.CompaniesEmptyStateHeading}
        pageSubheading={Texts.CompaniesEmptyStateSubheading}
        buttonTextKey={Texts.CompaniesEmptyStateButtonText}
        mobileButtonTextKey={Texts.AddUserMobileButtonText}
        modalComponent={AddCompanyFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getTemplatesPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongTemplates}
        button
        buttonTextKey={Texts.AddTemplateButtonText}
        modalComponent={AddTemplateFormContainer}
      />
    ),
    [PageFlowState.DATA]: <TemplatesSectionContainer templates={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.TemplatesEmptyStateHeading}
        pageSubheading={Texts.TemplatesEmptyStateSubheading}
        buttonTextKey={Texts.AddTemplateButtonText}
        mobileButtonTextKey={Texts.AddButtonText}
        modalComponent={AddTemplateFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getNewsCardsPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongNewsCards}
        button
        buttonTextKey={Texts.AddNewsCardButtonText}
        modalComponent={AddNewsCardFormContainer}
      />
    ),
    [PageFlowState.DATA]: <NewsCardsContainer newsCards={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.AddNewsCardEmptyStateHeading}
        pageSubheading={Texts.AddNewsCardEmptyStateSubHeading}
        buttonTextKey={Texts.AddNewsCardButtonText}
        mobileButtonTextKey={Texts.AddButtonText}
        modalComponent={AddNewsCardFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getImagesPageViews = (data: Array<any>, currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongAddImage}
        button
        buttonTextKey={Texts.AddImageButtonText}
        modalComponent={AddImageFormContainer}
      />
    ),
    [PageFlowState.DATA]: <ImagesSectionContainer images={data} />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.AddImageEmptyStateHeading}
        pageSubheading={Texts.AddImageEmptyStateSubHeading}
        buttonTextKey={Texts.AddImageButtonText}
        mobileButtonTextKey={Texts.AddButtonText}
        modalComponent={AddImageFormContainer}
      />
    )
  }
  return flows[currentPageFlow]
}

export const getBlogPostsPageViews = (currentPageFlow: PageFlowState) => {
  const flows: { [key in PageFlowState]: React.ReactNode } = {
    [PageFlowState.LOADING]: <BackOfficeLoader />,
    [PageFlowState.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongAddBlogPost}
        button
        buttonTextKey={Texts.AddBlogPostButtonText}
        modalComponent={AddBlogPostContainer}
      />
    ),
    [PageFlowState.DATA]: <BlogPostsSectionContainer />,
    [PageFlowState.EMPTY]: (
      <EmptyStatePage
        pageHeading={Texts.AddBlogPostsEmptyStateHeading}
        pageSubheading={Texts.AddNewsCardEmptyStateSubHeading}
        buttonTextKey={Texts.AddBlogPostButtonText}
        mobileButtonTextKey={Texts.AddButtonText}
        modalComponent={AddBlogPostContainer}
      />
    )
  }
  return flows[currentPageFlow]
}
