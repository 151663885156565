import React from 'react'
import { Control } from 'react-hook-form'
import { Texts } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from 'src/moesia/components/ReactHookComponents'
import StyledBox from 'src/moesia/components/StyledBox'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import ZIndex from '../figma/tokens/ZIndex'
import ImageContainer from '../moesia/components/ImageContainer'
import SubmitButton from '../moesia/components/SubmitButton'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  onClickSubmit: () => void
  control: Control<any>
  companyOptions: { label: string; value: string }[]
  positionOptions: { label: string; value: string }[]
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddEmployeeFormMobile: React.FC<Props> = ({
  open,
  onClose,
  onClickSubmit,
  control,
  positionOptions,
  companyOptions,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddButtonText}
              onClick={onClickSubmit}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <StyledBox fullWidth align="center" gap={Spacings.big}>
        <StyledBox fullWidth align="center" position="relative">
          <StyledImageContainer imageKey={Images.employeeDetailsMobileBackground} />
          <StyledGenericAvatar position="absolute">
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} border />
          </StyledGenericAvatar>
        </StyledBox>
        <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.small}>
          <TextFieldController name="name" control={control} labelTextKey={Texts.AddEmployeeNamePlaceholder} />
          <TextFieldController name="password" control={control} labelTextKey={Texts.AddUserPasswordLabel} />
          <TextFieldController name="email" control={control} labelText="Email" />
          <DatePickerController
            name="startingDate"
            control={control}
            labelTextKey={Texts.AddEmployeeStartDayPlaceholder}
          />
          <AutoCompleteController
            name="position"
            control={control}
            options={positionOptions}
            labelTextKey={Texts.AddEmployeePositionPlaceholder}
          />
          <SelectChipController
            name="companies"
            control={control}
            options={companyOptions}
            labelTextKey={Texts.AddEmployeeCompanyPlaceholder}
          />
          <DatePickerController
            name="birthDate"
            control={control}
            labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
          />
          <EditorController name="plan" labelTextKey={Texts.AddEmployeePlanPlaceholder} control={control} />
          <EditorController name="goals" labelTextKey={Texts.AddEmployeeGoalsPlaceholder} control={control} />
          <EditorController name="notes" labelTextKey={Texts.AddEmployeeNotesPlaceholder} control={control} />
        </StyledBox>
      </StyledBox>
    </GenericPageModal>
  </>
)

export default AddEmployeeFormMobile

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
