import styled from 'styled-components'
import deleteDocumentIcon from '../figma/images/deleteDocumentIcon'
import editDocumentIcon from '../figma/images/editDocumentIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'

type Props = {
  handleShowDeleteConfirmation: () => void
  onEdit: () => void
}

const BlogPostOptions: React.FC<Props> = ({ handleShowDeleteConfirmation, onEdit }) => (
  <Container position="absolute" direction="row" fullPadding spacing={Spacings.minimum} gap={Spacings.min}>
    <SVGWrapper
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation()
        handleShowDeleteConfirmation()
      }}
    >
      <StyledSVGContainer SVG={deleteDocumentIcon} />
    </SVGWrapper>
    <SVGWrapper
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation()
        onEdit()
      }}
    >
      <StyledSVGContainer SVG={editDocumentIcon} />
    </SVGWrapper>
  </Container>
)
export default BlogPostOptions

const Container = styled(StyledBox)`
  background-color: ${FigmaColors.dirtyWhite};
  border: none;
  border-radius: 0 0 ${BorderRadius.soft} ${BorderRadius.soft};
  box-shadow: 0px 5px 8px rgb(178, 178, 178);
  bottom: -${Spacings.large};
  right: ${Spacings.medium};
`

const SVGWrapper = styled(StyledBox)`
  cursor: pointer;
  border-radius: ${BorderRadius.circle};

  :hover {
    background-color: ${FigmaColors.gray2};
  }
`

const StyledSVGContainer = styled(SVGContainer)`
  display: flex;
  align-items: center;
`
