import BackOfficeLayout from 'src/components/BackOfficeLayout'
import OverviewDesktop from 'src/components/Overview'
import { Texts } from 'src/figma/helpers/TextRepository'
import { Images } from 'src/figma/helpers/imageHelper'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import OverviewMobile from '../components/OverviewMobile'

const OverviewPage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <BackOfficeLayout
      showHeading
      pageHeading={Texts.OverviewHeading}
      pageSubheading={Texts.OverviewSubheading}
      backgroundImage={Images.overviewBackgroundMobile}
      fullBackgroundImage
    >
      {isMobile && <OverviewMobile />}
      {!isMobile && <OverviewDesktop />}
    </BackOfficeLayout>
  )
}

export default OverviewPage
