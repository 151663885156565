import styled from 'styled-components'
import eyeDisplayNewsCards from '../figma/images/eyeDisplayNewsCards'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { getCurrentImage } from '../helpers/ImageActionsHelper'
import { useTervelImages } from '../helpers/QueryHooks'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'

type Props = {
  imageID: string
  setImageName: React.Dispatch<React.SetStateAction<string | null>>
  setImageUrl: React.Dispatch<React.SetStateAction<string | null>>
}

const ImageViewButton: React.FC<Props> = ({ imageID, setImageName, setImageUrl }) => {
  const isMobile = useIsMobile()
  const { data: imagesData } = useTervelImages()
  const currentImage = getCurrentImage(imageID, imagesData)
  const { name: imageName, signedUrl: imageUrl } = currentImage ?? { name: '', signedUrl: '' }

  const handleShowImage = () => {
    setImageName(imageName)
    setImageUrl(imageUrl)
  }

  return (
    <SVGWrapper position="absolute" align="center" justify="center" onClick={handleShowImage} $isMobile={isMobile}>
      <StyledSVG SVG={eyeDisplayNewsCards} />
    </SVGWrapper>
  )
}

export default ImageViewButton

const SVGWrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  height: ${Spacings.medium};
  width: ${Spacings.medium};
  right: ${({ $isMobile }) => ($isMobile ? Spacings.minimum : Spacings.tiny)};
  border-radius: ${BorderRadius.circle};

  &:hover {
    background-color: ${FigmaColors.grayLight};
  }
`

const StyledSVG = styled(SVGContainer)`
  display: flex;
`
