import React from 'react'
import AddUserFormContainer from 'src/components/AddUserFormContainer'
import BackOfficeLoader from 'src/components/BackOfficeLoader'
import EditEmployeeFormContainer from 'src/components/EditEmployeeFormContainer'
import EditUserFormContainer from 'src/components/EditUserFormContainer'
import EmployeeDetails from 'src/components/EmployeeDetails'
import SomethingWentWrongPage from 'src/components/SomethingWentWrongPage'
import UserDetailsContainer from 'src/components/UserDetailsContainer'
import { Texts } from 'src/figma/helpers/TextRepository'
import { BlogPost, Company, Template, TervelImage, User } from 'src/interfaces/Dto'
import AddBlogPostContainer from '../components/AddBlogPostContainer'
import AddCompanyFormContainer from '../components/AddCompanyFormContainer'
import AddEmployeeFormContainer from '../components/AddEmployeeFormContainer'
import AddImageFormContainer from '../components/AddImageFormContainer'
import AddNewsCardFormContainer from '../components/AddNewsCardFormContainer'
import AddTemplateFormContainer from '../components/AddTemplateFormContainer'
import EditBlogPostContainer from '../components/EditBlogPostContainer'
import EditCompanyFormContainer from '../components/EditCompanyFormContainer'
import EditNewsCardFormContainer from '../components/EditNewsCardFormContainer'
import EditTemplateFormContainer from '../components/EditTemplateFormContainer'
import { NewsCardDto } from '../interfaces/Dto'
import CompanyDetails from './CompanyDetails'

export enum PageSubstate {
  DEFAULT = 'default',
  CREATE = 'create',
  EDIT = 'edit',
  DETAILS = 'details',
  ERROR = 'error',
  LOADING = 'loading'
}

type PageSubstateType = {
  substate: PageSubstate
  setSubstate: (state: PageSubstate) => void
}

export const PageSubstateContext = React.createContext<PageSubstateType>({
  substate: PageSubstate.DEFAULT,
  setSubstate: () => ({})
})

export const getUsersPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  user: User | null,
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: <></>,
    [PageSubstate.CREATE]: <AddUserFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!user && (
      <EditUserFormContainer user={user} open onClose={() => setSubstate(PageSubstate.DEFAULT)} />
    ),
    [PageSubstate.DETAILS]: !!user && (
      <UserDetailsContainer
        user={user}
        open
        onClose={() => {
          setSubstate(PageSubstate.DEFAULT)
          setSelectedUser(null)
        }}
      />
    ),
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongUsers}
        button
        buttonTextKey={Texts.AddUserButtonText}
        modalComponent={AddUserFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getEmployeesPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  employee: User | null,
  setSelectedEmployee: React.Dispatch<React.SetStateAction<User | null>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddEmployeeFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!employee && (
      <EditEmployeeFormContainer employee={employee} open onClose={() => setSubstate(PageSubstate.DEFAULT)} />
    ),
    [PageSubstate.DETAILS]: !!employee && (
      <EmployeeDetails
        employee={employee}
        open
        onClose={() => {
          setSubstate(PageSubstate.DEFAULT)
          setSelectedEmployee(null)
        }}
      />
    ),
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongEmployees}
        button
        buttonTextKey={Texts.AddEmployeeButtonText}
        modalComponent={AddEmployeeFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getTemplatesPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  template: Template | null
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddTemplateFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!template && (
      <EditTemplateFormContainer
        open
        onClose={() => setSubstate(PageSubstate.DEFAULT)}
        template={template}
      ></EditTemplateFormContainer>
    ),
    [PageSubstate.DETAILS]: <></>, //TODO component
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongTemplates}
        button
        buttonTextKey={Texts.AddTemplateButtonText}
        modalComponent={AddTemplateFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getCompaniesPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  company: Company | null | undefined,
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | null | undefined>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddCompanyFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!company && (
      <EditCompanyFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} company={company} />
    ),
    [PageSubstate.DETAILS]: !!company && (
      <CompanyDetails
        open
        onClose={() => {
          setSubstate(PageSubstate.DEFAULT)
          setSelectedCompany(null)
        }}
        company={company}
      />
    ),
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongEmployees}
        button
        buttonTextKey={Texts.AddEmployeeButtonText}
        modalComponent={AddCompanyFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getNewsCardsPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  newsCard: NewsCardDto | null,
  setSelectedNewsCard: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddNewsCardFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!newsCard && (
      <EditNewsCardFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} newsCard={newsCard} />
    ),
    [PageSubstate.DETAILS]: <></>,
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongNewsCards}
        button
        buttonTextKey={Texts.AddNewsCardButtonText}
        modalComponent={AddNewsCardFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getImagesPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  image: TervelImage | null,
  setSelectedImage: React.Dispatch<React.SetStateAction<TervelImage | null>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddImageFormContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: <></>,
    [PageSubstate.DETAILS]: <></>,
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongAddImage}
        button
        buttonTextKey={Texts.AddImageButtonText}
        modalComponent={AddImageFormContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}

export const getBlogPostsPageSubstate = (
  currentPageSubstate: PageSubstate,
  setSubstate: (substate: PageSubstate) => void,
  selectedBlogPost: BlogPost | null,
  setSelectedBlogPost: React.Dispatch<React.SetStateAction<BlogPost | null>>
) => {
  const state: { [key in PageSubstate]: React.ReactNode } = {
    [PageSubstate.DEFAULT]: null,
    [PageSubstate.CREATE]: <AddBlogPostContainer open onClose={() => setSubstate(PageSubstate.DEFAULT)} />,
    [PageSubstate.EDIT]: !!selectedBlogPost && (
      <EditBlogPostContainer
        open
        onClose={() => setSubstate(PageSubstate.DEFAULT)}
        selectedBlogPost={selectedBlogPost}
      />
    ),
    [PageSubstate.DETAILS]: <></>,
    [PageSubstate.ERROR]: (
      <SomethingWentWrongPage
        subheading={Texts.SomethingWrongSubheading}
        additionalText={Texts.SomethingWentWrongAddBlogPost}
        button
        buttonTextKey={Texts.AddBlogPostButtonText}
        modalComponent={AddBlogPostContainer}
      />
    ),
    [PageSubstate.LOADING]: <BackOfficeLoader />
  }
  return state[currentPageSubstate]
}
