import isNil from 'lodash/isNil'
import { FieldValues, UseFormSetError } from 'react-hook-form'
import { handleFieldValidationByErrorCode } from './FieldValidationHelper'
import { handleErrorMessage } from './NotificationsHelper'
import { PageSubstate } from './PageSubstateHelper'

//TODO setSubstate won't be optional once we merge our add company container in which we use substate
//TODO once it's merged, we will pass setSubstate from there also
export const handleFormErrorCatch = (err: any, setError: UseFormSetError<FieldValues>, setSubstate?: any) => {
  if (!err) return handleErrorMessage('Bad Request')

  const { errorCode, message } = err.response?.data || {}

  if (!isNil(errorCode)) {
    handleFieldValidationByErrorCode(errorCode, setError, message)
  }
  if (isNil(errorCode)) {
    !!setSubstate && setSubstate(PageSubstate.ERROR)
    handleErrorMessage(err)
  }
}
