import { memo } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import buttonAddIcon from 'src/figma/images/buttonAddIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import MediaQueries from 'src/figma/tokens/MediaQueries'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'
import MobileSubmitButton from './MobileSubmitButton'

type Props = {
  pageHeading: TextType
  pageSubheading: TextType
  buttonTextKey: TextType
  onButtonClick: () => void
  isModalOpen?: boolean
  modalComponent?: React.ReactNode
}

const EmptyStatePageMobileView: React.FC<Props> = ({
  pageHeading,
  pageSubheading,
  buttonTextKey,
  onButtonClick,
  isModalOpen,
  modalComponent
}) => (
  <>
    <Wrapper
      fullWidth
      left
      right
      bottom
      spacing={Spacings.medium}
      gap={Spacings.big}
      align="center"
      justify="center"
      alignText="center"
    >
      <ImageWrapper alignSelf="center" left right bottom spacing={Spacings.medium}>
        <ImageContainer imageKey={Images.emptyStatePhoto} />
      </ImageWrapper>
      <StyledBox fullWidth gap={Spacings.medium} align="center">
        <TextWrapper gap={Spacings.medium}>
          <TextContainer textKey={pageHeading} />
          <TextContainer textKey={pageSubheading} />
        </TextWrapper>
        <MobileSubmitButton
          textKey={buttonTextKey}
          buttonIcon={buttonAddIcon}
          textBackgroundColor={FigmaColors.bhBlue}
          iconBackgroundColor={FigmaColors.bhBlueMedium}
          onClick={onButtonClick}
        />
      </StyledBox>
    </Wrapper>
    {isModalOpen && !!modalComponent && <>{modalComponent}</>}
  </>
)

export default memo(EmptyStatePageMobileView)

const Wrapper = styled(StyledBox)`
  overflow: hidden;
  width: 100vw;
  margin-top: -${Spacings.max};
`

const TextWrapper = styled(StyledBox)`
  max-width: 80%;
`

const ImageWrapper = styled(StyledBox)`
  justify-self: flex-end;

  @media (min-width: ${MediaQueries.xxs}px) and (max-width: ${MediaQueries.xs}px) {
    img {
      max-width: 80% !important;
      min-width: 80% !important;
    }
  }
`
