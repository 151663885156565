import React from 'react'
import { Control } from 'react-hook-form'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'
import { TEMPLATES_MODAL_FIGMA_WIDTH } from '../moesia/StrongHardCodedSizes'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  onApplyClick: () => void
  control: Control<any>
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
}

const EditTemplateFormDesktop: React.FC<Props> = ({
  open,
  onClose,
  onApplyClick,
  control,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={onApplyClick}
        confirmationTitle={Texts.UnsavedChangesModalTitle}
        confirmationQuestion={Texts.UnsavedChangesModalQuestion}
        confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.ApplyChangesButtonText}
              onClick={onApplyClick}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <form>
        <Wrapper align="center" top={Spacings.huge} left right bottom spacing={Spacings.large} gap={Spacings.medium}>
          <TextFieldController name="name" control={control} labelTextKey={Texts.AddTemplateDocumentName} />
          <TextFieldController name="wikiUrl" control={control} labelTextKey={Texts.AddTemplateDocumentWiki} />
        </Wrapper>
      </form>
    </GenericPageModal>
  </>
)

export default EditTemplateFormDesktop

const Wrapper = styled(StyledBox)`
  overflow-y: auto;
  width: ${TEMPLATES_MODAL_FIGMA_WIDTH};
`
