import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'

type Props = {
  websiteArr: string[] | '-'
}

const ClickableAnchor: React.FC<Props> = ({ websiteArr }) => (
  <>
    {websiteArr !== '-' &&
      websiteArr.map((website) => {
        const websiteUrl = website.startsWith('http') ? website : `https://${website}`

        return (
          <Anchor key={website} href={websiteUrl} target="_blank" rel="noopener noreferrer">
            {website}
          </Anchor>
        )
      })}
  </>
)

export default ClickableAnchor

const Anchor = styled.a`
  text-decoration: underline;
  color: ${FigmaColors.bhBluePrimaryButton};
`
