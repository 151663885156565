/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import { css, CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import Cancel from '@mui/icons-material/Cancel'
import Event from '@mui/icons-material/Event'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { useCallback, useRef } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Colours from 'src/figma/tokens/Colours'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import ImageViewButton from '../../components/ImageViewButton'
import { getText } from '../../figma/helpers/TextRepository'
import { generateUUID } from '../../helpers/Common'
import useLanguage from '../../hooks/useLanguage'
import { getLabel } from '../helpers/LabelTextHelper'
import { TextType } from '../interfaces/FigmaTypes'
import { Language } from '../interfaces/LanguageType'
import StyledBox from './StyledBox'

export const createAdornmentWithLoading = (adornment: JSX.Element, loading?: boolean) => {
  if (!loading) return <StyledBox style={{ padding: '14.5px 14px' }}>{adornment}</StyledBox>

  return (
    <StyledBox direction="row" align="center" justify="center">
      <StyledBox style={{ padding: '14.5px 14px' }}>{adornment}</StyledBox>
    </StyledBox>
  )
}

export const getLabelText = (language: Language, textkey?: TextType, text?: string) => {
  if (!text && textkey) return getText(textkey, language)

  return text
}

export const StyledTextField = styled(TextField)<{
  containerstyles: CSSObject
  inputstyles: CSSObject
}>`
  &&& {
    flex: '100%';
    ${({ containerstyles }) =>
      css`
        ${containerstyles}
      `}
    input {
      ${({ inputstyles }) =>
        css`
          ${inputstyles}
        `}
    }

    .MuiInputBase-formControl.MuiInputBase-multiline {
      padding: 14px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.soft};
      overflow: hidden;
      background-color: ${FigmaColors.white};
    }
  }
`

export const DatePickerController = <T extends Record<string, unknown>>({
  name,
  disabled,
  icon: Icon = Event,
  labelTextKey,
  labelText,
  control,
  readOnly,
  loading,
  containerstyles,
  inputstyles,
  placeholder
}: {
  name: Path<T>
  value?: dayjs.Dayjs | null
  onChange?: (value: dayjs.Dayjs) => unknown
  disabled?: boolean
  labelTextKey?: TextType
  labelText?: string
  control: Control<T>
  icon?: React.ElementType
  readOnly?: boolean
  loading?: boolean
  containerstyles?: CSSObject
  inputstyles?: CSSObject
  placeholder?: string
}) => {
  const language = useLanguage()

  const IconWithLoading = useCallback(() => createAdornmentWithLoading(<Icon />, loading), [Icon, loading])

  return (
    <Controller
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <DatePicker
          label={getLabelText(language, labelTextKey, labelText)}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          disabled={disabled}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              {...params}
              containerstyles={containerstyles as CSSObject}
              inputstyles={inputstyles as CSSObject}
              placeholder={placeholder}
              error={Boolean(errors[name]?.message)}
              helperText={errors[name]?.message as React.ReactNode}
            />
          )}
          components={{
            OpenPickerIcon: IconWithLoading
          }}
        />
      )}
      control={control}
      name={name}
    />
  )
}

export function AutoCompleteController<T extends Record<string, unknown>>({
  name,
  control,
  placeholderTextKey,
  labelTextKey,
  labelText,
  options,
  onClose,
  disabled,
  containerstyles,
  inputstyles,
  showViewButton,
  setImageName,
  setImageUrl
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  labelText?: string
  placeholderTextKey?: TextType
  options: Required<Pick<{ label: string; value: Record<string, unknown> | number | string }, 'label' | 'value'>>[]
  onClose?: () => void
  disabled?: boolean
  containerstyles?: CSSObject
  inputstyles?: CSSObject
  showViewButton?: boolean
  setImageName?: React.Dispatch<React.SetStateAction<string | null>>
  setImageUrl?: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value = '' }, formState: { errors } }) => {
        const option = options?.find((option) => {
          if (typeof option.value === 'object') {
            return JSON.stringify(option.value) === JSON.stringify(value)
          }
          return option.value === value
        }) || { label: '', value: '' }

        return (
          <Autocomplete
            fullWidth
            value={option}
            options={options}
            onClose={onClose}
            disabled={disabled}
            disableClearable={true}
            getOptionLabel={(option) => option.label}
            onChange={(event, { value = '' }) => onChange(value)}
            isOptionEqualToValue={(option, value) => {
              if (typeof option.value === 'object') {
                return value.value === '' || JSON.stringify(option.value) === JSON.stringify(value.value)
              }
              return value.value === '' || option.value === value.value
            }}
            renderInput={(props) => (
              <StyledTextField
                {...props}
                label={getLabelText(language, labelTextKey, labelText)}
                placeholder={placeholderTextKey ? getText(placeholderTextKey, language) : undefined}
                error={Boolean(errors[name]?.message)}
                helperText={errors[name]?.message as React.ReactNode}
                containerstyles={containerstyles as CSSObject}
                inputstyles={inputstyles as CSSObject}
              />
            )}
            renderOption={(props, _option) => {
              if (option.value === '') props['aria-selected'] = false

              return (
                <li {...props} key={props.id}>
                  {_option.label}
                  {showViewButton && setImageName && setImageUrl && (
                    <ImageViewButton imageID={_option.value as string} setImageName={setImageName} setImageUrl={setImageUrl} />
                  )}
                </li>
              )
            }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function AutoCompleteChipController<T extends Record<string, unknown>>({
  name,
  control,
  placeholderTextKey,
  labelTextKey,
  labelText,
  options,
  onClose,
  defaultValue,
  disabled,
  containerstyles,
  inputstyles
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  labelText?: string
  placeholderTextKey?: TextType
  options?: { value: number | string; label: string }[]
  onClose?: () => void
  defaultValue?: string[]
  disabled?: boolean
  containerstyles?: CSSObject
  inputstyles?: CSSObject
}) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange }, formState: { errors } }) => (
        <Autocomplete
          multiple
          fullWidth
          onClose={onClose}
          disabled={disabled}
          disableClearable={true}
          sx={{ '.MuiOutlinedInput-root': { flex: '100%' }, height: 'auto' }}
          onChange={(event, newValue) => {
            const newValueArray = Array.isArray(newValue) ? newValue : [newValue]
            onChange(newValueArray)
          }}
          options={options || ([] as any)}
          freeSolo
          defaultValue={defaultValue}
          renderTags={(value, getTagProps) =>
            value.map((option: any, index: number) => {
              option = option as string | { label: string }
              const chipValue = typeof option !== 'string' ? option?.label : option
              return (
                <StyledBox key={`${chipValue}-${index}`} direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                  <Chip clickable label={chipValue} {...getTagProps({ index })} />
                </StyledBox>
              )
            })
          }
          renderInput={(props) => (
            <StyledTextField
              {...props}
              label={getLabelText(language, labelTextKey, labelText)}
              placeholder={placeholderTextKey ? getText(placeholderTextKey, language) : undefined}
              error={Boolean(errors[name]?.message)}
              helperText={errors[name]?.message as React.ReactNode}
              containerstyles={containerstyles as CSSObject}
              inputstyles={inputstyles as CSSObject}
            />
          )}
        />
      )}
      control={control}
      name={name}
    />
  )
}

const StyledChipSelectLabel = styled(InputLabel)`
  &:not(.MuiInputLabel-shrink) {
    padding: 5px !important;
  }
`

export function SelectChipController<T extends Record<string, unknown>>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  onBlur,
  onChipRemove
}: {
  control: Control<T>
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: TextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<HTMLElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  loading?: boolean
  options: { value: number | string; label: string }[]
  onChipRemove?: (value: unknown) => void
}) {
  const language = useLanguage()
  const selectRef = useRef<HTMLElement>(null)
  const inputLabel = getLabel(language, labelText, labelTextKey)

  return (
    <Controller
      render={({ field: { onChange, value = [] as string[] } }) => (
        <FormControl fullWidth>
          {!!inputLabel && <StyledChipSelectLabel id={`${name}Label`}>{inputLabel}</StyledChipSelectLabel>}
          <Select
            onBlur={onBlur}
            inputRef={selectRef}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            labelId={`${name}Label`}
            label={inputLabel}
            value={value}
            multiple
            style={{ height: 'auto' }}
            onChange={onChange}
            renderValue={(values) => {
              const selected = values as string[]

              return (
                <StyledBox direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                  {selected.map((selectedValue: any) => (
                    <Chip
                      clickable
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => {
                            event.stopPropagation()

                            // When stopping the propagation the components looses its focus state
                            // we need to refocus it after a slight delay
                            // eslint-disable-next-line no-magic-numbers
                            setTimeout(() => selectRef.current?.focus(), 10)
                          }}
                        />
                      }
                      onDelete={(e) => {
                        e?.stopPropagation()

                        if (onChipRemove) {
                          return onChipRemove(selectedValue)
                        }

                        onChange((value as string[]).filter((v) => v !== selectedValue))
                      }}
                      key={selectedValue ?? generateUUID()}
                      label={options.find((v) => v.value === selectedValue)?.label ?? selectedValue.label}
                    />
                  ))}
                </StyledBox>
              )
            }}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      control={control}
      name={name}
    />
  )
}

export function SelectController<T extends Record<string, unknown>>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  onBlur,
  readOnly
}: {
  control: Control<T>
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: TextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<HTMLElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  loading?: boolean
  options: { value: number | string; label: string; disabled?: boolean }[]
  onChipRemove?: (value: unknown) => void
  readOnly?: boolean
}) {
  const language = useLanguage()
  const selectRef = useRef<HTMLElement>(null)
  const inputLabel = getLabel(language, labelText, labelTextKey)

  return (
    <Controller
      render={({ field: { onChange, value = [] } }) => (
        <FormControl fullWidth>
          {!!inputLabel && <InputLabel id={`${name}Label`}>{inputLabel}</InputLabel>}
          <Select
            readOnly={readOnly}
            onBlur={onBlur}
            inputRef={selectRef}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            labelId={`${name}Label`}
            label={inputLabel}
            value={value as ''}
            style={{ height: 'auto' }}
            onChange={onChange}
          >
            {options.map(({ value, label, disabled }) => (
              <MenuItem key={value} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      control={control}
      name={name}
    />
  )
}

export function CheckboxController<T extends Record<string, unknown>>({
  name,
  control,
  onChange: _onChange,
  labelTextKey,
  labelText,
  labelPlacement = 'end',
  justifyContent = 'flex-start',
  disabled,
  error
}: {
  name: Path<T>
  control: Control<T>
  onChange?: (checked: boolean) => void
  labelTextKey?: TextType
  labelText?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
  justifyContent?: React.CSSProperties['justifyContent']
  disabled?: boolean
  error?: boolean
}) {
  const language = useLanguage()

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={getLabelText(language, labelTextKey, labelText)}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <Checkbox
              disabled={disabled}
              checked={Boolean(value)}
              onChange={(...event) => {
                onChange(...event)
                _onChange && _onChange(event[1])
              }}
              sx={{ color: `${error ? 'red' : FigmaColors.baseGrey}` }}
            />
          )}
          control={control}
          name={name}
        />
      }
      sx={{ marginRight: 0, marginLeft: 0, justifyContent }}
    />
  )
}

export function RadioGroupController<T extends Record<string, unknown>>({
  name,
  control,
  options = [],
  defaultValue,
  error
}: {
  name: Path<T>
  control: Control<T>
  options: { value: number | string; label: string }[]
  defaultValue?: string
  error?: boolean
}) {
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <FormControl>
          <RadioGroup onChange={onChange} defaultValue={defaultValue}>
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio
                    sx={{
                      color: `${error ? 'red' : FigmaColors.baseGrey}`,
                      '&.Mui-checked': { color: Colours.blueDark }
                    }}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      control={control}
      name={name}
    />
  )
}
