import { toast } from 'react-toastify'
import NotificationDefault from '../components/NotificationDefault'
import NotificationError from '../components/NotificationError'
import NotificationInfo from '../components/NotificationInfo'
import NotificationSuccess from '../components/NotificationSuccess'
import NotificationWarning from '../components/NotificationWarning'
import { TextType } from '../interfaces/FigmaTypes'

export const successNotification = (successMessage: TextType) => {
  toast(NotificationSuccess(successMessage), {
    hideProgressBar: true,
    className: 'success'
  })
}

export const errorNotification = (errorTextKey: TextType, errorText?: string) => {
  toast(NotificationError(errorTextKey, errorText), {
    hideProgressBar: true,
    className: 'error'
  })
}

export const warningNotification = (warningMessage: TextType) => {
  toast(NotificationWarning(warningMessage), {
    hideProgressBar: true,
    className: 'warning'
  })
}

export const infoNotification = (infoMessage: TextType) => {
  toast(NotificationInfo(infoMessage), {
    hideProgressBar: true,
    className: 'info'
  })
}

export const defaultNotification = (defaultMessage: TextType) => {
  toast(NotificationDefault(defaultMessage), {
    hideProgressBar: true,
    className: 'default'
  })
}

export const promiseNotification = (promise: Promise<unknown>) => {
  toast.promise(promise, {
    pending: 'Promise is pending',
    success: 'Promise resolved 👌',
    error: 'Promise rejected 🤯'
  })
}

export const useNotifications = () => ({
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  defaultNotification,
  promiseNotification
})
