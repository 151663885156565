import React, { useState } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import buttonAddIcon from '../figma/images/buttonAddIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import useIsMobile from '../moesia/hooks/useIsMobile'
import MobileSubmitButton from './MobileSubmitButton'

type Props = {
  subheading?: TextType
  additionalText?: TextType
  button?: boolean
  buttonTextKey?: TextType
  modalComponent?: React.ElementType
}

const SomethingWentWrongPage: React.FC<Props> = ({
  subheading,
  additionalText,
  button,
  buttonTextKey,
  modalComponent: ModalComponent
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useIsMobile()

  return (
    <>
      <Wrapper fullWidth align="center" justify="center" gap={Spacings.large} left right $isMobile={isMobile}>
        <ImageContainer
          imageKey={isMobile ? Images.somethingWentWrongPhotoMobile : Images.somethingWentWrongPhoto}
          minHeight={!isMobile}
        />
        <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
          <TextContainer textKey={Texts.SomethingWrongHeading} />
          {!!subheading && <TextContainer textKey={subheading} />}
          {!!additionalText && <TextContainer textKey={additionalText} />}
        </StyledBox>
        {!isMobile && button && buttonTextKey && (
          <SubmitButton
            variant="primary"
            textKey={buttonTextKey}
            onClick={() => {
              setIsModalOpen(true)
            }}
          />
        )}
        {isMobile && button && (
          <MobileSubmitButton
            textKey={Texts.AddButtonText}
            buttonIcon={buttonAddIcon}
            onClick={() => {
              setIsModalOpen(true)
            }}
            textBackgroundColor={FigmaColors.bhBluePrimaryButton}
            iconBackgroundColor={FigmaColors.bhBlueMedium}
          />
        )}
      </Wrapper>
      {isModalOpen && !!ModalComponent && <ModalComponent open onClose={() => setIsModalOpen(false)} />}
    </>
  )
}
export default React.memo(SomethingWentWrongPage)

const Wrapper = styled(StyledBox)<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => !$isMobile && '100vh'};
  margin-top: ${({ $isMobile }) => $isMobile && `-${Spacings.max}`};
`
