import { yupResolver } from '@hookform/resolvers/yup'
import { convertToRaw } from 'draft-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { UserType } from '../enums/UserType'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged } from '../helpers/Common'
import { handleAddContact } from '../helpers/CompanyContactsHelper'
import { UploadingProgress, createFileFormData } from '../helpers/DocumentsHelper'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useDeleteDocument, useUpdateCompany, useUploadDocument } from '../helpers/QueryHooks'
import { handleUserArchiveStatus, hasAccess } from '../helpers/UsersHelper'
import { createEmailYupValidation, createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { Company, ContactPerson, Document } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import EditCompanyFormDesktop from './EditCompanyFormDesktop'
import EditCompanyFormMobile from './EditCompanyFormMobile'

type Props = {
  open: boolean
  onClose: () => void
  company: Company
}

const EditCompanyFormContainer: React.FC<Props> = ({ open, onClose, company: currentCompany }) => {
  const language = useLanguage()
  const isMobile = useIsMobile()
  const updateCompany = useUpdateCompany(currentCompany._id)
  const currentUserRole = useUser().role
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const [uploadingProgress, setUploadingProgress] = useState<UploadingProgress>(UploadingProgress.LOADING)
  const uploadDocument = useUploadDocument()
  const deleteDocument = useDeleteDocument()
  const { setSubstate } = useContext(PageSubstateContext)
  const [isCompleted, setIsCompleted] = useState(false)
  const { name, vat, email, website, projects, description, documents } = currentCompany
  const defaultValues = useMemo(
    () => ({
      name: name,
      vat: vat,
      email: email,
      website: website,
      projects: projects,
      description: description,
      documents: documents
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddCompanyNameErrorRequired, language)),
        email: createEmailYupValidation(
          getText(Texts.AddCompanyEmailErrorRequired, language),
          getText(Texts.AddCompanyEmailErrorValid, language)
        ),
        vat: createRequiredStringYupValidation(getText(Texts.AddCompanyVATErrorRequired, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { isSubmitSuccessful }
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const isContactInfoFilled = !!watch('contactName') && !!watch('contactPosition') && !!watch('contactEmail')

  const { files } = watch()

  const handleUploadDocument = (newFiles: File[] = []) => {
    if (!newFiles.length) return Promise.resolve(null)

    const newFile = newFiles[newFiles.length - 1]
    const fileData = createFileFormData({ name: 'document', file: newFile, companyId: currentCompany._id })

    return uploadDocument
      .mutateAsync(fileData)
      .then((document) => {
        setUploadingProgress(UploadingProgress.SUCCESS)
        const { _id } = document
        const { type, name, size } = newFile
        const newFiles = [...(files || []), { type, name, size, _id }]
        setValue('files', newFiles)
        return _id
      })
      .catch((err: Error) => {
        setUploadingProgress(UploadingProgress.ERROR)
        handleFormErrorCatch(err, setError, setSubstate)
        return Promise.resolve()
      })
  }

  const handleDeleteFile = (documentId: string) => {
    deleteDocument
      .mutateAsync(documentId)
      .then((data) => {
        setValue('files', [...files.filter((file: File & Partial<Document>) => file._id !== documentId)])
        return data
      })
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const handleDeleteDocument = (documentId: string) => {
    deleteDocument
      .mutateAsync(documentId)
      .then(() => successNotification(Texts.DocumentDeletedNotification))
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { description, website, projects, ...restData } = data
    const companyInformation = description && JSON.stringify(convertToRaw(description.getCurrentContent()))

    updateCompany
      .mutateAsync({
        ...restData,
        website,
        projects,
        description: companyInformation,
        documents: files?.concat(documents)
      })
      .then(() => {
        onClose && onClose()
        successNotification(Texts.NotificationUpdateCompanySuccessful)
        setSubstate(PageSubstate.DEFAULT)
        setIsCompleted(true)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  const onAddContact = (
    contactsContainer?: ContactPerson[] | [],
    setContactsContainer?: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
  ) => handleAddContact(watch, language, setError, clearErrors, setValue, contactsContainer, setContactsContainer)

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(currentCompany, updateCompany, onClose)

  useEffect(() => {
    if (isSubmitSuccessful && isCompleted) {
      reset({ defaultValues })
    }
  }, [isCompleted, isSubmitSuccessful])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {!isMobile && (
        <EditCompanyFormDesktop
          open={open}
          onClose={() => {
            onClose()
            reset(watch())
          }}
          onSubmit={handleSubmit(handleSubmitButton)}
          onAddContact={onAddContact}
          isContactInfoFilled={isContactInfoFilled}
          control={control}
          language={language}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          watch={watch}
          company={currentCompany}
          onDeleteDocument={handleDeleteDocument}
          handleDeleteFile={handleDeleteFile}
          files={files}
          onFileUpload={handleUploadDocument}
          documents={documents}
          isNotRegularUser={isNotRegularUser}
          uploadingProgress={uploadingProgress}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
        />
      )}
      {isMobile && (
        <EditCompanyFormMobile
          open={open}
          onClose={() => {
            onClose()
            reset(watch())
          }}
          onSubmit={handleSubmit(handleSubmitButton)}
          onAddContact={onAddContact}
          isContactInfoFilled={isContactInfoFilled}
          control={control}
          language={language}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          watch={watch}
          company={currentCompany}
          onDeleteDocument={handleDeleteDocument}
          handleDeleteFile={handleDeleteFile}
          files={files}
          onFileUpload={handleUploadDocument}
          documents={documents}
          isNotRegularUser={isNotRegularUser}
          uploadingProgress={uploadingProgress}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
        />
      )}
    </>
  )
}

export default EditCompanyFormContainer
