/* eslint-disable max-lines */
import MUIRichTextEditor from 'mui-rte'
import React, { useContext, useState } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { Company, ContactPerson } from 'src/interfaces/Dto'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { generateUUID, getArrowSvg } from '../helpers/Common'
import { getDataFromArray } from '../helpers/GetDataFromArrayHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import {
  COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT,
  DESKTOP_COMPANY_DETAILS_MODAL_MAX_WIDTH,
  DESKTOP_COMPANY_DETAILS_MODAL_MIN_HEIGHT
} from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import AvatarInitials from './AvatarInitials'
import ClickableAnchor from './ClickableAnchor'
import CompanyContactPerson from './CompanyContactPerson'
import CompanyDocumentsContainer from './CompanyDocumentsContainer'
import ConditionalDetailsFormsButtonDesktop from './ConditionalDetailsFormsButtonDesktop'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import InvoicesTable from './InvoicesTable'
import { Line } from './Line'
import ModalButtons from './ModalButtons'
import ReminderBox from './ReminderBox'
import ToggleArchiveButton from './ToggleArchiveButton'

type Props = {
  open: boolean
  onClose: () => void
  company: Company
  handleDeleteButton: () => void

  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
}

const CompanyDetailsDesktop: React.FC<Props> = ({
  open,
  onClose,
  company,
  handleDeleteButton,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation
}) => {
  const { name, vat, archived, website, projects, employees } = company
  const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false)
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const companyWebsite = getDataFromArray(website)
  const companyProjects = getDataFromArray(projects)
  const { contacts, documents, description } = company
  const [isEmployedPeopleMenuOpen, setIsEmployedPeopleMenuOpen] = useState(false)
  const [isContactsMenuOpen, setIsContactsMenuOpen] = useState(false)
  const [showCompanyInfo, setShowCompanyInfo] = useState(false)
  const hasDescriptionText = JSON.parse(description as unknown as string).blocks[0].text

  const onShowCompanyInfo = () => hasDescriptionText && setShowCompanyInfo(!showCompanyInfo)

  const handleEditButton = () => {
    onClose()
    setSubstate(PageSubstate.EDIT)
  }

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteCompanyTitle}
          confirmationQuestion={Texts.ConfirmDeleteCompanyQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
        />
      )}
      {isInvoicesModalOpen && (
        <InvoicesTable open={isInvoicesModalOpen} onClose={() => setIsInvoicesModalOpen(false)} />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateCompanyTitle : Texts.ConfirmArchiveCompanyTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateCompanyProfileQuestion : Texts.ConfirmArchiveCompanyQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
        />
      )}
      {substate !== PageSubstate.ERROR && !isInvoicesModalOpen && (
        <GenericPageModal
          open={open}
          onClose={onClose}
          buttons={
            <ModalButtons
              primaryButton={
                <ConditionalDetailsFormsButtonDesktop
                  isArchived={!!archived}
                  onDeleteClick={handleShowDeleteConfirmation}
                  onEditClick={handleEditButton}
                />
              }
              tertiaryButton={
                <ToggleArchiveButton
                  activateTextKey={Texts.ActivateButtonTextModal}
                  archiveTextKey={Texts.ArchiveButtonTextModal}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                  isArchived={archived}
                />
              }
            />
          }
        >
          <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
            <StyledBox fullWidth direction="row" gap={Spacings.medium}>
              <StyledBox fillWidth top spacing={Spacings.tiny} gap={Spacings.medium}>
                <StyledBox fullWidth direction="row" gap={Spacings.medium}>
                  <Column fullPadding spacing={Spacings.tiny} gap={Spacings.large} justify="space-between">
                    <StyledBox gap={Spacings.medium} direction="row" align="center">
                      <AvatarInitials name={name} />
                      <StyledBox>
                        <TextContainer textKey={Texts.EmployeeDetailsName} text={name} />
                        <TextContainer textKey={Texts.EmployeeDetailsStartDate} text={vat} />
                      </StyledBox>
                    </StyledBox>
                    <ReminderBox
                      titleKey={Texts.BillingDueReminder}
                      descriptionKey={Texts.BillingDueReminderDescription}
                    />
                  </Column>
                  <Column fullPadding spacing={Spacings.tiny} gap={Spacings.medium}>
                    <Expander
                      fullWidth
                      justify="space-between"
                      align="center"
                      fullPadding
                      spacing={Spacings.tiny}
                      direction="row"
                      $showContent={isEmployedPeopleMenuOpen}
                    >
                      <TextContainer textKey={Texts.EmployedPeople} />
                      <StyledBox
                        onClick={() => !!employees?.length && setIsEmployedPeopleMenuOpen(!isEmployedPeopleMenuOpen)}
                      >
                        <StyledSVGContainer
                          SVG={getArrowSvg(isEmployedPeopleMenuOpen)}
                          $hasContent={!!employees?.length}
                        />
                      </StyledBox>
                    </Expander>
                    <ContactsWrapper
                      fullWidth
                      top={Spacings.minimum}
                      gap={Spacings.min}
                      $showContent={isEmployedPeopleMenuOpen}
                      $isMarginMedium
                    >
                      {isEmployedPeopleMenuOpen &&
                        employees.map(({ name, position, email }: ContactPerson) => (
                          <CompanyContactPerson
                            key={generateUUID()}
                            name={name}
                            position={position}
                            email={email}
                            details
                          />
                        ))}
                    </ContactsWrapper>
                    <Invoices
                      fullWidth
                      justify="center"
                      fullPadding
                      spacing={Spacings.tiny}
                      onClick={() => setIsInvoicesModalOpen(true)}
                    >
                      <TextContainer textKey={Texts.CompanyDetailsInvoices} />
                    </Invoices>
                    <ReminderBox
                      titleKey={Texts.HolidaysReminderTitle}
                      descriptionKey={Texts.HolidaysReminderDescription}
                    />
                  </Column>
                </StyledBox>
                <StyledBox fullWidth gap={Spacings.min}>
                  <TextContainer textKey={Texts.CompanyDetailsInformation} />
                  <Line fullWidth />
                </StyledBox>
                <StyledBox fullWidth direction="row" gap={Spacings.medium} bottom={Spacings.big}>
                  <Column fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <Expander
                      fullWidth
                      justify="space-between"
                      align="center"
                      fullPadding
                      spacing={Spacings.tiny}
                      direction="row"
                      $showContent={isContactsMenuOpen}
                    >
                      <TextContainer textKey={Texts.ContactPeople} />
                      <StyledBox onClick={() => !!contacts?.length && setIsContactsMenuOpen(!isContactsMenuOpen)}>
                        <StyledSVGContainer SVG={getArrowSvg(isContactsMenuOpen)} $hasContent={!!contacts?.length} />
                      </StyledBox>
                    </Expander>
                    <ContactsWrapper fullWidth gap={Spacings.min} $showContent={isContactsMenuOpen}>
                      {isContactsMenuOpen &&
                        contacts.map(({ name, position, email }: ContactPerson) => (
                          <CompanyContactPerson
                            key={generateUUID()}
                            name={name}
                            position={position}
                            email={email}
                            details
                          />
                        ))}
                    </ContactsWrapper>
                    <StyledBox gap={Spacings.minimum}>
                      <TextContainer textKey={Texts.CompanyDetailsWebsiteTitle} />
                      {companyWebsite !== '-' ? <ClickableAnchor websiteArr={companyWebsite} /> : companyWebsite}
                    </StyledBox>
                    <StyledBox gap={Spacings.minimum}>
                      <TextContainer textKey={Texts.CompanyDetailsProjectsTitle} />
                      {companyProjects !== '-'
                        ? companyProjects.map((project: string) => (
                            <TextContainer key={generateUUID()} textKey={Texts.CompanyDetailsInvoices} text={project} />
                          ))
                        : companyProjects}
                    </StyledBox>
                  </Column>
                  <Column fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <Expander
                      fullWidth
                      justify="space-between"
                      fullPadding
                      spacing={Spacings.tiny}
                      align="center"
                      direction="row"
                      $showContent={showCompanyInfo}
                    >
                      <TextContainer textKey={Texts.CompanyDetailsInformationForPresentations} />
                      <StyledBox onClick={onShowCompanyInfo}>
                        <StyledSVGContainer SVG={getArrowSvg(showCompanyInfo)} $hasContent={hasDescriptionText} />
                      </StyledBox>
                    </Expander>
                    {showCompanyInfo && hasDescriptionText && (
                      <CompanyInformation
                        fullWidth
                        $showContent={showCompanyInfo}
                        fullPadding
                        spacing={Spacings.minimum}
                      >
                        <MUIRichTextEditor toolbar={false} defaultValue={description} />
                      </CompanyInformation>
                    )}
                    <StyledBox fullWidth gap={Spacings.tiny}>
                      <StyledBox fullWidth gap={Spacings.min}>
                        <TextContainer textKey={Texts.CompanyDetailsDocumentsTitle} />
                        <Line fullWidth />
                      </StyledBox>
                      {!documents?.length && (
                        <StyledBox left={Spacings.smallest}>
                          <TextContainer textKey={Texts.CompanyDetailsNoDocumentsToShow} />
                        </StyledBox>
                      )}
                      {!!documents?.length && <CompanyDocumentsContainer documents={documents} isDetails />}
                    </StyledBox>
                  </Column>
                </StyledBox>
              </StyledBox>
            </StyledBox>
          </Wrapper>
        </GenericPageModal>
      )}
    </>
  )
}

export default CompanyDetailsDesktop

const Wrapper = styled(StyledBox)`
  overflow-y: auto;
  max-width: ${DESKTOP_COMPANY_DETAILS_MODAL_MAX_WIDTH};
  min-height: ${DESKTOP_COMPANY_DETAILS_MODAL_MIN_HEIGHT};
`

const Column = styled(StyledBox)`
  flex: 1;
`

const Expander = styled(StyledBox)<{ $showContent?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $showContent }) => ($showContent ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min)};
`
const Invoices = styled(StyledBox)`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${Spacings.min};
  cursor: pointer;
`

//TODO admin view with documents

const StyledSVGContainer = styled(SVGContainer)<{ $hasContent: boolean }>`
  cursor: ${({ $hasContent }) => ($hasContent ? 'pointer' : 'not-allowed')};
`
const ContactsWrapper = styled(StyledBox)<{ $showContent: boolean; $isMarginMedium?: boolean }>`
  border: ${({ $showContent }) => $showContent && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: ${({ $isMarginMedium }) => ($isMarginMedium ? `-${Spacings.medium}` : `-${Spacings.large}`)};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;

  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`

const CompanyInformation = styled(StyledBox)<{ $showContent: boolean }>`
  border: ${({ $showContent }) => $showContent && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: -${Spacings.large};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;

  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`
