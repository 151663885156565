import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import SubmitButton from '../moesia/components/SubmitButton'

type Props = {
  isArchived: boolean
  onDeleteClick: () => void
  onEditClick: () => void
}

const ConditionalDetailsFormsButtonDesktop: React.FC<Props> = ({ isArchived, onDeleteClick, onEditClick }) => (
  <StyledSubmitButton
    variant="primary"
    textKey={isArchived ? Texts.DeleteButtonText : Texts.EditEmployeeButtonText}
    onClick={isArchived ? onDeleteClick : onEditClick}
    $isArchived={isArchived}
  />
)

export default ConditionalDetailsFormsButtonDesktop

const StyledSubmitButton = styled(SubmitButton)<{ $isArchived: boolean }>`
  div {
    background-color: ${({ $isArchived }) => $isArchived && FigmaColors.lighterRed};

    &:hover {
      background-color: ${({ $isArchived }) => $isArchived && FigmaColors.lighterRed};
    }
  }
`
