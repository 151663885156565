import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { Position } from '../helpers/AddEmployeeFormHelper'
import { CompanyOptionsType, RolesType } from '../helpers/UserTypesHelper'
import { USER_FORM_MULTILINE_TEXTAREAS_MAX_WIDTH, USER_FORM_WIDTH } from '../moesia/StrongHardCodedSizes'
import EditorController from '../moesia/components/EditorController'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import UserAdditionalProfileFields from './UserAdditionalProfileFields'

type Props = {
  open: boolean
  onClose: () => void
  handleSubmit: () => void
  control: Control<FieldValues, any>
  positionOptions: Position[]
  companyOptions: CompanyOptionsType
  roles: RolesType
  isEmployeeOrCustomer: boolean
  values: FieldValues
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddUserFormDesktopView: React.FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  control,
  positionOptions,
  companyOptions,
  roles,
  isEmployeeOrCustomer,
  values,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddUserButtonText}
              onClick={handleSubmit}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
        <form>
          <StyledBox direction="row" gap={Spacings.medium}>
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} />
            <Form fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
              <TextFieldController name="name" control={control} labelText="Name" />
              <TextFieldController name="email" control={control} labelText="Email" />
              <TextFieldController name="password" control={control} labelText="Password" />
              <AutoCompleteController
                name="position"
                control={control}
                options={positionOptions}
                labelTextKey={Texts.AddEmployeePositionPlaceholder}
              />
              <SelectChipController name="companies" control={control} options={companyOptions} labelText="Company" />
              <AutoCompleteController name="role" control={control} options={roles} labelText="User role" />
              <WidthLimitWrapper fullWidth gap={Spacings.large}>
                {isEmployeeOrCustomer && <UserAdditionalProfileFields control={control} role={values.role} />}
                <EditorController name="goals" labelTextKey={Texts.GoalsPlaceholder} control={control} />
                <EditorController name="notes" labelTextKey={Texts.NotesPlaceholder} control={control} />
              </WidthLimitWrapper>
              <DatePickerController
                name="birthDate"
                control={control}
                labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
              />
            </Form>
          </StyledBox>
        </form>
      </Wrapper>
    </GenericPageModal>
  </>
)

export default AddUserFormDesktopView

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
`

const Form = styled(StyledBox)`
  min-width: ${USER_FORM_WIDTH};
`

const WidthLimitWrapper = styled(StyledBox)`
  max-width: ${USER_FORM_MULTILINE_TEXTAREAS_MAX_WIDTH};
`
