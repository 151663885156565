import MaterialTable, { MTableToolbar } from '@material-table/core'
import { useMemo } from 'react'
import styled from 'styled-components'
import { getText, Texts } from '../figma/helpers/TextRepository'
import buttonAddIcon from '../figma/images/buttonAddIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { sortElements } from '../helpers/Common'
import { getEmployeesMobileColumns } from '../helpers/EmployeesTableColumnsHelper'
import { getTableOptions } from '../helpers/getTableOptions'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { getTableExtraOptions } from '../helpers/TablesExtraOptionsHelper'
import useLanguage from '../hooks/useLanguage'
import { User } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE } from '../moesia/StrongHardCodedSizes'
import EmployeesMobileRowInfoWrapper from './EmployeesMobileRowInfoWrapper'
import GenericConfirmationModal from './GenericConfirmationModal'
import MobileTableAddButton from './MobileTableAddButton'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type Props = {
  users: User[]
  setSubstate: (state: PageSubstate) => void
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  onUserDelete: () => void
}

const UsersTableMobileView: React.FC<Props> = ({
  users,
  setSubstate,
  setSelectedUser,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  onUserDelete
}) => {
  const language = useLanguage()
  const isMobile = useIsMobile()
  const extraOptions = useMemo(() => getTableExtraOptions(), [])
  const tableOptions = getTableOptions({ extraOptions: extraOptions })
  const searchPlaceholder = useMemo(() => getText(Texts.UsersSearchPlaceholder, language), [language])
  const tableColumnsMobile = useMemo(() => getEmployeesMobileColumns(language), [language])

  return (
    <>
      {shouldShowDeleteConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={onUserDelete}
          confirmationTitle={Texts.ConfirmDeleteUserTitle}
          confirmationQuestion={Texts.ConfirmDeleteUserQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      <StyledBox fullWidth justify="center" position="absolute">
        <TableWrapper isMobile={isMobile}>
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <TableToolbar fullWidth>
                  <ToggleArchiveButton
                    activateTextKey={Texts.ActivatedButtonTextMobile}
                    archiveTextKey={Texts.ArchivedButtonTextMobile}
                    onClick={handleShouldShowArchived}
                    isArchived={isShowingArchived}
                  />
                  <StyledBox fullWidth bottom={Spacings.minimum} direction="row" align="center" justify="space-between">
                    <MTableToolbar
                      {...props}
                      searchFieldStyle={{
                        ...tableOptions.searchFieldStyle,
                        width: isShowingArchived && MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE
                      }}
                    />
                    {!isShowingArchived && (
                      <MobileTableAddButton
                        onClick={() => setSubstate(PageSubstate.CREATE)}
                        buttonIcon={buttonAddIcon}
                      />
                    )}
                  </StyledBox>
                </TableToolbar>
              )
            }}
            columns={!!tableColumnsMobile ? tableColumnsMobile : []}
            data={sortElements(users)}
            localization={{
              toolbar: {
                searchPlaceholder: searchPlaceholder
              }
            }}
            options={tableOptions}
            detailPanel={[
              {
                render: ({ rowData }) => (
                  <EmployeesMobileRowInfoWrapper
                    employee={rowData}
                    onInfoClick={() => {
                      !!rowData && setSelectedUser(rowData as User)
                      setSubstate(PageSubstate.DETAILS)
                    }}
                    onEditClick={
                      !isShowingArchived
                        ? () => {
                            setSelectedUser(rowData as User)
                            setSubstate(PageSubstate.EDIT)
                          }
                        : undefined
                    }
                    onDeleteClick={
                      isShowingArchived
                        ? () => {
                            setSelectedUser(rowData as User)
                            handleShowDeleteConfirmation()
                          }
                        : undefined
                    }
                  />
                )
              }
            ]}
          />
        </TableWrapper>
      </StyledBox>
    </>
  )
}

export default UsersTableMobileView

const TableToolbar = styled(StyledBox)`
  margin-top: -${Spacings.small};
`
