import { memo, useState } from 'react'
import styled from 'styled-components'
import GenericConfirmationModal from '../../components/GenericConfirmationModal'
import { Texts } from '../../figma/helpers/TextRepository'
import deteleDocumentIconBigger from '../../figma/images/deteleDocumentIconBigger'
import editDocumentIconBigger from '../../figma/images/editDocumentIconBigger'
import BorderRadius from '../../figma/tokens/BorderRadius'
import FigmaColors from '../../figma/tokens/FigmaColors'
import Spacings from '../../figma/tokens/Spacings'
import { handleChangeStatus } from '../../helpers/NewsCardsStatusHelper'
import { PageSubstate } from '../../helpers/PageSubstateHelper'
import { useUpdateNewsCard } from '../../helpers/QueryHooks'
import { NewsCardDto } from '../../interfaces/Dto'
import { TextType } from '../interfaces/FigmaTypes'
import NewsCardMobileComponent from './NewsCardMobileComponent'
import SVGContainer from './SVGContainer'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'

type Props = {
  newsCard: NewsCardDto
  setSubstate?: (state: PageSubstate) => void
  setSelectedNewsCard?: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
  onDelete?: (newsCardId: string) => void
}

const NewsCardMobileView: React.FC<Props> = ({ newsCard, setSubstate, setSelectedNewsCard, onDelete }) => {
  const [showBack, setShowBack] = useState(false)
  const updateNewsCard = useUpdateNewsCard(newsCard._id)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)
  const [shouldShowStatusConfirmationModal, setShouldShowStatusConfirmationModal] = useState(false)
  const { frontText, frontImage, backText, backImage, backTextLink } = newsCard
  const isInBackoffice = !!setSubstate && !!setSelectedNewsCard && !!onDelete
  const activeTextKey = 'ActiveInTervel' in Texts && Texts.ActiveInTervel
  const inactiveTextKey = 'InactiveInTervel' in Texts && Texts.InactiveInTervel
  const confirmVisibleTextKey = 'ConfirmVisibleToTervel' in Texts && Texts.ConfirmVisibleToTervel
  const confirmNotVisibleTextKey = 'ConfirmNotVisibleToTervel' in Texts && Texts.ConfirmNotVisibleToTervel

  const status = newsCard.active ? activeTextKey : inactiveTextKey

  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)

  const handleDelete = () => {
    if (newsCard._id && !!onDelete) onDelete(newsCard._id)
  }

  const handleShowStatusConfirmation = () => setShouldShowStatusConfirmationModal(!shouldShowStatusConfirmationModal)

  const triggerStatusUpdate = () => handleChangeStatus(newsCard, updateNewsCard, handleShowStatusConfirmation)

  return (
    <>
      {shouldShowDeleteConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDelete}
          confirmationTitle={'ConfirmDeleteNewsCardTitle' in Texts ? Texts.ConfirmDeleteNewsCardTitle : ''}
          confirmationQuestion={'ConfirmDeleteNewsCardQuestion' in Texts ? Texts.ConfirmDeleteNewsCardQuestion : ''}
          confirmButtonTextKey={'ConfirmDeleteButtonText' in Texts ? Texts.ConfirmDeleteButtonText : ''}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowStatusConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowStatusConfirmation}
          onConfirm={triggerStatusUpdate}
          confirmationTitle={'ConfirmCardStatus' in Texts ? Texts.ConfirmCardStatus : ''}
          confirmationQuestion={newsCard.active ? (confirmNotVisibleTextKey as TextType) : (confirmVisibleTextKey as TextType)}
          confirmButtonTextKey={'ConfirmChangeStatusButtonText' in Texts ? Texts.ConfirmChangeStatusButtonText : ''}
          smallSize
        />
      )}
      <StyledBox position="relative" top>
        {isInBackoffice && (
          <Status position="absolute" fullWidth direction="row" align="center" gap={Spacings.minimum}>
            <StyledBox onClick={handleShowStatusConfirmation}>
              <StatusCircle $active={newsCard.active} />
            </StyledBox>
            <TextContainer textKey={status as TextType} />
          </Status>
        )}
        {isInBackoffice && (
          <Options fullPadding spacing={Spacings.smallest} position="absolute" direction="row" align="center" justify="center" gap={Spacings.min}>
            <StyledBox onClick={handleShowDeleteConfirmation}>
              <SVGContainer SVG={deteleDocumentIconBigger} />
            </StyledBox>
            <StyledBox
              onClick={() => {
                setSubstate(PageSubstate.EDIT)
                setSelectedNewsCard(newsCard)
              }}
            >
              <SVGContainer SVG={editDocumentIconBigger} />
            </StyledBox>
          </Options>
        )}
        <StyledBox alignSelf="center" onClick={() => setShowBack(!showBack)}>
          {!showBack && <NewsCardMobileComponent textKey={frontText} image={frontImage} />}
          {showBack && <NewsCardMobileComponent textKey={backText} image={backImage} isBackSide backTextLink={backTextLink} />}
        </StyledBox>
      </StyledBox>
    </>
  )
}

export default memo(NewsCardMobileView)

const Options = styled(StyledBox)`
  top: -${Spacings.small};
  right: ${Spacings.tiny};
  background-color: ${FigmaColors.lightStone};
  border-radius: ${BorderRadius.soft} ${BorderRadius.soft} 0 0;
`

const Status = styled(StyledBox)`
  top: -${Spacings.smallest};
`

const StatusCircle = styled(StyledBox)<{ $active?: boolean }>`
  width: ${Spacings.min};
  height: ${Spacings.min};
  background-color: ${({ $active }) => ($active ? FigmaColors.baseGreen : FigmaColors.lighterRed)};
  border-radius: ${BorderRadius.circle};
`
