import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { convertToRaw, EditorState } from 'draft-js'

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import { PageSubstate, PageSubstateContext } from 'src/helpers/PageSubstateHelper'
import { useCompanies, useCreateUser } from 'src/helpers/QueryHooks'
import { getUserRoles, isUserCustomer, isUserEmployee } from 'src/helpers/UsersHelper'
import {
  createEmailYupValidation,
  createNotRequiredDateYupValidation,
  createRequiredStringYupValidation,
  createStartingDateYupValidation
} from 'src/helpers/ValidationHelper'
import useLanguage from 'src/hooks/useLanguage'

import * as Yup from 'yup'
import { getPositionOptions } from '../helpers/AddEmployeeFormHelper'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import getCompanyOptions from '../helpers/getCompanyOptions'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddUserFormDesktopView from './AddUserFormDesktopView'
import AddUserFormMobileView from './AddUserFormMobileView'

type Props = {
  open: boolean
  onClose: () => void
}

const AddUserFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const { setSubstate } = useContext(PageSubstateContext)
  const isMobile = useIsMobile()
  const createUser = useCreateUser()
  const language = useLanguage()
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const roles = useMemo(() => getUserRoles(), [])
  const { data: companies = [] } = useCompanies({ archived: false })
  const companyOptions = useMemo(() => getCompanyOptions(companies), [companies, companies.length])
  const positionOptions = useMemo(() => getPositionOptions(), [])

  const defaultValues = useMemo(
    () => ({
      name: '',
      email: '',
      password: '',
      position: '',
      companies: [],
      role: '',
      plan: EditorState.createEmpty(),
      notes: EditorState.createEmpty(),
      goals: EditorState.createEmpty(),
      birthDate: null,
      startingDate: dayjs()
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddUserNameErrorRequired, language)),
        email: createEmailYupValidation(
          getText(Texts.AddUserEmailErrorRequired, language),
          getText(Texts.AddUserEmailErrorValid, language)
        ),
        password: createRequiredStringYupValidation(getText(Texts.AddUserPasswordErrorRequired, language)),
        position: createRequiredStringYupValidation(getText(Texts.AddEmployeePositionErrorRequired, language)),
        companies: Yup.mixed().required(getText(Texts.AddUserCompanyErrorRequired, language)),
        role: createRequiredStringYupValidation(getText(Texts.AddUserRoleErrorRequired, language)),
        startingDate: createStartingDateYupValidation(getText(Texts.AddEmployeeStartDateErrorInvalid, language)),
        birthDate: createNotRequiredDateYupValidation(
          getText(Texts.AddEmployeeBirthDateErrorInvalid, language),
          'birthDate'
        )
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitSuccessful },
    setError
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const values = watch()
  const isEmployeeOrCustomer = isUserEmployee(values.role) || isUserCustomer(values.role)

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { goals, notes, plan, ...restData } = data
    const userGoals = JSON.stringify(convertToRaw(goals?.getCurrentContent()))
    const userNotes = JSON.stringify(convertToRaw(notes?.getCurrentContent()))
    const userPlan = isUserEmployee(values.role) && JSON.stringify(convertToRaw(plan?.getCurrentContent()))
    createUser
      .mutateAsync({ ...restData, goals: hasData(userGoals), notes: hasData(userNotes), plan: hasData(userPlan) })
      .then(() => {
        //TODO change textkey for users once we have it exported from figma
        successNotification(Texts.NotificationAddEmployeeSuccessful)
        setSubstate(PageSubstate.DEFAULT)
        setIsCompleted(true)
      })
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    if (isSubmitSuccessful && isCompleted) {
      reset({ defaultValues })
    }
  }, [isCompleted, isSubmitSuccessful])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {isMobile && (
        <AddUserFormMobileView
          open={open}
          onClose={onClose}
          handleSubmit={handleSubmit(handleSubmitButton)}
          control={control}
          positionOptions={positionOptions}
          companyOptions={companyOptions}
          roles={roles}
          isEmployeeOrCustomer={isEmployeeOrCustomer}
          values={values}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
      {!isMobile && (
        <AddUserFormDesktopView
          open={open}
          onClose={onClose}
          handleSubmit={handleSubmit(handleSubmitButton)}
          control={control}
          positionOptions={positionOptions}
          companyOptions={companyOptions}
          roles={roles}
          isEmployeeOrCustomer={isEmployeeOrCustomer}
          values={values}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddUserFormContainer
