import { SVGProps } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import { Line } from './Line'

export interface IDetailsInformationElement {
  SVG: React.FC<SVGProps<SVGSVGElement>>
  titleTextKey: TextType
  dataTextKey: TextType
  dataText: string
}

const DetailsInformationElement: React.FC<IDetailsInformationElement> = ({
  SVG,
  titleTextKey,
  dataTextKey,
  dataText
}) => (
  <>
    {!!dataText && (
      <StyledBox fullWidth gap={Spacings.min}>
        <StyledBox fullWidth direction="row" gap={Spacings.min} align="center">
          <SVGContainer SVG={SVG} />
          <TextContainer textKey={titleTextKey} />
        </StyledBox>
        <TextContainer textKey={dataTextKey} text={dataText} />
        <Line fullWidth />
      </StyledBox>
    )}
  </>
)

export default DetailsInformationElement
