import { Texts } from 'src/figma/helpers/TextRepository'
import companyEditIcon from 'src/figma/images/companyEditIcon'
import detailsInfoIcon from 'src/figma/images/detailsInfoIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { Company } from 'src/interfaces/Dto'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import deleteIconMobileRow from '../figma/images/deleteIconMobileRow'
import { Line } from './Line'

type Props = {
  company: Company
  onInfoClick: () => void
  onEditClick?: () => void
  onDeleteClick?: () => void
}

const CompaniesMobileRowInfoWrapper: React.FC<Props> = ({ company, onInfoClick, onEditClick, onDeleteClick }) => {
  const companyVat = (!!company && !!company.vat && company.vat) || '-'
  const numberOfEmployees =
    (!!company && !!company.employees && !!company.employees.length && company.employees.length) || '-'

  return (
    <StyledBox fullWidth gap={Spacings.min}>
      <Line fullWidth />
      <AdditionalInfoWrapper fullWidth fullPadding spacing={Spacings.tiny} direction="row" justify="space-between">
        <StyledBox fullWidth gap={Spacings.min} flex="0.9">
          <AdditionalInfoWrapper fullWidth fullPadding spacing={Spacings.min}>
            <StyledBox direction="row" gap={Spacings.minimum} align="center">
              <TextContainer textKey={Texts.CompanyVAT} />
              <TextContainer textKey={Texts.CompanyVAT} text={companyVat} />
            </StyledBox>
            <StyledBox direction="row" gap={Spacings.minimum} align="center">
              <TextContainer textKey={Texts.CompanyEmployedPeople} />
              <TextContainer textKey={Texts.CompanyEmployedPeople} text={numberOfEmployees.toString()} />
            </StyledBox>
          </AdditionalInfoWrapper>
        </StyledBox>
        <StyledBox fullWidth align="center" justify="space-between" direction="row" gap={Spacings.tiny} flex="0.1">
          <StyledBox onClick={onInfoClick}>
            <SVGContainer SVG={detailsInfoIcon} />
          </StyledBox>
          {!!onEditClick && !onDeleteClick && (
            <StyledBox onClick={onEditClick}>
              <SVGContainer SVG={companyEditIcon} />
            </StyledBox>
          )}
          {!!onDeleteClick && !onEditClick && (
            <StyledBox onClick={onDeleteClick}>
              <SVGContainer SVG={deleteIconMobileRow} />
            </StyledBox>
          )}
        </StyledBox>
      </AdditionalInfoWrapper>
    </StyledBox>
  )
}

export default CompaniesMobileRowInfoWrapper

const AdditionalInfoWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.dirtyWhite};
`
