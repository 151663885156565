import React from 'react'
import { Control } from 'react-hook-form'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import { generateUUID } from '../helpers/Common'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { Document } from '../interfaces/Dto'
import { TEMPLATES_MODAL_FIGMA_WIDTH } from '../moesia/StrongHardCodedSizes'
import TextFieldController from '../moesia/components/TextFieldController'
import DocumentItem from './DocumentItem'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import UploadFileContainer from './UploadFileContainer'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  control: Control<any>
  substate: PageSubstate
  setTemplate: (files: File[]) => void
  removeTemplate: (documentTitle: string) => void
  files: Document[]
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isUploadContainerBorderRed: boolean
  isValuesChanged?: boolean
}

const AddTemplateFormDesktop: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  control,
  substate,
  setTemplate,
  removeTemplate,
  files,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isUploadContainerBorderRed,
  isValuesChanged
}) => (
  <>
    {substate !== PageSubstate.ERROR && shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
      />
    )}
    {substate !== PageSubstate.ERROR && (
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.AddTemplateButtonText}
                onClick={onSubmit}
                disabled={!isValuesChanged}
              />
            }
          />
        }
      >
        <form>
          <Wrapper fullPadding spacing={Spacings.big} gap={Spacings.medium}>
            <TextFieldController name="name" control={control} labelTextKey={Texts.AddTemplateDocumentName} />
            <TextFieldController name="wikiUrl" control={control} labelTextKey={Texts.AddTemplateDocumentWiki} />
            <UploadZoneWrapper fullWidth fullPadding $isUploadContainerBorderRed={isUploadContainerBorderRed}>
              {!files?.length && <UploadFileContainer files={files} onChange={setTemplate} fullWidth />}
              {!!files?.length &&
                files.map((document) => (
                  <DocumentItem
                    data-testid="document-container"
                    key={generateUUID()}
                    onDeleteDocument={removeTemplate}
                    document={document}
                    isUploading
                  />
                ))}
            </UploadZoneWrapper>
          </Wrapper>
        </form>
      </GenericPageModal>
    )}
  </>
)

export default AddTemplateFormDesktop

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
  min-width: ${TEMPLATES_MODAL_FIGMA_WIDTH};
  max-width: ${TEMPLATES_MODAL_FIGMA_WIDTH};
`

const UploadZoneWrapper = styled(StyledBox)<{ $isUploadContainerBorderRed: boolean }>`
  border: ${({ $isUploadContainerBorderRed }) =>
    $isUploadContainerBorderRed ? `1px solid ${FigmaColors.lighterRed}` : `1px solid ${FigmaColors.gray2}`};
  border-radius: ${BorderRadius.soft};
`
