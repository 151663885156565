import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultUser, useAppState, useUser } from 'src/components/AuthProvider'
import { Texts } from 'src/figma/helpers/TextRepository'
import userMenuLogoutIcon from 'src/figma/images/userMenuLogoutIcon'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getAccountMenuContent } from 'src/helpers/SidebarHelper'
import TokenService from 'src/helpers/TokenHelper'
import { SIDEBAR_ACCOUNT_MENU_WIDTH, SIDEBAR_ELEMENTS_BORDER } from 'src/moesia/StrongHardCodedSizes'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'
import { hasAccess } from '../helpers/UsersHelper'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import SidebarAccountMenuItem from './SidebarAccountMenuItem'
import SidebarLanguageMenu from './SidebarLanguageMenu'

const SidebarAccountMenu: React.FC = () => {
  const { state, setState } = useAppState()
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false)
  const navigate = useNavigate()
  const currentUserRole = useUser().role

  const userMenuItems = useMemo(() => getAccountMenuContent(), [])

  const handleClick = (idx: number, url?: string) =>
    idx === userMenuItems.length - 1 ? () => setIsOpenLanguageMenu(true) : () => navigate(url || '')

  const handleLogout = () => {
    setState({ ...state, user: defaultUser })
    TokenService.removeUser()
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {!isOpenLanguageMenu && (
          <Wrapper
            key={generateUUID()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <MenuUserInformation iconType={UserInformationType.NONE} />
            <StyledBox fullWidth fullPadding spacing={Spacings.min} gap={Spacings.tiny}>
              <StyledBox fullWidth>
                {!!userMenuItems &&
                  userMenuItems.map(
                    ({ icon, iconMobile, textKey, arrow, url, permissions }, idx) =>
                      (!permissions || (!!permissions.length && hasAccess(permissions, currentUserRole))) && (
                        <SidebarAccountMenuItem
                          onClick={handleClick(idx, url)}
                          key={generateUUID()}
                          icon={icon}
                          textKey={textKey}
                          arrow={arrow}
                          iconMobile={iconMobile}
                          active={location.pathname === url}
                        />
                      )
                  )}
              </StyledBox>
              <AccountMenuLogOutItem
                icon={userMenuLogoutIcon}
                textKey={Texts.UserMenuLogOutTitle}
                onClick={handleLogout}
                iconMobile={userMenuLogoutIcon}
              />
            </StyledBox>
          </Wrapper>
        )}
      </AnimatePresence>
      {!!isOpenLanguageMenu && <SidebarLanguageMenu setIsOpenLanguageMenu={setIsOpenLanguageMenu} />}
    </>
  )
}

export default SidebarAccountMenu

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: ${SIDEBAR_ACCOUNT_MENU_WIDTH};
  position: fixed;
  overflow: hidden;
  z-index: 999;
  border-radius: ${BorderRadius.soft};
  bottom: 30px; //TODO check for better solution bc this is not responsive
  left: 300px; //TODO check for better solution
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseHeavy};
`

const AccountMenuLogOutItem = styled(SidebarAccountMenuItem)`
  border-top: ${SIDEBAR_ELEMENTS_BORDER} !important;

  span {
    color: ${FigmaColors.lighterRed} !important;
  }
`
