import dayjs from 'dayjs'
import React, { useState } from 'react'
import { DATE_ORDINAL_FORMAT } from '../Constants'
import { UserInvitationStatus } from '../enums/UserInvitationStatus'
import { UserType } from '../enums/UserType'
import { getText, Texts } from '../figma/helpers/TextRepository'
import { getPrepareCompanies } from '../helpers/CompaniesPreparationHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useDeleteUser, useInviteUser, useUpdateUser } from '../helpers/QueryHooks'
import { handleUserArchiveStatus, hasAccess } from '../helpers/UsersHelper'
import useLanguage from '../hooks/useLanguage'
import { User } from '../interfaces/Dto'
import { replaceTextParams } from '../moesia/helpers/Common'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import EmployeeDetailsDesktop from './EmployeeDetailsDesktop'
import EmployeeDetailsMobile from './EmployeeDetailsMobile'

type Props = {
  open: boolean
  onClose: () => void
  employee: User
}

const EmployeeDetails: React.FC<Props> = ({ open, onClose, employee }) => {
  const isMobile = useIsMobile()
  const deleteUserMutation = useDeleteUser()
  const updateEmployeeMutation = useUpdateUser(employee._id)
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] = useState(false)
  const inviteUserMutation = useInviteUser(employee._id)
  const {
    position,
    companies,
    profile: { startingDate }
  } = employee
  const language = useLanguage()
  const date = dayjs(startingDate).format(DATE_ORDINAL_FORMAT)
  const currentUserRole = useUser().role
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const startingDateText = replaceTextParams(getText(Texts.EmployeeDetailsStartingDateWithParams, language), [date])
  const employeeCompanies = getPrepareCompanies(companies)
  const positionAndCompany = replaceTextParams(getText(Texts.EmployeeDetailsPositionWithParams, language), [
    position,
    employeeCompanies
  ])
  const isInvitationAccepted = employee.invitationStatus === UserInvitationStatus.ACCEPTED

  const handleRemindButton = () => {
    inviteUserMutation
      .mutateAsync({ invitationStatus: employee.invitationStatus })
      .then(() => {
        successNotification(Texts.NotificationRemindedSuccess)
      })
      .catch((err: Error) => {
        handleErrorMessage(err.message)
      })
  }

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(employee, updateEmployeeMutation, onClose)

  const handleDeleteButton = () => {
    deleteUserMutation
      .mutateAsync(employee._id)
      .then(() => {
        onClose()
        successNotification(Texts.NotificationEmployeeDeleted)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmation(!shouldShowDeleteConfirmation)

  return (
    <>
      {!isMobile && (
        <EmployeeDetailsDesktop
          open={open}
          onClose={onClose}
          employee={employee}
          handleDeleteButton={handleDeleteButton}
          startingDateText={startingDateText}
          positionAndCompany={positionAndCompany}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          currentUserRole={currentUserRole}
          isNotRegularUser={isNotRegularUser}
          handleRemindButton={handleRemindButton}
          isInvitationAccepted={isInvitationAccepted}
        />
      )}
      {!!isMobile && (
        <EmployeeDetailsMobile
          open={open}
          onClose={onClose}
          employee={employee}
          handleDeleteButton={handleDeleteButton}
          startingDateText={startingDateText}
          positionAndCompany={positionAndCompany}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          currentUserRole={currentUserRole}
          isNotRegularUser={isNotRegularUser}
          handleRemindButton={handleRemindButton}
          isInvitationAccepted={isInvitationAccepted}
        />
      )}
    </>
  )
}

export default EmployeeDetails
