import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged } from '../helpers/Common'
import { getBackImages, getFrontImages } from '../helpers/ImageTypesHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useCreateNewsCard, useTervelImages } from '../helpers/QueryHooks'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { useNotifications } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddNewsCardFormDesktopView from './AddNewsCardFormDesktopView'
import AddNewsCardFormMobileView from './AddNewsCardFormMobileView'

type Props = {
  open: boolean
  onClose: () => void
}

const AddNewsCardFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const [imageName, setImageName] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const { data: imagesData = [], isLoading } = useTervelImages()
  const frontImages = getFrontImages(imagesData)
  const backImages = getBackImages(imagesData)
  const isMobile = useIsMobile()
  const createNewsCardMutation = useCreateNewsCard()
  const { setSubstate } = useContext(PageSubstateContext)
  const language = useLanguage()
  const { successNotification } = useNotifications()
  const defaultValues = useMemo(
    () => ({
      frontText: '',
      backText: '',
      backTextLink: '',
      frontImage: '',
      backImage: ''
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        frontText: createRequiredStringYupValidation(getText(Texts.AddFrontTextErrorRequired, language)),
        backText: createRequiredStringYupValidation(getText(Texts.AddBackTextErrorRequired, language)),
        backTextLink: Yup.string().url(),
        frontImage: createRequiredStringYupValidation(getText(Texts.AddImageFrontErrorRequired, language)),
        backImage: createRequiredStringYupValidation(getText(Texts.AddImageBackErrorRequired, language))
      }),
    [language]
  )

  const { control, handleSubmit, watch } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleImageViewClose = () => {
    setImageName(null)
    setImageUrl(null)
  }

  const handleSubmitButton = (data: { [key: string]: unknown }) => {
    createNewsCardMutation
      .mutateAsync(data)
      .then(() => {
        onClose && onClose()
        successNotification(Texts.NotificationAddNewsCardSuccessful)
        setSubstate(PageSubstate.DEFAULT)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {!isMobile && (
        <AddNewsCardFormDesktopView
          open={open}
          onClose={onClose}
          control={control}
          onAddClick={handleSubmit(handleSubmitButton)}
          imageName={imageName}
          imageUrl={imageUrl}
          handleImageViewClose={handleImageViewClose}
          frontImages={frontImages}
          backImages={backImages}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          areImagesLoading={isLoading}
          handleShowConfirmation={handleShowConfirmation}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
      {isMobile && (
        <AddNewsCardFormMobileView
          open={open}
          onClose={onClose}
          control={control}
          onAddClick={handleSubmit(handleSubmitButton)}
          imageName={imageName}
          imageUrl={imageUrl}
          handleImageViewClose={handleImageViewClose}
          frontImages={frontImages}
          backImages={backImages}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          areImagesLoading={isLoading}
          handleShowConfirmation={handleShowConfirmation}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddNewsCardFormContainer
