const routePaths = {
  entry: '/',
  companies: '/companies',
  employees: '/employees',
  activity: '/activity',
  billing: '/billing',
  templates: '/templates',
  users: '/users',
  documentation: '/documentation',
  forgotPassword: '/forgotpassword',
  resetPassword: '/resetpassword/:resetPassowordCode',
  newsCards: '/tervel/news-cards',
  images: '/tervel/images',
  blogPosts: '/tervel/blog-posts',
  blogPost: '/tervel/blog-posts/:id'
}

export default routePaths
