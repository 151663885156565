import merge from 'deepmerge'
import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from 'react-query'

const usePatchMutation = <TData, TVariables>(
  queryKey: string[],
  fn: MutationFunction<TData, TVariables>,
  invalidateCacheKeyPrefix?: MutationKey[]
) => {
  const queryClient = useQueryClient()
  return _useMutation(fn, {
    onMutate: async (data: any) => {
      await queryClient.cancelQueries(queryKey)

      const previous = queryClient.getQueryData(queryKey)

      const newData = merge(previous as any, data as any) //TODO fix any
      queryClient.setQueryData(queryKey, newData) // rerender of react component oppourtinistic update
      return { previous, data }
    },
    // Benhind the scenes call service
    onError: (err: Error, newValue: TVariables, context: any) => {
      queryClient.setQueryData(queryKey, context.previous)
    },
    onSettled: (data, err) => {
      if (err) return

      data && queryClient.setQueryData(queryKey, data)

      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries(key))
      }
    }
  })
}

export default usePatchMutation
