import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import routePaths from '../config/RoutePaths'
import { getText, Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useForgotPassword } from '../helpers/QueryHooks'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import useIsMobile from '../moesia/hooks/useIsMobile'
import LoginForgotPasswordDesktopView from './LoginForgotPasswordDesktopView'
import LoginForgotPasswordMobileView from './LoginForgotPasswordMobileView'

const LoginForgotPasswordPage: React.FC = () => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const navigate = useNavigate()
  const forgotPassword = useForgotPassword()
  const [isSentResetRequest, setIsSentResetRequest] = useState<boolean>(false)

  const handleResetPasswordRequest = (data: { [key: string]: string }) => {
    forgotPassword
      .mutateAsync(data)
      .then(() => {
        setIsSentResetRequest(true)
      })
      .catch(({ response }) => handleErrorMessage(response.data.message))
  }

  const handleBackToLogin = () => {
    navigate(routePaths.entry)
  }

  const defaultValues = useMemo(
    () => ({
      email: ''
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: createRequiredStringYupValidation(getText(Texts.LoginEmailErrorRequired, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful }
  } = useForm<{ email: string }>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  return (
    <>
      {!isMobile && (
        <LoginForgotPasswordDesktopView
          control={control}
          handleResetPasswordRequest={handleSubmit(handleResetPasswordRequest)}
          handleBackToLogin={handleBackToLogin}
          isSentResetRequest={isSentResetRequest}
        />
      )}
      {isMobile && (
        <LoginForgotPasswordMobileView
          control={control}
          handleResetPasswordRequest={handleSubmit(handleResetPasswordRequest)}
          handleBackToLogin={handleBackToLogin}
          isSentResetRequest={isSentResetRequest}
        />
      )}
    </>
  )
}

export default LoginForgotPasswordPage
