import { Texts } from '../figma/helpers/TextRepository'
import documentImgIcon from '../figma/images/documentImgIcon'
import documentPdfIcon from '../figma/images/documentPdfIcon'
import documentUnknownIcon from '../figma/images/documentUnknownIcon'
import documentWordIcon from '../figma/images/documentWordIcon'
import { Document } from '../interfaces/Dto'

export enum DocumentFileTypes {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export enum ImageFileTypes {
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  JPEG = 'image/jpeg'
}

export const getDocumentIcon = (fileType: string) => {
  if (!fileType) return documentUnknownIcon
  switch (fileType) {
    case DocumentFileTypes.PDF:
      return documentPdfIcon
    case DocumentFileTypes.DOC:
      return documentWordIcon
    case DocumentFileTypes.DOCX:
      return documentWordIcon
    case ImageFileTypes.SVG:
    case ImageFileTypes.PNG:
    case ImageFileTypes.JPEG:
      return documentImgIcon
    default:
      return documentUnknownIcon
  }
}

export const convertFileSize = (size: number) => {
  if (!size) return
  return `${(size / 1024 / 1024).toFixed(2)} MB`
}
//we receive size in bytes but we want it converted to MB, 1 KB has 1024 bytes and 1 MB has 1024 KB, this is why I devide thwice by 1024

type Props = {
  name: string
  file: File
  userId?: string
  companyId?: string
}

export const createFileFormData = ({ name, file, userId, companyId }: Props) => {
  const fileData = new FormData()
  const fileName = file.name
  fileData.append('name', fileName)
  fileData.append(name, file)
  userId && fileData.append('userId', userId ?? '')
  companyId && fileData.append('companyId', companyId ?? '')
  return fileData
}

export enum UploadingProgress {
  LOADING = 'Loading',
  SUCCESS = 'Success',
  ERROR = 'Error'
}

export const getFileUploadingProgressMessage = (uploadingProgress?: UploadingProgress) => {
  if (!uploadingProgress) return null
  if (uploadingProgress === UploadingProgress.SUCCESS) {
    return Texts.DocumentUploadSuccess
  } else if (uploadingProgress === UploadingProgress.ERROR) {
    return Texts.DocumentUploadError
  }
}

export const getDocumentsContainerView = (document: Document, isDetails?: boolean, hasRightsToDelete?: boolean) => {
  const { _id: documentId } = document

  const isInEditForm = !isDetails && !!hasRightsToDelete && !!documentId

  return { isInEditForm }
}
