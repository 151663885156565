import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import styled from 'styled-components'
import { Texts, getText } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../hooks/useLanguage'
import { ContactPerson } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import TextFieldController from '../moesia/components/TextFieldController'
import useIsMobile from '../moesia/hooks/useIsMobile'
import CompanyContactsSubmitButton from './CompanyContactsSubmitButton'
import GenericAvatar, { AvatarType } from './GenericAvatar'

type Props = {
  control: Control<FieldValues, any>
  onAddContact: (
    contactsContainer: ContactPerson[] | [],
    setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
  ) => void
  selectedContactEmail: string | null
  isContactInfoFilled: boolean
  contactsContainer: ContactPerson[] | []
  watch: UseFormWatch<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  setSelectedContactEmail: React.Dispatch<React.SetStateAction<string | null>>
  setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>>
}

const CompanyContactSectionContainer: React.FC<Props> = ({
  control,
  onAddContact,
  selectedContactEmail,
  isContactInfoFilled,
  contactsContainer,
  watch,
  setError,
  clearErrors,
  setValue,
  setSelectedContactEmail,
  setContactsContainer
}) => {
  const isMobile = useIsMobile()
  const language = useLanguage()

  return (
    <>
      {!!isMobile && (
        <AddContactWrapper fullWidth gap={Spacings.min} fullPadding spacing={Spacings.tiny} justify="space-between">
          <StyledBox fullPadding spacing={Spacings.min}>
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} />
          </StyledBox>
          <TextFieldController
            name="contactName"
            control={control}
            labelText={getText(Texts.ContactNameLabel, language)}
          />
          <TextFieldController
            name="contactPosition"
            control={control}
            labelText={getText(Texts.ContactPositionLabel, language)}
          />
          <TextFieldController
            name="contactEmail"
            control={control}
            labelText={getText(Texts.ContactEmailLabel, language)}
          />
          <CompanyContactsSubmitButton
            onAddContact={onAddContact}
            selectedContactEmail={selectedContactEmail}
            isContactInfoFilled={isContactInfoFilled}
            contactsContainer={contactsContainer}
            watch={watch}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            setSelectedContactEmail={setSelectedContactEmail}
            setContactsContainer={setContactsContainer}
          />
        </AddContactWrapper>
      )}
      {!isMobile && (
        <ContactWrapper
          fullWidth
          direction="row"
          gap={Spacings.small}
          fullPadding
          spacing={Spacings.tiny}
          justify="space-between"
        >
          <StyledBox>
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} smallSize />
          </StyledBox>
          <StyledBox fullWidth align="flex-end" gap={Spacings.min}>
            <TextFieldController
              name="contactName"
              control={control}
              labelText={getText(Texts.ContactNameLabel, language)}
            />
            <TextFieldController
              name="contactPosition"
              control={control}
              labelText={getText(Texts.ContactPositionLabel, language)}
            />
            <TextFieldController
              name="contactEmail"
              control={control}
              labelText={getText(Texts.ContactEmailLabel, language)}
            />
            <CompanyContactsSubmitButton
              onAddContact={onAddContact}
              selectedContactEmail={selectedContactEmail}
              isContactInfoFilled={isContactInfoFilled}
              contactsContainer={contactsContainer}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              setSelectedContactEmail={setSelectedContactEmail}
              setContactsContainer={setContactsContainer}
            />
          </StyledBox>
        </ContactWrapper>
      )}
    </>
  )
}

export default CompanyContactSectionContainer

const AddContactWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.white};
  border-radius: ${Spacings.min};
  border: 1px solid ${FigmaColors.grayLight};

  .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: ${FigmaColors.white} !important;
  }
`

const ContactWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.lightStone};
  border-radius: ${Spacings.min};
  .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: ${FigmaColors.white} !important;
  }
`
