import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import deleteDocumentIcon from '../figma/images/deleteDocumentIcon'
import downloadDocumentIcon from '../figma/images/downloadDocumentIcon'
import smallCloseIcon from '../figma/images/smallCloseIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { getDocumentsContainerView } from '../helpers/DocumentsHelper'
import { Document } from '../interfaces/Dto'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import GenericConfirmationModal from './GenericConfirmationModal'
import StyledLink from './StyledLink'

type DocumentOptionsProps = {
  document: Document
  isDetails?: boolean
  isUploading?: boolean
  hasRightsToDelete?: boolean
  onDeleteDocument?: (documentId: string) => void
}

const DocumentItemOptions: React.FC<DocumentOptionsProps> = ({
  document,
  onDeleteDocument,
  isDetails,
  isUploading,
  hasRightsToDelete
}) => {
  const { isInEditForm } = getDocumentsContainerView(document, isDetails, hasRightsToDelete)
  const { signedUrl, _id: documentId, name: documentName } = document
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const isMobile = useIsMobile()

  const handleShowConfirmationModal = () => setShowConfirmationModal(!showConfirmationModal)

  const handleDeleteDocument = (documentId: string) => {
    if (!onDeleteDocument) return

    if (documentId) {
      onDeleteDocument(documentId)
      return
    }

    onDeleteDocument(documentName)
  }

  return (
    <>
      {showConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowConfirmationModal}
          onConfirm={() => handleDeleteDocument(documentId)}
          confirmationTitle={documentId ? Texts.ConfirmDeleteDocTitle : Texts.ConfirmDeleteTemplateTitle}
          confirmationQuestion={documentId ? Texts.ConfirmDeleteDocQuestion : Texts.ConfirmDeleteTemplateQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          cancelButtonTextKey={Texts.CancelButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize={isMobile}
        />
      )}

      {!!isUploading && !!onDeleteDocument && (
        <CloseIcon data-testid="close-button" onClick={handleShowConfirmationModal}>
          <SVGContainer SVG={smallCloseIcon} />
        </CloseIcon>
      )}
      {isInEditForm && (
        <ActionIcon data-testid="delete-button" onClick={handleShowConfirmationModal}>
          <StyledSVGContainer SVG={deleteDocumentIcon} />
        </ActionIcon>
      )}
      {!!isDetails && (
        <StyledBox data-testid="download-button">
          <StyledLink href={signedUrl} openInNewTab>
            <ActionIcon>
              <StyledSVGContainer SVG={downloadDocumentIcon} />
            </ActionIcon>
          </StyledLink>
        </StyledBox>
      )}
    </>
  )
}

export default DocumentItemOptions

const StyledSVGContainer = styled(SVGContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacings.smallest};
`

const CloseIcon = styled(StyledBox)`
  cursor: pointer;
`
const ActionIcon = styled(StyledBox)`
  border-radius: ${BorderRadius.circle};
  cursor: pointer;

  &:hover {
    background-color: ${FigmaColors.gray2} !important;
  }
`
