import React, { useContext, useMemo, useState } from 'react'
import { PageSubstate, PageSubstateContext, getUsersPageSubstate } from 'src/helpers/PageSubstateHelper'
import { User } from 'src/interfaces/Dto'
import { Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useArchivedUsers, useDeleteUser } from '../helpers/QueryHooks'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import UsersTableDesktopView from './UsersTableDesktopView'
import UsersTableMobileView from './UsersTableMobileView'

type UsersTableProps = {
  users: User[]
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const deleteUserMutation = useDeleteUser()
  const { data: archivedUsers = [] } = useArchivedUsers()
  const [shouldShowArchived, setShouldShowArchived] = useState(false)
  const isMobile = useIsMobile()
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)

  const handleShouldShowArchived = () => setShouldShowArchived(!shouldShowArchived)
  const activeUsers = () => users.filter((user) => !user.archived)
  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)

  const substateView = useMemo(
    () => getUsersPageSubstate(substate, setSubstate, selectedUser, setSelectedUser),
    [substate, setSubstate, selectedUser]
  )

  const deleteMutation = (userId: string) => {
    deleteUserMutation
      .mutateAsync(userId)
      .then(() => successNotification(Texts.NotificationUserDeleted))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleDeleteUser = () => {
    if (!!selectedUser) {
      handleShowDeleteConfirmation()
      deleteMutation(selectedUser._id)
    }
  }

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && !isMobile && (
        <UsersTableDesktopView
          users={shouldShowArchived ? archivedUsers : activeUsers()}
          setSubstate={setSubstate}
          setSelectedUser={setSelectedUser}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          onUserDelete={handleDeleteUser}
        />
      )}
      {substate !== PageSubstate.ERROR && isMobile && (
        <UsersTableMobileView
          users={shouldShowArchived ? archivedUsers : activeUsers()}
          setSubstate={setSubstate}
          setSelectedUser={setSelectedUser}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          onUserDelete={handleDeleteUser}
        />
      )}
    </>
  )
}

export default UsersTable
