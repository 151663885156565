import { Control, FieldValues } from 'react-hook-form'
import {
  AutoCompleteChipController,
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from 'src/moesia/components/ReactHookComponents'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { BLOG_POST_BIG_FIELD_HEIGHT } from '../moesia/StrongHardCodedSizes'
import EditorController from '../moesia/components/EditorController'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import TextFieldController from '../moesia/components/TextFieldController'
import ExpandedImageModal from './ExpandedImageModal'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  control: Control<FieldValues, any>
  onAddBlogPost: () => void
  thumbnailImageOptions: {
    value: string
    label: string
  }[]
  areImagesLoading: boolean
  categoryOptions: {
    value: string
    label: string
  }[]
  areCategoriesLoading: boolean
  imageName: string | null
  imageUrl: string | null
  setImageName: React.Dispatch<React.SetStateAction<string | null>>
  setImageUrl: React.Dispatch<React.SetStateAction<string | null>>
  handleImageViewClose: () => void
  handleClose: () => void
  handleShowConfirmation: () => void
  shouldShowConfirmationModal: boolean
  isValuesChanged?: boolean
}

const AddBlogPostMobileView: React.FC<Props> = ({
  open,
  onClose,
  control,
  onAddBlogPost,
  thumbnailImageOptions,
  areImagesLoading,
  categoryOptions,
  areCategoriesLoading,
  imageName,
  imageUrl,
  setImageName,
  setImageUrl,
  handleImageViewClose,
  handleClose,
  handleShowConfirmation,
  shouldShowConfirmationModal,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        confirmButtonColor={FigmaColors.bhBluePrimaryButton}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    {!!imageUrl && !!imageName && (
      <ExpandedImageModal isOpen onClose={handleImageViewClose} imageName={imageName} imageUrl={imageUrl} />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddButtonText}
              onClick={() => isValuesChanged && onAddBlogPost()}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <StyledBox fullWidth fullPadding spacing={Spacings.large}>
        <form>
          <StyledBox fullWidth left right spacing={Spacings.small} bottom={Spacings.medium} gap={Spacings.small}>
            <StyledBox fullWidth align="center" gap={Spacings.smallest}>
              <TextContainer textKey={Texts.BlogPostEditLabel} />
              <HorizontalLine />
            </StyledBox>
            {!areImagesLoading && (
              <AutoCompleteController
                name="thumbnail"
                control={control}
                options={thumbnailImageOptions}
                labelTextKey={Texts.AdddThumbnailLabel}
                setImageName={setImageName}
                setImageUrl={setImageUrl}
                showViewButton
              />
            )}
            <TextFieldController name="title" control={control} labelTextKey={Texts.HeadingOfBlogPostLabel} />
            <TextFieldController name="previewContent" control={control} labelTextKey={Texts.PreviewOfBlogPostLabel} />
            <EditorController
              name="content"
              labelTextKey={Texts.WriteYourMainContentLabel}
              control={control}
              customHeight={BLOG_POST_BIG_FIELD_HEIGHT}
            />
            {!areCategoriesLoading && !!categoryOptions.length && (
              <SelectChipController
                name="category"
                control={control}
                options={categoryOptions}
                labelTextKey={Texts.CategoriesBlogPostLabel}
              />
            )}
            <TextFieldController name="author" control={control} labelTextKey={Texts.AuthorBlogPostLabel} />
            <TextFieldController name="authorUrl" control={control} labelTextKey={Texts.AuthorLinkedinBlogPostLabel} />
            <DatePickerController name="publishedAt" control={control} labelTextKey={Texts.PublishDateLabel} />
            <AutoCompleteChipController name="seoTags" control={control} labelTextKey={Texts.SeoTagsBlogPostLabel} />
          </StyledBox>
        </form>
      </StyledBox>
    </GenericPageModal>
  </>
)

export default AddBlogPostMobileView

const HorizontalLine = styled.hr`
  width: 30%;
  color: ${FigmaColors.black};
`
