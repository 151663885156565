import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from 'react-query'

const useCreateMutation = <TData, TVariables>(
  queryKey: string[],
  fn: MutationFunction<TData, TVariables>,
  invalidateCacheKeyPrefix?: MutationKey[]
) => {
  const queryClient = useQueryClient()
  return _useMutation(queryKey, fn, {
    onMutate: async (item: TVariables) => {
      await queryClient.cancelQueries(queryKey)
      const previous: Array<TData> = queryClient.getQueryData(queryKey) || []
      const data = Object.assign(previous, item)
      queryClient.setQueryData(queryKey, data)
      return { previous, data }
    },
    onError: (err: Error, newValue: TVariables, context) => {
      queryClient.setQueryData(queryKey, context?.previous || [])
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onSuccess: () => {
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries(key))
      }
    }
  })
}
export default useCreateMutation
