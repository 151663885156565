import LoginForgotPasswordPage from './components/LoginForgotPasswordPage'
import LoginResetPasswordPage from './components/LoginResetPasswordPage'
import routePaths from './config/RoutePaths'

const RouterPublicRoutes = [
  { path: routePaths.forgotPassword, component: LoginForgotPasswordPage },
  { path: routePaths.resetPassword, component: LoginResetPasswordPage }
]

export default RouterPublicRoutes
