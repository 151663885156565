import React from 'react'
import styled from 'styled-components'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import StyledBox from 'src/moesia/components/StyledBox'
import { DEFAULT_LINE_COMPONENT_HEIGHT } from 'src/moesia/StrongHardCodedSizes'

type LineProps = {
  height?: string
  color?: string
}
export const Line = React.memo(styled(StyledBox)<LineProps>`
  height: ${({ height }) => (height ? height : DEFAULT_LINE_COMPONENT_HEIGHT)};
  background-color: ${({ color }) => (color ? color : FigmaColors.grayLight)};
`)
