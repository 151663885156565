import { DELETED_COMPANY_NAME, DELETED_USER_NAME } from '../Constants'
import { UserType } from '../enums/UserType'
import { Company, User } from '../interfaces/Dto'
import { getCompanies, getUsers } from './ApiHandler'

export const getArchivedCompanies = () =>
  getCompanies({ archived: true }).then((res) =>
    res.filter((company: Company) => company.name !== DELETED_COMPANY_NAME)
  )

export const getArchivedUsers = () =>
  getUsers({ archived: true }).then((res) => res.filter((user: User) => user.name !== DELETED_USER_NAME))

export const getArchivedEmployees = () =>
  getUsers({ role: UserType.EMPLOYEE, archived: true }).then((res) =>
    res.filter((employee: User) => employee.name !== DELETED_USER_NAME)
  )
