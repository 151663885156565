import styled from 'styled-components'
import React, { SVGProps } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import { Language } from 'src/helpers/SidebarHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import selectedLanguageCircle from 'src/figma/images/selectedLanguageCircle'
import notSelectedLanguageCircle from 'src/figma/images/notSelectedLanguageCircle'

type Props = {
  flag: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  value?: Language
  onClick?: () => void
  isSelectedLanguage: boolean
}

const SidebarLanguageMenuItem: React.FC<Props> = ({ flag, textKey, onClick, isSelectedLanguage }) => (
  <MenuItem
    onClick={onClick}
    direction="row"
    fullWidth
    fullPadding
    spacing={Spacings.minimum}
    align="center"
    justify="space-between"
  >
    <StyledBox direction="row" gap={Spacings.min} align="center">
      <SVGContainer SVG={flag} />
      <TextContainer textKey={textKey} />
    </StyledBox>
    <StyledBox align="center">
      <SVGContainer SVG={isSelectedLanguage ? selectedLanguageCircle : notSelectedLanguageCircle} />
    </StyledBox>
  </MenuItem>
)

export default SidebarLanguageMenuItem

const MenuItem = styled(StyledBox)`
  cursor: pointer;
`
