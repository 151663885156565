import { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import { getIsItemContentNotEmpty } from '../helpers/MobileDetailsBoxConditionHelper'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import { Line } from './Line'

type Props = {
  itemIcon: React.FC<React.SVGProps<SVGSVGElement>>
  itemTitle: TextType
  itemInfo: JSX.Element | string
  itemButton?: ReactNode
}

const EmployeeDetailsItemDesktop: React.FC<Props> = ({ itemIcon, itemTitle, itemInfo, itemButton }) => {
  const isItemContentNotEmpty = useMemo(() => getIsItemContentNotEmpty(itemInfo), [itemInfo])

  return (
    <>
      {!!itemInfo && !!isItemContentNotEmpty && (
        <StyledBox fullWidth gap={Spacings.min}>
          <StyledBox fullWidth direction="row" gap={Spacings.min} align="center">
            <SVGContainer SVG={itemIcon} />
            <TextContainer textKey={itemTitle} />
          </StyledBox>
          <InfoWrapper fullWidth direction="row" justify="space-between" align="center" flexWrap="wrap">
            {typeof itemInfo === 'string' ? (
              <TextContainer textKey={Texts.EmployeeDetailsNotes} text={itemInfo} />
            ) : (
              itemInfo
            )}
            {itemButton}
          </InfoWrapper>
          <Line fullWidth />
        </StyledBox>
      )}
    </>
  )
}

export default EmployeeDetailsItemDesktop

const InfoWrapper = styled(StyledBox)`
  word-break: break-all;
`
