import StyledBox from './StyledBox'
import TextContainer from './TextContainer'
import { TextType } from '../interfaces/FigmaTypes'
import { Texts } from 'src/figma/helpers/TextRepository'

const NotificationInfo = (infoMessage: TextType) => (
  <StyledBox>
    <TextContainer textKey={Texts.NotificationInfo} />
    <TextContainer textKey={infoMessage} />
  </StyledBox>
)

export default NotificationInfo
