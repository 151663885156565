export const COMPANIES = 'COMPANIES'
export const ARCHIVED_COMPANIES = 'ARCHIVED_COMPANIES'
export const COMPANY = 'COMPANY'
export const USER = 'USER'
export const USERS = 'USERS'
export const ARCHIVED_USERS = 'ARCHIVED_USERS'
export const EMPLOYEES = 'EMPLOYEES'
export const OVERVIEW = 'OVERVIEW'
export const DOCUMENT = 'DOCUMENT'
export const DOCUMENTS = 'DOCUMENTS'
export const TEMPLATE = 'TEMPLATE'
export const TEMPLATES = 'TEMPLATES'
export const NEWS_CARD = 'NEWS_CARD'
export const BLOG_POST = 'BLOG_POST'
export const BLOG_POSTS = 'BLOG_POSTS'
export const NEWS_CARDS = 'NEWS_CARDS'
export const IMAGE = 'IMAGE'
export const IMAGES = 'IMAGES'
export const TERVEL_IMAGES = 'TERVEL_IMAGES'
export const BLOG_POST_CATEGORY = 'BLOG_POST_CATEGORY'
export const BLOG_POSTS_CATEGORIES = 'BLOG_POSTS_CATEGORIES'
