import merge from 'deepmerge'
import { MutationFunction, MutationKey, useMutation as _useMutation, useQueryClient } from 'react-query'

const overwriteMerge = (sourceArray: any) => sourceArray
const useMutation = <TData, TVariables>(
  queryKey: string[],
  fn: MutationFunction<TData, TVariables>,
  invalidateCacheKeyPrefix?: MutationKey[]
) => {
  const queryClient = useQueryClient()
  return _useMutation(fn, {
    onMutate: async (data: TVariables) => {
      await queryClient.cancelQueries(queryKey)
      const previous: Array<TData> = queryClient.getQueryData(queryKey) || []
      const newData = merge(previous, data as any, { arrayMerge: overwriteMerge })
      queryClient.setQueryData(queryKey, newData)
      return { previous, data }
    },
    onError: (err: Error, newValue: TVariables, context) => {
      queryClient.setQueryData(queryKey, context?.previous || [])
    },
    onSettled: (data) => {
      queryClient.setQueryData(queryKey, data)
      queryClient.invalidateQueries(queryKey)
      if (invalidateCacheKeyPrefix) {
        invalidateCacheKeyPrefix.forEach((key) => queryClient.invalidateQueries(key))
      }
    }
  })
}
export default useMutation
