import { useContext, useEffect, useMemo, useState } from 'react'
import { ImagesType } from '../enums/ImagesType'
import { PageSubstate, PageSubstateContext, getImagesPageSubstate } from '../helpers/PageSubstateHelper'
import { TervelImage } from '../interfaces/Dto'
import useIsMobile from '../moesia/hooks/useIsMobile'
import ImagesSectionDesktop from './ImagesSectionDesktop'
import ImagesSectionMobile from './ImagesSectionMobile'

type Props = {
  images: TervelImage[]
}

const ImagesSectionContainer: React.FC<Props> = ({ images }) => {
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const isMobile = useIsMobile()
  const [filterType, setFilterType] = useState<ImagesType | null>(null)
  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [selectedImage, setSelectedImage] = useState<TervelImage | null>(null)
  const substateView = useMemo(
    () => getImagesPageSubstate(substate, setSubstate, selectedImage, setSelectedImage),
    [substate, setSubstate, selectedImage]
  )

  useEffect(() => setShowFilterOptions(false), [filterType])

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && isMobile && (
        <ImagesSectionMobile
          images={images}
          onAddImageClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          filterType={filterType}
          showFilterOptions={showFilterOptions}
          setShowFilterOptions={setShowFilterOptions}
          setFilterType={setFilterType}
        />
      )}
      {substate !== PageSubstate.ERROR && !isMobile && (
        <ImagesSectionDesktop
          images={images}
          onAddImageClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          filterType={filterType}
          showFilterOptions={showFilterOptions}
          setShowFilterOptions={setShowFilterOptions}
          setFilterType={setFilterType}
        />
      )}
    </>
  )
}

export default ImagesSectionContainer
