import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { convertToRaw } from 'draft-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { getBlogPostThumbnailImages } from '../helpers/ImageTypesHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useCategories, useCreateBlogPost, useTervelImages } from '../helpers/QueryHooks'
import { createLinkedInYupValidation, createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import { getCategoryOptions } from '../helpers/getCategoryOptions'
import useLanguage from '../hooks/useLanguage'
import { BlogPostCategory } from '../interfaces/Dto'
import { useNotifications } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddBlogPostDesktopView from './AddBlogPostDesktopView'
import AddBlogPostMobileView from './AddBlogPostMobileView'

type Props = {
  open: boolean
  onClose: () => void
}

const AddBlogPostContainer: React.FC<Props> = ({ open, onClose }) => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const [imageName, setImageName] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const createBlogPostMutation = useCreateBlogPost()
  const { data: images = [], isLoading: areImagesLoading } = useTervelImages()
  const thumbnailImageOptions = getBlogPostThumbnailImages(images)
  const { data: categories = [] as BlogPostCategory[], isLoading: areCategoriesLoading } = useCategories()
  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories])
  const { setSubstate } = useContext(PageSubstateContext)
  const language = useLanguage()
  const { successNotification } = useNotifications()
  const defaultValues = useMemo(
    () => ({
      title: '',
      category: [],
      author: '',
      authorUrl: '',
      status: '',
      thumbnail: '',
      previewContent: '',
      views: 0,
      content: '',
      publishedAt: dayjs()
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        thumbnail: createRequiredStringYupValidation(getText(Texts.AddThumbnailErrorRequired, language)),
        title: createRequiredStringYupValidation(getText(Texts.AddHeadingErrorRequired, language)),
        previewContent: createRequiredStringYupValidation(getText(Texts.AddPreviewContentErrorRequired, language)),
        author: createRequiredStringYupValidation(getText(Texts.AddAuthorErrorRequired, language)),
        authorUrl: createLinkedInYupValidation(getText(Texts.InvalidLinkedinProfile, language))
      }),
    [language]
  )

  const { control, watch, handleSubmit } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleCreateBlogPost = (data: { [key: string]: any }) => {
    const { content, ...restData } = data
    const blogPostContent = JSON.stringify(convertToRaw(content?.getCurrentContent()))

    createBlogPostMutation
      .mutateAsync({
        ...restData,
        content: hasData(blogPostContent)
      })
      .then((res) => {
        onClose && onClose()
        successNotification(Texts.NotificationNewBlogPostSuccessful)
        setSubstate(PageSubstate.DEFAULT)
        navigate(res._id)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  const handleImageViewClose = () => {
    setImageName(null)
    setImageUrl(null)
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {!isMobile && (
        <AddBlogPostDesktopView
          open={open}
          onClose={onClose}
          control={control}
          onAddBlogPost={handleSubmit(handleCreateBlogPost)}
          thumbnailImageOptions={thumbnailImageOptions}
          areImagesLoading={areImagesLoading}
          categoryOptions={categoryOptions}
          areCategoriesLoading={areCategoriesLoading}
          imageName={imageName}
          imageUrl={imageUrl}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          handleImageViewClose={handleImageViewClose}
          handleClose={handleClose}
          handleShowConfirmation={handleShowConfirmation}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          isValuesChanged={isValuesChanged}
        />
      )}
      {isMobile && (
        <AddBlogPostMobileView
          open={open}
          onClose={onClose}
          control={control}
          onAddBlogPost={handleSubmit(handleCreateBlogPost)}
          thumbnailImageOptions={thumbnailImageOptions}
          areImagesLoading={areImagesLoading}
          categoryOptions={categoryOptions}
          areCategoriesLoading={areCategoriesLoading}
          imageName={imageName}
          imageUrl={imageUrl}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          handleImageViewClose={handleImageViewClose}
          handleClose={handleClose}
          handleShowConfirmation={handleShowConfirmation}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddBlogPostContainer
