import React, { useMemo } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getOverviewNotificationBoxes, getOverviewRedirectionBoxes } from 'src/helpers/OverviewHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'
import { Line } from './Line'
import OverviewNotificationBox from './OverviewNotificationBox'
import OverviewRedirectionBox from './OverviewRedirectionBox'

const OverviewMobile: React.FC = () => {
  const redirectionBoxes = useMemo(() => getOverviewRedirectionBoxes(), [])
  const notificationBoxes = useMemo(() => getOverviewNotificationBoxes(), [])

  return (
    <StyledBox
      gap={Spacings.big}
      top={Spacings.max}
      bottom={Spacings.big}
      left
      right
      spacing={Spacings.large}
      align="center"
    >
      <StyledBox fullWidth top={Spacings.large}>
        <Line fullWidth />
      </StyledBox>
      <BoxesWrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!notificationBoxes &&
          notificationBoxes.map(({ icon, title, notificationMessage, emptyStateTextKey }) => (
            <OverviewNotificationBox
              key={generateUUID()}
              icon={icon}
              title={title}
              notificationMessage={notificationMessage}
              emptyStateTextKey={emptyStateTextKey}
            />
          ))}
      </BoxesWrapper>
      <Line fullWidth />
      <BoxesWrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!redirectionBoxes &&
          redirectionBoxes.map(({ image, title, description, url }) => (
            <OverviewRedirectionBox
              key={generateUUID()}
              image={image}
              title={title}
              description={description}
              url={url}
            />
          ))}
      </BoxesWrapper>
    </StyledBox>
  )
}

export default OverviewMobile

const BoxesWrapper = styled(StyledBox)`
  flex-wrap: wrap;
`
