import React, { SVGProps } from 'react'
import { useLocation } from 'react-router-dom'
import { isButton, isDivider, isExpandable } from '../helpers/Common'
import { SidebarItemType, SubButton } from '../helpers/SidebarHelper'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import SidebarButton from './SidebarButton'
import SidebarDivider from './SidebarDivider'
import SidebarSubButton from './SidebarSubButton'

type Props = {
  type: SidebarItemType
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url?: string
  idx: number
  section?: string
  subButtons?: SubButton[]
  showSubButtons: boolean[]
  setShowSubButtons: React.Dispatch<React.SetStateAction<boolean[]>>
  handleCloseMenu?: () => void
}

const SidebarNavigationItem: React.FC<Props> = ({
  type,
  icon,
  textKey,
  url,
  idx,
  section,
  subButtons,
  showSubButtons,
  setShowSubButtons,
  handleCloseMenu
}) => {
  const location = useLocation()

  const doesPathNameIncludesSection = (section?: string) => !!section && location.pathname.includes(section)

  return (
    <>
      {isExpandable(type) && (
        <SidebarButton
          icon={icon}
          textKey={textKey}
          url={url}
          hasSubButtons
          showSubButtons={showSubButtons[idx]}
          setShowSubButtons={() => {
            const newShowSubButtons = [...showSubButtons]
            newShowSubButtons[idx] = !newShowSubButtons[idx]
            setShowSubButtons(newShowSubButtons)
          }}
          section={section}
          doesPathNameIncludesSection={doesPathNameIncludesSection}
          handleCloseMenu={handleCloseMenu}
        />
      )}
      {isButton(type) && <SidebarButton icon={icon} textKey={textKey} url={url} handleCloseMenu={handleCloseMenu} />}
      {!showSubButtons[idx] && doesPathNameIncludesSection(section) && !!subButtons?.length && (
        <SidebarSubButton subButtons={subButtons} handleCloseMenu={handleCloseMenu} />
      )}
      {!!showSubButtons[idx] && !doesPathNameIncludesSection(section) && !!subButtons?.length && (
        <SidebarSubButton subButtons={subButtons} handleCloseMenu={handleCloseMenu} />
      )}
      {isDivider(type) && <SidebarDivider textKey={textKey} />}
    </>
  )
}

export default SidebarNavigationItem
