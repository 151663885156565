import styled from 'styled-components'
import { ImagesType } from '../enums/ImagesType'
import { Texts } from '../figma/helpers/TextRepository'
import uploadDocumentsIcon from '../figma/images/uploadDocumentsIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { TervelImage } from '../interfaces/Dto'
import { IMAGE_ITEM_FIGMA_HEIGHT_MOBILE, IMAGE_ITEM_FIGMA_WIDTH_MOBILE } from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import ImageItemsContainer from './ImageItemsContainer'
import ImagesFilterContainer from './ImagesFilterContainer'

type Props = {
  images: TervelImage[]
  onAddImageClick: () => void
  filterType: ImagesType | null
  showFilterOptions: boolean
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>
  setFilterType: React.Dispatch<React.SetStateAction<ImagesType | null>>
}

const ImagesSectionMobile: React.FC<Props> = ({
  images,
  onAddImageClick,
  filterType,
  showFilterOptions,
  setShowFilterOptions,
  setFilterType
}) => (
  <StyledBox left right bottom spacing={Spacings.tiny}>
    <ImagesFilterContainer
      showFilterOptions={showFilterOptions}
      setShowFilterOptions={setShowFilterOptions}
      filterType={filterType}
      setFilterType={setFilterType}
    />
    <StyledBox top={Spacings.tiny} direction="row" justify="flex-start" gap={Spacings.small} flexWrap="wrap">
      <UploadImageButton onClick={onAddImageClick} gap={Spacings.tiny} align="center" justify="center">
        <SVGContainer SVG={uploadDocumentsIcon} />
        <TextContainer textKey={Texts.UploadImage} />
      </UploadImageButton>
      {!!images?.length && <ImageItemsContainer images={images} filterType={filterType} />}
    </StyledBox>
  </StyledBox>
)

export default ImagesSectionMobile

const UploadImageButton = styled(StyledBox)`
  height: ${IMAGE_ITEM_FIGMA_HEIGHT_MOBILE};
  width: ${IMAGE_ITEM_FIGMA_WIDTH_MOBILE};
  border: 1px dashed ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseSoft};
`
