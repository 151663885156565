import { SVGProps } from 'react'
import { BG, EN, SV } from 'src/Constants'
import { State } from 'src/components/AuthProvider'
import routePaths from 'src/config/RoutePaths'
import { Texts } from 'src/figma/helpers/TextRepository'
import bulgariaFlag from 'src/figma/images/bulgariaFlag'
import navigationBillingIcon from 'src/figma/images/navigationBillingIcon'
import navigationCustomersIcon from 'src/figma/images/navigationCustomersIcon'
import navigationEmployeesIcon from 'src/figma/images/navigationEmployeesIcon'
import navigationImagesIcon from 'src/figma/images/navigationImagesIcon'
import navigationNewsCardsIcon from 'src/figma/images/navigationNewsCardsIcon'
import navigationOverviewIcon from 'src/figma/images/navigationOverviewIcon'
import navigationTemplatesIcon from 'src/figma/images/navigationTemplatesIcon'
import navigationTervelIcon from 'src/figma/images/navigationTervelIcon'
import swedenFlag from 'src/figma/images/swedenFlag'
import ukFlag from 'src/figma/images/ukFlag'
import userMenuAccountSettingsIcon from 'src/figma/images/userMenuAccountSettingsIcon'
import userMenuAccountSettingsIconMobile from 'src/figma/images/userMenuAccountSettingsIconMobile'
import userMenuArrowRight from 'src/figma/images/userMenuArrowRight'
import userMenuLanguageIcon from 'src/figma/images/userMenuLanguageIcon'
import userMenuLanguagesIconMobile from 'src/figma/images/userMenuLanguagesIconMobile'
import userMenuManageUsersIcon from 'src/figma/images/userMenuManageUsersIcon'
import userMenuManageUsersIconMobile from 'src/figma/images/userMenuManageUsersIconMobile'
import userMenuUserGuidesIcon from 'src/figma/images/userMenuUserGuidesIcon'
import userMenuUserGuidesIconMobile from 'src/figma/images/userMenuUserGuidesIconMobile'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import { UserType } from '../enums/UserType'
import blogPostIcon from '../figma/images/blogPostIcon'

export enum SidebarItemType {
  BUTTON = 'Button',
  SUBBUTTON = 'Subbutton',
  EXPANDABLE = 'Expandable',
  DIVIDER = 'Divider'
}

export type NavigationContent = {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url?: string
  type: SidebarItemType
  subButtons?: SubButton[]
  section?: string
  permissions?: UserType[]
}

export type SubButton = {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url?: string
  type: SidebarItemType
}

export const getSidebarContent = (): NavigationContent[] => [
  {
    icon: navigationOverviewIcon,
    textKey: Texts.NavigationOverviewTitle,
    url: routePaths.entry,
    type: SidebarItemType.BUTTON
  },
  // {
  //   icon: navigationActivityIcon,
  //   textKey: Texts.NavigationActivityTitle,
  //   url: routePaths.activity,
  //   type: SidebarItemType.BUTTON
  // },
  {
    textKey: Texts.NavigationGeneralTitle,
    type: SidebarItemType.DIVIDER
  },
  {
    icon: navigationCustomersIcon,
    textKey: Texts.NavigationCustomersTitle,
    url: routePaths.companies,
    type: SidebarItemType.BUTTON,
    permissions: [UserType.ADMIN, UserType.MANAGER]
  },
  {
    icon: navigationEmployeesIcon,
    textKey: Texts.NavigationEmployeesTitle,
    url: routePaths.employees,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationBillingIcon,
    textKey: Texts.NavigationBillingTitle,
    url: routePaths.billing,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationTemplatesIcon,
    textKey: Texts.NavigationTemplatesTitle,
    url: routePaths.templates,
    type: SidebarItemType.BUTTON
  },
  {
    icon: navigationTervelIcon,
    textKey: Texts.NavigationTervelTitle,
    type: SidebarItemType.EXPANDABLE,
    subButtons: [
      {
        icon: navigationNewsCardsIcon,
        textKey: Texts.NavigationNewsCardsTitle,
        url: routePaths.newsCards,
        type: SidebarItemType.SUBBUTTON
      },
      {
        icon: navigationImagesIcon,
        textKey: Texts.NavigationImagesTitle,
        url: routePaths.images,
        type: SidebarItemType.SUBBUTTON
      },
      {
        icon: blogPostIcon,
        textKey: Texts.BlogPostsTitle,
        url: routePaths.blogPosts,
        type: SidebarItemType.SUBBUTTON
      }
    ],
    section: '/tervel',
    permissions: [UserType.ADMIN, UserType.MANAGER]
  }
]

interface AccountMenuItem {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconMobile: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  arrow?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  url?: string
  permissions?: UserType[]
}

export const getAccountMenuContent = (): AccountMenuItem[] => [
  {
    icon: userMenuAccountSettingsIcon,
    iconMobile: userMenuAccountSettingsIconMobile,
    textKey: Texts.UserMenuAccountSettingsTitle
  },
  {
    icon: userMenuManageUsersIcon,
    iconMobile: userMenuManageUsersIconMobile,
    textKey: Texts.UserMenuManageUsersTitle,
    url: routePaths.users,
    permissions: [UserType.ADMIN, UserType.MANAGER]
  },
  {
    icon: userMenuUserGuidesIcon,
    iconMobile: userMenuUserGuidesIconMobile,
    textKey: Texts.UserMenuUserGuidesTitle
  },
  {
    icon: userMenuLanguageIcon,
    iconMobile: userMenuLanguagesIconMobile,
    textKey: Texts.UserMenuLanguagesTitle,
    arrow: userMenuArrowRight
  }
]

export type Language = 'bg' | 'default' | 'en' | 'sv'

interface LanguageMenuItem {
  flag: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  value: Language
}

export const getLanguageMenuContent = (): LanguageMenuItem[] => [
  {
    flag: ukFlag,
    textKey: Texts.English,
    value: EN
  },
  {
    flag: swedenFlag,
    textKey: Texts.Swedish,
    value: SV
  },
  {
    flag: bulgariaFlag,
    textKey: Texts.Bulgarian,
    value: BG
  }
]

export const isLanguageSelected = (currentLanguage: Language, selectedLanguage: LanguageMenuItem): boolean =>
  currentLanguage === selectedLanguage.value

export const getSelectedLanguage = (state: State) => {
  const languages = getLanguageMenuContent()
  return languages.find((lang) => lang.value === state.language) || languages[0]
}
