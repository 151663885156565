import React from 'react'
import { useNavigate } from 'react-router-dom'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { OverviewRedirectionBoxType } from 'src/helpers/OverviewHelper'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from 'src/moesia/Constants'
import { OVERVIEW_ACTION_BOX_WIDTH, OVERVIEW_REDIRECTION_BOX_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'

const OverviewRedirectionBox: React.FC<OverviewRedirectionBoxType> = ({ image, title, description, url }) => {
  const navigate = useNavigate()
  return (
    <Wrapper
      fullPadding
      direction="row"
      justify="space-between"
      spacing={Spacings.medium}
      onClick={() => navigate(url)}
    >
      <StyledBox gap={Spacings.min}>
        <TextContainer textKey={title} />
        <TextContainer textKey={description} />
      </StyledBox>
      <ImageContainer imageKey={image} minHeight />
    </Wrapper>
  )
}

export default OverviewRedirectionBox

const Wrapper = styled(StyledBox)`
  flex: 0 1 ${OVERVIEW_ACTION_BOX_WIDTH};
  height: ${OVERVIEW_REDIRECTION_BOX_HEIGHT};
  background-color: ${FigmaColors.white};
  border-radius: ${BorderRadius.soft};
  cursor: pointer;
  box-shadow: ${Shadows.baseHeavy};
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${REGULAR_TRANSFORM};
  }
`
