import { memo } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import StyledBox from '../moesia/components/StyledBox'

type Props = {
  pageHeading: TextType
  pageSubheading: TextType
  buttonTextKey: TextType
  onButtonClick?: () => void
  isModalOpen?: boolean
  modalComponent?: React.ReactNode
}

const EmptyStatePageDesktopView: React.FC<Props> = ({
  pageHeading,
  pageSubheading,
  buttonTextKey,
  onButtonClick,
  isModalOpen,
  modalComponent
}) => (
  <>
    <Wrapper fullWidth fullPadding spacing={Spacings.medium} top={Spacings.huge} position="relative">
      <StyledBox gap={Spacings.small}>
        <TextWrapper gap={Spacings.min}>
          <TextContainer textKey={pageHeading} />
          <TextContainer textKey={pageSubheading} />
        </TextWrapper>
        <SubmitButton onClick={onButtonClick} variant="primary" textKey={buttonTextKey} />
      </StyledBox>
      <ImageWrapper alignSelf="center" fullPadding spacing={Spacings.medium}>
        <ImageContainer imageKey={Images.emptyStatePhoto} minHeight />
      </ImageWrapper>
    </Wrapper>
    {isModalOpen && !!modalComponent && modalComponent}
  </>
)

export default memo(EmptyStatePageDesktopView)

const Wrapper = styled(StyledBox)`
  overflow: hidden;
`

const TextWrapper = styled(StyledBox)`
  max-width: 80%;
`

const ImageWrapper = styled(StyledBox)`
  justify-self: flex-end;
`
