import { memo } from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'
import StyledBox from '../moesia/components/StyledBox'

const DevHarbourLoader = () => (
  <PositionedBox fullWidth justify="center" align="center">
    <PropagateLoader size={50} color={FigmaColors.bhBlue} />
  </PositionedBox>
)

export default memo(DevHarbourLoader)

const PositionedBox = styled(StyledBox)`
  height: 100vh;
`
