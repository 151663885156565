import Pagination from '@mui/material/Pagination/Pagination'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import buttonAddIcon from '../figma/images/buttonAddIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { BlogPost } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import BlogPostCategoriesContainer from './BlogPostCategoriesContainer'
import BlogPostItem from './BlogPostItem'
import DevHarbourLoader from './DevHarbourLoader'
import MobileSubmitButton from './MobileSubmitButton'

type Props = {
  blogPosts: BlogPost[]
  blogPostPages: number
  currentPage: number | null
  onPageChange: (event: any, value: any) => void
  isNotRegularUser: boolean
  onAddBlogPostClick: () => void
  setSubstate: (state: PageSubstate) => void
  setSelectedBlogPost: React.Dispatch<React.SetStateAction<BlogPost | null>>
  onDelete: (blogPostId: string) => void
  isContentLoading: boolean
}

const BlogPostsSectionMobileView: React.FC<Props> = ({
  blogPosts,
  blogPostPages,
  currentPage,
  onPageChange,
  isNotRegularUser,
  onAddBlogPostClick,
  setSubstate,
  setSelectedBlogPost,
  onDelete,
  isContentLoading
}) => (
  <StyledBox fullWidth bottom={Spacings.medium}>
    {isContentLoading && <DevHarbourLoader />}
    {!isContentLoading && (
      <>
        <BlogPostCategoriesContainer setSubstate={setSubstate} />
        <StyledBox
          fullWidth
          align="center"
          justify="center"
          gap={Spacings.max}
          top={Spacings.large}
          bottom={Spacings.max}
        >
          {!!blogPosts.length &&
            blogPosts.map((blogPost) => (
              <BlogPostItem
                key={blogPost._id}
                blogPost={blogPost}
                isNotRegularUser={isNotRegularUser}
                onEdit={() => {
                  setSubstate(PageSubstate.EDIT)
                  setSelectedBlogPost(blogPost)
                }}
                onDelete={() => onDelete(blogPost._id)}
              />
            ))}
        </StyledBox>
        <StyledBox fullWidth bottom={Spacings.huge} align="center">
          <Pagination
            count={blogPostPages}
            page={currentPage ? currentPage : 1}
            onChange={onPageChange}
            color="primary"
          />
        </StyledBox>
        <ButtonWrapper fullWidth position="fixed" align="center">
          <MobileSubmitButton
            textKey={Texts.AddButtonText}
            buttonIcon={buttonAddIcon}
            textBackgroundColor={FigmaColors.bhBluePrimaryButton}
            iconBackgroundColor={FigmaColors.bhBlueMedium}
            onClick={onAddBlogPostClick}
          />
        </ButtonWrapper>
      </>
    )}
  </StyledBox>
)

export default BlogPostsSectionMobileView

const ButtonWrapper = styled(StyledBox)`
  bottom: ${Spacings.tiny};
  z-index: ${ZIndex.high};
`
