import { Texts } from 'src/figma/helpers/TextRepository'
import { TextType } from '../interfaces/FigmaTypes'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'

const NotificationError = (errorTextKey: TextType, errorText?: string) => (
  <StyledBox>
    <TextContainer textKey={Texts.NotificationError} />
    {!!errorTextKey && !errorText && <TextContainer textKey={errorTextKey} />}
    {!!errorText && !!errorTextKey && <TextContainer textKey={errorTextKey} text={errorText} />}
  </StyledBox>
)

export default NotificationError
