import loadable from '@loadable/component'
import { ComponentType } from 'react'
import {
  COMPANIES,
  EMPLOYEES,
  TEMPLATES,
  TERVEL_BLOG_POST,
  TERVEL_BLOG_POSTS,
  TERVEL_IMAGES,
  TERVEL_NEWS_CARDS,
  USERS
} from './Constants'
import DevHarbourLoader from './components/DevHarbourLoader'
import routePaths from './config/RoutePaths'
import { UserType } from './enums/UserType'
import { RouteType } from './interfaces/Dto'

//make sure to double check if the page's component name is correct and is located in folder 'pages'
const loadPage = (pageComponentPath: string): ComponentType =>
  loadable(() => import(`./pages/${pageComponentPath}`), {
    fallback: <DevHarbourLoader />
  })

const CompaniesPage = loadPage('CompaniesPage')
const EmployeesPage = loadPage('EmployeesPage')
const UsersPage = loadPage('UsersPage')
const NewsCardsPage = loadPage('NewsCardsPage')
const ImagesPage = loadPage('ImagesPage')
const TemplatesPage = loadPage('TemplatesPage')
const BlogPostsPage = loadPage('BlogPostsPage')
const BlogPostSoloViewPage = loadPage('BlogPostSoloViewPage')

const RouterPrivateRoutes: RouteType[] = [
  {
    path: routePaths.companies,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: CompaniesPage,
    title: COMPANIES
  },
  { path: routePaths.employees, permissions: [], component: EmployeesPage, title: EMPLOYEES },
  {
    path: routePaths.users,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: UsersPage,
    title: USERS
  },
  {
    path: routePaths.newsCards,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: NewsCardsPage,
    title: TERVEL_NEWS_CARDS
  },
  {
    path: routePaths.images,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: ImagesPage,
    title: TERVEL_IMAGES
  },
  { path: routePaths.templates, permissions: [], component: TemplatesPage, title: TEMPLATES },
  {
    path: routePaths.blogPosts,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: BlogPostsPage,
    title: TERVEL_BLOG_POSTS
  },
  {
    path: routePaths.blogPost,
    permissions: [UserType.ADMIN, UserType.MANAGER],
    component: BlogPostSoloViewPage,
    title: TERVEL_BLOG_POST
  }
]

export default RouterPrivateRoutes
