import LinearProgress from '@mui/material/LinearProgress'
import dayjs from 'dayjs'
import React from 'react'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import styled, { css } from 'styled-components'
import { DATE_ORDINAL_FORMAT } from '../Constants'
import BorderRadius from '../figma/tokens/BorderRadius'
import Shadows from '../figma/tokens/Shadows'
import {
  convertFileSize,
  getDocumentIcon,
  getFileUploadingProgressMessage,
  UploadingProgress
} from '../helpers/DocumentsHelper'
import useLanguage from '../hooks/useLanguage'
import { Document } from '../interfaces/Dto'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from '../moesia/Constants'
import { replaceTextParams } from '../moesia/helpers/Common'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { DOCUMENT_CONTAINER_HEIGHT, MUI_LINEAR_PROGRESS_HEIGHT } from '../moesia/StrongHardCodedSizes'
import DocumentItemOptions from './DocumentItemOptions'

type TDocument = {
  document: Document
  uploadingProgress?: UploadingProgress
  isDetails?: boolean
  isUploading?: boolean
  hasRightsToDelete?: boolean
  onDeleteDocument?: (documentId: string) => void
}

const DocumentItem: React.FC<TDocument> = ({
  uploadingProgress,
  isDetails,
  hasRightsToDelete,
  isUploading,
  onDeleteDocument,
  document
}) => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const { name, size, type, createdAt } = document
  const uploadingProgressMessage = getFileUploadingProgressMessage(uploadingProgress)
  const fileIcon = getDocumentIcon(type)
  const fileSize = convertFileSize(size)
  const dateOfUpload = dayjs(createdAt).format(DATE_ORDINAL_FORMAT)
  const dateOfUploadText = replaceTextParams(getText(Texts.DocumentUploadDate, language), [dateOfUpload])

  return (
    <DocumentWrapper
      fullWidth
      fullPadding
      spacing={Spacings.min}
      direction="row"
      align="center"
      justify="space-between"
      $isMobile={isMobile}
      $uploadingProgress={uploadingProgress}
    >
      <StyledBox direction="row" gap={Spacings.min} align="center">
        {!!fileIcon && <SVGContainer SVG={fileIcon} />}
        <StyledBox gap={Spacings.minimum}>
          <DocumentFileTitleContainer textKey={Texts.DocumentFileTitle} text={name} />
          <StyledBox direction="row" gap={Spacings.minimum}>
            {!!fileSize && <TextContainer textKey={Texts.DocumentFileSize} text={fileSize} />}
            <TextContainer textKey={Texts.DocumentFileSize} text={dateOfUploadText} />
          </StyledBox>
          {uploadingProgress === UploadingProgress.LOADING && <StyledLinearProgress />}
        </StyledBox>
      </StyledBox>
      <StyledBox direction="row" gap={Spacings.min} align="center">
        {!!uploadingProgressMessage && <TextContainer textKey={uploadingProgressMessage} />}
        <DocumentItemOptions
          document={document}
          onDeleteDocument={onDeleteDocument}
          isDetails={isDetails}
          hasRightsToDelete={hasRightsToDelete}
          isUploading={isUploading}
        />
      </StyledBox>
    </DocumentWrapper>
  )
}

export default DocumentItem

const DocumentFileTitleContainer = styled(TextContainer)`
  span {
    //styles which handle the truncate of the document file title and add '...' when it's too long
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    //in this case, we show two lines of the title if it's that long so the user can see more of the title before uploading
    &:hover {
      -webkit-line-clamp: 2;
    }
  }
`
const StyledLinearProgress = styled(LinearProgress)`
  width: ${MUI_LINEAR_PROGRESS_HEIGHT};
`

const DocumentWrapper = styled(StyledBox)<{
  $isMobile: boolean
  $uploadingProgress: UploadingProgress
}>`
  border-radius: ${BorderRadius.soft};
  border: 1px solid ${FigmaColors.grayLight};
  min-height: ${DOCUMENT_CONTAINER_HEIGHT};
  max-height: ${DOCUMENT_CONTAINER_HEIGHT};
  box-shadow: ${Shadows.baseSoft};
  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${({ $isMobile }) => !$isMobile && REGULAR_TRANSFORM};
  }

  ${({ $uploadingProgress }) =>
    $uploadingProgress === UploadingProgress.SUCCESS &&
    css`
      border: 1px solid ${FigmaColors.baseSuccess};
      background-color: ${FigmaColors.lightGreenBackground};
    `}

  ${({ $uploadingProgress }) =>
    $uploadingProgress === UploadingProgress.ERROR &&
    css`
      border: 1px solid ${FigmaColors.tertiaryButtonBorderColor};
      background-color: ${FigmaColors.lightRedBackground};
    `}
`
