import { GoogleAnalyticsEvents } from '../enums/GoogleAnalyticsEnums'
import { sendGAEvent } from './GoogleAnalyticsHelper'

type Params = number | string | undefined

export const replaceTextParams = (text: string, params: Params[]): string => {
  const _params = params.map((param) => {
    if (isNaN(Number(param))) return param?.toString()
    return param ? param.toString() : ''
  })
  const splits = text.split('XXX')

  return splits.reduce((a, b, i) => ({ res: a.res + b + (i < splits.length - 1 ? _params[a.i] : '')?.toString(), i: i + 1 }), {
    res: '',
    i: 0
  }).res
}

export const trackGAEvent = (googleAnalyticsEvent?: GoogleAnalyticsEvents | string) => {
  if (googleAnalyticsEvent) {
    sendGAEvent(googleAnalyticsEvent)
  }
}

export const openLinkInNewTab = (link?: string) => {
  if (link) {
    window.open(link, '_blank')
  }
}
