import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GenericConfirmationModal from 'src/components/GenericConfirmationModal'
import { Texts } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import {
  areMainBlogPostPropsTruthy,
  getStatusModalInfo,
  getStatusModalPrimaryButtonColor,
  getStatusModalPrimaryButtonOnClick,
  getStatusModalPrimaryButtonTextKey,
  getStatusModalSecondaryButtonColor,
  getStatusModalSecondaryButtonOnClick,
  getStatusModalSecondaryButtonTextKey
} from '../helpers/BlogPostsHelper'
import { useCategories, useUpdateBlogPost } from '../helpers/QueryHooks'
import { BlogPost, BlogPostCategory } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import BlogPostContent from './BlogPostContent'
import BlogPostStatusView from './BlogPostStatusView'

type Props = {
  blogPost: BlogPost
  isNotRegularUser: boolean
  onEdit: () => void
  onDelete: () => void
}

const BlogPostItem: React.FC<Props> = ({ blogPost, isNotRegularUser, onEdit, onDelete }) => {
  const { _id, title, thumbnail, previewContent, author, publishedAt, status } = blogPost
  const updateBlogPostMutation = useUpdateBlogPost(_id)
  const areMainPropsTruthy = areMainBlogPostPropsTruthy(title, thumbnail, previewContent, author, publishedAt)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const [shouldShowStatusModal, setShouldShowStatusModal] = useState(false)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { data: allCategories = [] as BlogPostCategory[], isLoading: areCategoriesLoading } = useCategories()
  const statusModalInfo = useMemo(() => getStatusModalInfo(status), [status])
  const statusModalPrimaryButtonTextKey = useMemo(() => getStatusModalPrimaryButtonTextKey(status), [status])
  const statusModalPrimaryButtonColor = useMemo(() => getStatusModalPrimaryButtonColor(status), [status])
  const statusModalSecondaryButtonTextKey = useMemo(() => getStatusModalSecondaryButtonTextKey(status), [status])
  const statusModalSecondaryButtonColor = useMemo(() => getStatusModalSecondaryButtonColor(status), [status])

  const handleShowDeleteConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleShowStatusModal = () => setShouldShowStatusModal(!shouldShowStatusModal)

  const handlePrimaryButtonClick = () =>
    getStatusModalPrimaryButtonOnClick(blogPost, status, updateBlogPostMutation, handleShowStatusModal)

  const handleSecondaryButtonClick = () =>
    getStatusModalSecondaryButtonOnClick(blogPost, status, updateBlogPostMutation, handleShowStatusModal)

  return (
    <>
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={onDelete}
          confirmationTitle={Texts.ConfirmDeleteBlogPostTitle}
          confirmationQuestion={Texts.ConfirmDeleteBlogPostQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize={isMobile}
        />
      )}
      {shouldShowStatusModal && (
        <GenericConfirmationModal
          open
          onClose={handleSecondaryButtonClick}
          onConfirm={handlePrimaryButtonClick}
          onModalClose={handleShowStatusModal}
          confirmationTitle={Texts.BlogPostStatusModalTitle}
          confirmationQuestion={statusModalInfo}
          confirmButtonTextKey={statusModalPrimaryButtonTextKey}
          confirmButtonColor={statusModalPrimaryButtonColor}
          cancelButtonTextKey={statusModalSecondaryButtonTextKey}
          cancelButtonColor={statusModalSecondaryButtonColor}
          smallSize={isMobile}
        />
      )}
      <StyledBox position="relative" onClick={() => navigate(blogPost._id)}>
        <BlogPostStatusView handleShowStatusModal={handleShowStatusModal} status={status} />
        <BlogPostContent
          areMainPropsTruthy={areMainPropsTruthy}
          allCategories={allCategories}
          areCategoriesLoading={areCategoriesLoading}
          blogPost={blogPost}
          isNotRegularUser={isNotRegularUser}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          onEdit={onEdit}
        />
      </StyledBox>
    </>
  )
}

export default BlogPostItem
