import { UseMutationResult } from 'react-query'
import { BlogPostStatus } from '../enums/BlogPostStatus'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import { BlogPost, BlogPostCategory, TervelImage } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import { handleErrorMessage } from './NotificationsHelper'

export const handleTrendingStatus = (
  category: BlogPostCategory,
  mutationHook: UseMutationResult<
    any,
    Error,
    unknown,
    {
      previous: unknown
      data: BlogPostCategory
    }
  >,
  handleClose: () => void
) => {
  const data = { isTrending: !category.isTrending }

  mutationHook
    .mutateAsync(data)
    .then(() => {
      handleClose()
      successNotification(Texts.NotificationCategoryTrending)
    })
    .catch((err: Error) => {
      handleErrorMessage(err)
    })
}

export const areMainBlogPostPropsTruthy = (
  title: string,
  thumbnail: TervelImage,
  previewContent: string,
  author: string,
  publishedAt: string
) => !!title && !!thumbnail && !!previewContent && !!author && !!publishedAt

export const handleChangeBlogPostStatus = (
  blogPost: BlogPost,
  newStatus: BlogPostStatus,
  mutationHook: UseMutationResult<
    any,
    Error,
    unknown,
    {
      previous: unknown
      data: BlogPost
    }
  >,
  handleClose: () => void
) => {
  const data = { ...blogPost, status: newStatus }

  mutationHook
    .mutateAsync(data)
    .then(() => {
      handleClose()
      successNotification(
        newStatus === BlogPostStatus.APPROVED
          ? Texts.NotificationApprovedBlogPostSuccessful
          : Texts.NotificationDisapprovedBlogPostSuccessful
      )
    })
    .catch((err: Error) => {
      handleErrorMessage(err)
    })
}

export const getBlogPostStatusTextKey = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return Texts.ActiveInTervel
    case BlogPostStatus.DISAPPROVED:
      return Texts.DisapprovedStatus
    default:
      return Texts.PendingStatus
  }
}

export const getStatusColor = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return FigmaColors.baseGreen
    case BlogPostStatus.DISAPPROVED:
      return FigmaColors.lighterRed
    default:
      return FigmaColors.yellow
  }
}

export const getStatusModalInfo = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return Texts.BlogPostStatusApproved
    case BlogPostStatus.DISAPPROVED:
      return Texts.BlogPostStatusRejected
    default:
      return Texts.BlogPostStatusPendingApproval
  }
}

export const getStatusModalPrimaryButtonTextKey = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return Texts.BlogPostDisapprove
    case BlogPostStatus.DISAPPROVED:
      return Texts.BlogPostApprove
    default:
      return Texts.BlogPostApprove
  }
}

export const getStatusModalSecondaryButtonTextKey = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.PENDING:
      return Texts.BlogPostDisapprove
    default:
      return Texts.CancelButtonText
  }
}

export const getStatusModalPrimaryButtonColor = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return FigmaColors.lighterRed
    default:
      return
  }
}

export const getStatusModalSecondaryButtonColor = (status: BlogPostStatus) => {
  switch (status) {
    case BlogPostStatus.PENDING:
      return FigmaColors.lighterRed
    default:
      return
  }
}

export const getStatusModalPrimaryButtonOnClick = (
  blogPost: BlogPost,
  status: BlogPostStatus,
  updateBlogPostMutation: UseMutationResult<
    BlogPost,
    Error,
    unknown,
    {
      previous: unknown
      data: BlogPost
    }
  >,
  handleShowStatusModal: () => void
) => {
  switch (status) {
    case BlogPostStatus.APPROVED:
      return handleChangeBlogPostStatus(
        blogPost,
        BlogPostStatus.DISAPPROVED,
        updateBlogPostMutation,
        handleShowStatusModal
      )
    default:
      return handleChangeBlogPostStatus(
        blogPost,
        BlogPostStatus.APPROVED,
        updateBlogPostMutation,
        handleShowStatusModal
      )
  }
}

export const getStatusModalSecondaryButtonOnClick = (
  blogPost: BlogPost,
  status: BlogPostStatus,
  updateBlogPostMutation: UseMutationResult<
    BlogPost,
    Error,
    unknown,
    {
      previous: unknown
      data: BlogPost
    }
  >,
  handleShowStatusModal: () => void
) => {
  switch (status) {
    case BlogPostStatus.PENDING:
      return handleChangeBlogPostStatus(
        blogPost,
        BlogPostStatus.DISAPPROVED,
        updateBlogPostMutation,
        handleShowStatusModal
      )
    default:
      return handleShowStatusModal()
  }
}
