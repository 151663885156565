import dayjs from 'dayjs'
import { EditorState } from 'draft-js'

export const getDefaultValues = () => ({
  name: '',
  email: '',
  password: '',
  startingDate: dayjs(),
  position: '',
  companies: [],
  birthDate: null,
  plan: EditorState.createEmpty(),
  notes: EditorState.createEmpty(),
  goals: EditorState.createEmpty()
})
