import { memo, useState } from 'react'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import EmptyStatePageDesktopView from './EmptyStatePageDesktopView'
import EmptyStatePageMobileView from './EmptyStatePageMobileView'

type Props = {
  pageHeading: TextType
  pageSubheading: TextType
  buttonTextKey: TextType
  mobileButtonTextKey: TextType
  modalComponent?: React.ElementType
}

const EmptyStatePage: React.FC<Props> = ({
  pageHeading,
  pageSubheading,
  buttonTextKey,
  mobileButtonTextKey,
  modalComponent: ModalComponent
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useIsMobile()

  return (
    <>
      {!isMobile && !!ModalComponent && (
        <EmptyStatePageDesktopView
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          buttonTextKey={buttonTextKey}
          onButtonClick={() => setIsModalOpen(true)}
          isModalOpen={isModalOpen}
          modalComponent={<ModalComponent open={isModalOpen} onClose={() => setIsModalOpen(false)} />}
        />
      )}
      {isMobile && !!ModalComponent && (
        <EmptyStatePageMobileView
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          buttonTextKey={mobileButtonTextKey}
          onButtonClick={() => setIsModalOpen(true)}
          isModalOpen={isModalOpen}
          modalComponent={<ModalComponent open={isModalOpen} onClose={() => setIsModalOpen(false)} />}
        />
      )}
    </>
  )
}

export default memo(EmptyStatePage)
