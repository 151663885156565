import MaterialTable, { MTableToolbar } from '@material-table/core'
import { useMemo } from 'react'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import downloadIcon from 'src/figma/images/downloadIcon'
import printIcon from 'src/figma/images/printIcon'
import remindIcon from 'src/figma/images/remindIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { getTableOptions } from 'src/helpers/getTableOptions'
import { getInvoices } from 'src/helpers/InvoicesHelper'
import { getTableExtraOptions } from 'src/helpers/TablesExtraOptionsHelper'
import useLanguage from 'src/hooks/useLanguage'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { sortElements } from '../helpers/Common'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import TableWrapper from './TableWrapper'

type Props = {
  open: boolean
  onClose: () => void
}

const InvoicesTable = ({ open, onClose }: Props) => {
  const invoices = useMemo(() => getInvoices(), [])
  const language = useLanguage()
  const extraOptions = getTableExtraOptions()

  return (
    <GenericPageModal
      open={open}
      onClose={onClose}
      buttons={
        <ModalButtons
          fullPadding
          spacing={Spacings.small}
          primaryButton={<SubmitButton variant="primary" textKey={Texts.ZohoButtonText} />}
          secondaryButton={<SubmitButton variant="secondary" textKey={Texts.UploadInvoiceButtonText} />}
        />
      }
    >
      <TableWrapper fullWidth>
        <StyledBox fullPadding spacing={Spacings.large}>
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <StyledToolbar fullWidth direction="row">
                  <MTableToolbar {...props} />
                </StyledToolbar>
              )
            }}
            columns={[
              { title: getText(Texts.InvoicesTableInvoiceTitle, language), field: 'invoiceNumber' },
              { title: getText(Texts.InvoicesTableDateTitle, language), field: 'invoiceDate' },
              { title: getText(Texts.InvoicesTableDueTitle, language), field: 'invoiceDueDate' },
              { title: getText(Texts.InvoicesTableAmountTitle, language), field: 'invoiceAmount' },
              { title: getText(Texts.InvoicesTableStateTitle, language), field: 'invoiceState' }
            ]}
            data={sortElements(invoices)}
            actions={[
              {
                icon: () => <SVGContainer SVG={remindIcon} />,
                tooltip: Texts.RemindIconTooltip.characters,
                onClick: () => {
                  // TODO this button will lead mail sending
                }
              },
              {
                icon: () => <SVGContainer SVG={downloadIcon} />,
                tooltip: Texts.DownloadIconTooltip.characters,
                onClick: () => {
                  // TODO this button will cause download
                }
              },
              {
                icon: () => <SVGContainer SVG={printIcon} />,
                tooltip: Texts.PrintIconTooltip.characters,
                onClick: () => {
                  // TODO this button will open print settings
                }
              }
            ]}
            title={<TextContainer textKey={Texts.InvoicesPageHeading} />}
            localization={{
              toolbar: {
                searchPlaceholder: getText(Texts.InvoiceSearchPlaceholder, language)
              }
            }}
            options={getTableOptions({
              extraOptions: {
                ...extraOptions,
                showTitle: true,
                searchFieldAlignment: 'right'
              }
            })}
          />
        </StyledBox>
      </TableWrapper>
    </GenericPageModal>
  )
}

export default InvoicesTable

const StyledToolbar = styled(StyledBox)`
  background-color: ${FigmaColors.white};
  & .MuiToolbar-root {
    width: 100% !important;
  }
`
