type InvoiceContent = {
  invoiceNumber: number
  invoiceDate: string
  invoiceDueDate: string
  invoiceAmount: string
  invoiceState: 'Completed' | 'Overdue' | 'Pending'
}

export const getInvoices = (): InvoiceContent[] => [
  {
    invoiceNumber: 1,
    invoiceDate: '01-01-2022',
    invoiceDueDate: '01-01-2023',
    invoiceAmount: '1234,56 BGN',
    invoiceState: 'Pending'
  },
  {
    invoiceNumber: 2,
    invoiceDate: '01-01-2023',
    invoiceDueDate: '01-01-2024',
    invoiceAmount: '23143,14 BGN',
    invoiceState: 'Completed'
  },
  {
    invoiceNumber: 3,
    invoiceDate: '01-01-2025',
    invoiceDueDate: '01-01-2026',
    invoiceAmount: '4702,84 BGN',
    invoiceState: 'Overdue'
  }
]
