import Pagination from '@mui/material/Pagination/Pagination'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { BlogPost } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import BlogPostCategoriesContainer from './BlogPostCategoriesContainer'
import BlogPostItem from './BlogPostItem'
import DevHarbourLoader from './DevHarbourLoader'

type Props = {
  blogPosts: BlogPost[]
  blogPostPages: number
  currentPage: number | null
  onPageChange: (event: any, value: any) => void
  isNotRegularUser: boolean
  onAddBlogPostClick: () => void
  setSubstate: (state: PageSubstate) => void
  setSelectedBlogPost: React.Dispatch<React.SetStateAction<BlogPost | null>>
  onDelete: (blogPostId: string) => void
  isContentLoading: boolean
}

const BlogPostsSectionDesktopView: React.FC<Props> = ({
  blogPosts,
  blogPostPages,
  currentPage,
  onPageChange,
  isNotRegularUser,
  onAddBlogPostClick,
  setSubstate,
  setSelectedBlogPost,
  onDelete,
  isContentLoading
}) => (
  <StyledBox fullWidth align="center" position="relative" bottom spacing={Spacings.large}>
    {isContentLoading && <DevHarbourLoader />}
    {!isContentLoading && (
      <>
        <BlogPostCategoriesContainer setSubstate={setSubstate} />
        {!!blogPosts?.length && (
          <StyledBox
            fullWidth
            direction="row"
            justify="space-around"
            flexWrap="wrap"
            gap={Spacings.max}
            top={Spacings.large}
            bottom={Spacings.max}
          >
            {blogPosts.map((blogPost) => (
              <BlogPostItem
                key={blogPost._id}
                blogPost={blogPost}
                isNotRegularUser={isNotRegularUser}
                onEdit={() => {
                  setSubstate(PageSubstate.EDIT)
                  setSelectedBlogPost(blogPost)
                }}
                onDelete={() => onDelete(blogPost._id)}
              />
            ))}
          </StyledBox>
        )}
        <StyledBox fullWidth bottom={Spacings.huge} align="center">
          <Pagination count={blogPostPages} page={currentPage ?? 1} onChange={onPageChange} color="primary" />
        </StyledBox>
        <ButtonWrapper position="fixed">
          <SubmitButton onClick={onAddBlogPostClick} textKey={Texts.AddBlogPostButtonText} variant="primary" />
        </ButtonWrapper>
      </>
    )}
  </StyledBox>
)

export default BlogPostsSectionDesktopView

const ButtonWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
  bottom: ${Spacings.large};
  right: ${Spacings.large};
`
