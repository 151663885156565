import dayjs from 'dayjs'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import authorLinkedinIcon from '../figma/images/authorLinkedinIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { BlogPost } from '../interfaces/Dto'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'

type Props = {
  blogPost: BlogPost
}

const BlogPostAdditionalInformation: React.FC<Props> = ({ blogPost }) => {
  const { authorUrl, author, publishedAt } = blogPost

  return (
    <AdditionalBlogInformation
      fullWidth
      top
      bottom
      direction="row"
      gap={Spacings.min}
      align="center"
      position="absolute"
    >
      {!!authorUrl ? (
        <AuthorAnchor
          onClick={(event) => {
            event.stopPropagation()
          }}
          href={authorUrl}
          target="_blank"
          rel="noreferrer"
        >
          <StyledBox direction="row" align="center" justify="center" gap={Spacings.minimum} left={Spacings.tiny}>
            <SVGContainer SVG={authorLinkedinIcon} />
            <TextContainer textKey={Texts.BlogPostAuthor} text={author} />
          </StyledBox>
        </AuthorAnchor>
      ) : (
        <StyledBox left={Spacings.tiny}>
          <TextContainer textKey={Texts.BlogPostAuthor} text={author} />
        </StyledBox>
      )}
      <Delimiter />
      <TextContainer textKey={Texts.BlogPostDate} text={dayjs(publishedAt).format('LL')} />
    </AdditionalBlogInformation>
  )
}
export default BlogPostAdditionalInformation

const AdditionalBlogInformation = styled(StyledBox)`
  position: absolute;
  border-top: 1px solid ${FigmaColors.gray2};
  left: 0;
  bottom: 0;
  z-index: ${ZIndex.minimum};
  border-radius: 0 0 ${BorderRadius.soft} ${BorderRadius.soft};
`

const AuthorAnchor = styled.a`
  text-decoration: none;
`

const Delimiter = styled(StyledBox)`
  width: ${Spacings.minimum};
  height: ${Spacings.minimum};
  border-radius: ${BorderRadius.circle};
  background-color: ${FigmaColors.gray1};
`
