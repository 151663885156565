import dayjs from 'dayjs'
import { useMemo } from 'react'
import styled from 'styled-components'
import { DATE_ORDINAL_FORMAT } from '../Constants'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import archiveIcon from '../figma/images/archiveIcon'
import deleteImageIcon from '../figma/images/deleteImageIcon'
import editIcon from '../figma/images/editIcon'
import invitationAcceptedIcon from '../figma/images/invitationAcceptedIcon'
import invitationPendingIcon from '../figma/images/invitationPendingIcon'
import remindButtonIcon from '../figma/images/remindButtonIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { generateUUID } from '../helpers/Common'
import { getUserDetailsMobile } from '../helpers/UsersHelper'
import useLanguage from '../hooks/useLanguage'
import { User } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import { replaceTextParams } from '../moesia/helpers/Common'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileDetailsBox from './MobileDetailsBox'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  handleEditButton: () => void
  handleDeleteButton: () => void
  user: User
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
  handleRemindButton: () => void
  isInvitationAccepted: boolean
}

const UserDetailsMobileView: React.FC<Props> = ({
  open,
  onClose,
  handleEditButton,
  handleDeleteButton,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation,
  user,
  handleRemindButton,
  isInvitationAccepted
}) => {
  const { name, profile, archived, invitationStatus } = user
  const { startingDate } = profile ?? {}
  const date = !!startingDate && dayjs(startingDate).format(DATE_ORDINAL_FORMAT)
  const language = useLanguage()
  const userDetailsInformation = useMemo(() => getUserDetailsMobile(user), [user])
  const startingDateText =
    !!date && replaceTextParams(getText(Texts.EmployeeDetailsStartingDateWithParams, language), [date])

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteUserTitle}
          confirmationQuestion={Texts.ConfirmDeleteUserQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateEmployeeTitle : Texts.ConfirmArchiveEmployeeTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateEmplpyeeProfileQuestion : Texts.ConfirmArchiveEmployeeQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={onClose}
        buttons={
          <ModalButtons
            primaryButton={
              <MobileSubmitButton
                textKey={!!archived ? Texts.ConfirmDeleteButtonText : Texts.EditButtonText}
                buttonIcon={!!archived ? deleteImageIcon : editIcon}
                textBackgroundColor={!!archived ? FigmaColors.darkerRed : FigmaColors.bhBluePrimaryButton}
                iconBackgroundColor={!!archived ? FigmaColors.lighterRed : FigmaColors.bhBlueMedium}
                onClick={!!archived ? handleShowDeleteConfirmation : handleEditButton}
              />
            }
            tertiaryButton={
              <MobileSubmitButton
                textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                buttonIcon={!!archived ? activeIcon : archiveIcon}
                textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                onClick={handleShowArchiveStatusChangeConfirmation}
              />
            }
          />
        }
      >
        <StyledBox fullWidth gap={Spacings.big}>
          <StyledBox fullWidth align="center" position="relative">
            <StyledImageContainer imageKey={Images.companyDetailsMobileBackground} />
            <BasicInformation align="center" justify="center" position="absolute">
              <AvatarInitials name={name} border />
              <StyledBox align="center" justify="center" top={Spacings.min}>
                <StyledBox gap={Spacings.minimum} align="center">
                  <TextContainer textKey={Texts.EmployeeDetailsName} text={name} />
                  {!!startingDateText && (
                    <TextContainer textKey={Texts.EmployeeDetailsStartDate} text={startingDateText} />
                  )}
                </StyledBox>
              </StyledBox>
            </BasicInformation>
          </StyledBox>
          <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.medium}>
            {!!userDetailsInformation.length &&
              userDetailsInformation.map(({ boxIcon, boxTitle, boxInfo }) => (
                <MobileDetailsBox
                  key={generateUUID()}
                  itemIcon={boxIcon}
                  itemTitle={boxTitle}
                  itemInfo={boxInfo as JSX.Element | string}
                />
              ))}
            {!archived && (
              <MobileDetailsBox
                itemIcon={isInvitationAccepted ? invitationAcceptedIcon : invitationPendingIcon}
                itemTitle={Texts.EmployeeDetailsInvitationTitle}
                itemInfo={invitationStatus}
                itemButton={
                  !isInvitationAccepted && (
                    <MobileSubmitButton
                      textKey={Texts.RemindButtonText}
                      buttonIcon={remindButtonIcon}
                      textBackgroundColor={FigmaColors.green1}
                      iconBackgroundColor={FigmaColors.green2}
                      onClick={handleRemindButton}
                    />
                  )
                }
              />
            )}
          </StyledBox>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default UserDetailsMobileView

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;

  img {
    width: 100%;
  }
`
const BasicInformation = styled(StyledBox)`
  bottom: -${Spacings.large};
`
