import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import 'react-toastify/dist/ReactToastify.css'
import Router from './Router'
import AppInit from './components/AppInit'
import ErrorBoundaryPage from './components/ErrorBoundaryPage'
import HelmetMaterialUIIcons from './helpers/HelmetMaterialUIIcons'
import { ToastProvider } from './moesia/helpers/ToastStyler'

const App: React.FC = () => (
  <Sentry.ErrorBoundary fallback={<ErrorBoundaryPage />}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HelmetProvider>
        <AppInit />
        <HelmetMaterialUIIcons />
        <Router />
        <ToastProvider />
      </HelmetProvider>
    </LocalizationProvider>
  </Sentry.ErrorBoundary>
)

export default App
