import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from 'src/components/AuthProvider'
import App from './App'
import config from './Config'
import ErrorBoundary from './components/ErrorBoundary'
import DefaultMaterialTheme from './helpers/DefaultMaterialTheme'

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

Sentry.init({
  dsn: config.sentryDSN,
  tracesSampleRate: 1.0,
  debug: config.enableSentry,
  enabled: config.enableSentry,
  environment: config.name
})

const root = document.getElementById('root')

if (!!root) {
  ReactDOM.createRoot(root).render(
    <ErrorBoundary>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={DefaultMaterialTheme}>
            <App />
          </ThemeProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}
