import { useContext, useMemo, useState } from 'react'
import { Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext, getNewsCardsPageSubstate } from '../helpers/PageSubstateHelper'
import { useDeleteNewsCard } from '../helpers/QueryHooks'
import { NewsCardDto } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import NewsCardsSectionDesktop from './NewsCardsSectionDesktop'
import NewsCardsSectionMobile from './NewsCardsSectionMobile'

type Props = {
  newsCards: NewsCardDto[]
}

const NewsCardsContainer: React.FC<Props> = ({ newsCards }) => {
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const isMobile = useIsMobile()
  const deleteNewsCard = useDeleteNewsCard()
  const [selectedNewsCard, setSelectedNewsCard] = useState<NewsCardDto | null>(null)
  const substateView = useMemo(
    () => getNewsCardsPageSubstate(substate, setSubstate, selectedNewsCard, setSelectedNewsCard),
    [substate, setSubstate, selectedNewsCard]
  )

  const handleDeleteNewsCard = (newsCardId: string) => {
    deleteNewsCard
      .mutateAsync(newsCardId)
      .then(() => successNotification(Texts.NotificationDeletedNewsCardSucceful))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && isMobile && (
        <NewsCardsSectionMobile
          newsCards={newsCards}
          onAddNewsCardClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          setSubstate={setSubstate}
          setSelectedNewsCard={setSelectedNewsCard}
          onDelete={handleDeleteNewsCard}
        />
      )}
      {substate !== PageSubstate.ERROR && !isMobile && (
        <NewsCardsSectionDesktop
          newsCards={newsCards}
          onAddNewsCardClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          setSubstate={setSubstate}
          setSelectedNewsCard={setSelectedNewsCard}
          onDelete={handleDeleteNewsCard}
        />
      )}
    </>
  )
}

export default NewsCardsContainer
