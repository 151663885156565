import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import deleteDocumentIcon from '../figma/images/deleteDocumentIcon'
import editDocumentIcon from '../figma/images/editDocumentIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { ContactPerson } from '../interfaces/Dto'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'

const CompanyContactPerson = ({ name, position, email, onEdit, onDelete, details }: ContactPerson) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const isMobile = useIsMobile()

  const handleShowConfirmationModal = () => setShowConfirmationModal(!showConfirmationModal)

  const handleDeleteContact = () => {
    if (onDelete) onDelete()
  }

  return (
    <>
      {showConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowConfirmationModal}
          onConfirm={handleDeleteContact}
          confirmationTitle={Texts.ConfirmDeleteContactTitle}
          confirmationQuestion={Texts.ConfirmDeleteContactQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          smallSize={isMobile}
        />
      )}

      <StyledBox fullWidth direction="row" align="center" justify="space-between">
        <StyledBox fullPadding spacing={Spacings.min} direction="row" align="center" gap={Spacings.tiny}>
          <AvatarInitials name={name} size="40" />
          <EmployeeDetailsWrapper>
            <StyledBox direction="row">
              <TextContainer textKey={Texts.CompanyDetailsEmployerName} text={`${name}, ${position}`} />
            </StyledBox>
            <TextContainer textKey={Texts.CompanyDetailsEmployerName} text={email} />
          </EmployeeDetailsWrapper>
        </StyledBox>
        {!details && (
          <StyledBox direction="row" justify="center" align="center" right={Spacings.minimum}>
            <StyledBox onClick={onEdit}>
              <StyledSVGContainer SVG={editDocumentIcon} />
            </StyledBox>
            <StyledBox onClick={handleShowConfirmationModal}>
              <StyledSVGContainer SVG={deleteDocumentIcon} />
            </StyledBox>
          </StyledBox>
        )}
      </StyledBox>
    </>
  )
}

export default CompanyContactPerson

const EmployeeDetailsWrapper = styled(StyledBox)`
  word-break: break-all;
`

const StyledSVGContainer = styled(SVGContainer)`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${FigmaColors.lightStone};
    border-radius: ${BorderRadius.circle};
  }
`
