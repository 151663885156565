import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Texts } from 'src/figma/helpers/TextRepository'
import closeIcon from 'src/figma/images/closeIcon'
import navigationLogo from 'src/figma/images/navigationLogo'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import routePaths from '../config/RoutePaths'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import MobileAccountMenu from './MobileAccountMenu'
import SidebarNavigationItems from './SidebarNavigationItems'

type Props = {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileMenu: React.FC<Props> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [isOpenAccountMenu, setIsOpenAccountMenu] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const handleLogoClick = () => {
    if (location.pathname === routePaths.entry) {
      setIsMenuOpen(false)
      return
    }

    navigate(routePaths.entry)
  }

  return (
    <Wrapper fullWidth>
      <NavigationWrapper fullWidth fullPadding direction="row" spacing={Spacings.min} justify="space-between">
        <StyledBox direction="row" gap={Spacings.tiny} align="center" justify="center" onClick={handleLogoClick}>
          <StyledDevHarbourLogo />
          <TextContainer textKey={Texts.NavigationTitle} />
        </StyledBox>
        <StyledBox onClick={() => setIsMenuOpen(false)} right={Spacings.minimum} justify="center">
          <SVGContainer SVG={closeIcon} />
        </StyledBox>
      </NavigationWrapper>
      {!!isMenuOpen && (
        <StyledWrapper fullWidth justify="space-between">
          {!isOpenAccountMenu && (
            <>
              <StyledBox fullWidth top={Spacings.max} bottom={Spacings.max} left right spacing={Spacings.medium}>
                <SidebarNavigationItems handleCloseMenu={() => setIsMenuOpen(false)} />
              </StyledBox>
              <MenuUserInformation iconType={UserInformationType.OPTIONS} onClick={() => setIsOpenAccountMenu(true)} />
            </>
          )}
          {!!isOpenAccountMenu && (
            <MobileAccountMenu
              setIsOpenAccountMenu={setIsOpenAccountMenu}
              handleCloseMenu={() => setIsMenuOpen(false)}
            />
          )}
        </StyledWrapper>
      )}
    </Wrapper>
  )
}

export default MobileMenu

const NavigationWrapper = styled(StyledBox)`
  z-index: 999;
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseMedium};
  position: fixed;
`

const StyledDevHarbourLogo = styled(navigationLogo)`
  height: 100%;
  span {
    height: 100% !important;
  }
`

const Wrapper = styled(StyledBox)`
  position: fixed;
  inset: 0;
  background-color: ${FigmaColors.white};
  overflow: auto;
  * {
    box-sizing: border-box;
  }
`

const StyledWrapper = styled(StyledBox)`
  height: 100vh;
  background-color: ${FigmaColors.white};
`
