import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import React from 'react'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import ZIndex from 'src/figma/tokens/ZIndex'
import { GENERIC_PAGE_MODAL_MAX_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import StyledBox from 'src/moesia/components/StyledBox'
import styled, { css } from 'styled-components'
import closeModalIcon from '../figma/images/closeModalIcon'
import SVGContainer from '../moesia/components/SVGContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'

export type GenericModalProps = {
  open: boolean
  children: React.ReactNode
  onClose?: () => void
  buttons?: React.ReactNode
  buttonsBackground?: string
  overflowX?: string
  smallSize?: boolean
  customHeight?: string
  customWidth?: string
}

const GenericPageModal: React.FC<GenericModalProps> = ({
  open,
  children,
  onClose,
  buttons,
  buttonsBackground,
  overflowX,
  smallSize,
  customHeight,
  customWidth
}) => {
  const isMobile = useIsMobile()
  return (
    <StyledModal open={open} onClose={onClose} components={{ Backdrop: Backdrop }}>
      <Wrapper fullWidth align="center" justify="center">
        <StyledWrapper
          position="relative"
          $isMobile={isMobile}
          $smallSize={smallSize}
          $customHeight={customHeight}
          $customWidth={customWidth}
        >
          <Content fullWidth $isMobile={isMobile} $overflowX={overflowX}>
            <StyledBox fullWidth bottom={isMobile && Spacings.big}>
              {children}
            </StyledBox>
          </Content>
          {!!onClose && (
            <CloseButton onClick={onClose} fullPadding spacing={Spacings.tiny} position="absolute">
              <SVGContainer SVG={closeModalIcon} />
            </CloseButton>
          )}
          {!!buttons && (
            <ButtonsWrapper
              fullWidth
              direction="row"
              fullPadding
              $buttonsBackground={buttonsBackground}
              $isMobile={isMobile}
              $smallSize={smallSize}
            >
              {buttons}
            </ButtonsWrapper>
          )}
        </StyledWrapper>
      </Wrapper>
    </StyledModal>
  )
}

export default GenericPageModal

const StyledModal = styled(Modal)`
  &:focus-visible {
    outline: transparent;
  }
  overflow: hidden;
`

const Wrapper = styled(StyledBox)`
  height: 100vh;
`

const StyledWrapper = styled(StyledBox)<{
  $isMobile: boolean
  $smallSize?: boolean
  $customHeight?: string
  $customWidth?: string
}>`
  background-color: ${FigmaColors.white};
  border-radius: ${({ $isMobile, $smallSize }) => (!$isMobile || $smallSize) && `${Spacings.tiny}`};
  z-index: ${ZIndex.modal};
  overflow: hidden;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 100vh;
      width: 100vw;
    `}

  ${({ $smallSize }) =>
    $smallSize &&
    css`
      height: 30% !important;
      width: 90vw !important;
    `}

  ${({ $customHeight }) =>
    $customHeight &&
    css`
      height: ${$customHeight};
    `}

  ${({ $customWidth }) =>
    $customWidth &&
    css`
      width: ${$customWidth};
    `}
`

const CloseButton = styled(StyledBox)`
  top: 0;
  right: 0;
  z-index: ${ZIndex.heaven};
  cursor: pointer;
`
const Content = styled(StyledBox)<{ $isMobile: boolean; $overflowX?: string }>`
  max-height: ${({ $isMobile }) => !$isMobile && `${GENERIC_PAGE_MODAL_MAX_HEIGHT}`};
  height: ${({ $isMobile }) => $isMobile && `calc(100vh - 68px)`};
  overflow-y: auto;
  overflow-x: ${({ $overflowX }) => ($overflowX ? $overflowX : 'visible')};
`
const ButtonsWrapper = styled(StyledBox)<{ $isMobile: boolean; $buttonsBackground?: string; $smallSize?: boolean }>`
  position: ${({ $isMobile }) => $isMobile && 'fixed'};
  position: ${({ $smallSize }) => $smallSize && 'absolute !important'};
  bottom: ${({ $isMobile }) => $isMobile && '0'};
  z-index: ${({ $isMobile }) => $isMobile && ZIndex.heaven};
  background-color: ${({ $buttonsBackground }) => (!!$buttonsBackground ? $buttonsBackground : FigmaColors.white)};
  box-shadow: ${Shadows.baseSoft};
`
