import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import { DATE_ORDINAL_FORMAT } from 'src/Constants'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import detailsEditIcon from 'src/figma/images/detailsEditIcon'
import detailsInfoIcon from 'src/figma/images/detailsInfoIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { getPrepareCompanies } from 'src/helpers/CompaniesPreparationHelper'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import deleteIconMobileRow from '../figma/images/deleteIconMobileRow'
import useLanguage from '../hooks/useLanguage'
import { User } from '../interfaces/Dto'
import { replaceTextParams } from '../moesia/helpers/Common'
import { Line } from './Line'

type Props = {
  employee: User
  onInfoClick: () => void
  onEditClick?: () => void
  onDeleteClick?: () => void
}

const EmployeesMobileRowInfoWrapper: React.FC<Props> = ({ employee, onInfoClick, onEditClick, onDeleteClick }) => {
  const { companies, profile, position } = employee
  const { startingDate } = profile ?? ''
  const language = useLanguage()
  const employeeCompanies = useMemo(() => getPrepareCompanies(companies), [companies])
  const positionAndCompany = replaceTextParams(getText(Texts.EmployeeDetailsPositionWithParams, language), [
    position,
    employeeCompanies
  ])

  return (
    <StyledBox fullWidth gap={Spacings.min}>
      <Line fullWidth />
      <AdditionalInfoWrapper fullWidth fullPadding spacing={Spacings.tiny} direction="row" justify="space-between">
        <StyledBox flex="0.9">
          <PositionWrapper direction="row" align="center" gap={Spacings.minimum}>
            <TextContainer textKey={Texts.EmployeeDetailsPositionWithParams} text={positionAndCompany} />
          </PositionWrapper>
          <StyledBox direction="row" align="center" gap={Spacings.minimum}>
            <TextContainer textKey={Texts.EmployeeDetailsStartDate} />
            <TextContainer
              textKey={Texts.EmployeeDetailsStartDate}
              text={!!startingDate ? dayjs(startingDate).format(DATE_ORDINAL_FORMAT) : '-'} //returns formatted date like -> 1st Jan 2023
            />
          </StyledBox>
        </StyledBox>
        <StyledBox align="center" justify="space-between" direction="row" gap={Spacings.tiny} flex="0.1">
          <StyledBox onClick={onInfoClick}>
            <SVGContainer SVG={detailsInfoIcon} />
          </StyledBox>
          {!!onEditClick && !onDeleteClick && (
            <StyledBox onClick={onEditClick}>
              <SVGContainer SVG={detailsEditIcon} />
            </StyledBox>
          )}
          {!!onDeleteClick && !onEditClick && (
            <StyledBox onClick={onDeleteClick}>
              <SVGContainer SVG={deleteIconMobileRow} />
            </StyledBox>
          )}
        </StyledBox>
      </AdditionalInfoWrapper>
    </StyledBox>
  )
}

export default memo(EmployeesMobileRowInfoWrapper)

const AdditionalInfoWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.dirtyWhite};
`
const PositionWrapper = styled(StyledBox)`
  word-wrap: break-word;
  white-space: pre-wrap;
`
