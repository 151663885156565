import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import { Template } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TemplateContainer from './TemplateContainer'

type Props = {
  templates: Template[]
  onAddTemplateClick: () => void
  hasRightsToUploadTemplate: boolean
  hasRightsToDelete: boolean
  onDeleteTemplate: (templateId: string) => void
  setSelectedTemplate: React.Dispatch<React.SetStateAction<Template | null>>
}

const TemplatesSectionDesktop: React.FC<Props> = ({
  templates,
  onAddTemplateClick,
  hasRightsToUploadTemplate,
  onDeleteTemplate,
  hasRightsToDelete,
  setSelectedTemplate
}) => (
  <StyledBox fullWidth>
    <StyledBox fullWidth gap={Spacings.min}>
      <StyledBox fullWidth gap={Spacings.large} align="flex-start" justify="flex-start" direction="row" flexWrap="wrap">
        {!!templates &&
          templates.map((template) => (
            <TemplateContainer
              key={template._id}
              template={template}
              onDeleteTemplate={onDeleteTemplate}
              hasRightsToDelete={hasRightsToDelete}
              setSelectedTemplate={setSelectedTemplate}
            />
          ))}
      </StyledBox>
    </StyledBox>
    {hasRightsToUploadTemplate && (
      <StyledSubmitButton position="absolute">
        <SubmitButton onClick={onAddTemplateClick} textKey={Texts.AddTemplateButtonText} variant="primary" />
      </StyledSubmitButton>
    )}
  </StyledBox>
)

export default TemplatesSectionDesktop

const StyledSubmitButton = styled(StyledBox)`
  bottom: ${Spacings.large};
  right: ${Spacings.large};
`
