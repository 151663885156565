import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import MediaQueries from '../figma/tokens/MediaQueries'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { BlogPost, BlogPostCategory } from '../interfaces/Dto'
import { REGULAR_TRANSFORM, REGULAR_TRANSITION } from '../moesia/Constants'
import {
  BLOG_POST_BIGGER_MOBILE_SCREEN_WIDTH,
  BLOG_POST_FIGMA_HEIGHT,
  BLOG_POST_FIGMA_WIDTH,
  BLOG_POST_MOBILE_HEIGHT,
  BLOG_POST_SMALLER_MOBILE_SCREEN_WIDTH
} from '../moesia/StrongHardCodedSizes'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import BlogPostAdditionalInformation from './BlogPostAdditionalInformation'
import BlogPostCategories from './BlogPostCategories'
import BlogPostHeadSection from './BlogPostHeadSection'
import BlogPostOptions from './BlogPostOptions'
import DevHarbourLoader from './DevHarbourLoader'

type Props = {
  areMainPropsTruthy: boolean
  allCategories: BlogPostCategory[]
  areCategoriesLoading: boolean
  blogPost: BlogPost
  isNotRegularUser: boolean
  handleShowDeleteConfirmation: () => void
  onEdit: () => void
}

const BlogPostContent: React.FC<Props> = ({
  areMainPropsTruthy,
  allCategories,
  areCategoriesLoading,
  blogPost,
  isNotRegularUser,
  handleShowDeleteConfirmation,
  onEdit
}) => {
  const isMobile = useIsMobile()
  const { thumbnail, title, previewContent, category: blogPostCategories } = blogPost

  return (
    <Container fullPadding gap={Spacings.tiny} $isMobile={isMobile}>
      {areMainPropsTruthy ? (
        <>
          <BlogPostHeadSection thumbnail={thumbnail} title={title} previewContent={previewContent} />
          {!!allCategories?.length && (
            <BlogPostCategories
              blogPostCategories={blogPostCategories}
              areCategoriesLoading={areCategoriesLoading}
              allCategories={allCategories}
            />
          )}
          <BlogPostAdditionalInformation blogPost={blogPost} />
          {isNotRegularUser && (
            <BlogPostOptions handleShowDeleteConfirmation={handleShowDeleteConfirmation} onEdit={onEdit} />
          )}
        </>
      ) : (
        <DevHarbourLoader />
      )}
    </Container>
  )
}
export default BlogPostContent

const Container = styled(StyledBox)<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => ($isMobile ? BLOG_POST_MOBILE_HEIGHT : BLOG_POST_FIGMA_HEIGHT)};
  width: ${({ $isMobile }) => ($isMobile ? BLOG_POST_BIGGER_MOBILE_SCREEN_WIDTH : BLOG_POST_FIGMA_WIDTH)};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.illustrationMedium};
  cursor: pointer;

  @media (max-width: ${MediaQueries.xs}px) {
    width: ${BLOG_POST_SMALLER_MOBILE_SCREEN_WIDTH};
  }

  transition: ${REGULAR_TRANSITION};
  &:hover {
    transform: ${({ $isMobile }) => ($isMobile ? 'none' : REGULAR_TRANSFORM)};
  }
`
