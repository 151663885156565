import { UseMutationResult } from 'react-query'
import { Texts } from '../figma/helpers/TextRepository'
import { NewsCardDto } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import { handleErrorMessage } from './NotificationsHelper'

export const handleChangeStatus = (
  newsCard: NewsCardDto,
  mutationHook: UseMutationResult<
    any,
    Error,
    unknown,
    {
      previous: unknown
      data: NewsCardDto
    }
  >,
  handleClose: () => void
) => {
  const data = { ...newsCard, active: !newsCard.active }

  mutationHook
    .mutateAsync(data)
    .then(() => {
      handleClose()
      successNotification(Texts.NotificationSuccessfullyUpdatedNewsCard)
    })
    .catch((err: Error) => {
      handleErrorMessage(err)
    })
}
