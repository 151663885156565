import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { convertToRaw } from 'draft-js'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import { getDocumentsChecklistItems, getPositionOptions } from 'src/helpers/AddEmployeeFormHelper'
import { useCompanies, useDeleteDocument, useUpdateUser, useUploadDocument } from 'src/helpers/QueryHooks'
import useLanguage from 'src/hooks/useLanguage'
import { Company, Document, User } from 'src/interfaces/Dto'
import * as Yup from 'yup'
import { UserType } from '../enums/UserType'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { UploadingProgress, createFileFormData } from '../helpers/DocumentsHelper'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { handleUserArchiveStatus, hasAccess } from '../helpers/UsersHelper'
import { createStartingDateYupValidation } from '../helpers/ValidationHelper'
import getCompanyOptions from '../helpers/getCompanyOptions'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import EditEmployeeFormDesktop from './EditEmployeeFormDesktop'
import EditEmployeeFormMobile from './EditEmployeeFormMobile'

type Props = {
  open: boolean
  onClose: () => void
  employee: User
}

const EditEmployeeFormContainer: React.FC<Props> = ({ open, onClose, employee: currentEmployee }) => {
  const { setSubstate } = useContext(PageSubstateContext)
  const isMobile = useIsMobile()
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const [uploadingProgress, setUploadingProgress] = useState<UploadingProgress>(UploadingProgress.LOADING)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const updateEmployee = useUpdateUser(currentEmployee._id)
  const language = useLanguage()
  const uploadDocument = useUploadDocument()
  const deleteDocument = useDeleteDocument()
  const currentUserRole = useUser().role
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const documentsChecklistItems = useMemo(() => getDocumentsChecklistItems(), [])
  const positionOptions = useMemo(() => getPositionOptions(), [])
  const { data: allCompanies = [] } = useCompanies({ archived: false })
  const { name, birthDate, profile, position, companies, notes, goals, documents } = currentEmployee
  const { plan, startingDate, documentsChecklist } = profile
  const companyOptions = useMemo(() => getCompanyOptions(allCompanies), [allCompanies, allCompanies.length])

  const defaultValues = useMemo(
    () => ({
      name: name,
      birthDate: birthDate,
      startingDate: startingDate,
      position: position,
      companies: companies.map((company: Company) => company._id),
      plan: plan,
      notes: notes,
      goals: goals,
      files: documents,
      documentsChecklist: documentsChecklist ? JSON.parse(documentsChecklist) : ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [birthDate, companies, documents, goals, name, notes, plan, position, startingDate]
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(getText(Texts.AddEmployeeNameErrorRequired, language)),
        companies: Yup.mixed().required(getText(Texts.AddEmployeeCompanyErrorRequired, language)),
        position: Yup.string().required(getText(Texts.AddEmployeePositionErrorRequired, language)),
        birthDate: Yup.mixed()
          .notRequired()
          .test('birthDate', 'Birth date must be actual date', (val) => dayjs(val).isValid() || val === null),
        startingDate: createStartingDateYupValidation(getText(Texts.AddEmployeeStartDateErrorInvalid, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    watch,
    formState: { isSubmitSuccessful }
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const { files } = watch()

  const handleUploadDocument = (newFiles: File[] = []) => {
    if (!newFiles.length) return Promise.resolve(null)

    const newFile = newFiles[newFiles.length - 1]
    const fileData = createFileFormData({ name: 'document', file: newFile, companyId: currentEmployee._id })

    return uploadDocument
      .mutateAsync(fileData)
      .then((document) => {
        setUploadingProgress(UploadingProgress.SUCCESS)
        const { _id } = document
        const { type, name, size } = newFile
        const newFiles = [...(files || []), { type, name, size, _id }]
        setValue('files', newFiles)
        return _id
      })
      .catch((err: Error) => {
        setUploadingProgress(UploadingProgress.ERROR)
        handleFormErrorCatch(err, setError, setSubstate)
        return Promise.resolve()
      })
  }

  const handleDeleteFile = (documentId: string) => {
    deleteDocument
      .mutateAsync(documentId)
      .then((data) => {
        setValue('files', [...files.filter((file: File & Partial<Document>) => file._id !== documentId)])
        return data
      })
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const handleDeleteDocument = (documentId: string) => {
    deleteDocument
      .mutateAsync(documentId)
      .then(() => successNotification(Texts.DocumentDeletedNotification))
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { plan, goals, notes, startingDate, documentsChecklist: _documentsChecklist, ...restData } = data
    const employeePlan = plan && JSON.stringify(convertToRaw(plan?.getCurrentContent()))
    const employeeGoals = goals && JSON.stringify(convertToRaw(goals?.getCurrentContent()))
    const employeeNotes = notes && JSON.stringify(convertToRaw(notes?.getCurrentContent()))

    updateEmployee
      .mutateAsync({
        ...restData,
        profile: {
          startingDate: hasData(startingDate),
          plan: hasData(employeePlan),
          documentsChecklist: JSON.stringify(_documentsChecklist)
        },
        goals: hasData(employeeGoals),
        notes: hasData(employeeNotes),
        documents: files
      })
      .then(() => {
        setSubstate(PageSubstate.DEFAULT)
        successNotification(Texts.NotificationUpdateEmployeeSuccessful)
      })
      .catch((err: Error) => {
        setSubstate(PageSubstate.ERROR)
        handleErrorMessage(err.message)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(currentEmployee, updateEmployee, onClose)

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ defaultValues })
    }
  }, [defaultValues, isSubmitSuccessful, reset, documents])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {!isMobile && (
        <EditEmployeeFormDesktop
          open={open}
          onClose={onClose}
          control={control}
          onClickSubmit={handleSubmit(handleSubmitButton)}
          companyOptions={companyOptions}
          positionOptions={positionOptions}
          documentsChecklistItems={documentsChecklistItems}
          employee={currentEmployee}
          companies={companies}
          onDeleteDocument={handleDeleteDocument}
          handleDeleteFile={handleDeleteFile}
          files={files}
          onFileUpload={handleUploadDocument}
          documents={documents}
          isNotRegularUser={isNotRegularUser}
          uploadingProgress={uploadingProgress}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
        />
      )}
      {!!isMobile && (
        <EditEmployeeFormMobile
          open={open}
          onClose={onClose}
          control={control}
          onClickSubmit={handleSubmit(handleSubmitButton)}
          companyOptions={companyOptions}
          positionOptions={positionOptions}
          documentsChecklistItems={documentsChecklistItems}
          employee={currentEmployee}
          companies={companies}
          onDeleteDocument={handleDeleteDocument}
          handleDeleteFile={handleDeleteFile}
          files={files}
          onFileUpload={handleUploadDocument}
          documents={documents}
          isNotRegularUser={isNotRegularUser}
          uploadingProgress={uploadingProgress}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
        />
      )}
    </>
  )
}

export default EditEmployeeFormContainer
