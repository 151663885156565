/* eslint-disable max-lines */
import React, { useState } from 'react'
import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import { AutoCompleteChipController } from 'src/moesia/components/ReactHookComponents'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import { generateUUID, getArrowSvg } from '../helpers/Common'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { Language } from '../helpers/SidebarHelper'
import { Company, ContactPerson, Document } from '../interfaces/Dto'
import {
  COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT,
  DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH
} from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import AvatarInitials from './AvatarInitials'
import CompanyContactPerson from './CompanyContactPerson'
import CompanyContactSectionContainer from './CompanyContactSectionContainer'
import CompanyContactsWrapperContainer from './CompanyContactsWrapperContainer'
import CompanyDocumentsContainer from './CompanyDocumentsContainer'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import ToggleArchiveButton from './ToggleArchiveButton'
import UploadFileContainer from './UploadFileContainer'
type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onAddContact: (
    contactsContainer: ContactPerson[] | [],
    setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
  ) => void
  isContactInfoFilled: boolean
  control: Control<FieldValues, any>
  language: Language
  setValue: UseFormSetValue<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  watch: UseFormWatch<FieldValues>
  company: Company
  files: Document[]
  onFileUpload: (files: File[]) => void
  onDeleteDocument: (documentId: string) => void
  handleDeleteFile: (documentId: string) => void
  isNotRegularUser: boolean
  documents: Document[]
  uploadingProgress: UploadingProgress
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
}
const EditCompanyFormDesktop: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  onAddContact,
  isContactInfoFilled,
  control,
  language,
  setValue,
  setError,
  clearErrors,
  watch,
  company,
  onFileUpload,
  onDeleteDocument,
  handleDeleteFile,
  isNotRegularUser,
  documents,
  uploadingProgress,
  files,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm
}) => {
  const { name, archived, description, website, projects, contacts, employees } = company
  const [isContactsMenuOpen, setIsContactsMenuOpen] = useState(false)
  const [isEmployedPeopleMenuOpen, setIsEmployedPeopleMenuOpen] = useState(false)
  const [selectedContactEmail, setSelectedContactEmail] = useState<string | null>(null)
  const watchContacts = watch('contacts') || []
  const [contactsContainer, setContactsContainer] = useState<ContactPerson[] | []>(watchContacts.concat(contacts))

  return (
    <>
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateCompanyTitle : Texts.ConfirmArchiveCompanyTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateCompanyProfileQuestion : Texts.ConfirmArchiveCompanyQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
        />
      )}
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={onClose}
          onConfirm={onSubmit}
          confirmationTitle={Texts.UnsavedChangesModalTitle}
          confirmationQuestion={Texts.UnsavedChangesModalQuestion}
          confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
          cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
          cancelButtonColor={FigmaColors.lighterRed}
        />
      )}
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.ApplyChangesButtonText}
                onClick={onSubmit}
                disabled={!isValuesChanged}
              />
            }
            tertiaryButton={
              isNotRegularUser && (
                <ToggleArchiveButton
                  activateTextKey={Texts.ActivateButtonTextModal}
                  archiveTextKey={Texts.ArchiveButtonTextModal}
                  isArchived={archived}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                />
              )
            }
          />
        }
      >
        <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
          <form>
            <StyledBox direction="row" gap={Spacings.medium}>
              <AvatarInitials name={name} />
              <StyledBox gap={Spacings.medium}>
                <StyledBox direction="row" fullWidth>
                  <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <TextFieldController
                      name="name"
                      control={control}
                      labelText={getText(Texts.AddCompanyNameLabel, language)}
                    />
                    <TextFieldController
                      name="vat"
                      control={control}
                      labelText={getText(Texts.AddCompanyVATLabel, language)}
                    />
                    <TextFieldController
                      name="email"
                      control={control}
                      labelText={getText(Texts.AddCompanyEmailLabel, language)}
                    />
                  </FormColumn>
                  <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <Expander
                      fullWidth
                      justify="space-between"
                      align="center"
                      fullPadding
                      spacing={Spacings.tiny}
                      direction="row"
                      $showContent={isEmployedPeopleMenuOpen}
                    >
                      <TextContainer textKey={Texts.EmployedPeople} />
                      <StyledBox
                        onClick={() => !!employees?.length && setIsEmployedPeopleMenuOpen(!isEmployedPeopleMenuOpen)}
                      >
                        <StyledSVGContainer
                          SVG={getArrowSvg(isEmployedPeopleMenuOpen)}
                          $hasContent={!!employees?.length}
                        />
                      </StyledBox>
                    </Expander>
                    <ContactsWrapper
                      fullWidth
                      gap={Spacings.min}
                      $showContent={isEmployedPeopleMenuOpen}
                      $isMarginMedium
                    >
                      {isEmployedPeopleMenuOpen &&
                        employees.map(({ name, position, email }: ContactPerson) => (
                          <CompanyContactPerson
                            key={generateUUID()}
                            name={name}
                            position={position}
                            email={email}
                            details
                          />
                        ))}
                    </ContactsWrapper>
                  </FormColumn>
                </StyledBox>
                <StyledBox fullWidth>
                  <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min} alignText="center">
                    <TextContainer textKey={Texts.CompanyInformation} />
                  </DocumentsTitleWrapper>
                  <StyledBox direction="row" top bottom spacing={Spacings.tiny}>
                    <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                      <StyledBox fullWidth gap={Spacings.medium}>
                        <StyledBox fullWidth gap={Spacings.min}>
                          <TextContainer textKey={Texts.ContactInformation} />
                          <CompanyContactSectionContainer
                            control={control}
                            onAddContact={onAddContact}
                            selectedContactEmail={selectedContactEmail}
                            isContactInfoFilled={isContactInfoFilled}
                            contactsContainer={contactsContainer}
                            watch={watch}
                            setError={setError}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            setSelectedContactEmail={setSelectedContactEmail}
                            setContactsContainer={setContactsContainer}
                          />
                          {!!contactsContainer?.length && (
                            <ExpandableBox
                              fullWidth
                              justify="space-between"
                              align="center"
                              fullPadding
                              spacing={Spacings.tiny}
                              direction="row"
                              $isContactsMenuOpen={isContactsMenuOpen}
                            >
                              <TextContainer textKey={Texts.ContactPeople} />
                              <StyledBox onClick={() => setIsContactsMenuOpen(!isContactsMenuOpen)}>
                                <StyledSVGContainer SVG={getArrowSvg(isContactsMenuOpen)} />
                              </StyledBox>
                            </ExpandableBox>
                          )}
                          {isContactsMenuOpen && (
                            <CompanyContactsWrapperContainer
                              isContactsMenuOpen={isContactsMenuOpen}
                              contactsContainer={contactsContainer}
                              setValue={setValue}
                              setSelectedContactEmail={setSelectedContactEmail}
                              setContactsContainer={setContactsContainer}
                              setIsContactsMenuOpen={setIsContactsMenuOpen}
                            />
                          )}
                        </StyledBox>
                        <AutoCompleteChipController
                          name="website"
                          control={control}
                          labelText={getText(Texts.WebsiteAddress, language)}
                          defaultValue={website}
                        />
                        <AutoCompleteChipController
                          name="projects"
                          control={control}
                          labelText={getText(Texts.ProjectNameAddress, language)}
                          defaultValue={projects}
                        />
                      </StyledBox>
                    </FormColumn>
                    <FormColumn fullWidth fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                      <WidthLimitWrapper fullWidth gap={Spacings.min}>
                        <TextContainer textKey={Texts.InformationForPresentations} />
                        <EditorController
                          name="description"
                          labelTextKey={Texts.CompanyInformationPlaceholder}
                          control={control}
                          defaultValue={description}
                        />
                      </WidthLimitWrapper>
                      <StyledBox gap={Spacings.small}>
                        <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min}>
                          <TextContainer textKey={Texts.AddEmployeeDocsTitle} />
                        </DocumentsTitleWrapper>
                        <WidthLimitWrapper gap={Spacings.small}>
                          <UploadDocuments
                            fullWidth
                            fullPadding
                            spacing={Spacings.medium}
                            align="center"
                            gap={Spacings.tiny}
                          >
                            <UploadFileContainer
                              fullWidth
                              onChange={onFileUpload}
                              files={files}
                              onDeleteDocument={handleDeleteFile}
                              uploadingProgress={uploadingProgress}
                            />
                          </UploadDocuments>
                          <CompanyDocumentsContainer
                            documents={documents}
                            hasRightsToDelete={isNotRegularUser}
                            onDeleteDocument={onDeleteDocument}
                          />
                        </WidthLimitWrapper>
                      </StyledBox>
                    </FormColumn>
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </StyledBox>
          </form>
        </Wrapper>
      </GenericPageModal>
    </>
  )
}
export default EditCompanyFormDesktop
const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
`
const FormColumn = styled(StyledBox)`
  flex: 1;
`
const DocumentsTitleWrapper = styled(StyledBox)`
  border-bottom: 1px solid ${FigmaColors.grayLight};
`
const ExpandableBox = styled(StyledBox)<{ $isContactsMenuOpen?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $isContactsMenuOpen }) =>
    $isContactsMenuOpen ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min};
`
const WidthLimitWrapper = styled(StyledBox)`
  max-width: ${DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH};
  min-width: ${DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH};
`
const StyledSVGContainer = styled(SVGContainer)<{ $hasContent?: boolean }>`
  cursor: ${({ $hasContent }) => ($hasContent ? 'pointer' : 'not-allowed')};
`
const UploadDocuments = styled(StyledBox)`
  border: 1px solid ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
`

const Expander = styled(StyledBox)<{ $showContent?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $showContent }) => ($showContent ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min)};
`
const ContactsWrapper = styled(StyledBox)<{ $showContent: boolean }>`
  border: ${({ $showContent }) => $showContent && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: -${Spacings.large};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;

  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`
