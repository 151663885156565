import isEqual from 'fast-deep-equal/es6'
import { FieldValues } from 'react-hook-form'
import CompanyDetailsPeopleComponent from '../components/CompanyDetailsPeopleComponent'
import routePaths from '../config/RoutePaths'
import { ImagesType } from '../enums/ImagesType'
import { getText } from '../figma/helpers/TextRepository'
import expandArrowDown from '../figma/images/expandArrowDown'
import expandArrowUp from '../figma/images/expandArrowUp'
import { ContactPerson } from '../interfaces/Dto'
import { Language } from '../moesia/interfaces/LanguageType'
import { DocumentFileTypes, ImageFileTypes } from './DocumentsHelper'
import { ImagesCategories, LabelType } from './ImageTypesHelper'
import { PageFlowState } from './PageFlowsHelper'
import { PageSubstate } from './PageSubstateHelper'
import { SidebarItemType } from './SidebarHelper'

export const generateUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const createKeyIndex = (index: number, location: string): string => {
  if (location === '') {
    return `${index}`
  }
  return `${index}-${location}`
}

export const getRawContent = (text: string) => ({
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: '2vm6d',
      text,
      type: 'normal'
    }
  ],
  entityMap: {}
})

export const isProductionENV = () => {
  if (process.env.NODE_ENV === 'production') {
    return true
  }
  return false
}

export const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/

export const hasData = (data: any) => (data ? data : '')

export const sortElements = <T extends Record<string, any>>(elements: T[], key: keyof T = 'createdAt') =>
  elements.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime())

export const getArrowSvg = (isExpanded: boolean) => (isExpanded ? expandArrowUp : expandArrowDown)

export const renderCompanyDetailsPeopleComponent = (contacts: ContactPerson[]) => {
  if (!!contacts?.length) {
    return <CompanyDetailsPeopleComponent contacts={contacts} />
  }
  return null
}

export const findItemById = <T extends { _id: string }>(items: T[], id: string): T => {
  const item = items.find((item) => item._id === id)
  if (!item) {
    throw new Error(`Invalid ID: ${id}`)
  }
  return item
}

export const isButton = (type: SidebarItemType) => type === SidebarItemType.BUTTON
export const isSubButton = (type: SidebarItemType) => type === SidebarItemType.SUBBUTTON
export const isDivider = (type: SidebarItemType) => type === SidebarItemType.DIVIDER
export const isExpandable = (type: SidebarItemType) => type === SidebarItemType.EXPANDABLE

export const containsSubButtons = (
  hasSubButtons?: boolean,
  setShowSubButtons?: React.Dispatch<React.SetStateAction<boolean>>
) => !!hasSubButtons && !!setShowSubButtons

export const isButtonActive = (url: string, path: string) => (url === path ? 'active' : '')

export const pageStateValidator = (substate: PageSubstate, currentPageFlow: PageFlowState) => {
  const isValidPageState =
    substate !== PageSubstate.ERROR &&
    currentPageFlow !== PageFlowState.ERROR &&
    currentPageFlow !== PageFlowState.EMPTY
  return isValidPageState
}

export const mapLabelsToLanguage = (items: LabelType[], language?: Language) => {
  if (items && items.length) {
    return items.map((item) => ({ ...item, label: getText(item.label, language) }))
  }
  return []
}

export const getFileTypes = (fileType?: string) => {
  switch (fileType) {
    case 'document':
      return Object.values(DocumentFileTypes)
    case 'image':
      return Object.values(ImageFileTypes)
    case undefined:
      return Object.values(DocumentFileTypes)
    default:
      return Object.values(DocumentFileTypes)
  }
}

export const getImageCategory = (filterType: ImagesType | null) => {
  switch (filterType) {
    case ImagesType.PRIVATE:
      return ImagesCategories.PRIVATE
    case ImagesType.NEWS_CARD_FRONT:
      return ImagesCategories.NEWS_CARD_FRONT
    case ImagesType.NEWS_CARD_BACK:
      return ImagesCategories.NEWS_CARD_BACK
    case ImagesType.TEAM_MEMBER_IMAGE:
      return ImagesCategories.TEAM_MEMBER_IMAGE
    case ImagesType.COMPANY_PORTFOLIO_CARD_FRONT:
      return ImagesCategories.COMPANY_PORTFOLIO_CARD_FRONT
    case ImagesType.COMPANY_PORTFOLIO_CARD_BACK:
      return ImagesCategories.COMPANY_PORTFOLIO_CARD_BACK
    case ImagesType.BLOG_POST_THUMBNAIL_IMAGE:
      return ImagesCategories.BLOG_POST_THUMBNAIL_IMAGE

    default:
      return ImagesCategories.ALL
  }
}

export const redirectHomePage = () => (window.location.href = routePaths.entry)

export const getInitialWatchValues = (watchValues: FieldValues) => {
  if (!!watchValues.length) {
    return watchValues
  }

  return null
}

export const getIsValuesChanged = (initialWatchValues: FieldValues | null, watchValues: FieldValues) => {
  if (!initialWatchValues) return

  return !isEqual(initialWatchValues, watchValues)
}
