import Spacings from '../figma/tokens/Spacings'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { NewsCardDto } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import NewsCardItem from './NewsCardItem'

type Props = {
  newsCards: NewsCardDto[]
  onDelete: (newsCardId: string) => void
  setSubstate: (state: PageSubstate) => void
  setSelectedNewsCard: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
}

const NewsCardItems: React.FC<Props> = ({ newsCards, setSubstate, setSelectedNewsCard, onDelete }) => (
  <StyledBox gap={Spacings.max} bottom={Spacings.large} direction="row" flexWrap="wrap" justify="space-around">
    {newsCards.map((newsCard) => (
      <NewsCardItem
        key={newsCard._id}
        newsCard={newsCard}
        onEdit={() => {
          setSubstate(PageSubstate.EDIT)
          !!setSelectedNewsCard && setSelectedNewsCard(newsCard)
        }}
        onDelete={() => newsCard._id && onDelete(newsCard._id)}
      />
    ))}
  </StyledBox>
)

export default NewsCardItems
