export default {
  linearBlue: 'linear-gradient(180deg, #7dd6c0 0%, #059ba5 28.44%, #086a8d 65.42%, rgba(0, 29, 73, 0.9) 100%)',
  secondaryButtonColor: '#C9E0E9',
  secondaryButtonIconBackgroundColor: '#AECFDC',
  primaryButtonHoverColor: '#69A6BD',
  tertiaryButtonBackgroundColor: '#F1CCCE',
  tertiaryButtonBorderColor: '#E30733',
  hoverGray: '#FAFAFA',
  lightStone: '#E9E9E9',
  loginButtonColor: '#004672',
  autoLoginButtonColor: '#65a4cb',
  bhBluePrimaryButton: '#00678E',
  bhBlueMedium: '#17789C',
  borderGray: 'rgba(0, 0, 0, 0.23)',
  seaDarkBlue: '#003366',
  dirtyWhite: '#F5F5F5',
  lighterRed: '#FF4B55',
  darkerRed: '#E30733',
  backOfficeBackground: '#F7F7F7',
  lightGreenBackground: '#F8FFFD',
  lightBlueBackground: '#FAFEFF',
  lightRedBackground: '#FFFBFB',
  blackDarkenedBackground: 'rgba(0, 0, 0, 0.8)'
}
