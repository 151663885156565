import { memo } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import errorIcon from 'src/figma/images/errorIcon'
import loginPageLogoMobile from 'src/figma/images/loginPageLogoMobile'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { LoginPageProps } from 'src/interfaces/AuthInterface'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { LOGIN_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import LoginPageTextFieldController from './LoginPageTextFieldController'

const LoginPageMobileView: React.FC<LoginPageProps> = ({
  control,
  handleLogin,
  handleAutoLogin,
  handleClickForgotPassword,
  wrongCredentials
}) => (
  <LoginPage fullWidth align="center" top={Spacings.large}>
    <StyledBox fullPadding top={Spacings.large} spacing={Spacings.large}>
      <StyledBox fullWidth gap={Spacings.tiny} align="center" justify="center">
        <SVGContainer SVG={loginPageLogoMobile} />
        <TextContainer textKey={Texts.LoginPageHeading} />
      </StyledBox>
      <StyledBox fullPadding spacing={Spacings.big} gap={Spacings.medium} align="center" justify="center">
        <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
          <TextContainer textKey={Texts.LoginFormHeading} />
          <TextContainer textKey={Texts.LoginFormSubheading} />
        </StyledBox>
        <LoginPageTextFieldController control={control} name="email" labelTextKey={Texts.LoginEmailLabel} />
        <LoginPageTextFieldController
          control={control}
          type="password"
          name="password"
          labelTextKey={Texts.LoginPasswordLabel}
        />
        {wrongCredentials && (
          <StyledBox direction="row" fullWidth gap={Spacings.min} align="center" justify="flex-end">
            <SVGContainer SVG={errorIcon} />
            <TextContainer textKey={Texts.LoginWrongCredentials} />
          </StyledBox>
        )}
        <ForgotPassword onClick={handleClickForgotPassword} alignSelf="flex-end" alignText="right">
          <TextContainer textKey={Texts.LoginForgotPassword} />
        </ForgotPassword>
        <StyledBox fullWidth gap={Spacings.medium} align="center" justify="center">
          <LoginButton
            fullWidth
            fullPadding
            spacing={Spacings.min}
            alignText="center"
            align="center"
            justify="center"
            onClick={handleLogin}
          >
            <TextContainer textKey={Texts.LoginButtonText} />
          </LoginButton>
          <AutoLoginButton
            fullWidth
            fullPadding
            spacing={Spacings.min}
            alignText="center"
            align="center"
            justify="center"
            onClick={handleAutoLogin}
          >
            <TextContainer textKey={Texts.AutoLoginButtonText} />
          </AutoLoginButton>
        </StyledBox>
      </StyledBox>
    </StyledBox>
  </LoginPage>
)

export default memo(LoginPageMobileView)

const LoginPage = styled(StyledBox)`
  height: 100vh;
  background-color: ${FigmaColors.white};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const LoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`

const AutoLoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.autoLoginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.bhBlue};
  }
`
const ForgotPassword = styled(StyledBox)`
  cursor: pointer;
  span:hover {
    color: ${FigmaColors.grayDark} !important;
  }
`
