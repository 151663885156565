import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useTemplates, useUpdateTemplate } from '../helpers/QueryHooks'
import { createTemplateFormData } from '../helpers/TemplatesHelper'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { Template } from '../interfaces/Dto'

import { getInitialWatchValues, getIsValuesChanged } from '../helpers/Common'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import EditTemplateFormDesktop from './EditTemplateFormDesktop'
import EditTemplateFormMobile from './EditTemplateFormMobile'

type Props = {
  open: boolean
  onClose: () => void
  template: Template
}

const EditTemplateFormContainer: React.FC<Props> = ({ open, onClose, template: _template }) => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const { setSubstate } = useContext(PageSubstateContext)
  const { data: templates } = useTemplates()
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const currentTemplate = templates?.find((template: Template) => template._id === _template._id)
  const updateTemplate = useUpdateTemplate(currentTemplate._id)
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddTemplateNameErrorRequired, language)),
        wikiUrl: createRequiredStringYupValidation(getText(Texts.AddTemplateWikiUrlErrorRequired))
      }),
    [language]
  )

  const defaultValues = useMemo(
    () => ({
      name: currentTemplate.name,
      wikiUrl: currentTemplate.wikiUrl,
      file: currentTemplate
    }),
    [currentTemplate]
  )

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitSuccessful }
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(validationSchema)
  })

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { name, wikiUrl, file } = data
    if (!file) return null
    const fileData = createTemplateFormData({ name: 'document', file: file, customName: name, wikiUrl: wikiUrl })
    updateTemplate
      .mutateAsync(fileData)
      .then(() => {
        successNotification(Texts.NotificationUpdateTemplateSuccessful)
        setSubstate(PageSubstate.DEFAULT)
      })
      .catch((err: Error) => {
        handleErrorMessage(err.message)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ defaultValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {isMobile && (
        <EditTemplateFormMobile
          open={open}
          onClose={onClose}
          onApplyClick={handleSubmit(handleSubmitButton)}
          control={control}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
      {!isMobile && (
        <EditTemplateFormDesktop
          open={open}
          onClose={onClose}
          onApplyClick={handleSubmit(handleSubmitButton)}
          control={control}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default EditTemplateFormContainer
