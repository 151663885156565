import Spacings from '../figma/tokens/Spacings'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { Document } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import DocumentItem from './DocumentItem'

type Props = {
  documents: Document[]
  uploadingProgress?: UploadingProgress
  isDetails?: boolean
  isUploading?: boolean
  hasRightsToDelete?: boolean
  onDeleteDocument?: (documentId: string) => void
}

const CompanyDocumentsContainer: React.FC<Props> = ({
  documents,
  uploadingProgress,
  isDetails,
  isUploading,
  hasRightsToDelete,
  onDeleteDocument
}) => (
  <>
    {!!documents.length && (
      <StyledBox fullWidth gap={Spacings.small}>
        {documents.map((document) => (
          <DocumentItem
            key={document._id}
            document={document}
            uploadingProgress={uploadingProgress}
            isUploading={isUploading}
            hasRightsToDelete={hasRightsToDelete}
            onDeleteDocument={onDeleteDocument}
            isDetails={isDetails}
          />
        ))}
      </StyledBox>
    )}
  </>
)

export default CompanyDocumentsContainer
