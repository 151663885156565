import { useContext, useMemo, useState } from 'react'
import { UserType } from '../enums/UserType'
import { Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext, getTemplatesPageSubstate } from '../helpers/PageSubstateHelper'
import { useDeleteTemplate } from '../helpers/QueryHooks'
import { Template } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import TemplatesSectionDesktop from './TemplatesSectionDesktop'
import TemplatesSectionMobile from './TemplatesSectionMobile'

type Props = {
  templates: Template[]
}

const TemplatesSectionContainer: React.FC<Props> = ({ templates }) => {
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const user = useUser()
  const isMobile = useIsMobile()
  const deleteTemplate = useDeleteTemplate()
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)
  const hasRightsToUploadTemplate = user.role === UserType.ADMIN || user.role === UserType.MANAGER
  const substateView = useMemo(
    () => getTemplatesPageSubstate(substate, setSubstate, selectedTemplate),
    [substate, setSubstate, selectedTemplate]
  )

  const handleDeleteTemplate = (templateId: string) => {
    deleteTemplate
      .mutateAsync(templateId)
      .then(() => successNotification(Texts.TemplateDeletedNotification))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  return (
    <StyledBox fullWidth left right>
      {substateView}
      {isMobile && (
        <TemplatesSectionMobile
          templates={templates}
          onAddTemplateClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          hasRightsToUploadTemplate={hasRightsToUploadTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          hasRightsToDelete={hasRightsToUploadTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {!isMobile && (
        <TemplatesSectionDesktop
          templates={templates}
          onAddTemplateClick={() => {
            setSubstate(PageSubstate.CREATE)
          }}
          hasRightsToUploadTemplate={hasRightsToUploadTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          hasRightsToDelete={hasRightsToUploadTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
    </StyledBox>
  )
}

export default TemplatesSectionContainer
