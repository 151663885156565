import { yupResolver } from '@hookform/resolvers/yup'
import { convertToRaw } from 'draft-js'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import { getPositionOptions } from 'src/helpers/AddEmployeeFormHelper'
import { getDefaultValues } from 'src/helpers/EmployeeFormHelper'
import { useCompanies, useCreateUser } from 'src/helpers/QueryHooks'
import {
  createEmailYupValidation,
  createNotRequiredDateYupValidation,
  createRequiredDateYupValidation,
  createRequiredStringYupValidation
} from 'src/helpers/ValidationHelper'
import useLanguage from 'src/hooks/useLanguage'
import { useNotifications } from 'src/moesia/helpers/NotificationsHelper'
import * as Yup from 'yup'
import { UserType } from '../enums/UserType'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import getCompanyOptions from '../helpers/getCompanyOptions'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddEmployeeFormDesktop from './AddEmployeeFormDesktop'
import AddEmployeeFormMobile from './AddEmployeeFormMobile'
import DevHarbourLoader from './DevHarbourLoader'

type Props = {
  open: boolean
  onClose: () => void
}

const AddEmployeeFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const [isCompleted, setIsCompleted] = useState(false)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const createEmployee = useCreateUser()
  const { successNotification } = useNotifications()
  const { data: companies = [], isLoading } = useCompanies({ archived: false })
  const { setSubstate } = useContext(PageSubstateContext)
  const defaultValues = useMemo(() => getDefaultValues(), [])
  const positionOptions = useMemo(() => getPositionOptions(), [])
  const companyOptions = useMemo(() => getCompanyOptions(companies), [companies, companies.length])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddEmployeeNameErrorRequired, language)),
        email: createEmailYupValidation(
          getText(Texts.AddUserEmailErrorRequired, language),
          getText(Texts.AddUserEmailErrorValid, language)
        ),
        password: createRequiredStringYupValidation(getText(Texts.AddUserPasswordErrorRequired, language)),
        startingDate: createRequiredDateYupValidation(
          getText(Texts.AddEmployeeStartDateErrorInvalid, language),
          'startingDate'
        ),
        position: createRequiredStringYupValidation(getText(Texts.AddEmployeePositionErrorRequired, language)),
        companies: Yup.mixed().required(getText(Texts.AddUserCompanyErrorRequired, language)),
        birthDate: createNotRequiredDateYupValidation(
          getText(Texts.AddEmployeeBirthDateErrorInvalid, language),
          'birthDate'
        )
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
    setError,
    watch
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { plan, goals, notes, ...restData } = data
    const employeePlan = JSON.stringify(convertToRaw(plan?.getCurrentContent()))
    const employeeGoals = JSON.stringify(convertToRaw(goals?.getCurrentContent()))
    const employeeNotes = JSON.stringify(convertToRaw(notes?.getCurrentContent()))

    return createEmployee
      .mutateAsync({
        ...restData,
        plan: hasData(employeePlan),
        goals: hasData(employeeGoals),
        notes: hasData(employeeNotes),
        role: UserType.EMPLOYEE
      })
      .then(() => {
        successNotification(Texts.NotificationAddEmployeeSuccessful)
        setSubstate(PageSubstate.DEFAULT)
        setIsCompleted(true)
      })
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    if (isSubmitSuccessful && isCompleted) {
      reset({ defaultValues })
    }
  }, [isCompleted, isSubmitSuccessful])

  //TODO future refactoring
  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {isLoading && <DevHarbourLoader />}
      {!isLoading && isMobile && (
        <AddEmployeeFormMobile
          control={control}
          open={open}
          companyOptions={companyOptions}
          positionOptions={positionOptions}
          onClickSubmit={handleSubmit(handleSubmitButton)}
          onClose={onClose}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
      {!isLoading && !isMobile && (
        <AddEmployeeFormDesktop
          control={control}
          open={open}
          companyOptions={companyOptions}
          positionOptions={positionOptions}
          onClickSubmit={handleSubmit(handleSubmitButton)}
          onClose={onClose}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddEmployeeFormContainer
