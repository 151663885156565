import styled from 'styled-components'
import { UserType } from '../enums/UserType'
import { Texts } from '../figma/helpers/TextRepository'
import deleteCategoryIcon from '../figma/images/deleteCategoryIcon'
import nonTrendingStarIcon from '../figma/images/nonTrendingStarIcon'
import trendingStarIcon from '../figma/images/trendingStarIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { generateUUID } from '../helpers/Common'
import { hasAccess } from '../helpers/UsersHelper'
import { BlogPostCategory } from '../interfaces/Dto'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'

type Props = {
  categories: BlogPostCategory[]
  handleShowDeleteConfirmationModal: (category?: BlogPostCategory) => void
  handleShowTrendingConfirmationModal: (category?: BlogPostCategory) => void
}

const BlogPostCategoriesContent: React.FC<Props> = ({
  categories,
  handleShowDeleteConfirmationModal,
  handleShowTrendingConfirmationModal
}) => {
  const isMobile = useIsMobile()
  const currentUserRole = useUser().role
  const hasRightsToDelete = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)

  return (
    <StyledBox right bottom spacing={Spacings.medium}>
      <TitleWrapper fullPadding spacing={Spacings.tiny} position="absolute" fullWidth>
        <TextContainer textKey={Texts.BlogPostCategories} />
      </TitleWrapper>
      {!categories.length && (
        <StyledBox left top={Spacings.huge}>
          <TextContainer textKey={Texts.NoCategoriesToDisplay} />
        </StyledBox>
      )}
      {!!categories.length && (
        <CategoriesWrapper
          direction="row"
          flexWrap="wrap"
          gap={Spacings.small}
          bottom={isMobile ? Spacings.medium : Spacings.huge}
          left={Spacings.large}
        >
          {categories.map((category) => (
            <CategoryItem key={generateUUID()} align="center" fullPadding spacing={Spacings.min} position="relative">
              <TextContainer key={category.title} textKey={Texts.BlogPostCategoryTitleBig} text={category.title} />
              {hasRightsToDelete && (
                <DeleteOption position="absolute" onClick={() => handleShowDeleteConfirmationModal(category)}>
                  <SVGContainer SVG={deleteCategoryIcon} />
                </DeleteOption>
              )}
              <TrendingOption
                title={!!category.isTrending && Texts.TrendingCategoryTitle.characters}
                position="absolute"
                onClick={() => handleShowTrendingConfirmationModal(category)}
              >
                <SVGContainer SVG={category.isTrending ? trendingStarIcon : nonTrendingStarIcon} />
              </TrendingOption>
            </CategoryItem>
          ))}
        </CategoriesWrapper>
      )}
    </StyledBox>
  )
}

export default BlogPostCategoriesContent

const TitleWrapper = styled(StyledBox)`
  z-index: ${ZIndex.minimum};
  background-color: ${FigmaColors.white};
  border-bottom: 1px solid ${FigmaColors.gray2};
`

const CategoriesWrapper = styled(StyledBox)`
  padding-top: calc(${Spacings.huge} + ${Spacings.small});
`

const CategoryItem = styled(StyledBox)`
  border: 1px solid ${FigmaColors.borderGray};
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.lightStone};
  min-width: ${Spacings.big};
`

const DeleteOption = styled(StyledBox)`
  top: -${Spacings.min};
  right: -${Spacings.minimum};
  cursor: pointer;
`

const TrendingOption = styled(StyledBox)`
  top: -${Spacings.min};
  left: -${Spacings.minimum};
  cursor: pointer;

  &:hover {
    svg path {
      fill: ${FigmaColors.yellow};
    }
  }
`
