import isEqual from 'fast-deep-equal/es6'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { UserType } from '../enums/UserType'
import { Texts } from '../figma/helpers/TextRepository'
import { getBlogPosts } from '../helpers/ApiHandler'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext, getBlogPostsPageSubstate } from '../helpers/PageSubstateHelper'
import { useBlogPosts, useDeleteBlogPost } from '../helpers/QueryHooks'
import { hasAccess } from '../helpers/UsersHelper'
import { BlogPost } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import scrollToTop from '../moesia/helpers/ScrollToTopHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import BlogPostsSectionDesktopView from './BlogPostsSectionDesktopView'
import BlogPostsSectionMobileView from './BlogPostsSectionMobileView'

const BlogPostsSectionContainer: React.FC = () => {
  const isMobile = useIsMobile()
  const currentUserRole = useUser().role
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const [currentPage, setCurrentPage] = useState<number | null>(null)
  const { data: blogPostsData, isRefetching } = useBlogPosts()
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>(isRefetching ? [] : blogPostsData.newsArticles)
  const [selectedBlogPost, setSelectedBlogPost] = useState<BlogPost | null>(null)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const deleteBlogPostMutation = useDeleteBlogPost()
  const substateView = useMemo(
    () => getBlogPostsPageSubstate(substate, setSubstate, selectedBlogPost, setSelectedBlogPost),
    [substate, setSubstate, selectedBlogPost]
  )

  const onPageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
    scrollToTop()
  }

  const handleDeleteBlogPost = (blogPostId: string) => {
    deleteBlogPostMutation
      .mutateAsync(blogPostId)
      .then(() => successNotification(Texts.NotificationDeletedBlogPostSuccessful))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  useEffect(() => {
    const handleChangePage = async () => {
      if (!!currentPage) {
        setIsContentLoading(true)
        setBlogPosts(await getBlogPosts({ page: currentPage }).then((res) => res.newsArticles))
        setIsContentLoading(false)
      }
    }

    handleChangePage()
  }, [currentPage])

  useEffect(() => {
    const detectChanges = async () => {
      const currentBlogs = await getBlogPosts({ page: currentPage }).then((res) => res.newsArticles)
      if (!isEqual(currentBlogs, blogPosts)) {
        setBlogPosts(currentBlogs)
      }

      !currentBlogs.length && !!currentPage && setCurrentPage(currentPage - 1)
    }

    detectChanges()
  })

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && !isMobile && (
        <BlogPostsSectionDesktopView
          blogPosts={blogPosts}
          blogPostPages={blogPostsData?.totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onAddBlogPostClick={() => setSubstate(PageSubstate.CREATE)}
          setSubstate={setSubstate}
          setSelectedBlogPost={setSelectedBlogPost}
          onDelete={handleDeleteBlogPost}
          isNotRegularUser={isNotRegularUser}
          isContentLoading={isContentLoading}
        />
      )}
      {substate !== PageSubstate.ERROR && isMobile && (
        <BlogPostsSectionMobileView
          blogPosts={blogPosts}
          blogPostPages={blogPostsData?.totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onAddBlogPostClick={() => setSubstate(PageSubstate.CREATE)}
          setSubstate={setSubstate}
          setSelectedBlogPost={setSelectedBlogPost}
          onDelete={handleDeleteBlogPost}
          isNotRegularUser={isNotRegularUser}
          isContentLoading={isContentLoading}
        />
      )}
    </>
  )
}

export default BlogPostsSectionContainer
