import { memo, useState } from 'react'
import styled from 'styled-components'
import { NewsCardDto } from '../../interfaces/Dto'
import { TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE } from '../StrongHardCodedSizes'
import NewsCardBack from './NewsCardBack'
import NewsCardFront from './NewsCardFront'
import StyledBox from './StyledBox'

type Props = {
  newsCard: NewsCardDto
}

const NewsCard = ({ newsCard }: Props) => {
  const { frontText, backText, frontImage, backImage, backTextLink, gaEvent } = newsCard
  const [isHovered, setIsHovered] = useState(false)

  const handleHoverState = () => setIsHovered(!isHovered)

  return (
    <FlipCard onMouseEnter={handleHoverState} onMouseLeave={handleHoverState}>
      <FlipCardInner position="relative" $isHovered={isHovered}>
        <NewsCardFront textKey={frontText} image={frontImage} />
        <NewsCardBack textKey={backText} image={backImage} backTextLink={backTextLink} googleAnalyticsEvent={gaEvent} />
      </FlipCardInner>
    </FlipCard>
  )
}

export default memo(NewsCard)

const FlipCard = styled(StyledBox)`
  // Used for the 3D effect when flipping the card
  perspective: ${TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE};
`

const FlipCardInner = styled(StyledBox)<{ $isHovered: boolean }>`
  transition: transform 0.8s;
  transform: ${({ $isHovered }) => $isHovered && 'rotateY(180deg)'};
  transform-style: preserve-3d;
`
