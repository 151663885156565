import MaterialTable, { Column, MTableToolbar, Options } from '@material-table/core'
import { TableBodyProps } from '@mui/material/TableBody/TableBody'
import { useContext, useMemo } from 'react'
import buttonAddIcon from 'src/figma/images/buttonAddIcon'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { sortElements } from '../helpers/Common'
import { PageSubstate, PageSubstateContext, getEmployeesPageSubstate } from '../helpers/PageSubstateHelper'
import { getTableOptions } from '../helpers/getTableOptions'
import { User } from '../interfaces/Dto'
import { MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE } from '../moesia/StrongHardCodedSizes'
import useIsMobile from '../moesia/hooks/useIsMobile'
import EmployeesMobileRowInfoWrapper from './EmployeesMobileRowInfoWrapper'
import GenericConfirmationModal from './GenericConfirmationModal'
import MobileTableAddButton from './MobileTableAddButton'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type EmployeesTableProps = {
  employees: User[]
  extraOptions: Options<TableBodyProps>
  tableColumnsMobile: Column<User>[]
  searchPlaceholder: string
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  isNotRegularUser: boolean
  selectedEmployee: User | null
  setSelectedEmployee: React.Dispatch<React.SetStateAction<User | null>>
  onEmployeeDelete: () => void
}

const EmployeesTableMobile: React.FC<EmployeesTableProps> = ({
  employees,
  extraOptions,
  tableColumnsMobile,
  searchPlaceholder,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  isNotRegularUser,
  selectedEmployee,
  setSelectedEmployee,
  onEmployeeDelete
}) => {
  const isMobile = useIsMobile()
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const tableOptions = getTableOptions({ extraOptions: extraOptions })
  const substateView = useMemo(
    () => getEmployeesPageSubstate(substate, setSubstate, selectedEmployee, setSelectedEmployee),
    [substate, setSubstate, selectedEmployee]
  )

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && (
        <>
          {shouldShowDeleteConfirmationModal && (
            <GenericConfirmationModal
              open
              onClose={handleShowDeleteConfirmation}
              onConfirm={onEmployeeDelete}
              confirmationTitle={Texts.ConfirmDeleteEmployeeTitle}
              confirmationQuestion={Texts.ConfirmDeleteEmployeeQuestion}
              confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
              confirmButtonColor={FigmaColors.lighterRed}
              smallSize
            />
          )}
          <StyledBox fullWidth justify="center" position="absolute">
            <TableWrapper isMobile={isMobile}>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <TableToolbar fullWidth>
                      {isNotRegularUser && (
                        <ToggleArchiveButton
                          activateTextKey={Texts.ActivatedButtonTextMobile}
                          archiveTextKey={Texts.ArchivedButtonTextMobile}
                          onClick={handleShouldShowArchived}
                          isArchived={isShowingArchived}
                        />
                      )}
                      <StyledBox
                        fullWidth
                        bottom={Spacings.minimum}
                        direction="row"
                        align="center"
                        justify="space-between"
                      >
                        <MTableToolbar
                          {...props}
                          searchFieldStyle={{
                            ...tableOptions.searchFieldStyle,
                            width: isShowingArchived && MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE
                          }}
                        />
                        {!isShowingArchived && (
                          <MobileTableAddButton
                            onClick={() => setSubstate(PageSubstate.CREATE)}
                            buttonIcon={buttonAddIcon}
                          />
                        )}
                      </StyledBox>
                    </TableToolbar>
                  )
                }}
                columns={!!tableColumnsMobile ? tableColumnsMobile : []}
                data={sortElements(employees)}
                localization={{
                  toolbar: {
                    searchPlaceholder: searchPlaceholder
                  }
                }}
                options={tableOptions}
                detailPanel={[
                  {
                    render: ({ rowData }) => (
                      <EmployeesMobileRowInfoWrapper
                        employee={rowData}
                        onInfoClick={() => {
                          !!rowData && setSelectedEmployee(rowData as User)
                          setSubstate(PageSubstate.DETAILS)
                        }}
                        onEditClick={
                          !isShowingArchived && isNotRegularUser
                            ? () => {
                                setSelectedEmployee(rowData as User)
                                setSubstate(PageSubstate.EDIT)
                              }
                            : undefined
                        }
                        onDeleteClick={
                          isShowingArchived
                            ? () => {
                                setSelectedEmployee(rowData as User)
                                handleShowDeleteConfirmation()
                              }
                            : undefined
                        }
                      />
                    )
                  }
                ]}
              />
            </TableWrapper>
          </StyledBox>
        </>
      )}
    </>
  )
}

export default EmployeesTableMobile

const TableToolbar = styled(StyledBox)`
  margin-top: -${Spacings.small};
`
