import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userMenuArrowLeft from 'src/figma/images/userMenuArrowLeft'
import Spacings from 'src/figma/tokens/Spacings'
import { getAccountMenuContent } from 'src/helpers/SidebarHelper'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import { generateUUID } from '../helpers/Common'
import TokenService from '../helpers/TokenHelper'
import { hasAccess } from '../helpers/UsersHelper'
import { defaultUser, useAppState, useUser } from './AuthProvider'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import MobileLanguageMenu from './MobileLanguageMenu'
import SidebarAccountMenuItem from './SidebarAccountMenuItem'

type Props = {
  setIsOpenAccountMenu: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseMenu: () => void
}

const MobileAccountMenu: React.FC<Props> = ({ setIsOpenAccountMenu, handleCloseMenu }) => {
  const [isOpenLanguageMenu, setIsOpenLanguageMenu] = useState(false)
  const { state, setState } = useAppState()
  const location = useLocation()
  const navigate = useNavigate()
  const currentUserRole = useUser().role

  const userMenuItems = useMemo(() => getAccountMenuContent(), [])

  const handleClick = (idx: number, url?: string) =>
    idx === userMenuItems.length - 1
      ? () => setIsOpenLanguageMenu(true)
      : () => {
          if (location.pathname === url) {
            handleCloseMenu()
            return
          }

          navigate(url || '')
        }

  const handleLogout = () => {
    setState({ ...state, user: defaultUser })
    TokenService.removeUser()
  }

  return (
    <>
      {!isOpenLanguageMenu && (
        <StyledBox fullWidth justify="space-between" top={Spacings.huge}>
          <StyledBox fullWidth fullPadding spacing={Spacings.medium} justify="space-between">
            <StyledBox onClick={() => setIsOpenAccountMenu(false)}>
              <SVGContainer SVG={userMenuArrowLeft} />
            </StyledBox>
            <StyledBox fullWidth gap={Spacings.min}>
              {!!userMenuItems &&
                userMenuItems.map(
                  ({ icon, iconMobile, textKey, arrow, url, permissions }, idx) =>
                    (!permissions || (!!permissions.length && hasAccess(permissions, currentUserRole))) && (
                      <SidebarAccountMenuItem
                        key={generateUUID()}
                        onClick={handleClick(idx, url)}
                        icon={icon}
                        textKey={textKey}
                        arrow={arrow}
                        iconMobile={iconMobile}
                        active={location.pathname === url}
                      />
                    )
                )}
            </StyledBox>
          </StyledBox>
          <MenuUserInformation iconType={UserInformationType.LOG_OUT} onClick={handleLogout} />
        </StyledBox>
      )}
      {!!isOpenLanguageMenu && <MobileLanguageMenu setIsOpenLanguageMenu={setIsOpenLanguageMenu} />}
    </>
  )
}

export default MobileAccountMenu
