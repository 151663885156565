import { memo } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import errorIcon from 'src/figma/images/errorIcon'
import loginPageLogo from 'src/figma/images/loginPageLogo'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { LoginPageProps } from 'src/interfaces/AuthInterface'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { LOGIN_BUTTON_HEIGHT, LOGIN_PAGE_MAX_HEIGHT, LOGIN_PAGE_MAX_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import LoginPageTextFieldController from './LoginPageTextFieldController'

const LoginPageDesktopView: React.FC<LoginPageProps> = ({
  control,
  handleLogin,
  handleAutoLogin,
  handleClickForgotPassword,
  wrongCredentials
}) => (
  <form onSubmit={handleLogin}>
    <PageWrapper fullWidth fullPadding spacing={Spacings.large} align="center" justify="center">
      <LoginPage fullWidth direction="row" justify="space-between">
        <StyledBox fullPadding top={Spacings.large} spacing={Spacings.large}>
          <StyledBox direction="row" gap={Spacings.medium} align="center">
            <SVGContainer SVG={loginPageLogo} />
            <TextContainer textKey={Texts.LoginPageHeading} />
          </StyledBox>
          <StyledBox
            fullPadding
            top={Spacings.big}
            left={Spacings.huge}
            gap={Spacings.medium}
            align="center"
            justify="center"
          >
            <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
              <TextContainer textKey={Texts.LoginFormHeading} />
              <TextContainer textKey={Texts.LoginFormSubheading} />
            </StyledBox>

            <LoginPageTextFieldController control={control} name="email" labelTextKey={Texts.LoginEmailLabel} />
            <LoginPageTextFieldController
              control={control}
              type="password"
              name="password"
              labelTextKey={Texts.LoginPasswordLabel}
            />
            {wrongCredentials && (
              <StyledBox direction="row" fullWidth gap={Spacings.min} align="center" justify="flex-end">
                <SVGContainer SVG={errorIcon} />
                <TextContainer textKey={Texts.LoginWrongCredentials} />
              </StyledBox>
            )}
            <ForgotPassword onClick={handleClickForgotPassword} alignSelf="flex-end" alignText="right">
              <TextContainer textKey={Texts.LoginForgotPassword} />
            </ForgotPassword>
            <StyledBox fullWidth gap={Spacings.medium} align="center" justify="center">
              <LoginButton onClick={handleLogin} fullWidth fullPadding spacing={Spacings.min} alignText="center">
                <TextContainer textKey={Texts.LoginButtonText} />
                <SubmitButtonProvider type="submit" />
              </LoginButton>
              <AutoLoginButton
                onClick={handleAutoLogin}
                fullWidth
                fullPadding
                spacing={Spacings.min}
                alignText="center"
              >
                <TextContainer textKey={Texts.AutoLoginButtonText} />
              </AutoLoginButton>
            </StyledBox>
          </StyledBox>
        </StyledBox>
        <StyledBox>
          <StyledImageContainer imageKey={Images.loginPageImage} />
        </StyledBox>
      </LoginPage>
    </PageWrapper>
  </form>
)

export default memo(LoginPageDesktopView)

const PageWrapper = styled(StyledBox)`
  height: 100vh;
  box-sizing: border-box;
  background-color: ${FigmaColors.lightStone};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const LoginPage = styled(StyledBox)`
  max-width: ${LOGIN_PAGE_MAX_WIDTH};
  max-height: ${LOGIN_PAGE_MAX_HEIGHT};
  border-radius: ${BorderRadius.verySoft};
  overflow: hidden;
  background-color: ${FigmaColors.white};
`

const LoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`

const AutoLoginButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.autoLoginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.bhBlue};
  }
`
const ForgotPassword = styled(StyledBox)`
  cursor: pointer;
  span:hover {
    color: ${FigmaColors.grayDark} !important;
  }
`
const StyledImageContainer = styled(ImageContainer)`
  img {
    height: 100%;
  }
`
const SubmitButtonProvider = styled.button`
  visibility: hidden;
`
