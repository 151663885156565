import styled from 'styled-components'
import { UserInformationType } from '../components/MenuUserInformation'
import navigationUserMenuIcon from '../figma/images/navigationUserMenuIcon'
import userMenuLogoutIcon from '../figma/images/userMenuLogoutIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'

export const isNotIconTypeNone = (iconType: UserInformationType | undefined) => iconType !== UserInformationType.NONE

export const isMatchingIconType = (iconType: UserInformationType | undefined, infoType: UserInformationType) =>
  iconType === infoType

export const getInfoIcon = (iconType: UserInformationType | undefined, onClick: (() => void) | undefined) => {
  switch (true) {
    case iconType === UserInformationType.LOG_OUT:
      return (
        <StyledBox onClick={onClick} link>
          <StyledSVGContainer SVG={userMenuLogoutIcon} />
        </StyledBox>
      )

    case iconType === UserInformationType.OPTIONS:
      return (
        <StyledBox onClick={onClick} link>
          <SVGContainer SVG={navigationUserMenuIcon} />
        </StyledBox>
      )

    default:
      return null
  }
}

const StyledSVGContainer = styled(SVGContainer)`
  path {
    fill: ${FigmaColors.lighterRed};
  }
`
