import { useContext } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import archiveIcon from 'src/figma/images/archiveIcon'
import editIcon from 'src/figma/images/editIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import deleteImageIcon from '../figma/images/deleteImageIcon'
import ZIndex from '../figma/tokens/ZIndex'
import { generateUUID } from '../helpers/Common'
import { getCompanyBoxesOptions } from '../helpers/CompanyDetailsMobileHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { Company } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileDetailsBox from './MobileDetailsBox'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  company: Company
  handleDeleteButton: () => void
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
}

const CompanyDetailsMobile: React.FC<Props> = ({
  open,
  onClose,
  company,
  handleDeleteButton,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation
}) => {
  const { setSubstate } = useContext(PageSubstateContext)
  if (!company) return null
  const { archived, name, vat } = company
  const companyDetailsBoxes = getCompanyBoxesOptions(company)
  const handleEditButton = () => setSubstate(PageSubstate.EDIT)

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteCompanyTitle}
          confirmationQuestion={Texts.ConfirmDeleteCompanyQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateCompanyTitle : Texts.ConfirmArchiveCompanyTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateCompanyProfileQuestion : Texts.ConfirmArchiveCompanyQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={onClose}
        buttons={
          <ModalButtons
            primaryButton={
              <MobileSubmitButton
                textKey={!!archived ? Texts.ConfirmDeleteButtonText : Texts.EditButtonText}
                buttonIcon={!!archived ? deleteImageIcon : editIcon}
                textBackgroundColor={!!archived ? FigmaColors.darkerRed : FigmaColors.bhBluePrimaryButton}
                iconBackgroundColor={!!archived ? FigmaColors.lighterRed : FigmaColors.bhBlueMedium}
                onClick={!!archived ? handleShowDeleteConfirmation : handleEditButton}
              />
            }
            tertiaryButton={
              <MobileSubmitButton
                textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                buttonIcon={!!archived ? activeIcon : archiveIcon}
                textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                onClick={handleShowArchiveStatusChangeConfirmation}
              />
            }
          />
        }
      >
        <StyledBox fullWidth gap={Spacings.big}>
          <StyledBox fullWidth align="center" position="relative">
            <StyledImageContainer imageKey={Images.companyDetailsMobileBackground} />
            <BasicInformation align="center" justify="center" position="absolute">
              <AvatarInitials name={name} border />
              <StyledBox align="center" justify="center" top={Spacings.min}>
                <StyledBox gap={Spacings.minimum} align="center">
                  <TextContainer textKey={Texts.CompanyDetailsName} text={name} />
                  <TextContainer textKey={Texts.CompanyDetailsVAT} text={vat} />
                </StyledBox>
              </StyledBox>
            </BasicInformation>
          </StyledBox>
          <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.medium}>
            {!!companyDetailsBoxes.length &&
              companyDetailsBoxes.map(({ boxIcon, boxTitle, boxInfo }) => (
                <MobileDetailsBox
                  key={generateUUID()}
                  itemIcon={boxIcon}
                  itemTitle={boxTitle}
                  itemInfo={boxInfo as JSX.Element | string}
                />
              ))}
          </StyledBox>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default CompanyDetailsMobile

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;

  img {
    width: 100%;
  }
`
const BasicInformation = styled(StyledBox)`
  bottom: -${Spacings.large};
`
