import React, { useState } from 'react'
import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import StyledBox from 'src/moesia/components/StyledBox'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import archiveIcon from '../figma/images/archiveIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import ZIndex from '../figma/tokens/ZIndex'
import { getArrowSvg } from '../helpers/Common'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { Language } from '../helpers/SidebarHelper'
import { Company, ContactPerson, Document } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import { AutoCompleteChipController } from '../moesia/components/ReactHookComponents'
import SVGContainer from '../moesia/components/SVGContainer'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import AvatarInitials from './AvatarInitials'
import CompanyContactSectionContainer from './CompanyContactSectionContainer'
import CompanyContactsWrapperContainer from './CompanyContactsWrapperContainer'
import CompanyDocumentsContainer from './CompanyDocumentsContainer'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'
import UploadFileContainer from './UploadFileContainer'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onAddContact: (
    contactsContainer: ContactPerson[] | [],
    setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
  ) => void
  isContactInfoFilled: boolean
  control: Control<FieldValues, any>
  language: Language
  setValue: UseFormSetValue<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  watch: UseFormWatch<FieldValues>
  company: Company
  files: Document[]
  onFileUpload: (files: File[]) => void
  onDeleteDocument: (documentId: string) => void
  handleDeleteFile: (documentId: string) => void
  isNotRegularUser: boolean
  documents: Document[]
  uploadingProgress: UploadingProgress
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
}

const EditCompanyFormMobile: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  onAddContact,
  isContactInfoFilled,
  control,
  language,
  setValue,
  setError,
  clearErrors,
  watch,
  company,
  onDeleteDocument,
  handleDeleteFile,
  onFileUpload,
  isNotRegularUser,
  uploadingProgress,
  documents,
  files,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm
}) => {
  const { name, archived, description, website, projects, contacts } = company
  const [isContactsMenuOpen, setIsContactsMenuOpen] = useState(false)
  const [selectedContactEmail, setSelectedContactEmail] = useState<string | null>(null)
  const watchContacts = watch('contacts') || []
  const [contactsContainer, setContactsContainer] = useState<ContactPerson[] | []>(watchContacts.concat(contacts))

  return (
    <>
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateCompanyTitle : Texts.ConfirmArchiveCompanyTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateCompanyProfileQuestion : Texts.ConfirmArchiveCompanyQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={onClose}
          onConfirm={onSubmit}
          confirmationTitle={Texts.UnsavedChangesModalTitle}
          confirmationQuestion={Texts.UnsavedChangesModalQuestion}
          confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
          cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
          cancelButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.ApplyChangesButtonTextMobile}
                onClick={onSubmit}
                disabled={!isValuesChanged}
              />
            }
            tertiaryButton={
              isNotRegularUser && (
                <MobileSubmitButton
                  textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                  buttonIcon={!!archived ? activeIcon : archiveIcon}
                  textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                  iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                />
              )
            }
          />
        }
      >
        <StyledBox fullWidth align="center">
          <StyledBox fullWidth align="center" position="relative">
            <StyledImageContainer imageKey={Images.companyDetailsMobileBackground} />
            <StyledGenericAvatar position="absolute">
              <AvatarInitials name={name} border />
            </StyledGenericAvatar>
          </StyledBox>
          <FormColumn
            fullWidth
            top={Spacings.small}
            bottom={Spacings.large}
            left
            right
            spacing={Spacings.big}
            gap={Spacings.small}
          >
            <TextFieldController
              name="name"
              control={control}
              labelText={getText(Texts.AddCompanyNameLabel, language)}
            />
            <TextFieldController name="vat" control={control} labelText={getText(Texts.AddCompanyVATLabel, language)} />
            <TextFieldController
              name="email"
              control={control}
              labelText={getText(Texts.AddCompanyEmailLabel, language)}
            />
            <CompanyContactSectionContainer
              control={control}
              onAddContact={onAddContact}
              selectedContactEmail={selectedContactEmail}
              isContactInfoFilled={isContactInfoFilled}
              contactsContainer={contactsContainer}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              setSelectedContactEmail={setSelectedContactEmail}
              setContactsContainer={setContactsContainer}
            />
            {!!contactsContainer?.length && (
              <ExpandableBox
                fullWidth
                justify="space-between"
                align="center"
                fullPadding
                spacing={Spacings.tiny}
                direction="row"
                $isContactsMenuOpen={isContactsMenuOpen}
              >
                <TextContainer textKey={Texts.ContactPeople} />
                <StyledBox onClick={() => setIsContactsMenuOpen(!isContactsMenuOpen)}>
                  <SVGContainer SVG={getArrowSvg(isContactsMenuOpen)} />
                </StyledBox>
              </ExpandableBox>
            )}
            {isContactsMenuOpen && (
              <CompanyContactsWrapperContainer
                isContactsMenuOpen={isContactsMenuOpen}
                contactsContainer={contactsContainer}
                setValue={setValue}
                setSelectedContactEmail={setSelectedContactEmail}
                setContactsContainer={setContactsContainer}
                setIsContactsMenuOpen={setIsContactsMenuOpen}
              />
            )}
            <AutoCompleteChipController
              name="website"
              control={control}
              labelText={getText(Texts.WebsiteAddress, language)}
              defaultValue={website}
            />
            <AutoCompleteChipController
              name="projects"
              control={control}
              labelText={getText(Texts.ProjectNameAddress, language)}
              defaultValue={projects}
            />
            <EditorController
              name="description"
              labelTextKey={Texts.CompanyInformationPlaceholder}
              control={control}
              defaultValue={description}
            />
            <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min}>
              <TextContainer textKey={Texts.AddEmployeeDocsTitle} />
            </DocumentsTitleWrapper>
            <UploadDocuments fullWidth fullPadding spacing={Spacings.medium} align="center" gap={Spacings.tiny}>
              <UploadFileContainer
                fullWidth
                onChange={onFileUpload}
                files={files}
                onDeleteDocument={handleDeleteFile}
                uploadingProgress={uploadingProgress}
              />
            </UploadDocuments>
            <CompanyDocumentsContainer
              documents={documents}
              hasRightsToDelete={isNotRegularUser}
              onDeleteDocument={onDeleteDocument}
            />
          </FormColumn>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default EditCompanyFormMobile

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;

  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
const FormColumn = styled(StyledBox)`
  flex: 1;
`
const ExpandableBox = styled(StyledBox)<{ $isContactsMenuOpen?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $isContactsMenuOpen }) =>
    $isContactsMenuOpen ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min};
`
const UploadDocuments = styled(StyledBox)`
  border: 1px solid ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
`
const DocumentsTitleWrapper = styled(StyledBox)`
  border-bottom: 1px solid ${FigmaColors.grayLight};
`
