import { useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import DevHarbourLoader from './DevHarbourLoader'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import StyledLink from './StyledLink'

type Props = {
  isOpen: boolean
  onClose: () => void
  imageName: string
  imageUrl: string
  downloadButton?: boolean
}
const ExpandedImageModal: React.FC<Props> = ({ isOpen, onClose, imageName, imageUrl, downloadButton }) => {
  const isMobile = useIsMobile()
  const [isLoaded, setIsLoaded] = useState(false)

  const handleImageLoad = () => setIsLoaded(true)
  return (
    <>
      {!isLoaded && <DevHarbourLoader />}
      <StyledGenericPageModal
        open={isOpen}
        onClose={onClose}
        buttons={
          downloadButton && (
            <ModalButtons
              primaryButton={
                <StyledLink openInNewTab href={imageUrl}>
                  <SubmitButton variant="primary" textKey={Texts.DownloadButtonText} />
                </StyledLink>
              }
            />
          )
        }
        $isLoaded={isLoaded}
      >
        <StyledBox top={isMobile ? Spacings.max : Spacings.large}>
          <ImageNameWrapper bottom={isMobile ? Spacings.large : Spacings.min} left={Spacings.big}>
            <TextContainer textKey={Texts.NotificationDefault} text={imageName} />
          </ImageNameWrapper>
          <ImageWrapper left right bottom spacing={Spacings.big}>
            <Image src={imageUrl} onLoad={handleImageLoad} />
          </ImageWrapper>
        </StyledBox>
      </StyledGenericPageModal>
    </>
  )
}
export default ExpandedImageModal

const StyledGenericPageModal = styled(GenericPageModal)<{ $isLoaded: boolean }>`
  display: ${({ $isLoaded }) => !$isLoaded && 'none'};
`

const ImageWrapper = styled(StyledBox)`
  max-width: 100vw;
`

const Image = styled.img`
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.illustrationMedium};
`

const ImageNameWrapper = styled(StyledBox)`
  margin: 0;
`
