import React from 'react'
import { Control } from 'react-hook-form'
import { Texts } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from 'src/moesia/components/ReactHookComponents'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import styled from 'styled-components'
import { DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH } from '../moesia/StrongHardCodedSizes'
import TextFieldController from '../moesia/components/TextFieldController'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  onClickSubmit: () => void
  control: Control<any>
  companyOptions: { label: string; value: string }[]
  positionOptions: { label: string; value: string }[]
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddEmployeeFormDesktop: React.FC<Props> = ({
  open,
  onClose,
  onClickSubmit,
  control,
  positionOptions,
  companyOptions,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddEmployeeButtonText}
              onClick={onClickSubmit}
              disabled={!isValuesChanged}
            />
          }
          tertiaryButton={<SubmitButton disabled variant="tertiary" textKey={Texts.AddEmployeeArchiveButtonText} />}
        />
      }
    >
      <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
        <form>
          <StyledBox fullWidth direction="row" gap={Spacings.medium}>
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} />
            <StyledBox fullPadding spacing={Spacings.tiny} gap={Spacings.large} flex="1">
              <WidthLimitWrapper fullWidth gap="36px">
                <TextFieldController name="name" control={control} labelTextKey={Texts.AddEmployeeNamePlaceholder} />
                <TextFieldController name="password" control={control} labelTextKey={Texts.AddUserPasswordLabel} />
                <TextFieldController name="email" control={control} labelText="Email" />
                <DatePickerController
                  name="startingDate"
                  control={control}
                  labelTextKey={Texts.AddEmployeeStartDayPlaceholder}
                />
                <AutoCompleteController
                  name="position"
                  control={control}
                  options={positionOptions}
                  labelTextKey={Texts.AddEmployeePositionPlaceholder}
                />
                <SelectChipController
                  name="companies"
                  control={control}
                  options={companyOptions}
                  labelTextKey={Texts.AddEmployeeCompanyPlaceholder}
                />
              </WidthLimitWrapper>
            </StyledBox>
            <WidthLimitWrapper fullWidth fullPadding spacing={Spacings.tiny} gap={Spacings.medium} flex="1">
              <DatePickerController
                name="birthDate"
                control={control}
                labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
              />
              <EditorController name="plan" labelTextKey={Texts.PlansPlaceholder} control={control} />
              <EditorController name="goals" labelTextKey={Texts.GoalsPlaceholder} control={control} />
              <EditorController name="notes" labelTextKey={Texts.NotesPlaceholder} control={control} />
            </WidthLimitWrapper>
          </StyledBox>
        </form>
      </Wrapper>
    </GenericPageModal>
  </>
)

export default AddEmployeeFormDesktop

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
`

const WidthLimitWrapper = styled(StyledBox)`
  div {
    max-width: ${DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH};
  }
`
