import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { Position } from '../helpers/AddEmployeeFormHelper'
import { CompanyOptionsType, RolesType } from '../helpers/UserTypesHelper'
import { Company } from '../interfaces/Dto'
import { USER_FORM_WIDTH } from '../moesia/StrongHardCodedSizes'
import EditorController from '../moesia/components/EditorController'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import ToggleArchiveButton from './ToggleArchiveButton'
import UserAdditionalProfileFields from './UserAdditionalProfileFields'

type Props = {
  open: boolean
  onClose: () => void
  handleSubmit: () => void
  control: Control<FieldValues, any>
  positionOptions: Position[]
  companies: Company[]
  companyOptions: CompanyOptionsType
  name: string
  roles: RolesType
  plan: JSON
  notes: JSON
  goals: JSON
  archived: boolean
  values: FieldValues
  isEmployeeOrCustomer: boolean
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  isNotRegularUser: boolean
}

const EditUserFormDesktopView: React.FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  control,
  positionOptions,
  companies,
  companyOptions,
  name,
  roles,
  plan,
  notes,
  goals,
  archived,
  values,
  isEmployeeOrCustomer,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  isNotRegularUser
}) => (
  <>
    {shouldShowArchiveStatusChangeConfirmation && (
      <GenericConfirmationModal
        open
        onClose={handleShowArchiveStatusChangeConfirmation}
        onConfirm={onArchiveStatusChangeConfirm}
        confirmationTitle={!!archived ? Texts.ConfirmActivateUserTitle : Texts.ConfirmArchiveUserTitle}
        confirmationQuestion={!!archived ? Texts.ConfirmActivateUserProfileQuestion : Texts.ConfirmArchiveUserQuestion}
        confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
        confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
      />
    )}
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleSubmit}
        confirmationTitle={Texts.UnsavedChangesModalTitle}
        confirmationQuestion={Texts.UnsavedChangesModalQuestion}
        confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.ApplyChangesButtonText}
              onClick={handleSubmit}
              disabled={!isValuesChanged}
            />
          }
          tertiaryButton={
            isNotRegularUser && (
              <ToggleArchiveButton
                activateTextKey={Texts.ActivateButtonTextModal}
                archiveTextKey={Texts.ArchiveButtonTextModal}
                isArchived={archived}
                onClick={handleShowArchiveStatusChangeConfirmation}
              />
            )
          }
        />
      }
    >
      <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
        <form>
          <StyledBox direction="row" gap={Spacings.medium}>
            <AvatarInitials name={name} />
            <StyledWrapper fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
              <TextFieldController name="name" control={control} labelText="Name" />
              <TextFieldController name="email" control={control} labelText="Email" />
              <AutoCompleteController
                name="position"
                control={control}
                options={positionOptions}
                labelTextKey={Texts.AddEmployeePositionPlaceholder}
              />
              <SelectChipController
                name="companies"
                defaultValue={companies.map((company) => company._id)}
                control={control}
                options={companyOptions}
                labelText="Company"
              />
              <AutoCompleteController name="role" control={control} options={roles} labelText="User role" />
              {isEmployeeOrCustomer && <UserAdditionalProfileFields control={control} plan={plan} role={values.role} />}
              <EditorController
                name="goals"
                labelTextKey={Texts.GoalsPlaceholder}
                control={control}
                defaultValue={goals}
              />
              <EditorController
                name="notes"
                labelTextKey={Texts.NotesPlaceholder}
                control={control}
                defaultValue={notes}
              />
              <DatePickerController
                name="birthDate"
                control={control}
                labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
              />
            </StyledWrapper>
          </StyledBox>
        </form>
      </Wrapper>
    </GenericPageModal>
  </>
)

export default EditUserFormDesktopView

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
`

const StyledWrapper = styled(StyledBox)`
  min-width: ${USER_FORM_WIDTH};
`
