import { SVGProps } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import { SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT, SIDEBAR_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import Spacings from '../figma/tokens/Spacings'
import { containsSubButtons } from '../helpers/Common'
import {
  getExpandableButtonArrowIcon,
  handleSidebarButtonClassName,
  handleSidebarButtonClick
} from '../helpers/SideBarButtonHelper'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'

type Props = {
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textKey: TextType
  url?: string
  hasSubButtons?: boolean
  showSubButtons?: boolean
  setShowSubButtons?: React.Dispatch<React.SetStateAction<boolean>>
  section?: string
  doesPathNameIncludesSection?: (section?: string) => boolean
  handleCloseMenu?: () => void
}

const SidebarButton = ({
  icon,
  textKey,
  url,
  hasSubButtons,
  showSubButtons,
  setShowSubButtons,
  section,
  doesPathNameIncludesSection,
  handleCloseMenu
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const handleClick = () =>
    handleSidebarButtonClick(navigate, location, handleCloseMenu, showSubButtons, setShowSubButtons, url)
  const handleClassName = () =>
    handleSidebarButtonClassName(location, url, section, showSubButtons, doesPathNameIncludesSection)

  return (
    <SidebarButtonWrapper
      fullWidth
      left={Spacings.tiny}
      align="center"
      justify="space-between"
      direction="row"
      onClick={handleClick}
      $showSubButtons={handleClassName()}
      className={handleClassName()}
    >
      <StyledBox direction="row" align="flex-start" gap={Spacings.min}>
        {!!icon && <SVGContainer SVG={icon} />}
        <TextContainer textKey={textKey} />
      </StyledBox>
      {containsSubButtons(hasSubButtons, setShowSubButtons) &&
        getExpandableButtonArrowIcon(showSubButtons, section, doesPathNameIncludesSection)}
    </SidebarButtonWrapper>
  )
}

export default SidebarButton

const SidebarButtonWrapper = styled(StyledBox)<{ $showSubButtons?: boolean }>`
  height: ${SIDEBAR_BUTTON_HEIGHT};
  cursor: pointer;
  border-radius: ${BorderRadius.soft};
  background-color: ${({ $showSubButtons }) => !!$showSubButtons && FigmaColors.surfaceOnWhite};
  svg path {
    fill: ${({ $showSubButtons }) => !!$showSubButtons && `${FigmaColors.grayDark} !important`};
  }
  span {
    font-weight: ${({ $showSubButtons }) => !!$showSubButtons && SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT};
  }

  &.active {
    background-color: ${FigmaColors.surfaceOnWhite};
    span {
      font-weight: ${SIDEBAR_ACTIVE_BUTTON_FONT_WEIGHT};
    }
    svg path {
      fill: ${FigmaColors.grayDark} !important;
    }
  }

  &:hover:not(.active) {
    background-color: ${FigmaColors.surfaceOnWhite};
    svg path {
      fill: ${FigmaColors.grayDark} !important;
    }
  }
`
