export const defaultConfiguration = {
  name: 'default',
  baseUrl: 'http://localhost:8080',
  enabledMockData: true,
  sentryDSN: 'https://56189b9efff54ab2879ece6464052577@o4504524943130624.ingest.sentry.io/4504751093448704',
  enableSentry: false
}

const local = {
  ...defaultConfiguration,
  name: 'local',
  enableSentry: false
} as typeof defaultConfiguration

export const development = {
  ...defaultConfiguration,
  name: 'development',
  baseUrl: process.env.REACT_APP_BACKOFFICE_API_URL,
  enableSentry: true
} as typeof defaultConfiguration

export const production = {
  ...defaultConfiguration,
  name: 'production',
  baseUrl: process.env.REACT_APP_BACKOFFICE_API_URL,
  sentryDSN: 'https://56189b9efff54ab2879ece6464052577@o4504524943130624.ingest.sentry.io/4504751093448704',
  enableSentry: true
} as typeof defaultConfiguration

export default {
  local,
  development,
  production
} as { [key: string]: typeof defaultConfiguration }

export type Config = typeof defaultConfiguration
