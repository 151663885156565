import { Options } from '@material-table/core'
import { TableBodyProps } from '@mui/material/TableBody/TableBody'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import { MUI_TABLE_SEARCH_HEIGHT, MUI_TABLE_SEARCH_WIDTH } from 'src/moesia/StrongHardCodedSizes'

export const getTableExtraOptions = (): Options<TableBodyProps> => ({
  actionsCellStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: `${Spacings.huge}`,
    padding: `${Spacings.tiny}`,
    gap: `${Spacings.tiny}`
  },
  filtering: false,
  showTitle: false,
  searchFieldAlignment: 'left',
  searchFieldStyle: {
    backgroundColor: `${FigmaColors.white}`,
    borderRadius: `${BorderRadius.soft}`,
    padding: `${Spacings.minimum}`,
    height: `${MUI_TABLE_SEARCH_HEIGHT}`,
    width: `${MUI_TABLE_SEARCH_WIDTH}`,
    border: `1px solid ${FigmaColors.grayLight}`,
    boxShadow: `${Shadows.baseHeavy}`
  },
  searchFieldVariant: 'standard',
  headerStyle: {
    backgroundColor: FigmaColors.white,
    borderBottom: `1px solid ${FigmaColors.grayLight}`
  }
})
