import { Options } from '@material-table/core'
import { TableBodyProps } from '@mui/material/TableBody/TableBody'

type TableOptionsProps = {
  extraOptions?: Options<TableBodyProps>
}

export const getTableOptions = ({ extraOptions }: TableOptionsProps): Options<any> => {
  const headerStyles = {}

  const rowStyles = {}

  const DEFAULT_OPTIONS = {
    pageSize: 5,
    thirdSortClick: false,
    pageSizeOptions: [5, 20, 50],
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false,
    headerStyle: {
      ...headerStyles
    },
    rowStyle: {
      ...rowStyles
    }
  }

  return { ...DEFAULT_OPTIONS, ...(!!extraOptions && extraOptions) }
}
