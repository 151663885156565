import React, { useState } from 'react'
import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import buttonAddIcon from 'src/figma/images/buttonAddIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import StyledBox from 'src/moesia/components/StyledBox'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import applyChangesIcon from '../figma/images/applyChangesIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import ZIndex from '../figma/tokens/ZIndex'
import { generateUUID, getArrowSvg } from '../helpers/Common'
import { handleApplyContactChanges, handleContactDelete, handleContactEdit } from '../helpers/CompanyContactsHelper'
import { Language } from '../helpers/SidebarHelper'
import { ContactPerson } from '../interfaces/Dto'
import { COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT } from '../moesia/StrongHardCodedSizes'
import ImageContainer from '../moesia/components/ImageContainer'
import { AutoCompleteChipController } from '../moesia/components/ReactHookComponents'
import SVGContainer from '../moesia/components/SVGContainer'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import CompanyContactPerson from './CompanyContactPerson'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onAddContact: () => void
  isContactInfoFilled: boolean
  contacts: ContactPerson[]
  control: Control<FieldValues, any>
  language: Language
  setValue: UseFormSetValue<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  watch: UseFormWatch<FieldValues>
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}
const AddCompanyFormMobile: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  onAddContact,
  isContactInfoFilled,
  contacts,
  control,
  language,
  setValue,
  setError,
  clearErrors,
  watch,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => {
  const [isContactsMenuOpen, setIsContactsMenuOpen] = useState(false)
  const [selectedContactEmail, setSelectedContactEmail] = useState<string | null>(null)

  const handleAddContactClick = () => {
    isContactInfoFilled && !selectedContactEmail && onAddContact()
    isContactInfoFilled &&
      selectedContactEmail &&
      handleApplyContactChanges(
        selectedContactEmail,
        contacts,
        watch,
        setError,
        clearErrors,
        setValue,
        setSelectedContactEmail
      )
  }

  return (
    <>
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={() => {
            onClose()
            handleShowConfirmation()
          }}
          onConfirm={handleShowConfirmation}
          confirmationTitle={Texts.AreYouSureToLeave}
          confirmationQuestion={Texts.IfYouLeaveLoseChanges}
          confirmButtonTextKey={Texts.ConfirmStayButtonText}
          cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
          cancelButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.AddUserMobileButtonText}
                onClick={onSubmit}
                disabled={!isValuesChanged}
              />
            }
          />
        }
      >
        <StyledBox fullWidth align="center">
          <StyledBox fullWidth align="center" position="relative">
            <StyledImageContainer imageKey={Images.companyDetailsMobileBackground} />
            <StyledGenericAvatar position="absolute">
              <GenericAvatar avatarType={AvatarType.COMPANY} border />
            </StyledGenericAvatar>
          </StyledBox>
          <FormColumn
            fullWidth
            top={Spacings.small}
            bottom={Spacings.large}
            left
            right
            spacing={Spacings.big}
            gap={Spacings.small}
          >
            <TextFieldController
              name="name"
              control={control}
              labelText={getText(Texts.AddCompanyNameLabel, language)}
            />
            <TextFieldController name="vat" control={control} labelText={getText(Texts.AddCompanyVATLabel, language)} />
            <TextFieldController
              name="email"
              control={control}
              labelText={getText(Texts.AddCompanyEmailLabel, language)}
            />
            <AddContactWrapper fullWidth gap={Spacings.min} fullPadding spacing={Spacings.tiny} justify="space-between">
              <StyledBox fullPadding spacing={Spacings.min}>
                <GenericAvatar avatarType={AvatarType.EMPLOYEE} />
              </StyledBox>
              <TextFieldController
                name="contactName"
                control={control}
                labelText={getText(Texts.ContactNameLabel, language)}
              />
              <TextFieldController
                name="contactPosition"
                control={control}
                labelText={getText(Texts.ContactPositionLabel, language)}
              />
              <TextFieldController
                name="contactEmail"
                control={control}
                labelText={getText(Texts.ContactEmailLabel, language)}
              />
              <StyledBox fullWidth align="flex-end" top bottom spacing={Spacings.min}>
                <MobileSubmitButton
                  textKey={!!selectedContactEmail ? Texts.ApplyChangesButtonTextMobile : Texts.AddButtonText}
                  buttonIcon={!!selectedContactEmail ? applyChangesIcon : buttonAddIcon}
                  textBackgroundColor={isContactInfoFilled ? FigmaColors.bhBluePrimaryButton : FigmaColors.grayLight}
                  iconBackgroundColor={isContactInfoFilled ? FigmaColors.bhBlueMedium : FigmaColors.lightStone}
                  onClick={handleAddContactClick}
                />
              </StyledBox>
            </AddContactWrapper>
            {!!contacts?.length && (
              <ExpandableBox
                fullWidth
                justify="space-between"
                align="center"
                fullPadding
                spacing={Spacings.tiny}
                direction="row"
                $isContactsMenuOpen={isContactsMenuOpen}
              >
                <TextContainer textKey={Texts.ContactPeople} />
                <StyledBox onClick={() => setIsContactsMenuOpen(!isContactsMenuOpen)}>
                  <SVGContainer SVG={getArrowSvg(isContactsMenuOpen)} />
                </StyledBox>
              </ExpandableBox>
            )}
            {isContactsMenuOpen && (
              <ContactsWrapper fullWidth gap={Spacings.min} $isContactsMenuOpen={isContactsMenuOpen}>
                {!!contacts?.length &&
                  contacts.map(({ name, position, email }: ContactPerson) => (
                    <CompanyContactPerson
                      key={generateUUID()}
                      name={name}
                      position={position}
                      email={email}
                      onEdit={() => {
                        handleContactEdit(contacts, setValue, email)
                        setSelectedContactEmail(email)
                      }}
                      onDelete={() => handleContactDelete(contacts, setValue, email)}
                    />
                  ))}
                {!contacts?.length && setIsContactsMenuOpen(false)}
              </ContactsWrapper>
            )}
            <AutoCompleteChipController
              name="website"
              control={control}
              labelText={getText(Texts.WebsiteAddress, language)}
            />
            <AutoCompleteChipController
              name="projects"
              control={control}
              labelText={getText(Texts.ProjectNameAddress, language)}
            />
            <EditorController name="description" labelTextKey={Texts.CompanyInformationPlaceholder} control={control} />
          </FormColumn>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default AddCompanyFormMobile

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
const FormColumn = styled(StyledBox)`
  flex: 1;
`
const AddContactWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.white};
  border-radius: ${Spacings.min};
  border: 1px solid ${FigmaColors.grayLight};
  .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: ${FigmaColors.white} !important;
  }
`
const ExpandableBox = styled(StyledBox)<{ $isContactsMenuOpen?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $isContactsMenuOpen }) =>
    $isContactsMenuOpen ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min};
`
const ContactsWrapper = styled(StyledBox)<{ $isContactsMenuOpen: boolean }>`
  border: ${({ $isContactsMenuOpen }) => $isContactsMenuOpen && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: -${Spacings.small};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;

  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`
