import React from 'react'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import { Texts } from '../figma/helpers/TextRepository'
import loginPageLogo from '../figma/images/loginPageLogo'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { ForgotPasswordProps } from '../interfaces/AuthInterface'
import ImageContainer from '../moesia/components/ImageContainer'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { LOGIN_BUTTON_HEIGHT, LOGIN_PAGE_MAX_HEIGHT, LOGIN_PAGE_MAX_WIDTH } from '../moesia/StrongHardCodedSizes'
import LoginPageTextFieldController from './LoginPageTextFieldController'

const LoginForgotPasswordDesktopView: React.FC<ForgotPasswordProps> = ({
  control,
  handleResetPasswordRequest,
  handleBackToLogin,
  isSentResetRequest
}) => (
  <PageWrapper fullWidth fullPadding spacing={Spacings.large} align="center" justify="center">
    <LoginPage fullWidth direction="row" justify="space-between">
      <StyledBox flex="0.5" fullPadding top={Spacings.large} spacing={Spacings.large}>
        <StyledBox direction="row" gap={Spacings.medium} align="center">
          <SVGContainer SVG={loginPageLogo} />
          <TextContainer textKey={Texts.LoginPageHeading} />
        </StyledBox>
        <ForgotPassword fullPadding left={Spacings.huge} gap={Spacings.medium} align="center" justify="center">
          <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
            <TextContainer textKey={Texts.LoginForgotPasswordHeading} />
            <TextContainer
              textKey={isSentResetRequest ? Texts.LoginForgotPasswordCheckEmail : Texts.LoginForgotPasswordSubheading}
            />
          </StyledBox>
          {isSentResetRequest && (
            <StyledButton
              onClick={handleBackToLogin}
              fullWidth
              fullPadding
              align="center"
              justify="center"
              spacing={Spacings.min}
              alignText="center"
            >
              <TextContainer textKey={Texts.BackToLoginWhiteButtonText} />
            </StyledButton>
          )}
          {!isSentResetRequest && (
            <>
              <LoginPageTextFieldController control={control} name="email" labelTextKey={Texts.LoginEmailLabel} />
              <StyledButton
                onClick={handleResetPasswordRequest}
                fullWidth
                fullPadding
                align="center"
                justify="center"
                spacing={Spacings.min}
                alignText="center"
              >
                <TextContainer textKey={Texts.ResetPasswordButtonText} />
              </StyledButton>
              <StyledBox fullWidth onClick={handleBackToLogin} align="center" justify="center" pointer>
                <TextContainer textKey={Texts.BackToLoginButtonText} />
              </StyledBox>
            </>
          )}
        </ForgotPassword>
      </StyledBox>
      <StyledBox flex="0.5">
        <StyledImageContainer imageKey={Images.loginPageImage} />
      </StyledBox>
    </LoginPage>
  </PageWrapper>
)

export default LoginForgotPasswordDesktopView

const PageWrapper = styled(StyledBox)`
  height: 100vh;
  box-sizing: border-box;
  background-color: ${FigmaColors.lightStone};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const LoginPage = styled(StyledBox)`
  max-width: ${LOGIN_PAGE_MAX_WIDTH};
  max-height: ${LOGIN_PAGE_MAX_HEIGHT};
  border-radius: ${BorderRadius.verySoft};
  overflow: hidden;
  background-color: ${FigmaColors.white};
`

const StyledButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`
const StyledImageContainer = styled(ImageContainer)`
  img {
    height: 100%;
  }
`
const ForgotPassword = styled(StyledBox)`
  height: 100%;
`
