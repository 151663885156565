import styled from 'styled-components'
import Spacings from '../figma/tokens/Spacings'
import React, { memo, MouseEventHandler } from 'react'

type StyledLinkProps = {
  href: string | undefined
  width?: string
  borderRadius?: string
  boxShadow?: string
  top?: string
  bottom?: string
  left?: string
  right?: string
  children?: React.ReactNode
  hoverEffect?: boolean
  hoverColor?: string
  buttonColor?: string
  textColor?: string
  openInNewTab?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined
  hoverBorderColor?: string
  hoverBackgroundColor?: string
  direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row'
  align?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'normal' | 'stretch'
  alignSelf?: 'center' | 'flex-end' | 'flex-start' | 'normal'
  justify?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly' | 'stretch'
  spacing?: string
  fullPadding?: boolean
}

type DeterminePaddingPropsOverloads = {
  (padding?: boolean | string, spacing?: string, fullPadding?: boolean, fallback?: string): string
}

const determinePadding: DeterminePaddingPropsOverloads = (padding, spacing, fullPadding, fallback) => {
  const defaultValue = '0'
  if (typeof padding === 'string') {
    return padding || defaultValue
  }
  if (padding || fullPadding) {
    return spacing || fallback || defaultValue
  }
  return defaultValue
}

const StyledLink = (props: StyledLinkProps) => (
  <StyledA
    onClick={props.onClick}
    target={props.openInNewTab ? '_blank' : ''}
    style={{ display: 'table-cell' }}
    href={props.href}
    width={props.width}
    borderRadius={props.borderRadius}
    boxShadow={props.boxShadow}
    top={props.top}
    left={props.left}
    right={props.right}
    bottom={props.bottom}
    hoverColor={props.hoverColor}
    buttonColor={props.buttonColor}
    textColor={props.textColor}
    hoverBorderColor={props.hoverBorderColor}
    hoverBackgroundColor={props.hoverBackgroundColor}
    direction={props.direction}
    justify={props.justify}
    spacing={props.spacing}
    fullPadding={props.fullPadding}
    align={props.align}
    alignSelf={props.alignSelf}
  >
    {props.children}
  </StyledA>
)

export default memo(StyledLink)

const StyledA = styled.a<StyledLinkProps>`
  text-decoration: none;
  width: ${({ width }) => width};
  border-radius: ${(props) => props.borderRadius};
  box-shadow: ${(props) => props.boxShadow};
  padding-left: ${({ left, spacing, fullPadding }) => determinePadding(left, spacing, fullPadding, Spacings.small)};
  padding-right: ${({ right, spacing, fullPadding }) => determinePadding(right, spacing, fullPadding, Spacings.small)};
  padding-top: ${({ top, spacing, fullPadding }) => determinePadding(top, spacing, fullPadding, Spacings.small)};
  padding-bottom: ${({ bottom, spacing, fullPadding }) =>
    determinePadding(bottom, spacing, fullPadding, Spacings.small)};
  background-color: ${(props) => props.buttonColor};
  color: ${(props) => props.textColor};
  font-size: 20px;
  font-weight: 700;
  font-family: 'Roboto';
  border-width: ${(props) => (!!props.hoverBorderColor ? '2px' : 0)};
  border-color: transparent;
  border-style: ${(props) => (!!props.hoverBorderColor ? 'solid' : 'none')};
  flex-direction: ${({ direction }) => direction || `column`};
  align-items: ${({ align }) => !!align && `${align}`};
  align-self: ${({ alignSelf }) => !!alignSelf && `${alignSelf}`};
  justify-content: ${({ justify }) => !!justify && `${justify}`};
  scroll-behavior: smooth;
  &:hover {
    background-color: ${(props) => !!props.hoverBackgroundColor && props.hoverBackgroundColor};
    border-color: ${(props) => props.hoverBorderColor};
    border-width: ${(props) => (!!props.hoverBorderColor ? '2px' : 0)};
    border-style: ${(props) => (!!props.hoverBorderColor ? 'solid' : 'none')};
    color: ${(props) => !!props.hoverBorderColor && props.hoverBorderColor};
  }
`
