import { yupResolver } from '@hookform/resolvers/yup'
import { convertToRaw } from 'draft-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { getBlogPostThumbnailImages } from '../helpers/ImageTypesHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useCategories, useTervelImages, useUpdateBlogPost } from '../helpers/QueryHooks'
import { createLinkedInYupValidation, createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import { getCategoryOptions } from '../helpers/getCategoryOptions'
import useLanguage from '../hooks/useLanguage'
import { BlogPost, BlogPostCategory } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import EditBlogPostDesktopView from './EditBlogPostDesktopView'
import EditBlogPostMobileView from './EditBlogPostMobileView'

type Props = {
  open: boolean
  onClose: () => void
  selectedBlogPost: BlogPost
}

const EditBlogPostContainer: React.FC<Props> = ({ open, onClose, selectedBlogPost }) => {
  const navigate = useNavigate()
  const { setSubstate } = useContext(PageSubstateContext)
  const [imageName, setImageName] = useState<string | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const { data: imagesData = [], isLoading } = useTervelImages()
  const thumbnailImageOptions = getBlogPostThumbnailImages(imagesData)
  const { data: categories = [] as BlogPostCategory[], isLoading: areCategoriesLoading } = useCategories()
  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories])
  const isMobile = useIsMobile()
  const updateBlogPost = useUpdateBlogPost(selectedBlogPost._id)
  const language = useLanguage()
  const {
    title,
    category,
    status,
    thumbnail,
    content,
    previewContent,
    views,
    author,
    authorUrl,
    publishedAt,
    _id,
    seoTags
  } = selectedBlogPost

  const defaultValues = useMemo(
    () => ({
      title: title,
      category: category,
      status: status,
      thumbnail: thumbnail?._id,
      content: content,
      previewContent: previewContent,
      views: views,
      author: author,
      authorUrl: authorUrl,
      publishedAt: publishedAt,
      _id: _id
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        thumbnail: createRequiredStringYupValidation(getText(Texts.AddThumbnailErrorRequired, language)),
        title: createRequiredStringYupValidation(getText(Texts.AddHeadingErrorRequired, language)),
        previewContent: createRequiredStringYupValidation(getText(Texts.AddPreviewContentErrorRequired, language)),
        author: createRequiredStringYupValidation(getText(Texts.AddAuthorErrorRequired, language)),
        authorUrl: createLinkedInYupValidation(getText(Texts.InvalidLinkedinProfile, language))
      }),
    [language]
  )

  const { control, handleSubmit, watch } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { content, ...restData } = data
    const blogPostContent = JSON.stringify(convertToRaw(content?.getCurrentContent()))

    updateBlogPost
      .mutateAsync({
        ...restData,
        content: hasData(blogPostContent)
      })
      .then((res) => {
        onClose && onClose()
        successNotification(Texts.NotificationUpdatedBlogPostSuccessful)
        setSubstate(PageSubstate.DEFAULT)
        navigate(res._id)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {isMobile && (
        <EditBlogPostMobileView
          open={open}
          onClose={onClose}
          handleClose={handleClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          areImagesLoading={isLoading}
          thumbnailImageOptions={thumbnailImageOptions}
          areCategoriesLoading={areCategoriesLoading}
          categoryOptions={categoryOptions}
          seoTags={seoTags}
          control={control}
          imageName={imageName}
          imageUrl={imageUrl}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          isValuesChanged={isValuesChanged}
          defaultContentValue={content}
        />
      )}
      {!isMobile && (
        <EditBlogPostDesktopView
          open={open}
          onClose={onClose}
          handleClose={handleClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          areImagesLoading={isLoading}
          thumbnailImageOptions={thumbnailImageOptions}
          areCategoriesLoading={areCategoriesLoading}
          categoryOptions={categoryOptions}
          seoTags={seoTags}
          control={control}
          imageName={imageName}
          imageUrl={imageUrl}
          setImageName={setImageName}
          setImageUrl={setImageUrl}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          isValuesChanged={isValuesChanged}
          defaultContentValue={content}
        />
      )}
    </>
  )
}

export default EditBlogPostContainer
