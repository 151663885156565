import React, { SVGProps } from 'react'
import routePaths from 'src/config/RoutePaths'
import { Images } from 'src/figma/helpers/imageHelper'
import { Texts } from 'src/figma/helpers/TextRepository'
import overviewBillsIcon from 'src/figma/images/overviewBillsIcon'
import overviewInvoicesIcon from 'src/figma/images/overviewInvoicesIcon'
import overviewBirthdaysIcon from 'src/figma/images/overviewBirthdaysIcon'
import { FigmaImageType, TextType } from 'src/moesia/interfaces/FigmaTypes'
import overviewInterviewsIcon from 'src/figma/images/overviewInterviewsIcon'
import overviewNationalHolidaysIcon from 'src/figma/images/overviewNationalHolidaysIcon'
import overviewEmployeesVacationsIcon from 'src/figma/images/overviewEmployeesVacationsIcon'

export type OverviewRedirectionBoxType = {
  image: FigmaImageType
  title: TextType
  description: TextType
  url: string
}

export const getOverviewRedirectionBoxes = (): OverviewRedirectionBoxType[] => [
  {
    image: Images.overviewClientsPhoto,
    title: Texts.OverviewClientsTitle,
    description: Texts.OverviewClientsDescription,
    url: routePaths.companies
  },
  {
    image: Images.overviewEmployeesPhoto,
    title: Texts.OverviewEmployeesTitle,
    description: Texts.OverviewEmployeesDescription,
    url: routePaths.employees
  },
  {
    image: Images.overviewDocumentationPhoto,
    title: Texts.OverviewDocumentationTitle,
    description: Texts.OverviewDocumentationDescription,
    url: routePaths.documentation
  }
]

export type OverviewNotificationBoxType = {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  title: TextType
  notificationMessage?: TextType
  emptyStateTextKey: TextType
  content?: React.ReactNode
}

export const getOverviewNotificationBoxes = (): OverviewNotificationBoxType[] => [
  {
    icon: overviewInterviewsIcon,
    title: Texts.OverviewInterviewsTitle,
    notificationMessage: Texts.OverviewInterviewsDescription,
    emptyStateTextKey: Texts.OverviewNoInterviewsDescription
  },
  {
    icon: overviewBillsIcon,
    title: Texts.OverviewBillsTitle,
    notificationMessage: Texts.OverviewBillsDescription,
    emptyStateTextKey: Texts.OverviewNoBillsDescription
  },
  {
    icon: overviewInvoicesIcon,
    title: Texts.OverviewInvoicesTitle,
    notificationMessage: Texts.OverviewInvoicesDescription,
    emptyStateTextKey: Texts.OverviewNoInvoicesDescription
  },
  {
    icon: overviewEmployeesVacationsIcon,
    title: Texts.OverviewEmployeesVacationsTitle,
    emptyStateTextKey: Texts.OverviewNoEmployeesVacationsDescription
  },
  {
    icon: overviewNationalHolidaysIcon,
    title: Texts.OverviewNationalHolidaysTitle,
    notificationMessage: Texts.OverviewNationalHolidaysDescription,
    emptyStateTextKey: Texts.OverviewNoNationalHolidaysDescription
  },
  {
    icon: overviewBirthdaysIcon,
    title: Texts.OverviewBirthdayTitle,
    emptyStateTextKey: Texts.OverviewNoBirthdaysDescription
  }
]
