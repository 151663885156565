import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { FigmaImageType, TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import { MOBILE_TABLE_SMALL_PICTURE_CONTAINER_FIGMA_HEIGHT } from '../moesia/StrongHardCodedSizes'
import MobileHeader from './MobileHeader'

type Props = {
  backgroundImage?: FigmaImageType
  fullBackgroundImage?: boolean
  showHeading?: boolean
  pageHeading?: TextType
  pageSubheading?: TextType
  content?: React.ReactNode
}

const BackOfficeLayoutMobile: React.FC<Props> = ({
  backgroundImage,
  fullBackgroundImage,
  showHeading,
  pageHeading,
  pageSubheading,
  content
}) => (
  <InnerWrapper fullWidth position="relative">
    {backgroundImage && (
      <StyledImageContainer
        fullWidth
        imageKey={backgroundImage}
        minHeight
        isFullBackgroundImage={fullBackgroundImage}
      />
    )}
    <MobileWrapper fullWidth>
      <MobileHeader />
      {showHeading && pageHeading && pageSubheading && (
        <StyledBox fullWidth left={Spacings.large} right={Spacings.big} gap={Spacings.min}>
          <TextContainer textKey={pageHeading} />
          <TextContainer textKey={pageSubheading} />
        </StyledBox>
      )}
      {fullBackgroundImage && (
        <StyledBox fullWidth top={Spacings.large} position="absolute">
          {content}
        </StyledBox>
      )}
    </MobileWrapper>
    {!fullBackgroundImage && (
      <StyledBox fullWidth top={Spacings.large}>
        {content}
      </StyledBox>
    )}
  </InnerWrapper>
)

export default BackOfficeLayoutMobile

const InnerWrapper = styled(StyledBox)`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
`

const StyledImageContainer = styled(ImageContainer)<{ isFullBackgroundImage?: boolean }>`
  position: ${({ isFullBackgroundImage }) => (isFullBackgroundImage ? 'fixed' : 'absolute')};
  inset: 0;
  z-index: -1;
  width: 100vw;
  min-width: 100vw !important;
  overflow: hidden;
  display: flex;
  max-height: ${({ isFullBackgroundImage }) =>
    !isFullBackgroundImage && MOBILE_TABLE_SMALL_PICTURE_CONTAINER_FIGMA_HEIGHT};
  img {
    width: 100%;
  }
`
const MobileWrapper = styled(StyledBox)`
  min-height: ${MOBILE_TABLE_SMALL_PICTURE_CONTAINER_FIGMA_HEIGHT};
`
