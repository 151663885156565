import React from 'react'
import { Control } from 'react-hook-form'
import { Texts } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { createKeyIndex } from 'src/helpers/Common'
import EditorController from 'src/moesia/components/EditorController'
import {
  AutoCompleteController,
  CheckboxController,
  DatePickerController,
  SelectChipController
} from 'src/moesia/components/ReactHookComponents'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import archiveIcon from '../figma/images/archiveIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import ZIndex from '../figma/tokens/ZIndex'
import { DocumentsChecklistItemType } from '../helpers/AddEmployeeFormHelper'
import { UploadingProgress } from '../helpers/DocumentsHelper'
import { Company, Document, User } from '../interfaces/Dto'
import ImageContainer from '../moesia/components/ImageContainer'
import SubmitButton from '../moesia/components/SubmitButton'
import AvatarInitials from './AvatarInitials'
import CompanyDocumentsContainer from './CompanyDocumentsContainer'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'
import UploadFileContainer from './UploadFileContainer'

type Props = {
  open: boolean
  onClose: () => void
  onClickSubmit: () => void
  documentsChecklistItems: DocumentsChecklistItemType[]
  control: Control<any>
  files: Document[]
  companyOptions: { label: string; value: string }[]
  positionOptions: { label: string; value: string }[]
  employee: User
  onFileUpload: (files: File[]) => void
  onDeleteDocument: (documentId: string) => void
  handleDeleteFile: (documentId: string) => void
  isNotRegularUser: boolean
  companies: Company[]
  documents: Document[]
  uploadingProgress: UploadingProgress
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
}

const EditEmployeeFormMobile: React.FC<Props> = ({
  open,
  onClose,
  control,
  onClickSubmit,
  documentsChecklistItems,
  companyOptions,
  positionOptions,
  companies,
  employee,
  onDeleteDocument,
  handleDeleteFile,
  onFileUpload,
  isNotRegularUser,
  uploadingProgress,
  documents,
  files,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm
}) => {
  const {
    name,
    notes,
    goals,
    archived,
    profile: { plan }
  } = employee

  return (
    <>
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={() => {
            onClose()
            handleShowConfirmation()
          }}
          onConfirm={onClickSubmit}
          confirmationTitle={Texts.UnsavedChangesModalTitle}
          confirmationQuestion={Texts.UnsavedChangesModalQuestion}
          confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
          cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
          cancelButtonColor={FigmaColors.lighterRed}
          smallSize
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateEmployeeTitle : Texts.ConfirmArchiveEmployeeTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateEmplpyeeProfileQuestion : Texts.ConfirmArchiveEmployeeQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
          smallSize
        />
      )}
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.ApplyChangesButtonTextMobile}
                onClick={onClickSubmit}
                disabled={!isValuesChanged}
              />
            }
            tertiaryButton={
              isNotRegularUser && (
                <MobileSubmitButton
                  textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                  buttonIcon={!!archived ? activeIcon : archiveIcon}
                  textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                  iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                />
              )
            }
          />
        }
      >
        <StyledBox fullWidth>
          <form>
            <StyledBox fullWidth gap={Spacings.big} align="center">
              <StyledBox fullWidth align="center" position="relative">
                <StyledImageContainer imageKey={Images.employeeDetailsMobileBackground} />
                <StyledGenericAvatar position="absolute">
                  <AvatarInitials name={name} border />
                </StyledGenericAvatar>
              </StyledBox>
              <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.small}>
                <TextFieldController name="name" control={control} labelText="Employee Name" />
                <DatePickerController name="startingDate" control={control} labelText="Start date" />
                <DatePickerController name="birthDate" control={control} labelText="Birth date" />
                <AutoCompleteController
                  name="position"
                  control={control}
                  options={positionOptions}
                  labelText="Position"
                />
                <SelectChipController
                  name="companies"
                  defaultValue={companies.map((company) => company._id)}
                  control={control}
                  options={companyOptions}
                  labelText="Company"
                />
                <EditorController
                  name="notes"
                  labelTextKey={Texts.NotesPlaceholder}
                  control={control}
                  defaultValue={notes}
                />
                <EditorController
                  name="plan"
                  labelTextKey={Texts.PlansPlaceholder}
                  control={control}
                  defaultValue={plan}
                />
                <EditorController
                  name="goals"
                  labelTextKey={Texts.GoalsPlaceholder}
                  control={control}
                  defaultValue={goals}
                />
                <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min}>
                  <TextContainer textKey={Texts.AddEmployeeDocsTitle} />
                </DocumentsTitleWrapper>
                <UploadDocuments fullWidth fullPadding spacing={Spacings.medium} align="center" gap={Spacings.tiny}>
                  <UploadFileContainer
                    fullWidth
                    onChange={onFileUpload}
                    files={files}
                    onDeleteDocument={handleDeleteFile}
                    uploadingProgress={uploadingProgress}
                  />
                </UploadDocuments>
                <CompanyDocumentsContainer
                  documents={documents}
                  hasRightsToDelete={isNotRegularUser}
                  onDeleteDocument={onDeleteDocument}
                />
                <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min}>
                  <TextContainer textKey={Texts.AddEmployeeDocsChecklistTitle} />
                </DocumentsTitleWrapper>
                <DocumentsChecklist fullWidth>
                  {!!documentsChecklistItems &&
                    documentsChecklistItems.map(({ textKey }, idx) => (
                      <DocumentsChecklistItem key={createKeyIndex(idx, 'item')} fullWidth gap={Spacings.min}>
                        <CheckboxController
                          name={`documentsChecklist[${textKey.characters}]`}
                          control={control}
                          labelTextKey={textKey}
                        />
                      </DocumentsChecklistItem>
                    ))}
                </DocumentsChecklist>
              </StyledBox>
            </StyledBox>
          </form>
        </StyledBox>
      </GenericPageModal>
    </>
  )
}

export default EditEmployeeFormMobile

const DocumentsTitleWrapper = styled(StyledBox)`
  border-bottom: 1px solid ${FigmaColors.grayLight};
`
const DocumentsChecklist = styled(StyledBox)`
  border-radius: ${Spacings.min};
  border: 1px solid ${FigmaColors.grayLight};
  height: fit-content;
  max-height: 200px;
  overflow-y: scroll;
`
const DocumentsChecklistItem = styled(StyledBox)`
  border-bottom: 1px solid ${FigmaColors.grayLight};
`
const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  overflow: hidden;
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
const UploadDocuments = styled(StyledBox)`
  border: 1px solid ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
`
