import { Company } from 'src/interfaces/Dto'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import { Language } from 'src/moesia/interfaces/LanguageType'
import TextContainer from 'src/moesia/components/TextContainer'
import { getText, Texts } from 'src/figma/helpers/TextRepository'

export const getCompaniesDesktopColumns = (language: Language) => [
  {
    title: getText(Texts.CompaniesTableNameTitle, language),
    field: 'companyName',
    render: (company: Company) => (
      <StyledBox key={company._id} direction="row" align="center" justify="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.EmployeeDetailsPosition} text={company.name} />
      </StyledBox>
    )
  },
  {
    title: getText(Texts.CompaniesTableVATTitle, language),
    field: 'VAT',
    render: (company: Company) => <TextContainer textKey={Texts.EmployeeDetailsPosition} text={company.vat} />
  }
]

export const getCompaniesMobileColumns = (language: Language) => [
  {
    title: getText(Texts.CompaniesTableNameTitle, language),
    field: 'companyName',
    render: (company: Company) => (
      <StyledBox key={company._id} direction="row" align="center" justify="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.EmployeeDetailsPosition} text={company.name} />
      </StyledBox>
    )
  }
]
