import React, { useEffect, useState } from 'react'
import { Images } from 'src/figma/helpers/imageHelper'
import addPhotoIcon from 'src/figma/images/addPhotoIcon'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import SVGContainer from 'src/moesia/components/SVGContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'

export enum AvatarType {
  EMPLOYEE = 'Employee',
  COMPANY = 'Company'
}

type Props = {
  canUpload?: boolean
  style?: React.CSSProperties
  avatarType: AvatarType
  border?: boolean
  smallSize?: boolean
}

const GenericAvatar: React.FC<Props> = ({ canUpload, style, avatarType, border, smallSize }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null)
      return
    }

    const objectUrl: any = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }

    setSelectedFile(e.target.files[0])
  }

  return (
    <AvatarWrapper style={style} className="avatarWrapper" $border={border} $smallSize={smallSize}>
      {!selectedFile && !preview && (
        <ImageContainer imageKey={avatarType === AvatarType.EMPLOYEE ? Images.noAvatarPhoto : Images.noLogo} />
      )}
      {!!selectedFile && !!preview && <Avatar src={preview} />}
      {canUpload && (
        <IconWrapper htmlFor="imageUpload">
          <StyledSVGContainer SVG={addPhotoIcon} />
          <Input id="imageUpload" type="file" accept=".png,.jpeg,.jpg" onChange={onSelectFile} />
        </IconWrapper>
      )}
    </AvatarWrapper>
  )
}

export default GenericAvatar

const AvatarWrapper = styled(StyledBox)<{ $border: boolean; $smallSize: boolean }>`
  width: ${({ $smallSize }) => ($smallSize ? '40px' : '70px')};
  height: ${({ $smallSize }) => ($smallSize ? '40px' : '70px')};
  border-radius: ${BorderRadius.circle};
  position: relative;

  border: ${({ $border }) => $border && `${Spacings.minimum} solid ${FigmaColors.white}`};
`
const StyledSVGContainer = styled(SVGContainer)`
  z-index: 999;
  cursor: pointer;
  position: absolute;
  right: -${Spacings.minimum};
  bottom: -${Spacings.min};
  background-color: white;
  border-radius: ${BorderRadius.rounded};
  svg {
    width: ${Spacings.medium} !important;
    height: ${Spacings.medium} !important;
  }
`
const Input = styled.input`
  display: none;
`

const IconWrapper = styled.label``

const Avatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: ${BorderRadius.circle};
`
