import { useMemo } from 'react'
import { ImagesType } from '../enums/ImagesType'
import { Texts, getText } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import { getImageCategory } from '../helpers/Common'
import useLanguage from '../hooks/useLanguage'
import { TervelImage } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import ImageItem from './ImageItem'

type Props = {
  images: TervelImage[]
  filterType: ImagesType | null
}

const ImageItemsContainer: React.FC<Props> = ({ images, filterType }) => {
  const filteredImages = filterType ? images.filter(({ type }) => type === filterType) : images
  const currentImageCategory = useMemo(() => getImageCategory(filterType), [filterType])
  const language = useLanguage()

  return (
    <>
      {!!filteredImages.length && filteredImages.map((image) => <ImageItem key={image._id} image={image} />)}
      {!filteredImages.length && (
        <StyledBox fullWidth alignText="center" top={Spacings.big} left right spacing={Spacings.min}>
          <TextContainer
            textKey={Texts.ThereAreNoImagesToDisplay}
            text={`${getText(Texts.ThereAreNoImagesToDisplay, language)} ${currentImageCategory}`}
          />
        </StyledBox>
      )}
    </>
  )
}

export default ImageItemsContainer
