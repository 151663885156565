import styled from 'styled-components'
import { TervelImage } from '../../interfaces/Dto'
import { NEWS_CARD_IMAGE_FIGMA_WIDTH } from '../StrongHardCodedSizes'

type Props = {
  image: TervelImage
}

//wip - will expect more args
const UploadedImageContainer: React.FC<Props> = ({ image }) => <DisplayUploadedImage src={image.signedUrl} />

export default UploadedImageContainer

const DisplayUploadedImage = styled.img`
  width: ${NEWS_CARD_IMAGE_FIGMA_WIDTH};
`
