import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { generateUUID } from '../helpers/Common'
import { getDataFromArray } from '../helpers/GetDataFromArrayHelper'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import ClickableAnchor from './ClickableAnchor'

type Props = {
  website?: string[]
  projects?: string[]
}

const WebsitesAndProjects: React.FC<Props> = ({ website, projects }) => {
  const _projects = getDataFromArray(projects)

  return (
    <StyledBox direction="row" fullWidth justify="space-between">
      <StyledBox>
        <TextContainer textKey={Texts.CompanyDetailsWebsiteTitle} />
        {!!website?.length ? <ClickableAnchor websiteArr={website} /> : getDataFromArray(website)}
      </StyledBox>
      <ProjectsWrapper>
        <TextContainer textKey={Texts.CompanyDetailsProjectsTitle} />
        {_projects !== '-' &&
          _projects.map((project) => (
            <TextContainer key={generateUUID()} textKey={Texts.CompanyDetailsInvoices} text={project} />
          ))}
      </ProjectsWrapper>
    </StyledBox>
  )
}

export default WebsitesAndProjects

const ProjectsWrapper = styled(StyledBox)`
  flex-wrap: wrap;
`
