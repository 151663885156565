import { ImagesType } from '../enums/ImagesType';
import { Texts } from '../figma/helpers/TextRepository';
import { TervelImage } from '../interfaces/Dto';
import { TextType } from '../moesia/interfaces/FigmaTypes';
import { getLabelText } from './LabelTextHelper';

export type LabelType = {
  label: TextType
  value: string
}

export enum ImagesCategories {
  ALL = 'All',
  PRIVATE = 'Private',
  NEWS_CARD_FRONT = 'News card front',
  NEWS_CARD_BACK = 'News card back',
  TEAM_MEMBER_IMAGE = 'Team member',
  COMPANY_PORTFOLIO_CARD_FRONT = 'Company front',
  COMPANY_PORTFOLIO_CARD_BACK = 'Company back',
  BLOG_POST_THUMBNAIL_IMAGE = 'Blog post thumbnail'
}

export const getImageTypes = (isFilter?: boolean) => [
  {
    label: getLabelText(Texts.PrivateLabel, Texts.PrivateImageOption, isFilter),
    value: ImagesType.PRIVATE
  },
  {
    label: getLabelText(Texts.NewsCardFrontImageLabel, Texts.NewsCardFrontImageOption, isFilter),
    value: ImagesType.NEWS_CARD_FRONT
  },
  {
    label: getLabelText(Texts.NewsCardBackImageLabel, Texts.NewsCardBackImageOption, isFilter),
    value: ImagesType.NEWS_CARD_BACK
  },
  {
    label: getLabelText(Texts.TeamMemberPhotoLabel, Texts.TeamMemberPhotoOption, isFilter),
    value: ImagesType.TEAM_MEMBER_IMAGE
  },
  {
    label: getLabelText(Texts.ComapyPortfolioFrontImageLabel, Texts.ComapyPortfolioFrontImageOption, isFilter),
    value: ImagesType.COMPANY_PORTFOLIO_CARD_FRONT
  },
  {
    label: getLabelText(Texts.CompanyPortfolioBackImageLabel, Texts.CompanyPortfolioBackImageOption, isFilter),
    value: ImagesType.COMPANY_PORTFOLIO_CARD_BACK
  },
  {
    label: getLabelText(Texts.BlogPostThumbnailLabel, Texts.BlogPostThumbnailOption, isFilter),
    value: ImagesType.BLOG_POST_THUMBNAIL_IMAGE
  }
]

export const getFrontImages = (images: TervelImage[]): { value: string; label: string }[] => {
  if (!!images?.length) {
    return images
      .filter((image) => image.type === ImagesType.NEWS_CARD_FRONT)
      .map((img) => ({ value: img._id, label: img.name }))
  }
  return []
}

export const getBackImages = (images: TervelImage[]): { value: string; label: string }[] => {
  if (!!images?.length) {
    return images
      .filter((image) => image.type === ImagesType.NEWS_CARD_BACK)
      .map((img) => ({ value: img._id, label: img.name }))
  }
  return []
}

export const getBlogPostThumbnailImages = (images: TervelImage[]): { value: string; label: string }[] => {
  if (!!images?.length) {
    return images
      .filter((image) => image.type === ImagesType.BLOG_POST_THUMBNAIL_IMAGE)
      .map((img) => ({ value: img._id, label: img.name }))
  }
  return []
}
