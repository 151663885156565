import { memo, SVGProps } from 'react'
import styled from 'styled-components'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import SVGContainer from 'src/moesia/components/SVGContainer'
import {
  MOBILE_TABLE_SMALL_ADD_BUTTON_WIDTH,
  MOBILE_TABLE_SMALL_ADD_BUTTON_HEIGHT
} from 'src/moesia/StrongHardCodedSizes'
import StyledBox from 'src/moesia/components/StyledBox'

type Props = {
  onClick: () => void
  buttonIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

const MobileTableAddButton: React.FC<Props> = ({ onClick, buttonIcon }) => (
  <ButtonStyles onClick={onClick} align="center" justify="center">
    <SVGContainer SVG={buttonIcon} />
  </ButtonStyles>
)

export default memo(MobileTableAddButton)

const ButtonStyles = styled(StyledBox)`
  border-radius: ${BorderRadius.circle};
  width: ${MOBILE_TABLE_SMALL_ADD_BUTTON_WIDTH};
  height: ${MOBILE_TABLE_SMALL_ADD_BUTTON_HEIGHT};
  background-color: ${FigmaColors.seaDarkBlue};
`
