import { yupResolver } from '@hookform/resolvers/yup'
import { convertToRaw, EditorState } from 'draft-js'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { getText, Texts } from '../figma/helpers/TextRepository'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { handleAddContact } from '../helpers/CompanyContactsHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { useCreateCompany } from '../helpers/QueryHooks'
import { createEmailYupValidation, createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { useNotifications } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import AddCompanyFormDesktop from './AddCompanyFormDesktop'
import AddCompanyFormMobile from './AddCompanyFormMobile'

type Props = {
  open: boolean
  onClose: () => void
}

const AddCompanyFormContainer: React.FC<Props> = ({ open, onClose }) => {
  const isMobile = useIsMobile()
  const createCompany = useCreateCompany()
  const { setSubstate } = useContext(PageSubstateContext)
  const [isCompleted, setIsCompleted] = useState(false)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const language = useLanguage()
  const { successNotification } = useNotifications()
  const defaultValues = useMemo(
    () => ({
      name: '',
      vat: '',
      email: '',
      website: [],
      projects: [],
      description: EditorState.createEmpty(),
      documents: [],
      contacts: {
        name: '',
        position: '',
        email: ''
      }
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddCompanyNameErrorRequired, language)),
        email: createEmailYupValidation(
          getText(Texts.AddCompanyEmailErrorRequired, language),
          getText(Texts.AddCompanyEmailErrorValid, language)
        ),
        vat: createRequiredStringYupValidation(getText(Texts.AddCompanyVATErrorRequired, language))
      }),
    [language]
  )

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { isSubmitSuccessful }
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const isContactInfoFilled = !!watch('contactName') && !!watch('contactPosition') && !!watch('contactEmail')
  const contacts = watch('contacts')

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { description, website, projects, ...restData } = data
    const companyInformation = description && JSON.stringify(convertToRaw(description.getCurrentContent()))
    const companyWebsite = website || []
    const companyProjects = projects || []

    createCompany
      .mutateAsync({
        ...restData,
        website: hasData(companyWebsite),
        projects: hasData(companyProjects),
        description: hasData(companyInformation)
      })
      .then(() => {
        onClose && onClose()
        successNotification(Texts.NotificationAddCompanySuccessful)
        setSubstate(PageSubstate.DEFAULT)
        setIsCompleted(true)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  useEffect(() => {
    if (isSubmitSuccessful && isCompleted) {
      reset({ defaultValues })
    }
  }, [isCompleted, isSubmitSuccessful])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {!isMobile && (
        <AddCompanyFormDesktop
          open={open}
          onClose={onClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          onAddContact={() => handleAddContact(watch, language, setError, clearErrors, setValue)}
          isContactInfoFilled={isContactInfoFilled}
          contacts={contacts}
          control={control}
          language={language}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          watch={watch}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
      {isMobile && (
        <AddCompanyFormMobile
          open={open}
          onClose={onClose}
          onSubmit={handleSubmit(handleSubmitButton)}
          onAddContact={() => handleAddContact(watch, language, setError, clearErrors, setValue)}
          isContactInfoFilled={isContactInfoFilled}
          contacts={contacts}
          control={control}
          language={language}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          watch={watch}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleShowConfirmation={handleShowConfirmation}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
        />
      )}
    </>
  )
}

export default AddCompanyFormContainer
