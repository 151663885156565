import { memo } from 'react'
import styled from 'styled-components'
import { Texts } from '../../figma/helpers/TextRepository'
import Spacings from '../../figma/tokens/Spacings'
import { TervelImage } from '../../interfaces/Dto'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'
import UploadedImageContainer from './UploadedImageContainer'

type Props = {
  image: TervelImage
  textKey: string
}

const NewsCardBackComponent = ({ image, textKey }: Props) => (
  <StyledBox fullWidth direction="row">
    <StyledBox alignSelf="center" left right spacing={Spacings.medium}>
      <UploadedImageContainer image={image} />
    </StyledBox>
    <TextBox justify="center" alignText="center">
      <TextContainer textKey={Texts.BuildingOurWebsiteDesc} text={textKey} />
    </TextBox>
  </StyledBox>
)

export default memo(NewsCardBackComponent)

const TextBox = styled(StyledBox)`
  // Used to style the text as in the design
  width: 50%;
`
