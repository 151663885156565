import { Fragment } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import trendingStarIcon from '../figma/images/trendingStarIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { generateUUID } from '../helpers/Common'
import { BlogPostCategory } from '../interfaces/Dto'
import { BLOG_POST_CATEGORIES_MAX_HEIGHT } from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'

type Props = {
  blogPostCategories: string[]
  areCategoriesLoading: boolean
  allCategories: BlogPostCategory[]
}

const BlogPostCategories: React.FC<Props> = ({ blogPostCategories, areCategoriesLoading, allCategories }) => (
  <>
    {!!blogPostCategories?.length && (
      <CategoriesWrapper top left right spacing={Spacings.min} direction="row" gap={Spacings.min} flexWrap="wrap">
        {blogPostCategories.map((blogPostCategory) => {
          const currentCategory =
            !areCategoriesLoading &&
            allCategories.find((category: BlogPostCategory) => category._id === blogPostCategory)

          return (
            <Fragment key={generateUUID()}>
              {currentCategory && (
                <CategoryItem position="relative" align="center" fullPadding spacing={Spacings.min}>
                  <TextContainer textKey={Texts.BlogPostCategoryTitle} text={currentCategory.title} />
                  {!!currentCategory.isTrending && (
                    <SVGWrapper
                      title={!!currentCategory.isTrending && Texts.TrendingCategoryTitle.characters}
                      position="absolute"
                    >
                      <SVGContainer SVG={trendingStarIcon} />
                    </SVGWrapper>
                  )}
                </CategoryItem>
              )}
            </Fragment>
          )
        })}
      </CategoriesWrapper>
    )}
  </>
)

export default BlogPostCategories

const CategoriesWrapper = styled(StyledBox)`
  max-height: ${BLOG_POST_CATEGORIES_MAX_HEIGHT};
  overflow-x: hidden;
  overflow-y: auto;
`
const CategoryItem = styled(StyledBox)`
  border: 1px solid ${FigmaColors.borderGray};
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.lightStone};
  min-width: ${Spacings.big};
`

const SVGWrapper = styled(StyledBox)`
  top: -${Spacings.min};
  left: -${Spacings.minimum};
`
