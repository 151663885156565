import MUIRichTextEditor from 'mui-rte'
import CompanyDetailsPeopleComponent from '../components/CompanyDetailsPeopleComponent'
import CompanyDocumentsContainer from '../components/CompanyDocumentsContainer'
import ReminderBoxComponent from '../components/ReminderBox'
import WebsitesAndProjects from '../components/WebsitesAndProjects'
import { Texts } from '../figma/helpers/TextRepository'
import companyDetailsBillingIcon from '../figma/images/companyDetailsBillingIcon'
import companyDetailsContactsIcon from '../figma/images/companyDetailsContactsIcon'
import companyDetailsDocsIcon from '../figma/images/companyDetailsDocsIcon'
import companyDetailsEmployeesIcon from '../figma/images/companyDetailsEmployeesIcon'
import companyDetailsHolidaysIcon from '../figma/images/companyDetailsHolidaysIcon'
import companyDetailsInformationIcon from '../figma/images/companyDetailsInformationIcon'
import companyDetailsInvoicesIcon from '../figma/images/companyDetailsInvoicesIcon'
import companyDetailsProjectsIcon from '../figma/images/companyDetailsProjectsIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import { Company } from '../interfaces/Dto'
import { MOBILE_MAX_AND_MIN_HEIGHT_REMINDER_BOX } from '../moesia/StrongHardCodedSizes'
import { renderCompanyDetailsPeopleComponent } from './Common'

export const getCompanyBoxesOptions = (company: Company) => {
  const { employees, description, website, projects, contacts, documents } = company

  return [
    {
      boxIcon: companyDetailsEmployeesIcon,
      boxTitle: Texts.CompanyDetailsEmployeesTitle,
      boxInfo: !!employees && !!employees.length && <CompanyDetailsPeopleComponent employees={employees} />
    },
    {
      boxIcon: companyDetailsInvoicesIcon,
      boxTitle: Texts.CompanyDetailsInvoicesTitle,
      boxInfo: '' //TODO show invoices
    },
    {
      boxIcon: companyDetailsBillingIcon,
      boxTitle: Texts.CompanyDetailsHolidaysTitle,
      boxInfo: (
        <ReminderBoxComponent
          descriptionKey={Texts.BillingDueReminderDescription}
          backgroundColor={FigmaColors.white}
          minHeight={MOBILE_MAX_AND_MIN_HEIGHT_REMINDER_BOX}
          maxHeight={MOBILE_MAX_AND_MIN_HEIGHT_REMINDER_BOX}
        />
      )
    },
    {
      boxIcon: companyDetailsHolidaysIcon,
      boxTitle: Texts.CompanyDetailsHolidaysTitle,
      boxInfo: '' //TODO show incoming holidays and reminder button
    },
    {
      boxIcon: companyDetailsContactsIcon,
      boxTitle: Texts.CompanyDetailsContactsTitle,
      boxInfo: renderCompanyDetailsPeopleComponent(contacts)
    },
    {
      boxIcon: companyDetailsInformationIcon,
      boxTitle: Texts.CompanyDetailsInformationTitle,
      boxInfo: !!description && <MUIRichTextEditor toolbar={false} defaultValue={description} readOnly />
    },
    {
      boxIcon: companyDetailsProjectsIcon,
      boxTitle: Texts.CompanyDetailsProjectsAndWebsitesTitle,
      boxInfo: <WebsitesAndProjects website={website} projects={projects} />
    },
    {
      boxIcon: companyDetailsDocsIcon,
      boxTitle: Texts.CompanyDetailsDocsTitle,
      boxInfo: <CompanyDocumentsContainer documents={documents} isDetails />
    }
  ]
}
