import { UseMutationResult } from 'react-query'
import { IDetailsInformationElement } from 'src/components/DetailsInformationElement'
import { UserType } from 'src/enums/UserType'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import emailIcon from 'src/figma/images/emailIcon'
import employeeDetailsPositionIcon from 'src/figma/images/employeeDetailsPositionIcon'
import Spacings from 'src/figma/tokens/Spacings'
import { Company, User } from 'src/interfaces/Dto'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { Language } from 'src/moesia/interfaces/LanguageType'
import { UserProfile } from '../enums/UserProfile'
import employeeDetailsPlanIcon from '../figma/images/employeeDetailsPlanIcon'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import { generateUUID } from './Common'
import { handleErrorMessage } from './NotificationsHelper'

const getSuccessNotificationMessage = (userRole: UserType | 'company', archived: boolean) => {
  switch (userRole) {
    case UserType.ADMIN:
      return archived ? Texts.NotificationAdminActivated : Texts.NotificationAdminArchived
    case UserType.MANAGER:
      return archived ? Texts.NotificationManagerActivated : Texts.NotificationManagerArchived
    case UserType.EMPLOYEE:
      return archived ? Texts.NotificationEmployeeActivated : Texts.NotificationEmployeeArchived
    case UserType.CUSTOMER:
      return archived ? Texts.NotificationCustomerActivated : Texts.NotificationCustomerArchived
    case 'company':
      return archived ? Texts.NotificationCompanyActivated : Texts.NotificationCompanyArchived

    default:
      return archived ? Texts.NotificationUserActivated : Texts.NotificationUserArchived
  }
}

export const handleUserArchiveStatus = (
  user: Company | User,
  mutationHook: UseMutationResult<
    any,
    Error,
    unknown,
    {
      previous: unknown
      data: User
    }
  >,
  handleClose: () => void
) => {
  const data = { archived: !user.archived }

  return mutationHook
    .mutateAsync(data)
    .then(() => {
      handleClose()
      const notification = getSuccessNotificationMessage('role' in user ? user.role : 'company', !!user.archived)
      successNotification(notification)
    })
    .catch((err: Error) => {
      handleErrorMessage(err)
    })
}

export const getUserRoles = () => [
  {
    value: UserType.DEFAULT,
    label: UserType.DEFAULT
  },
  {
    value: UserType.EMPLOYEE,
    label: UserType.EMPLOYEE
  },
  {
    value: UserType.CUSTOMER,
    label: UserType.CUSTOMER
  },
  {
    value: UserType.MANAGER,
    label: UserType.MANAGER
  },
  {
    value: UserType.ADMIN,
    label: UserType.ADMIN
  }
]

export const getUserProfile = (userRole: UserType) => {
  switch (userRole) {
    case UserType.ADMIN:
      return UserProfile.ADMIN_PROFILE
    case UserType.MANAGER:
      return UserProfile.MANAGER_PROFILE
    case UserType.EMPLOYEE:
      return UserProfile.EMPLOYEE_PROFILE
    case UserType.CUSTOMER:
      return UserProfile.CUSTOMER_PROFILE
    default:
      return UserProfile.DEFAULT_PROFILE
  }
}

export const isUserAdmin = (role: UserType) => role === UserType.ADMIN
export const isUserManager = (role: UserType) => role === UserType.MANAGER
export const isUserEmployee = (role: UserType) => role === UserType.EMPLOYEE
export const isUserCustomer = (role: UserType) => role === UserType.CUSTOMER
export const isUserDefault = (role: UserType) => role === UserType.DEFAULT

export const getUserDetailsInformation = (user: User): IDetailsInformationElement[] => {
  const { email, companies } = user
  return [
    {
      SVG: employeeDetailsPositionIcon,
      titleTextKey: Texts.UserDetailsCompanyTitle,
      dataTextKey: Texts.UserDetailsCompany,
      dataText: !!companies.length ? `${companies.map((company) => company.name)}` : ''
    },
    {
      SVG: emailIcon,
      titleTextKey: Texts.UserDetailsEmailTitle,
      dataTextKey: Texts.UserDetailsEmail,
      dataText: !!email ? email : ''
    }
  ]
}

export const getUserDetailsMobile = (user: User) => {
  const { position, role, email, companies: companiesArray } = user
  const companies = companiesArray.map((company) => company.name)

  return [
    {
      boxIcon: employeeDetailsPositionIcon,
      boxTitle: Texts.UserDetailsCompanyTitle,
      boxInfo: `${position} - ${companies}`
    },
    {
      boxIcon: employeeDetailsPlanIcon,
      boxTitle: Texts.UserDetailsRoleMobile,
      boxInfo: role
    },
    {
      boxIcon: emailIcon,
      boxTitle: Texts.UserDetailsEmailTitle,
      boxInfo: email
    }
  ]
}

export const getUsersTableColumns = (language: Language) => [
  {
    title: getText(Texts.UsersTableNameTitle, language),
    field: 'name',
    render: (user: any) => (
      <StyledBox key={`${user.name}`} direction="row" align="center" justify="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.EmployeeDetailsPosition} text={user.name} />
      </StyledBox>
    )
  },
  {
    title: getText(Texts.UsersTableCompanyTitle, language),
    field: 'companyRelation',
    render: (user: any) => {
      const { companies } = user
      return (
        <StyledBox key={user._id} direction="row" align="center" justify="center" gap={Spacings.tiny}>
          {!companies.length && '-'}
          {companies.map((company: Company) => (
            <TextContainer key={generateUUID()} textKey={Texts.EmployeeDetailsPosition} text={company.name} />
          ))}
        </StyledBox>
      )
    }
  },
  {
    title: getText(Texts.UsersTableUserTypeTitle, language),
    field: 'role'
  }
]

export const hasAccess = (permissions: UserType[], currentUserRole: UserType) =>
  !!permissions && !!permissions.length && permissions.includes(currentUserRole)
