import { FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { ContactPerson } from '../interfaces/Dto'
import { Language } from '../moesia/interfaces/LanguageType'
import { emailRegex } from './Common'

export const handleAddContact = (
  watch: UseFormWatch<FieldValues>,
  language: Language,
  setError: UseFormSetError<FieldValues>,
  clearErrors: UseFormClearErrors<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  contactsContainer?: ContactPerson[] | [],
  setContactsContainer?: React.Dispatch<React.SetStateAction<ContactPerson[] | []>> | null
) => {
  const { contactName, contactPosition, contactEmail } = watch()
  const newContact = { name: contactName, position: contactPosition, email: contactEmail }
  const existingContacts = watch('contacts') || contactsContainer || []

  if (!emailRegex.test(contactEmail)) {
    setError('contactEmail', { type: 'custom', message: getText(Texts.AddCompanyEmailErrorValid, language) })
    return
  }

  if (existingContacts.some((contact: ContactPerson) => contact.email === contactEmail)) {
    setError('contactEmail', { type: 'custom', message: 'Email already used' })
    return
  }

  !!setContactsContainer && setContactsContainer([...existingContacts, newContact])
  setValue('contacts', [...existingContacts, newContact])
  clearErrors('contactEmail')

  setValue('contactName', '')
  setValue('contactPosition', '')
  setValue('contactEmail', '')
}

export const handleContactDelete = (
  contacts: ContactPerson[],
  setValue: UseFormSetValue<FieldValues>,
  email: string,
  setContactsContainer?: React.Dispatch<React.SetStateAction<ContactPerson[] | []>>
) => {
  const newContacts = contacts.filter((contact: ContactPerson) => contact.email !== email)
  !!setContactsContainer && setContactsContainer(newContacts)
  setValue('contacts', newContacts)
}

export const handleContactEdit = (contacts: ContactPerson[], setValue: UseFormSetValue<FieldValues>, email: string) => {
  const index = contacts.findIndex((contact: ContactPerson) => contact.email === email)

  setValue('contactName', contacts[index].name)
  setValue('contactPosition', contacts[index].position)
  setValue('contactEmail', contacts[index].email)
}

export const handleApplyContactChanges = (
  email: string,
  contacts: ContactPerson[],
  watch: UseFormWatch<FieldValues>,
  setError: UseFormSetError<FieldValues>,
  clearErrors: UseFormClearErrors<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  setSelectedContactEmail: React.Dispatch<React.SetStateAction<string | null>>,
  setContactsContainer?: React.Dispatch<React.SetStateAction<ContactPerson[] | []>>
) => {
  const contactToUpdateIndex = contacts.findIndex((contact: ContactPerson) => contact.email === email)

  const newName = watch('contactName')
  const newPosition = watch('contactPosition')
  const newEmail = watch('contactEmail')

  if (newEmail !== email && contacts.some((contact: ContactPerson) => contact.email === newEmail)) {
    setError('contactEmail', { type: 'custom', message: 'Email already used' })
    return
  }

  const updatedContact = {
    name: newName,
    position: newPosition,
    email: newEmail
  }

  contacts[contactToUpdateIndex] = updatedContact

  !!setContactsContainer && setContactsContainer(contacts)
  setValue('contacts', [...contacts])
  setValue('contactName', '')
  setValue('contactPosition', '')
  setValue('contactEmail', '')
  clearErrors('contactEmail')
  setSelectedContactEmail(null)
}
