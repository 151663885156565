import styled from 'styled-components'
import React, { SVGProps } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import StyledBox from 'src/moesia/components/StyledBox'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'

type MobileButtonProps = {
  textKey: TextType
  buttonIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  textBackgroundColor: string
  iconBackgroundColor: string
  onClick: () => void
}

const MobileSubmitButton: React.FC<MobileButtonProps> = ({
  textKey,
  buttonIcon,
  textBackgroundColor,
  iconBackgroundColor,
  onClick
}) => (
  <Wrapper
    left
    direction="row"
    spacing={Spacings.min}
    gap={Spacings.tiny}
    align="center"
    justify="space-between"
    textBackgroundColor={textBackgroundColor}
    onClick={onClick}
  >
    <TextContainer textKey={textKey} />
    <IconWrapper align="center" justify="center" iconBackgroundColor={iconBackgroundColor}>
      <SVGContainer SVG={buttonIcon} />
    </IconWrapper>
  </Wrapper>
)

export default MobileSubmitButton

const Wrapper = styled(StyledBox)<{ textBackgroundColor: string }>`
  background-color: ${(props) => props.textBackgroundColor};
  border-radius: ${BorderRadius.soft};
  overflow: hidden;
  min-width: 130px;
  cursor: pointer;
`
const IconWrapper = styled(StyledBox)<{ iconBackgroundColor: string }>`
  background-color: ${(props) => props.iconBackgroundColor};
  width: 36px;
  height: 36px;
`
