import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { NewsCardDto } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import NewsCardItems from './NewsCardItems'

type Props = {
  newsCards: NewsCardDto[]
  onAddNewsCardClick: () => void
  setSubstate: (state: PageSubstate) => void
  setSelectedNewsCard: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
  onDelete: (newsCardId: string) => void
}

const NewsCardsSectionDesktop: React.FC<Props> = ({
  newsCards,
  onAddNewsCardClick,
  setSubstate,
  setSelectedNewsCard,
  onDelete
}) => (
  <StyledBox fullWidth bottom={Spacings.max} direction="row" align="flex-start" flexWrap="wrap">
    {!!newsCards?.length && (
      <NewsCardItems
        newsCards={newsCards}
        setSubstate={setSubstate}
        setSelectedNewsCard={setSelectedNewsCard}
        onDelete={onDelete}
      />
    )}
    <ButtonWrapper position="fixed">
      <SubmitButton onClick={onAddNewsCardClick} textKey={Texts.AddNewsCardButtonText} variant="primary" />
    </ButtonWrapper>
  </StyledBox>
)

export default NewsCardsSectionDesktop

const ButtonWrapper = styled(StyledBox)`
  z-index: ${ZIndex.high};
  bottom: ${Spacings.large};
  right: ${Spacings.large};
`
