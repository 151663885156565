import React, { useContext, useState } from 'react'
import { PageSubstate, PageSubstateContext } from 'src/helpers/PageSubstateHelper'
import { User } from 'src/interfaces/Dto'
import { UserInvitationStatus } from '../enums/UserInvitationStatus'
import { Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useDeleteUser, useInviteUser, useUpdateUser } from '../helpers/QueryHooks'
import { handleUserArchiveStatus } from '../helpers/UsersHelper'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import UserDetailsDesktopView from './UserDetailsDesktopView'
import UserDetailsMobileView from './UserDetailsMobileView'

type Props = {
  open: boolean
  onClose: () => void
  user: User
}

const UserDetailsContainer: React.FC<Props> = ({ open, onClose, user }) => {
  const updateUserMutation = useUpdateUser(user._id)
  const deleteUserMutation = useDeleteUser()
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] = useState(false)
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const isMobile = useIsMobile()
  const inviteUserMutation = useInviteUser(user._id)
  const { setSubstate } = useContext(PageSubstateContext)
  const handleEditButton = () => {
    setSubstate(PageSubstate.EDIT)
  }
  const isInvitationAccepted = user.invitationStatus === UserInvitationStatus.ACCEPTED

  const handleRemindButton = () => {
    inviteUserMutation
      .mutateAsync({ invitationStatus: user.invitationStatus })
      .then(() => {
        successNotification(Texts.NotificationRemindedSuccess)
      })
      .catch((err: Error) => {
        handleErrorMessage(err.message)
      })
  }

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(user, updateUserMutation, onClose)

  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmation(!shouldShowDeleteConfirmation)

  const handleDeleteUser = () => {
    deleteUserMutation
      .mutateAsync(user._id)
      .then(() => {
        onClose()
        successNotification(Texts.NotificationUserDeleted)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  return (
    <>
      {isMobile && (
        <UserDetailsMobileView
          open={open}
          onClose={onClose}
          handleEditButton={handleEditButton}
          handleDeleteButton={handleDeleteUser}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          user={user}
          handleRemindButton={handleRemindButton}
          isInvitationAccepted={isInvitationAccepted}
        />
      )}
      {!isMobile && (
        <UserDetailsDesktopView
          open={open}
          onClose={onClose}
          handleEditButton={handleEditButton}
          handleDeleteButton={handleDeleteUser}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          user={user}
          handleRemindButton={handleRemindButton}
          isInvitationAccepted={isInvitationAccepted}
        />
      )}
    </>
  )
}

export default UserDetailsContainer
