import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import arrowLeft from '../figma/images/arrowLeft'
import Spacings from '../figma/tokens/Spacings'
import ImageContainer from '../moesia/components/ImageContainer'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import TextFieldController from '../moesia/components/TextFieldController'
import useIsMobile from '../moesia/hooks/useIsMobile'

type Props = {
  handleShowForm: () => void
  control: Control<FieldValues, any>
}

const BlogPostCategoryFormContainer: React.FC<Props> = ({ handleShowForm, control }) => {
  const isMobile = useIsMobile()

  return (
    <form>
      <StyledBox
        fullWidth
        align="center"
        justify="center"
        top={Spacings.huge}
        left
        right
        spacing={Spacings.large}
        gap={Spacings.large}
        bottom={isMobile && Spacings.big}
      >
        <SVGWrapper position="absolute" onClick={handleShowForm}>
          <SVGContainer SVG={arrowLeft} />
        </SVGWrapper>
        <ImageContainer imageKey={Images.addTemplateImage} />
        <TextFieldController name="title" control={control} labelTextKey={Texts.CategoryTitleLabel} />
      </StyledBox>
    </form>
  )
}

export default BlogPostCategoryFormContainer

const SVGWrapper = styled(StyledBox)`
  cursor: pointer;
  top: ${Spacings.small};
  left: ${Spacings.small};
`
