import { CSSObject } from '@emotion/react'
import toString from 'lodash/toString'
import { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import useLanguage from '../../hooks/useLanguage'
import { TextType } from '../interfaces/FigmaTypes'
import { StyledTextField, createAdornmentWithLoading, getLabelText } from './ReactHookComponents'

export type TextFieldControllerType<T extends Record<string, unknown>> = {
  name: Path<T>
  control: Control<T>
  labelTextKey?: TextType
  multiline?: boolean
  adornment?: JSX.Element
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  rows?: number
  loading?: boolean
  size?: 'medium' | 'small'
  labelText?: string
  containerstyles?: CSSObject
  inputstyles?: CSSObject
  autoComplete?: string
}

function TextFieldController<T extends Record<string, unknown>>({
  name,
  control,
  labelTextKey,
  labelText,
  multiline,
  adornment,
  onBlur,
  onChange: _onChange,
  type,
  disabled,
  rows,
  size,
  loading,
  containerstyles,
  inputstyles,
  autoComplete = 'off'
}: TextFieldControllerType<T>) {
  const language = useLanguage()

  return (
    <Controller
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <StyledTextField
          size={!!size ? size : 'medium'}
          rows={rows}
          disabled={disabled}
          fullWidth
          value={value}
          onBlur={onBlur}
          onChange={(event) => {
            if (type === 'number') {
              event.target.value = isNaN(Number(event.target.dataset.valueAsNumber)) ? event.target.value : toString(event.target.dataset.valueAsNumber)
              onChange(event)
              return _onChange?.(event)
            }
            onChange(event)
            return _onChange?.(event)
          }}
          label={getLabelText(language, labelTextKey, labelText)}
          error={Boolean(errors[name]?.message)}
          helperText={errors[name]?.message as React.ReactNode}
          multiline={multiline}
          type={type}
          InputProps={adornment ? { endAdornment: createAdornmentWithLoading(adornment, loading) } : {}}
          containerstyles={containerstyles as CSSObject}
          inputstyles={inputstyles as CSSObject}
          autoComplete={autoComplete}
        />
      )}
      control={control}
      name={name}
    />
  )
}
export default TextFieldController
