import StyledBox from './StyledBox'
import TextContainer from './TextContainer'
import { TextType } from '../interfaces/FigmaTypes'
import { Texts } from 'src/figma/helpers/TextRepository'

const NotificationSuccess = (successMessage: TextType) => (
  <StyledBox>
    <TextContainer textKey={Texts.NotificationSuccess} />
    <TextContainer textKey={successMessage} />
  </StyledBox>
)

export default NotificationSuccess
