import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { Position } from '../helpers/AddEmployeeFormHelper'
import { CompanyOptionsType, RolesType } from '../helpers/UserTypesHelper'
import EditorController from '../moesia/components/EditorController'
import ImageContainer from '../moesia/components/ImageContainer'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'
import UserAdditionalProfileFields from './UserAdditionalProfileFields'

type Props = {
  open: boolean
  onClose: () => void
  handleSubmit: () => void
  control: Control<FieldValues, any>
  positionOptions: Position[]
  companyOptions: CompanyOptionsType
  roles: RolesType
  isEmployeeOrCustomer: boolean
  values: FieldValues
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddUserFormMobileView: React.FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  control,
  positionOptions,
  companyOptions,
  roles,
  isEmployeeOrCustomer,
  values,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => (
  <>
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleShowConfirmation}
        confirmationTitle={Texts.AreYouSureToLeave}
        confirmationQuestion={Texts.IfYouLeaveLoseChanges}
        confirmButtonTextKey={Texts.ConfirmStayButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.AddUserMobileButtonText}
              onClick={handleSubmit}
              disabled={!isValuesChanged}
            />
          }
        />
      }
    >
      <StyledBox fullWidth align="center" gap={Spacings.big}>
        <StyledBox fullWidth align="center" position="relative">
          <StyledImageContainer imageKey={Images.employeeDetailsMobileBackground} />
          <StyledGenericAvatar position="absolute">
            <GenericAvatar avatarType={AvatarType.EMPLOYEE} border />
          </StyledGenericAvatar>
        </StyledBox>
        <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.small}>
          <TextFieldController name="name" control={control} labelText="Name" />
          <TextFieldController name="email" control={control} labelText="Email" />
          <TextFieldController name="password" control={control} labelText="Password" />
          <AutoCompleteController
            name="position"
            control={control}
            options={positionOptions}
            labelTextKey={Texts.AddEmployeePositionPlaceholder}
          />
          <SelectChipController name="companies" control={control} options={companyOptions} labelText="Company" />
          <AutoCompleteController name="role" control={control} options={roles} labelText="User role" />
          {isEmployeeOrCustomer && <UserAdditionalProfileFields control={control} role={values.role} />}
          <EditorController name="goals" labelTextKey={Texts.GoalsPlaceholder} control={control} />
          <EditorController name="notes" labelTextKey={Texts.NotesPlaceholder} control={control} />
          <DatePickerController
            name="birthDate"
            control={control}
            labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
          />
        </StyledBox>
      </StyledBox>
    </GenericPageModal>
  </>
)

export default AddUserFormMobileView

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
