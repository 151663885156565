import { Line } from './Line'
import styled from 'styled-components'
import React, { useMemo } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import StyledBox from 'src/moesia/components/StyledBox'
import OverviewRedirectionBox from './OverviewRedirectionBox'
import OverviewNotificationBox from './OverviewNotificationBox'
import { getOverviewNotificationBoxes, getOverviewRedirectionBoxes } from 'src/helpers/OverviewHelper'

const Overview: React.FC = () => {
  const isMobile = useIsMobile()
  const redirectionBoxes = useMemo(() => getOverviewRedirectionBoxes(), [])
  const notificationBoxes = useMemo(() => getOverviewNotificationBoxes(), [])

  return (
    <StyledBox gap={Spacings.big} top={isMobile && Spacings.big} bottom spacing={Spacings.big} align="center">
      {isMobile && (
        <StyledBox fullWidth top={Spacings.huge} left right spacing={Spacings.large}>
          <Line fullWidth />
        </StyledBox>
      )}
      <Wrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!notificationBoxes &&
          notificationBoxes.map(({ icon, title, notificationMessage, emptyStateTextKey }) => (
            <OverviewNotificationBox
              key={generateUUID()}
              icon={icon}
              title={title}
              notificationMessage={notificationMessage}
              emptyStateTextKey={emptyStateTextKey}
            />
          ))}
      </Wrapper>
      <Line fullWidth />
      <Wrapper direction="row" left right spacing={Spacings.large} gap={Spacings.big} justify="center">
        {!!redirectionBoxes &&
          redirectionBoxes.map(({ image, title, description, url }) => (
            <OverviewRedirectionBox
              key={generateUUID()}
              image={image}
              title={title}
              description={description}
              url={url}
            />
          ))}
      </Wrapper>
    </StyledBox>
  )
}

export default Overview

const Wrapper = styled(StyledBox)`
  flex-wrap: wrap;
`
