import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import routePaths from '../config/RoutePaths'
import { getText, Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useResetPassword } from '../helpers/QueryHooks'
import { createConfirmPasswordYupValidation, createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import useIsMobile from '../moesia/hooks/useIsMobile'
import LoginResetPasswordDesktopView from './LoginResetPasswordDesktopView'
import LoginResetPasswordMobileView from './LoginResetPasswordMobileView'

const LoginResetPasswordPage: React.FC = () => {
  const isMobile = useIsMobile()
  const language = useLanguage()
  const navigate = useNavigate()
  const { resetPassowordCode = '' } = useParams()
  const resetPassword = useResetPassword(resetPassowordCode)
  const defaultValues = useMemo(
    () => ({
      password: '',
      newPassword: ''
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: createRequiredStringYupValidation(getText(Texts.LoginPasswordErrorRequired, language)),
        newPassword: createConfirmPasswordYupValidation(
          getText(Texts.ResetPasswordErrorConfirm, language),
          getText(Texts.ResetPasswordErrorNotMatching, language)
        )
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful }
  } = useForm<{ password: string; newPassword: string }>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleResetPassword = (data: { [key: string]: string }) => {
    resetPassword
      .mutateAsync(data)
      .then(() => navigate(routePaths.entry))
      .catch((res) => handleErrorMessage(res.message))
  }

  return (
    <>
      {!isMobile && (
        <LoginResetPasswordDesktopView handleResetPassword={handleSubmit(handleResetPassword)} control={control} />
      )}
      {isMobile && (
        <LoginResetPasswordMobileView handleResetPassword={handleSubmit(handleResetPassword)} control={control} />
      )}
    </>
  )
}

export default LoginResetPasswordPage
