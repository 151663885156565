import { Texts } from 'src/figma/helpers/TextRepository'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'

export type DocumentsChecklistItemType = {
  textKey: TextType
}

export const getDocumentsChecklistItems = (): DocumentsChecklistItemType[] => [
  {
    textKey: Texts.DocApplication
  },
  {
    textKey: Texts.DocContract
  },
  {
    textKey: Texts.DocBankAccount
  },
  {
    textKey: Texts.DocCriminalRecord
  },
  {
    textKey: Texts.DocDegreeTitle
  },
  {
    textKey: Texts.DocEmploymentBook
  },
  {
    textKey: Texts.DocIDCardCopy
  },
  {
    textKey: Texts.DocJobDescription
  },
  {
    textKey: Texts.DocMedicalAssessment
  },
  {
    textKey: Texts.DocNRAReference
  },
  {
    textKey: Texts.DocOccupationalMedicine
  },
  {
    textKey: Texts.DocSafetyBriefing
  }
]

export type Position = {
  label: string
  value: string
}

export const getPositionOptions = (): Position[] => [
  {
    value: 'Junior Front-End Developer',
    label: 'Junior Front-End Developer'
  },
  {
    value: 'Intermediate Frond-End Developer',
    label: 'Intermediate Frond-End Developer'
  },
  {
    value: 'Senior Front-End Developer',
    label: 'Senior Front-End Developer'
  },
  {
    value: 'Junior QA',
    label: 'Junior QA'
  },
  {
    value: 'Intermediate QA',
    label: 'Intermediate QA'
  },
  {
    value: 'Senior QA',
    label: 'Senior QA'
  },
  {
    value: 'Junior/Intermediate UI/UX Designer',
    label: 'Junior/Intermediate UI/UX Designer'
  },
  {
    value: 'Senior UI/UX Designer',
    label: 'Senior UI/UX Designer'
  },
  {
    value: 'Junior/Intermediate Back-End Developer',
    label: 'Junior/Intermediate Back-End Developer'
  },
  {
    value: 'Senior Back-End Developer',
    label: 'Senior Back-End Developer'
  },
  {
    value: 'Tech Lead',
    label: 'Tech Lead'
  },
  {
    value: 'CTO',
    label: 'CTO'
  }
]
