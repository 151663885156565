import React, { useMemo, useState } from 'react'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import { getEmployeesDesktopColumns, getEmployeesMobileColumns } from 'src/helpers/EmployeesTableColumnsHelper'
import { getTableExtraOptions } from 'src/helpers/TablesExtraOptionsHelper'
import useLanguage from 'src/hooks/useLanguage'
import { UserType } from '../enums/UserType'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useArchivedEmployees, useDeleteUser } from '../helpers/QueryHooks'
import { hasAccess } from '../helpers/UsersHelper'
import { User } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import EmployeesTableDesktop from './EmployeesTableDesktop'
import EmployeesTableMobile from './EmployeesTableMobile'

type EmployeesTableProps = {
  employees: User[]
}

const EmployeesTable: React.FC<EmployeesTableProps> = ({ employees }) => {
  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null)
  const { data: archivedEmployees = [] } = useArchivedEmployees()
  const [shouldShowArchived, setShouldShowArchived] = useState(false)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)
  const deleteUserMutation = useDeleteUser()
  const language = useLanguage()
  const isMobile = useIsMobile()
  const currentUserRole = useUser().role
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const extraOptions = useMemo(() => getTableExtraOptions(), [])
  const searchPlaceholder = useMemo(() => getText(Texts.EmployeeSearchPlaceholder, language), [language])
  const tableColumnsDesktop = useMemo(() => getEmployeesDesktopColumns(language), [language])
  const tableColumnsMobile = useMemo(() => getEmployeesMobileColumns(language), [language])

  const handleShouldShowArchived = () => setShouldShowArchived(!shouldShowArchived)
  const activeEmployees = () => employees.filter((employee) => !employee.archived)
  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)

  const deleteMutation = (userId: string) => {
    deleteUserMutation
      .mutateAsync(userId)
      .then(() => successNotification(Texts.NotificationEmployeeDeleted))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleDeleteEmployee = () => {
    if (!!selectedEmployee) {
      handleShowDeleteConfirmation()
      deleteMutation(selectedEmployee._id)
    }
  }

  return (
    <>
      {!isMobile && (
        <EmployeesTableDesktop
          employees={shouldShowArchived ? archivedEmployees : activeEmployees()}
          extraOptions={extraOptions}
          tableColumnsDesktop={tableColumnsDesktop}
          searchPlaceholder={searchPlaceholder}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          isNotRegularUser={isNotRegularUser}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          onEmployeeDelete={handleDeleteEmployee}
        />
      )}
      {isMobile && (
        <EmployeesTableMobile
          employees={shouldShowArchived ? archivedEmployees : activeEmployees()}
          extraOptions={extraOptions}
          tableColumnsMobile={tableColumnsMobile}
          searchPlaceholder={searchPlaceholder}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          isNotRegularUser={isNotRegularUser}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          onEmployeeDelete={handleDeleteEmployee}
        />
      )}
    </>
  )
}

export default EmployeesTable
