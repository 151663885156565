import { Location } from '@sentry/react/types/types'
import { NavigateFunction } from 'react-router-dom'
import arrowDown from '../figma/images/arrowDown'
import arrowUp from '../figma/images/arrowUp'
import SVGContainer from '../moesia/components/SVGContainer'
import { isButtonActive } from './Common'

export const handleSidebarButtonClick = (
  navigate: NavigateFunction,
  location: Location,
  handleCloseMenu?: () => void,
  showSubButtons?: boolean,
  setShowSubButtons?: React.Dispatch<React.SetStateAction<boolean>>,
  url?: string
) => {
  if (setShowSubButtons) return setShowSubButtons(!showSubButtons)
  if (location.pathname === url && !!handleCloseMenu) {
    handleCloseMenu()
    return
  }
  if (!!url) return navigate(url)
}

const handlePathNameInclude = (
  expectedReturn: 'boolean' | 'className',
  section?: string,
  showSubButtons?: boolean,
  doesPathNameIncludesSection?: (section?: string) => boolean
) => {
  if (!section || !doesPathNameIncludesSection) return

  if (expectedReturn === 'className') {
    if (
      (!showSubButtons && !!doesPathNameIncludesSection(section)) ||
      (!!showSubButtons && !doesPathNameIncludesSection(section))
    )
      return 'active'

    return ''
  }

  if (expectedReturn === 'boolean') return doesPathNameIncludesSection(section)
}

export const handleSidebarButtonClassName = (
  location: Location,
  url?: string,
  section?: string,
  showSubButtons?: boolean,
  doesPathNameIncludesSection?: (section?: string) => boolean
) => {
  if (!!url) return isButtonActive(url, location.pathname)

  return handlePathNameInclude('className', section, showSubButtons, doesPathNameIncludesSection)
}

export const getExpandableButtonArrowIcon = (
  showSubButtons?: boolean,
  section?: string,
  doesPathNameIncludesSection?: (section?: string) => boolean
) => (
  <>
    {showSubButtons && !handlePathNameInclude('boolean', section, showSubButtons, doesPathNameIncludesSection) && (
      <SVGContainer SVG={arrowUp} />
    )}
    {!showSubButtons && !handlePathNameInclude('boolean', section, showSubButtons, doesPathNameIncludesSection) && (
      <SVGContainer SVG={arrowDown} />
    )}
    {showSubButtons && handlePathNameInclude('boolean', section, showSubButtons, doesPathNameIncludesSection) && (
      <SVGContainer SVG={arrowDown} />
    )}
    {!showSubButtons && handlePathNameInclude('boolean', section, showSubButtons, doesPathNameIncludesSection) && (
      <SVGContainer SVG={arrowUp} />
    )}
  </>
)
