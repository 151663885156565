import { Texts } from 'src/figma/helpers/TextRepository'
import { errorNotification } from 'src/moesia/helpers/NotificationsHelper'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import { isProductionENV } from './Common'

const developmentErrorHandler = (errorTextKey: TextType, errorText?: string) => {
  console.log(errorText)
  errorNotification(errorTextKey, errorText)
}

export const handleErrorMessage = (error: Error | string) => {
  const errorText = typeof error === 'string' ? error : error.message
  const isProduction = isProductionENV()
  if (isProduction) {
    return errorNotification(Texts.NotificationSomethingWentWrong)
  }
  return developmentErrorHandler(Texts.NotificationSomethingWentWrong, errorText)
}
