import { memo } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import loginPageLogoMobile from 'src/figma/images/loginPageLogoMobile'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { LOGIN_BUTTON_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import { ForgotPasswordProps } from '../interfaces/AuthInterface'
import LoginPageTextFieldController from './LoginPageTextFieldController'

const LoginForgotPasswordMobileView: React.FC<ForgotPasswordProps> = ({
  control,
  handleResetPasswordRequest,
  handleBackToLogin,
  isSentResetRequest
}) => (
  <LoginPage fullWidth align="center" top={Spacings.large}>
    <StyledBox fullPadding top={Spacings.large} spacing={Spacings.large}>
      <StyledBox fullWidth gap={Spacings.tiny} align="center" justify="center">
        <SVGContainer SVG={loginPageLogoMobile} />
        <TextContainer textKey={Texts.LoginPageHeading} />
      </StyledBox>
      <StyledBox fullPadding spacing={Spacings.big} gap={Spacings.medium} align="center" justify="center">
        <StyledBox align="center" alignText="center" gap={Spacings.tiny}>
          <TextContainer textKey={Texts.LoginForgotPasswordHeading} />
          <TextContainer
            textKey={isSentResetRequest ? Texts.LoginForgotPasswordCheckEmail : Texts.LoginForgotPasswordSubheading}
          />
        </StyledBox>
        {isSentResetRequest && (
          <StyledButton
            onClick={handleBackToLogin}
            fullWidth
            fullPadding
            align="center"
            justify="center"
            spacing={Spacings.min}
            alignText="center"
          >
            <TextContainer textKey={Texts.BackToLoginWhiteButtonText} />
          </StyledButton>
        )}
        {!isSentResetRequest && (
          <>
            <LoginPageTextFieldController control={control} name="email" labelTextKey={Texts.LoginEmailLabel} />
            <StyledBox fullWidth gap={Spacings.medium} align="center" justify="center">
              <StyledButton
                fullWidth
                fullPadding
                spacing={Spacings.min}
                alignText="center"
                align="center"
                justify="center"
                onClick={handleResetPasswordRequest}
              >
                <TextContainer textKey={Texts.ResetPasswordButtonText} />
              </StyledButton>
              <StyledBox fullWidth align="center" justify="center" onClick={handleBackToLogin}>
                <TextContainer textKey={Texts.BackToLoginButtonText} />
              </StyledBox>
            </StyledBox>
          </>
        )}
      </StyledBox>
    </StyledBox>
  </LoginPage>
)

export default memo(LoginForgotPasswordMobileView)

const LoginPage = styled(StyledBox)`
  height: 100vh;
  background-color: ${FigmaColors.white};
  &&& {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-radius: ${BorderRadius.verySoft} !important;
    }
  }
`

const StyledButton = styled(StyledBox)`
  border-radius: ${BorderRadius.verySoft};
  background-color: ${FigmaColors.loginButtonColor};
  height: ${LOGIN_BUTTON_HEIGHT};
  cursor: pointer;
  &:hover {
    background-color: ${FigmaColors.blueDark};
  }
`
