import MaterialTable, { MTableToolbar } from '@material-table/core'
import { useContext, useMemo } from 'react'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import buttonAddIcon from 'src/figma/images/buttonAddIcon'
import Spacings from 'src/figma/tokens/Spacings'
import { getCompaniesMobileColumns } from 'src/helpers/CompaniesTableColumnsHelper'
import { getTableExtraOptions } from 'src/helpers/TablesExtraOptionsHelper'
import { getTableOptions } from 'src/helpers/getTableOptions'
import { Company } from 'src/interfaces/Dto'
import StyledBox from 'src/moesia/components/StyledBox'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'
import { sortElements } from '../helpers/Common'
import { PageSubstate, PageSubstateContext, getCompaniesPageSubstate } from '../helpers/PageSubstateHelper'
import useLanguage from '../hooks/useLanguage'
import { MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE } from '../moesia/StrongHardCodedSizes'
import CompaniesMobileRowInfoWrapper from './CompaniesMobileRowInfoWrapper'
import GenericConfirmationModal from './GenericConfirmationModal'
import MobileTableAddButton from './MobileTableAddButton'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type CompaniesTableMobileProps = {
  companies: Company[]
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  selectedCompany?: Company | null
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | null | undefined>>
  onDeleteCompany: () => void
}

const CompaniesTableMobile: React.FC<CompaniesTableMobileProps> = ({
  companies,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  selectedCompany,
  setSelectedCompany,
  onDeleteCompany
}) => {
  const language = useLanguage()
  const isMobile = useIsMobile()
  const searchPlaceholder = useMemo(() => getText(Texts.CompanySearchPlaceholder, language), [language])
  const columns = useMemo(() => getCompaniesMobileColumns(language), [language])
  const extraOptions = useMemo(() => getTableExtraOptions(), [])
  const tableOptions = getTableOptions({ extraOptions: extraOptions })
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const substateView = useMemo(
    () => getCompaniesPageSubstate(substate, setSubstate, selectedCompany, setSelectedCompany),
    [substate, setSubstate, selectedCompany]
  )
  const shouldShowTable = substate !== PageSubstate.ERROR && substate !== PageSubstate.CREATE

  return (
    <>
      {substateView}
      {shouldShowTable && (
        <>
          {shouldShowDeleteConfirmationModal && (
            <GenericConfirmationModal
              open
              onClose={handleShowDeleteConfirmation}
              onConfirm={onDeleteCompany}
              confirmationTitle={Texts.ConfirmDeleteCompanyTitle}
              confirmationQuestion={Texts.ConfirmDeleteCompanyQuestion}
              confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
              confirmButtonColor={FigmaColors.lighterRed}
              smallSize
            />
          )}
          <StyledBox fullWidth justify="center" position="absolute">
            <TableWrapper isMobile={isMobile}>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <TableToolbar fullWidth>
                      <ToggleArchiveButton
                        activateTextKey={Texts.ActivatedButtonTextMobile}
                        archiveTextKey={Texts.ArchivedButtonTextMobile}
                        onClick={handleShouldShowArchived}
                        isArchived={isShowingArchived}
                      />
                      <StyledBox
                        fullWidth
                        bottom={Spacings.minimum}
                        direction="row"
                        align="center"
                        justify="space-between"
                      >
                        <MTableToolbar
                          {...props}
                          searchFieldStyle={{
                            ...tableOptions.searchFieldStyle,
                            width: isShowingArchived && MUI_TABLE_SEARCH_FIELD_FULL_WIDTH_MOBILE
                          }}
                        />
                        {!isShowingArchived && (
                          <MobileTableAddButton
                            onClick={() => setSubstate(PageSubstate.CREATE)}
                            buttonIcon={buttonAddIcon}
                          />
                        )}
                      </StyledBox>
                    </TableToolbar>
                  )
                }}
                columns={columns}
                data={sortElements(companies)}
                localization={{
                  toolbar: {
                    searchPlaceholder: searchPlaceholder
                  }
                }}
                options={tableOptions}
                detailPanel={[
                  {
                    render: ({ rowData }) => (
                      <CompaniesMobileRowInfoWrapper
                        company={rowData}
                        onInfoClick={() => {
                          !!rowData && setSelectedCompany(rowData as Company)
                          setSubstate(PageSubstate.DETAILS)
                        }}
                        onEditClick={
                          !isShowingArchived
                            ? () => {
                                setSelectedCompany(rowData as Company)
                                setSubstate(PageSubstate.EDIT)
                              }
                            : undefined
                        }
                        onDeleteClick={
                          isShowingArchived
                            ? () => {
                                setSelectedCompany(rowData as Company)
                                handleShowDeleteConfirmation()
                              }
                            : undefined
                        }
                      />
                    )
                  }
                ]}
              />
            </TableWrapper>
          </StyledBox>
        </>
      )}
    </>
  )
}

export default CompaniesTableMobile

const TableToolbar = styled(StyledBox)`
  margin-top: -${Spacings.small};
`
