import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import { TervelImage } from '../interfaces/Dto'
import {
  BLOG_POST_THUMBNAIL_MAX_HEIGHT_DESKTOP,
  BLOG_POST_THUMBNAIL_MAX_HEIGHT_MOBILE
} from '../moesia/StrongHardCodedSizes'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'

type Props = {
  thumbnail: TervelImage
  title: string
  previewContent: string
}

const BlogPostHeadSection: React.FC<Props> = ({ thumbnail, title, previewContent }) => {
  const isMobile = useIsMobile()

  return (
    <>
      <Thumbnail src={thumbnail.signedUrl} alt="Blog post thumbnail" $isMobile={isMobile} />
      <TruncatedText textKey={Texts.BlogPostTitle} text={title} />
      <TruncatedText textKey={Texts.BlogPostPrevContent} text={previewContent} />
    </>
  )
}
export default BlogPostHeadSection

const TruncatedText = styled(TextContainer)`
  span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const Thumbnail = styled.img<{ $isMobile: boolean }>`
  border-radius: ${BorderRadius.soft};
  min-height: ${({ $isMobile }) =>
    $isMobile ? BLOG_POST_THUMBNAIL_MAX_HEIGHT_MOBILE : BLOG_POST_THUMBNAIL_MAX_HEIGHT_DESKTOP};
  max-height: ${({ $isMobile }) =>
    $isMobile ? BLOG_POST_THUMBNAIL_MAX_HEIGHT_MOBILE : BLOG_POST_THUMBNAIL_MAX_HEIGHT_DESKTOP};
  object-fit: cover;
`
