import { Column } from '@material-table/core'
import { getText, Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import { Company, User } from 'src/interfaces/Dto'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { Language } from './SidebarHelper'

export const getEmployeesDesktopColumns = (language: Language): Column<User>[] => [
  {
    title: getText(Texts.EmployeesTableNameTitle, language),
    field: 'name',
    render: (employee: User) => (
      <StyledBox key={employee._id} align="center" justify="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.EmployeeDetailsPosition} text={employee.name} />
      </StyledBox>
    )
  },
  {
    title: getText(Texts.EmployeesTableCompanyTitle, language),
    field: 'company',
    render: (employee: User) => {
      const { companies } = employee
      return (
        <StyledBox key={employee._id} align="center" justify="center" gap={Spacings.tiny}>
          {!companies.length && '-'}
          {companies.map((company: Company) => (
            <TextContainer key={company._id} textKey={Texts.EmployeeDetailsPosition} text={company.name} />
          ))}
        </StyledBox>
      )
    }
  },
  {
    title: getText(Texts.EmployeesTablePositionTitle, language),
    field: 'position'
  }
]

export const getEmployeesMobileColumns = (language: Language) => [
  {
    title: getText(Texts.EmployeesTableNameTitle, language),
    field: 'name',
    render: (employee: User) => (
      <StyledBox key={employee._id} align="center" justify="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.EmployeeDetailsPosition} text={employee.name} />
      </StyledBox>
    )
  },
  {
    title: getText(Texts.EmployeesTableCompanyTitle, language),
    field: 'company',
    render: (employee: User) => {
      const { companies } = employee
      return (
        <StyledBox key={employee._id} align="center" justify="center" gap={Spacings.tiny}>
          {!companies.length && '-'}
          {companies.map((company: Company) => (
            <TextContainer key={company._id} textKey={Texts.EmployeeDetailsPosition} text={company.name} />
          ))}
        </StyledBox>
      )
    }
  }
]
