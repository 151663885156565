import { memo } from 'react'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import styled from 'styled-components'
import { Texts } from '../../figma/helpers/TextRepository'
import Spacings from '../../figma/tokens/Spacings'
import { TervelImage } from '../../interfaces/Dto'
import { CardWrapper } from './StyleComponents'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'
import UploadedImageContainer from './UploadedImageContainer'

type Props = {
  textKey: string
  image: TervelImage
}

const NewsCardFront = ({ textKey, image }: Props) => (
  <CardWrapper backgroundColor={FigmaColors.white} position="absolute">
    <TextBox top left spacing={Spacings.large} alignText="center">
      <TextContainer textKey={Texts.BuildingOurWebsiteTitle} text={textKey} />
    </TextBox>
    <ImageBox position="absolute" align="flex-end">
      <UploadedImageContainer image={image} />
    </ImageBox>
  </CardWrapper>
)

export default memo(NewsCardFront)

const TextBox = styled(StyledBox)`
  // Used to style the text as in the design
  width: 50%;
`

const ImageBox = styled(StyledBox)`
  // Using position absolute to place all images in the same position.
  // Otherwise they are in different positions due to the different textKeys extending the container above the image and moving the image down.
  bottom: 10%;
  right: 10%;
`
