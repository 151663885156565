import { useContext, useState } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import deteleDocumentIconBigger from '../figma/images/deteleDocumentIconBigger'
import downloadDocumentIconBigger from '../figma/images/downloadDocumentIconBigger'
import editDocumentIconBigger from '../figma/images/editDocumentIconBigger'
import wikiDocumentIconBigger from '../figma/images/wikiDocumentIconBigger'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { Template } from '../interfaces/Dto'
import SVGContainer from '../moesia/components/SVGContainer'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import GenericConfirmationModal from './GenericConfirmationModal'
import StyledLink from './StyledLink'

type Props = {
  hasRightsToDelete?: boolean
  onDeleteTemplate?: (templateId: string) => void
  template: Template
  setSelectedTemplate?: React.Dispatch<React.SetStateAction<Template | null>>
}

const TemplateContainerOptions: React.FC<Props> = ({
  hasRightsToDelete,
  onDeleteTemplate,
  template,
  setSelectedTemplate
}) => {
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const { signedUrl, wikiUrl, _id: templateId } = template
  const isMobile = useIsMobile()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleDelete = () => onDeleteTemplate && onDeleteTemplate(templateId)

  const handleShowConfirmationModal = () => setShowConfirmationModal(!showConfirmationModal)

  const handleEditClick = () => {
    setSubstate(PageSubstate.EDIT)
    !!setSelectedTemplate && setSelectedTemplate(template)
  }

  return (
    <>
      {showConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowConfirmationModal}
          onConfirm={handleDelete}
          confirmationTitle={Texts.ConfirmDeleteTemplateTitle}
          confirmationQuestion={Texts.ConfirmDeleteTemplateQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          cancelButtonTextKey={Texts.CancelDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
          smallSize={isMobile}
        />
      )}
      <MenuActionButtons
        fullWidth
        direction="row"
        align="center"
        justify="center"
        position="absolute"
        gap={Spacings.minimum}
        fullPadding
        spacing={Spacings.minimum}
      >
        <StyledLink openInNewTab href={signedUrl}>
          <StyledSVGContainer SVG={downloadDocumentIconBigger} $isMobile={isMobile} />
        </StyledLink>
        <StyledLink href={wikiUrl}>
          <StyledSVGContainer SVG={wikiDocumentIconBigger} $isMobile={isMobile} />
        </StyledLink>
        {!!hasRightsToDelete && (
          <>
            <StyledBox onClick={handleEditClick}>
              <StyledSVGContainer SVG={editDocumentIconBigger} $isMobile={isMobile} />
            </StyledBox>
            {!!onDeleteTemplate && (
              <StyledBox onClick={handleShowConfirmationModal}>
                <StyledSVGContainer SVG={deteleDocumentIconBigger} $isMobile={isMobile} />
              </StyledBox>
            )}
          </>
        )}
      </MenuActionButtons>
    </>
  )
}

export default TemplateContainerOptions

const MenuActionButtons = styled(StyledBox)`
  width: 60%;
  background-color: ${FigmaColors.white};
  border-radius: ${BorderRadius.soft};
  flex-wrap: wrap;
`

const StyledSVGContainer = styled(SVGContainer)<{ $isMobile: boolean }>`
  display: flex;
  cursor: pointer;
  padding: ${({ $isMobile }) => ($isMobile ? Spacings.smallest : Spacings.minimum)};
  :hover {
    background-color: ${FigmaColors.grayLight};
    border-radius: ${BorderRadius.circle};
    align-items: center;
  }
`
