import React, { useState } from 'react'
import { Company } from 'src/interfaces/Dto'
import { Texts } from '../figma/helpers/TextRepository'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { useArchivedCompanies, useDeleteCompany } from '../helpers/QueryHooks'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import CompaniesTableDesktop from './CompaniesTableDesktop'
import CompaniesTableMobile from './CompaniesTableMobile'
import DevHarbourLoader from './DevHarbourLoader'

type CompaniesTableProps = {
  companies: Company[]
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ companies }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null | undefined>()
  const { data: archivedCompanies, isRefetching, isSuccess } = useArchivedCompanies()
  const [shouldShowArchived, setShouldShowArchived] = useState(false)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)
  const isMobile = useIsMobile()
  const deleteCompanyMutation = useDeleteCompany()
  const handleShouldShowArchived = () => setShouldShowArchived(!shouldShowArchived)
  const activeCompanies = () => companies.filter((company) => !company.archived)
  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)

  const deleteMutation = (companyId: string) => {
    deleteCompanyMutation
      .mutateAsync(companyId)
      .then(() => successNotification(Texts.NotificationCompanyDeleted))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleDeleteCompany = () => {
    if (!!selectedCompany) {
      handleShowDeleteConfirmation()
      deleteMutation(selectedCompany._id)
    }
  }

  return (
    <>
      {isRefetching && !isSuccess && <DevHarbourLoader />}
      {!isMobile && (
        <CompaniesTableDesktop
          companies={shouldShowArchived ? archivedCompanies : activeCompanies()}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          onDeleteCompany={handleDeleteCompany}
        />
      )}
      {isMobile && (
        <CompaniesTableMobile
          companies={shouldShowArchived ? archivedCompanies : activeCompanies()}
          handleShouldShowArchived={handleShouldShowArchived}
          isShowingArchived={shouldShowArchived}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          onDeleteCompany={handleDeleteCompany}
        />
      )}
    </>
  )
}

export default CompaniesTable
