import { FieldValues, UseFormSetValue } from 'react-hook-form'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { generateUUID } from '../helpers/Common'
import { handleContactDelete, handleContactEdit } from '../helpers/CompanyContactsHelper'
import { ContactPerson } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT } from '../moesia/StrongHardCodedSizes'
import CompanyContactPerson from './CompanyContactPerson'

type Props = {
  isContactsMenuOpen: boolean
  contactsContainer: ContactPerson[] | []
  setValue: UseFormSetValue<FieldValues>
  setSelectedContactEmail: React.Dispatch<React.SetStateAction<string | null>>
  setContactsContainer: React.Dispatch<React.SetStateAction<ContactPerson[] | []>>
  setIsContactsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CompanyContactsWrapperContainer: React.FC<Props> = ({
  isContactsMenuOpen,
  contactsContainer,
  setValue,
  setSelectedContactEmail,
  setContactsContainer,
  setIsContactsMenuOpen
}) => {
  const isMobile = useIsMobile()

  return (
    <>
      {!!isMobile && (
        <ContactsWrapper fullWidth gap={Spacings.min} $isContactsMenuOpen={isContactsMenuOpen} isMobile={isMobile}>
          {!!contactsContainer?.length &&
            contactsContainer.map(({ name, position, email }: ContactPerson) => (
              <CompanyContactPerson
                key={generateUUID()}
                name={name}
                position={position}
                email={email}
                onEdit={() => {
                  handleContactEdit(contactsContainer, setValue, email)
                  setSelectedContactEmail(email)
                }}
                onDelete={() => handleContactDelete(contactsContainer, setValue, email, setContactsContainer)}
              />
            ))}
          {!contactsContainer.length && setIsContactsMenuOpen(false)}
        </ContactsWrapper>
      )}
      {!isMobile && (
        <ContactsWrapper fullWidth gap={Spacings.min} $isContactsMenuOpen={isContactsMenuOpen} isMobile={isMobile}>
          {!!contactsContainer?.length &&
            contactsContainer.map(({ name, position, email }: ContactPerson) => (
              <CompanyContactPerson
                key={generateUUID()}
                name={name}
                position={position}
                email={email}
                onEdit={() => {
                  handleContactEdit(contactsContainer, setValue, email)
                  setSelectedContactEmail(email)
                }}
                onDelete={() => handleContactDelete(contactsContainer, setValue, email, setContactsContainer)}
              />
            ))}
          {!contactsContainer?.length && setIsContactsMenuOpen(false)}
        </ContactsWrapper>
      )}
    </>
  )
}

export default CompanyContactsWrapperContainer

const ContactsWrapper = styled(StyledBox)<{ $isContactsMenuOpen: boolean; isMobile: boolean }>`
  border: ${({ $isContactsMenuOpen }) => $isContactsMenuOpen && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: ${({ isMobile }) => (!!isMobile ? `-${Spacings.small}` : `-${Spacings.min}`)};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;
  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`
