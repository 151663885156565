/* eslint-disable max-lines */
import React, { useState } from 'react'
import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import EditorController from 'src/moesia/components/EditorController'
import { AutoCompleteChipController } from 'src/moesia/components/ReactHookComponents'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import TextFieldController from 'src/moesia/components/TextFieldController'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import { generateUUID, getArrowSvg } from '../helpers/Common'
import { handleApplyContactChanges, handleContactDelete, handleContactEdit } from '../helpers/CompanyContactsHelper'
import { Language } from '../helpers/SidebarHelper'
import { ContactPerson } from '../interfaces/Dto'
import {
  COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT,
  DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH
} from '../moesia/StrongHardCodedSizes'
import SVGContainer from '../moesia/components/SVGContainer'
import CompanyContactPerson from './CompanyContactPerson'
import GenericAvatar, { AvatarType } from './GenericAvatar'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onAddContact: () => void
  isContactInfoFilled: boolean
  contacts: ContactPerson[]
  control: Control<FieldValues, any>
  language: Language
  setValue: UseFormSetValue<FieldValues>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  watch: UseFormWatch<FieldValues>
  shouldShowConfirmationModal: boolean
  handleShowConfirmation: () => void
  handleClose: () => void
  isValuesChanged?: boolean
}

const AddCompanyFormDesktop: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  onAddContact,
  isContactInfoFilled,
  contacts,
  control,
  language,
  setValue,
  setError,
  clearErrors,
  watch,
  shouldShowConfirmationModal,
  handleShowConfirmation,
  handleClose,
  isValuesChanged
}) => {
  const [isContactsMenuOpen, setIsContactsMenuOpen] = useState(false)
  const [selectedContactEmail, setSelectedContactEmail] = useState<string | null>(null)

  const handleAddContactClick = () => {
    isContactInfoFilled && !selectedContactEmail && onAddContact()
    isContactInfoFilled &&
      selectedContactEmail &&
      handleApplyContactChanges(
        selectedContactEmail,
        contacts,
        watch,
        setError,
        clearErrors,
        setValue,
        setSelectedContactEmail
      )
  }

  return (
    <>
      {shouldShowConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={() => {
            onClose()
            handleShowConfirmation()
          }}
          onConfirm={handleShowConfirmation}
          confirmationTitle={Texts.AreYouSureToLeave}
          confirmationQuestion={Texts.IfYouLeaveLoseChanges}
          confirmButtonTextKey={Texts.ConfirmStayButtonText}
          cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
          cancelButtonColor={FigmaColors.lighterRed}
        />
      )}
      <GenericPageModal
        open={open}
        onClose={handleClose}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.AddCompanyButtonText}
                onClick={onSubmit}
                disabled={!isValuesChanged}
              />
            }
            tertiaryButton={<SubmitButton disabled variant="tertiary" textKey={Texts.AddEmployeeArchiveButtonText} />}
          />
        }
      >
        <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
          <form>
            <StyledBox direction="row" gap={Spacings.medium}>
              <GenericAvatar avatarType={AvatarType.COMPANY} />
              <StyledBox gap={Spacings.medium}>
                <StyledBox direction="row" fullWidth>
                  <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <TextFieldController
                      name="name"
                      control={control}
                      labelText={getText(Texts.AddCompanyNameLabel, language)}
                    />
                    <TextFieldController
                      name="vat"
                      control={control}
                      labelText={getText(Texts.AddCompanyVATLabel, language)}
                    />
                    <TextFieldController
                      name="email"
                      control={control}
                      labelText={getText(Texts.AddCompanyEmailLabel, language)}
                    />
                  </FormColumn>
                  <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                    <ExpandableBox fullWidth justify="center" fullPadding spacing={Spacings.tiny}>
                      <TextContainer textKey={Texts.EmployedPeople} />
                    </ExpandableBox>
                  </FormColumn>
                </StyledBox>
                <StyledBox fullWidth>
                  <DocumentsTitleWrapper fullWidth bottom spacing={Spacings.min} alignText="center">
                    <TextContainer textKey={Texts.CompanyInformation} />
                  </DocumentsTitleWrapper>
                  <StyledBox direction="row" top bottom spacing={Spacings.tiny}>
                    <FormColumn fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                      <WidthLimitWrapper>
                        <StyledBox fullWidth gap={Spacings.medium}>
                          <StyledBox fullWidth gap={Spacings.min}>
                            <TextContainer textKey={Texts.ContactInformation} />
                            <AddContactWrapper
                              fullWidth
                              direction="row"
                              gap={Spacings.min}
                              fullPadding
                              spacing={Spacings.tiny}
                              justify="space-between"
                            >
                              <StyledBox>
                                <GenericAvatar avatarType={AvatarType.EMPLOYEE} smallSize />
                              </StyledBox>
                              <StyledBox align="flex-end" gap={Spacings.min}>
                                <TextFieldController
                                  name="contactName"
                                  control={control}
                                  labelText={getText(Texts.ContactNameLabel, language)}
                                />
                                <TextFieldController
                                  name="contactPosition"
                                  control={control}
                                  labelText={getText(Texts.ContactPositionLabel, language)}
                                />
                                <TextFieldController
                                  name="contactEmail"
                                  control={control}
                                  labelText={getText(Texts.ContactEmailLabel, language)}
                                />
                                <SubmitButton
                                  variant="primary"
                                  textKey={
                                    !!selectedContactEmail ? Texts.ApplyChangesButtonText : Texts.AddContactButtonText
                                  }
                                  onClick={handleAddContactClick}
                                  disabled={!isContactInfoFilled}
                                />
                              </StyledBox>
                            </AddContactWrapper>
                            {!!contacts?.length && (
                              <ExpandableBox
                                fullWidth
                                justify="space-between"
                                align="center"
                                fullPadding
                                spacing={Spacings.tiny}
                                direction="row"
                                $isContactsMenuOpen={isContactsMenuOpen}
                              >
                                <TextContainer textKey={Texts.ContactPeople} />
                                <StyledBox onClick={() => setIsContactsMenuOpen(!isContactsMenuOpen)}>
                                  <StyledSVGContainer SVG={getArrowSvg(isContactsMenuOpen)} />
                                </StyledBox>
                              </ExpandableBox>
                            )}
                            {isContactsMenuOpen && (
                              <ContactsWrapper fullWidth gap={Spacings.min} $isContactsMenuOpen={isContactsMenuOpen}>
                                {!!contacts?.length &&
                                  contacts.map(({ name, position, email }: ContactPerson) => (
                                    <CompanyContactPerson
                                      key={generateUUID()}
                                      name={name}
                                      position={position}
                                      email={email}
                                      onEdit={() => {
                                        handleContactEdit(contacts, setValue, email)
                                        setSelectedContactEmail(email)
                                      }}
                                      onDelete={() => handleContactDelete(contacts, setValue, email)}
                                    />
                                  ))}
                                {!contacts?.length && setIsContactsMenuOpen(false)}
                              </ContactsWrapper>
                            )}
                          </StyledBox>
                          <AutoCompleteChipController
                            name="website"
                            control={control}
                            labelText={getText(Texts.WebsiteAddress, language)}
                          />
                          <AutoCompleteChipController
                            name="projects"
                            control={control}
                            labelText={getText(Texts.ProjectNameAddress, language)}
                          />
                        </StyledBox>
                      </WidthLimitWrapper>
                    </FormColumn>
                    <FormColumn fullWidth fullPadding spacing={Spacings.tiny} gap={Spacings.large}>
                      <WidthLimitWrapper fullWidth gap={Spacings.min}>
                        <TextContainer textKey={Texts.InformationForPresentations} />
                        <EditorController
                          name="description"
                          labelTextKey={Texts.CompanyInformationPlaceholder}
                          control={control}
                        />
                      </WidthLimitWrapper>
                    </FormColumn>
                  </StyledBox>
                </StyledBox>
              </StyledBox>
            </StyledBox>
          </form>
        </Wrapper>
      </GenericPageModal>
    </>
  )
}

export default AddCompanyFormDesktop

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
`
const FormColumn = styled(StyledBox)`
  flex: 1;
`
const DocumentsTitleWrapper = styled(StyledBox)`
  border-bottom: 1px solid ${FigmaColors.grayLight};
`
const ExpandableBox = styled(StyledBox)<{ $isContactsMenuOpen?: boolean }>`
  background-color: ${FigmaColors.lightStone};
  height: ${Spacings.big};
  border-radius: ${({ $isContactsMenuOpen }) =>
    $isContactsMenuOpen ? `${Spacings.min} ${Spacings.min} 0 0` : Spacings.min};
`
const AddContactWrapper = styled(StyledBox)`
  background-color: ${FigmaColors.lightStone};
  border-radius: ${BorderRadius.soft};

  .MuiInputBase-root.MuiOutlinedInput-root {
    background-color: ${FigmaColors.white} !important;
  }
`
const StyledSVGContainer = styled(SVGContainer)`
  cursor: pointer;
`

const WidthLimitWrapper = styled(StyledBox)`
  max-width: ${DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH};
  min-width: ${DESKTOP_FORM_MULTILINE_INPUT_MAX_WIDTH};
`
const ContactsWrapper = styled(StyledBox)<{ $isContactsMenuOpen: boolean }>`
  border: ${({ $isContactsMenuOpen }) => $isContactsMenuOpen && `${Spacings.smallest} solid ${FigmaColors.lightStone}`};
  border-radius: 0 0 ${BorderRadius.rounded} ${BorderRadius.rounded};
  margin-top: -${Spacings.min};
  max-height: ${COMPANY_CONTACT_PEOPLE_WRAPPER_MAX_HEIGHT};
  overflow: auto;

  ::-webkit-scrollbar {
    width: ${Spacings.minimum};
  }
`
