import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import remindButtonIcon from '../figma/images/remindButtonIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { DEFAULT_MAX_AND_MIN_HEIGHT_REMINDER_BOX } from '../moesia/StrongHardCodedSizes'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton, { Variant } from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import MobileSubmitButton from './MobileSubmitButton'

type Props = {
  titleKey?: TextType
  descriptionKey?: TextType
  buttonVariant?: Variant
  buttonTextKey?: TextType
  alignButton?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'normal' | 'stretch'
  justifyBoxContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | 'stretch'
  minHeight?: string
  maxHeight?: string
  backgroundColor?: string
}

const ReminderBox: React.FC<Props> = ({
  titleKey,
  descriptionKey,
  buttonVariant,
  buttonTextKey,
  alignButton,
  justifyBoxContent,
  minHeight,
  maxHeight,
  backgroundColor
}) => {
  const isMobile = useIsMobile()

  return (
    <StyledBox fullWidth gap={Spacings.minimum}>
      {!!titleKey && <TextContainer textKey={titleKey} />}
      <Reminder
        fullWidth
        fullPadding={!isMobile}
        spacing={Spacings.small}
        gap={Spacings.min}
        justify={!!justifyBoxContent ? justifyBoxContent : 'space-between'}
        minHeight={minHeight}
        maxHeight={maxHeight}
        backgroundColor={backgroundColor}
        isMobile={isMobile}
      >
        {!!descriptionKey && <TextContainer textKey={descriptionKey} />}
        <StyledBox fullWidth align={!!alignButton ? alignButton : 'flex-end'}>
          {!isMobile && (
            <SubmitButton
              variant={!!buttonVariant ? buttonVariant : 'primary'}
              textKey={!!buttonTextKey ? buttonTextKey : Texts.RemindButtonText}
            />
          )}
          {isMobile && (
            <MobileSubmitButton
              textKey={!!buttonTextKey ? buttonTextKey : Texts.RemindButtonText}
              buttonIcon={remindButtonIcon}
              textBackgroundColor={FigmaColors.bhBlue}
              iconBackgroundColor={FigmaColors.bhBlueMedium}
              onClick={() => {
                console.log('this will lead to confirm modal')
                //TODO functionality
              }}
            />
          )}
        </StyledBox>
      </Reminder>
    </StyledBox>
  )
}

export default ReminderBox

const Reminder = styled(StyledBox)<{
  minHeight?: string
  maxHeight?: string
  backgroundColor?: string
  isMobile?: boolean
}>`
  min-height: ${({ minHeight }) => (!!minHeight ? minHeight : DEFAULT_MAX_AND_MIN_HEIGHT_REMINDER_BOX)};
  max-height: ${({ maxHeight }) => (!!maxHeight ? maxHeight : DEFAULT_MAX_AND_MIN_HEIGHT_REMINDER_BOX)};
  background-color: ${({ backgroundColor }) => (!!backgroundColor ? backgroundColor : FigmaColors.lightStone)};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${({ isMobile }) => !isMobile && Shadows.baseMedium};
`
