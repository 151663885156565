import React, { useMemo, useState } from 'react'
import { NavigationContent, getSidebarContent } from 'src/helpers/SidebarHelper'
import { generateUUID } from '../helpers/Common'
import { hasAccess } from '../helpers/UsersHelper'
import { useUser } from './AuthProvider'
import SidebarNavigationItem from './SidebarNavigationItem'

type Props = {
  handleCloseMenu?: () => void
}

const SidebarNavigationItems: React.FC<Props> = ({ handleCloseMenu }) => {
  const [showSubButtons, setShowSubButtons] = useState<boolean[]>([])
  const sidebarItems: NavigationContent[] = useMemo(() => getSidebarContent(), [])
  const currentUserRole = useUser().role

  return (
    <>
      {!!sidebarItems?.length &&
        sidebarItems.map(
          ({ icon, textKey, type, url, subButtons, section, permissions }, idx) =>
            (!permissions || (!!permissions.length && hasAccess(permissions, currentUserRole))) && (
              <SidebarNavigationItem
                key={generateUUID()}
                icon={icon}
                textKey={textKey}
                type={type}
                url={url}
                subButtons={subButtons}
                section={section}
                idx={idx}
                showSubButtons={showSubButtons}
                setShowSubButtons={setShowSubButtons}
                handleCloseMenu={handleCloseMenu}
              />
            )
        )}
    </>
  )
}

export default SidebarNavigationItems
