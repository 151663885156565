import React from 'react'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { AVATAR_COLOR, AVATAR_ROUND, AVATAR_SIZE } from '../moesia/StrongHardCodedSizes'
import StyledBox from '../moesia/components/StyledBox'

type Props = {
  name: string
  border?: boolean
  size?: string
}

const AvatarInitials: React.FC<Props> = ({ name, border, size }) => (
  <BorderProvider $border={border}>
    <Avatar name={name} alt={name} size={!!size ? size : AVATAR_SIZE} color={AVATAR_COLOR} round={AVATAR_ROUND} />
  </BorderProvider>
)

export default AvatarInitials

const BorderProvider = styled(StyledBox)<{ $border?: boolean }>`
  border: ${({ $border }) => $border && `${Spacings.minimum} solid ${FigmaColors.white}`};
  border-radius: ${({ $border }) => $border && BorderRadius.circle};
`
