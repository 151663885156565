import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { BLOG_POSTS_CATEGORIES } from '../consts/QueryKeys'
import { Texts, getText } from '../figma/helpers/TextRepository'
import { handleTrendingStatus } from '../helpers/BlogPostsHelper'
import { handleErrorMessage } from '../helpers/NotificationsHelper'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { useCategories, useCreateCategory, useDeleteCategory, useUpdateCategory } from '../helpers/QueryHooks'
import { createRequiredStringYupValidation } from '../helpers/ValidationHelper'
import useLanguage from '../hooks/useLanguage'
import { BlogPostCategory } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import BlogPostCategoriesDesktopView from './BlogPostCategoriesDesktopView'
import BlogPostCategoriesMobileView from './BlogPostCategoriesMobileView'

type Props = {
  setSubstate: (state: PageSubstate) => void
}

const BlogPostCategoriesContainer: React.FC<Props> = ({ setSubstate }) => {
  const queryClient = useQueryClient()
  const isMobile = useIsMobile()
  const language = useLanguage()
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [shouldShowDeleteConfirmationModal, setShouldShowDeleteConfirmationModal] = useState(false)
  const [shouldShowTrendingConfirmationModal, setShouldShowTrendingConfirmationModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<BlogPostCategory | null>(null)
  const updateCategoryMutation = useUpdateCategory(selectedCategory?._id ?? '')
  const createCategoryMutation = useCreateCategory()
  const deleteCategoryMutation = useDeleteCategory()
  const { data: categories = [] as BlogPostCategory[], isLoading } = useCategories()

  const defaultValues = useMemo(
    () => ({
      title: '',
      articles: ''
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: createRequiredStringYupValidation(getText(Texts.AddCategoryTitleErrorRequired, language))
      }),
    [language]
  )

  const { control, setValue, handleSubmit } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })

  const handleShowModal = () => setShowModal(!showModal)

  const handleShowForm = () => setShowForm(!showForm)

  const handleShowDeleteConfirmationModal = (selectedCategory?: BlogPostCategory) => {
    selectedCategory && setSelectedCategory(selectedCategory)
    setShouldShowDeleteConfirmationModal(!shouldShowDeleteConfirmationModal)
  }

  const handleShowTrendingConfirmationModal = (selectedCategory?: BlogPostCategory) => {
    selectedCategory && setSelectedCategory(selectedCategory)
    setShouldShowTrendingConfirmationModal(!shouldShowTrendingConfirmationModal)
  }

  const handleCreateCategory = (data: { [key: string]: any }) => {
    createCategoryMutation
      .mutateAsync(data)
      .then(() => {
        setValue('title', '')
        handleShowForm()
        successNotification(Texts.NotificationCategoryAddedSuccessful)
        setSubstate(PageSubstate.DEFAULT)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
        setSubstate(PageSubstate.ERROR)
      })
  }

  useEffect(
    () => () => {
      queryClient.invalidateQueries(BLOG_POSTS_CATEGORIES)
    },
    [queryClient]
  )

  const handleDeleteCategory = (selectedCategoryId: string) => {
    deleteCategoryMutation
      .mutateAsync(selectedCategoryId)
      .then(() => successNotification(Texts.NotificationDeletedCategorySuccessful))
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleTrendClick = () => {
    !!selectedCategory &&
      handleTrendingStatus(selectedCategory, updateCategoryMutation, handleShowTrendingConfirmationModal)
    handleShowTrendingConfirmationModal()
  }

  const handleDeleteClick = () => {
    !!selectedCategory && handleDeleteCategory(selectedCategory._id)
    handleShowDeleteConfirmationModal()
  }

  return (
    <>
      {!isMobile && (
        <BlogPostCategoriesDesktopView
          onCreate={handleSubmit(handleCreateCategory)}
          onDelete={handleDeleteClick}
          onTrend={handleTrendClick}
          control={control}
          categories={categories}
          areCategoriesLoading={isLoading}
          selectedCategory={selectedCategory}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmationModal={handleShowDeleteConfirmationModal}
          shouldShowTrendingConfirmationModal={shouldShowTrendingConfirmationModal}
          handleShowTrendingConfirmationModal={handleShowTrendingConfirmationModal}
          handleShowModal={handleShowModal}
          handleShowForm={handleShowForm}
          showForm={showForm}
          showModal={showModal}
        />
      )}
      {isMobile && (
        <BlogPostCategoriesMobileView
          onCreate={handleSubmit(handleCreateCategory)}
          onDelete={handleDeleteClick}
          onTrend={handleTrendClick}
          control={control}
          categories={categories}
          areCategoriesLoading={isLoading}
          selectedCategory={selectedCategory}
          shouldShowDeleteConfirmationModal={shouldShowDeleteConfirmationModal}
          handleShowDeleteConfirmationModal={handleShowDeleteConfirmationModal}
          shouldShowTrendingConfirmationModal={shouldShowTrendingConfirmationModal}
          handleShowTrendingConfirmationModal={handleShowTrendingConfirmationModal}
          handleShowModal={handleShowModal}
          handleShowForm={handleShowForm}
          showForm={showForm}
          showModal={showModal}
        />
      )}
    </>
  )
}

export default BlogPostCategoriesContainer
