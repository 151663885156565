import teamMember1Img from '../images/teamMember1.png'
export const teamMember1 = teamMember1Img
import userPhotoImg from '../images/userPhoto.png'
export const userPhoto = userPhotoImg
import companiesEmptyPhotoImg from '../images/companiesEmptyPhoto.png'
export const companiesEmptyPhoto = companiesEmptyPhotoImg
import loginPageImageImg from '../images/loginPageImage.png'
export const loginPageImage = loginPageImageImg
import overviewClientsPhotoImg from '../images/overviewClientsPhoto.png'
export const overviewClientsPhoto = overviewClientsPhotoImg
import overviewEmployeesPhotoImg from '../images/overviewEmployeesPhoto.png'
export const overviewEmployeesPhoto = overviewEmployeesPhotoImg
import overviewDocumentationPhotoImg from '../images/overviewDocumentationPhoto.png'
export const overviewDocumentationPhoto = overviewDocumentationPhotoImg
import emptyStatePhotoImg from '../images/emptyStatePhoto.png'
export const emptyStatePhoto = emptyStatePhotoImg
import noLogoImg from '../images/noLogo.png'
export const noLogo = noLogoImg
import noAvatarPhotoImg from '../images/noAvatarPhoto.png'
export const noAvatarPhoto = noAvatarPhotoImg
import somethingWentWrongPhotoImg from '../images/somethingWentWrongPhoto.png'
export const somethingWentWrongPhoto = somethingWentWrongPhotoImg
import emptyStateNewsCardsImg from '../images/emptyStateNewsCards.png'
export const emptyStateNewsCards = emptyStateNewsCardsImg
import somethingWentWrongPhotoNewsCardsImg from '../images/somethingWentWrongPhotoNewsCards.png'
export const somethingWentWrongPhotoNewsCards = somethingWentWrongPhotoNewsCardsImg
import somethingWentWrongPhotoAddImageImg from '../images/somethingWentWrongPhotoAddImage.png'
export const somethingWentWrongPhotoAddImage = somethingWentWrongPhotoAddImageImg
import lookingForInvestorsPhotoImg from '../images/lookingForInvestorsPhoto.png'
export const lookingForInvestorsPhoto = lookingForInvestorsPhotoImg
import referFriendPhotoImg from '../images/referFriendPhoto.png'
export const referFriendPhoto = referFriendPhotoImg
import followUsPhotoImg from '../images/followUsPhoto.png'
export const followUsPhoto = followUsPhotoImg
import teamBuildingPhotoImg from '../images/teamBuildingPhoto.png'
export const teamBuildingPhoto = teamBuildingPhotoImg
import buildingOurWebsitePhotoImg from '../images/buildingOurWebsitePhoto.png'
export const buildingOurWebsitePhoto = buildingOurWebsitePhotoImg
import newPositionsPhotoImg from '../images/newPositionsPhoto.png'
export const newPositionsPhoto = newPositionsPhotoImg
import buildingOurWebsitePhotoFlippedImg from '../images/buildingOurWebsitePhotoFlipped.png'
export const buildingOurWebsitePhotoFlipped = buildingOurWebsitePhotoFlippedImg
import newPositionsPhotoFlippedImg from '../images/newPositionsPhotoFlipped.png'
export const newPositionsPhotoFlipped = newPositionsPhotoFlippedImg
import lookingForInvestorsPhotoFlippedImg from '../images/lookingForInvestorsPhotoFlipped.png'
export const lookingForInvestorsPhotoFlipped = lookingForInvestorsPhotoFlippedImg
import followUsPhotoFlippedImg from '../images/followUsPhotoFlipped.png'
export const followUsPhotoFlipped = followUsPhotoFlippedImg
import referFriendPhotoFlippedImg from '../images/referFriendPhotoFlipped.png'
export const referFriendPhotoFlipped = referFriendPhotoFlippedImg
import teamBuildingPhotoFlippedImg from '../images/teamBuildingPhotoFlipped.png'
export const teamBuildingPhotoFlipped = teamBuildingPhotoFlippedImg
import formBackgroundMobileImg from '../images/formBackgroundMobile.png'
export const formBackgroundMobile = formBackgroundMobileImg
import allEmployeesPhotoImg from '../images/allEmployeesPhoto.png'
export const allEmployeesPhoto = allEmployeesPhotoImg
import employeeDetailsMobileBackgroundImg from '../images/employeeDetailsMobileBackground.png'
export const employeeDetailsMobileBackground = employeeDetailsMobileBackgroundImg
import allCompaniesPhotoImg from '../images/allCompaniesPhoto.png'
export const allCompaniesPhoto = allCompaniesPhotoImg
import companyDetailsMobileBackgroundImg from '../images/companyDetailsMobileBackground.png'
export const companyDetailsMobileBackground = companyDetailsMobileBackgroundImg
import overviewBackgroundMobileImg from '../images/overviewBackgroundMobile.png'
export const overviewBackgroundMobile = overviewBackgroundMobileImg
import somethingWentWrongPhotoMobileImg from '../images/somethingWentWrongPhotoMobile.png'
export const somethingWentWrongPhotoMobile = somethingWentWrongPhotoMobileImg
import addTemplateImageImg from '../images/addTemplateImage.png'
export const addTemplateImage = addTemplateImageImg
import formBackgroundTervelImageImg from '../images/formBackgroundTervelImage.png'
export const formBackgroundTervelImage = formBackgroundTervelImageImg
import formBackgroundTervelNewsCardImg from '../images/formBackgroundTervelNewsCard.png'
export const formBackgroundTervelNewsCard = formBackgroundTervelNewsCardImg
import vectorImg from '../images/vector.png'
export const vector = vectorImg
import noAccessImageImg from '../images/noAccessImage.png'
export const noAccessImage = noAccessImageImg
import thereAreNoImagesToDisplayImg from '../images/thereAreNoImagesToDisplay.png'
export const thereAreNoImagesToDisplay = thereAreNoImagesToDisplayImg
import deleteIconImg from '../images/deleteIcon.png'
export const deleteIcon = deleteIconImg
import allUsersPhotoImg from '../images/allUsersPhoto.png'
export const allUsersPhoto = allUsersPhotoImg
import userDetailsMobileBackgroundImg from '../images/userDetailsMobileBackground.png'
export const userDetailsMobileBackground = userDetailsMobileBackgroundImg
import blogPostImageImg from '../images/blogPostImage.png'
export const blogPostImage = blogPostImageImg
import addPreviewContentErrorRequiredImg from '../images/addPreviewContentErrorRequired.png'
export const addPreviewContentErrorRequired = addPreviewContentErrorRequiredImg
import addCategoryTitleErrorRequiredImg from '../images/addCategoryTitleErrorRequired.png'
export const addCategoryTitleErrorRequired = addCategoryTitleErrorRequiredImg
import invalidLinkedinProfileImg from '../images/invalidLinkedinProfile.png'
export const invalidLinkedinProfile = invalidLinkedinProfileImg
import adddPreviewLabelImg from '../images/adddPreviewLabel.png'
export const adddPreviewLabel = adddPreviewLabelImg
import addThumbnailErrorRequiredImg from '../images/addThumbnailErrorRequired.png'
export const addThumbnailErrorRequired = addThumbnailErrorRequiredImg
import adddThumbnailLabelImg from '../images/adddThumbnailLabel.png'
export const adddThumbnailLabel = adddThumbnailLabelImg
import modalInputImg from '../images/modalInput.png'
export const modalInput = modalInputImg
import buttonPrimaryImg from '../images/buttonPrimary.png'
export const buttonPrimary = buttonPrimaryImg
import blogPostEditLabelImg from '../images/blogPostEditLabel.png'
export const blogPostEditLabel = blogPostEditLabelImg
import inputImg from '../images/input.png'
export const input = inputImg
import yourBlogPostLabelImg from '../images/yourBlogPostLabel.png'
export const yourBlogPostLabel = yourBlogPostLabelImg
import dividingLineImg from '../images/dividingLine.png'
export const dividingLine = dividingLineImg
import addEmployeeInputImg from '../images/addEmployeeInput.png'
export const addEmployeeInput = addEmployeeInputImg
import postInTervelImg from '../images/postInTervel.png'
export const postInTervel = postInTervelImg
import hiddenImg from '../images/hidden.png'
export const hidden = hiddenImg
import addImageNewsCardsImg from '../images/addImageNewsCards.png'
export const addImageNewsCards = addImageNewsCardsImg
import formBackgroundTervelBlogPostsImg from '../images/formBackgroundTervelBlogPosts.png'
export const formBackgroundTervelBlogPosts = formBackgroundTervelBlogPostsImg
import archivedEmployeesPhotoImg from '../images/archivedEmployeesPhoto.png'
export const archivedEmployeesPhoto = archivedEmployeesPhotoImg
import rectangleImg from '../images/rectangle.png'
export const rectangle = rectangleImg
