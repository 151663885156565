import styled from 'styled-components'
import { ImagesType } from '../enums/ImagesType'
import { Texts } from '../figma/helpers/TextRepository'
import Spacings from '../figma/tokens/Spacings'
import { TervelImage } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import ImageItemsContainer from './ImageItemsContainer'
import ImagesFilterContainer from './ImagesFilterContainer'

type Props = {
  images: TervelImage[]
  onAddImageClick: () => void
  filterType: ImagesType | null
  showFilterOptions: boolean
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>
  setFilterType: React.Dispatch<React.SetStateAction<ImagesType | null>>
}

const ImagesSectionDesktop: React.FC<Props> = ({
  images,
  onAddImageClick,
  filterType,
  showFilterOptions,
  setShowFilterOptions,
  setFilterType
}) => (
  <>
    <ImagesFilterContainer
      showFilterOptions={showFilterOptions}
      setShowFilterOptions={setShowFilterOptions}
      filterType={filterType}
      setFilterType={setFilterType}
    />
    {!!images?.length && (
      <StyledBox fullWidth flexWrap="wrap" direction="row" align="flex-start" gap={Spacings.large} top bottom>
        <ImageItemsContainer images={images} filterType={filterType} />
      </StyledBox>
    )}
    <StyledSubmitButton position="fixed">
      <SubmitButton onClick={onAddImageClick} textKey={Texts.AddImageButtonText} variant="primary" />
    </StyledSubmitButton>
  </>
)

export default ImagesSectionDesktop

const StyledSubmitButton = styled(StyledBox)`
  bottom: ${Spacings.large};
  right: ${Spacings.large};
`
