import React from 'react'
import routePaths from 'src/config/RoutePaths'
import { Texts } from 'src/figma/helpers/TextRepository'
import { Images } from 'src/figma/helpers/imageHelper'
import Spacings from 'src/figma/tokens/Spacings'
import ImageContainer from 'src/moesia/components/ImageContainer'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import TextContainer from 'src/moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import styled from 'styled-components'

const ErrorBoundaryPage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth align="center" justify="center" gap={Spacings.large}>
      <ImageContainer
        imageKey={isMobile ? Images.somethingWentWrongPhotoMobile : Images.somethingWentWrongPhoto}
        minHeight
      />
      <StyledBox align="center" gap={Spacings.tiny}>
        <TextContainer textKey={Texts.ErrorBoundaryHeading} />
        <TextContainer textKey={Texts.ErrorBoundarySubheading} />
      </StyledBox>
      <SubmitButton
        variant="primary"
        textKey={Texts.ErrorBoundaryButtonText}
        onClick={() => {
          window.location.pathname = routePaths.entry
        }}
      />
    </Wrapper>
  )
}
export default React.memo(ErrorBoundaryPage)

const Wrapper = styled(StyledBox)`
  height: 100vh;
`
