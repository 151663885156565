import React from 'react'
import { Line } from './Line'
import styled from 'styled-components'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import TextContainer from 'src/moesia/components/TextContainer'
import { SIDEBAR_BUTTON_HEIGHT, SIDEBAR_DIVIDER_HEIGHT } from 'src/moesia/StrongHardCodedSizes'

type DividerProps = {
  textKey: TextType
}

const SidebarDivider: React.FC<DividerProps> = ({ textKey }) => (
  <DividerWrapper fullWidth justify="flex-end" gap={Spacings.smallest} bottom={Spacings.minimum}>
    <Line fullWidth />
    <GeneralTextWrapper fullWidth left={Spacings.tiny} justify="center">
      <TextContainer textKey={textKey} />
    </GeneralTextWrapper>
  </DividerWrapper>
)

export default SidebarDivider

const DividerWrapper = styled(StyledBox)`
  height: ${SIDEBAR_DIVIDER_HEIGHT};
`

const GeneralTextWrapper = styled(StyledBox)`
  height: ${SIDEBAR_BUTTON_HEIGHT};
`
