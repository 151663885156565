import { useQuery } from 'react-query'
import useCreateMutation from 'src/hooks/useCreateMutation'
import useMutation from 'src/hooks/useMutation'
import usePatchMutation from 'src/hooks/usePatchMutation'
import { AWS_S3_FILE_SIGNED_URL_EXPIRY_TIME } from '../Constants'
import {
  ARCHIVED_COMPANIES,
  ARCHIVED_USERS,
  BLOG_POST,
  BLOG_POSTS,
  BLOG_POSTS_CATEGORIES,
  BLOG_POST_CATEGORY,
  COMPANIES,
  COMPANY,
  DOCUMENT,
  DOCUMENTS,
  EMPLOYEES,
  NEWS_CARD,
  NEWS_CARDS,
  OVERVIEW,
  TEMPLATE,
  TEMPLATES,
  TERVEL_IMAGES,
  USER,
  USERS
} from '../consts/QueryKeys'
import { UserType } from '../enums/UserType'
import useDeleteMutation from '../hooks/useDeleteMutation'
import {
  createBlogPost,
  createCategory,
  createCompany,
  createNewsCard,
  createUser,
  deleteBlogPostById,
  deleteCategoryById,
  deleteCompanyById,
  deleteDocumentById,
  deleteImageById,
  deleteNewsCardById,
  deleteTemplateById,
  deleteUserById,
  forgotPassword,
  getBlogPostById,
  getBlogPosts,
  getCategories,
  getCompanies,
  getCompanyById,
  getDocumentById,
  getDocuments,
  getNewsCards,
  getOverview,
  getTemplateById,
  getTemplates,
  getTervelImages,
  getUserById,
  getUsers,
  inviteUser,
  refreshToken,
  resetPassword,
  signin,
  updateBlogPostById,
  updateCategory,
  updateCompany,
  updateNewsCard,
  updateTemplateById,
  updateUser,
  uploadDocument,
  uploadTemplate,
  uploadTervelImage
} from './ApiHandler'
import { getArchivedCompanies, getArchivedEmployees, getArchivedUsers } from './ProcessHandler'

export const useCompanies = (filter?: any) => useQuery([COMPANIES], () => getCompanies(filter))
export const useCreateCompany = () => useCreateMutation([COMPANIES], (req) => createCompany(req), [COMPANIES])
export const useArchivedCompanies = () => useQuery([ARCHIVED_COMPANIES], () => getArchivedCompanies())
export const useCompany = (companyId: string) => useQuery([COMPANY, companyId], () => getCompanyById(companyId))
export const useDeleteCompany = () =>
  useDeleteMutation([COMPANY], (companyId: string) => deleteCompanyById(companyId), [
    COMPANIES,
    COMPANY,
    ARCHIVED_COMPANIES
  ])
export const useUpdateCompany = (companyId: string) =>
  usePatchMutation([COMPANY, companyId], (req) => updateCompany(companyId, req), [
    COMPANY,
    COMPANIES,
    ARCHIVED_COMPANIES,
    EMPLOYEES,
    USERS,
    companyId
  ])
export const useLogin = () => useMutation([USER], (req: any) => signin(req))
export const useRefreshCredentials = () => useQuery([USER], (req) => refreshToken(req))
export const useOverview = (filter: any) => useQuery([OVERVIEW], () => getOverview(filter))
export const useUsers = (filter?: any) => useQuery([USERS], () => getUsers(filter))
export const useArchivedUsers = () => useQuery([ARCHIVED_USERS], () => getArchivedUsers())
export const useEmployees = (filter?: any) =>
  useQuery([EMPLOYEES], () => getUsers({ role: UserType.EMPLOYEE, ...(filter || {}) }))
export const useArchivedEmployees = () => useQuery([ARCHIVED_USERS], () => getArchivedEmployees())
export const useUpdateUser = (userId: string) =>
  usePatchMutation([USER, userId], (req) => updateUser(userId, req), [
    USER,
    USERS,
    EMPLOYEES,
    ARCHIVED_USERS,
    COMPANIES,
    userId
  ])
export const useCreateUser = () =>
  useCreateMutation([USERS, EMPLOYEES], (req) => createUser(req), [USERS, EMPLOYEES, COMPANIES])
export const useDeleteUser = () =>
  useDeleteMutation([USER], (userId: string) => deleteUserById(userId), [
    USERS,
    USER,
    ARCHIVED_USERS,
    EMPLOYEES,
    COMPANIES
  ])
export const useInviteUser = (userId: string) =>
  useCreateMutation([USERS, EMPLOYEES], (req) => inviteUser(userId, req), [USERS, EMPLOYEES])
export const useUser = (userId: string) => useQuery([USER, userId], () => getUserById(userId))

export const useUploadDocument = () =>
  useCreateMutation([DOCUMENTS], (req: FormData & { userId?: string; companyId?: string }) => uploadDocument(req), [
    DOCUMENTS
  ])
export const useDocuments = (filter?: any, options?: any) =>
  useQuery([DOCUMENTS], () => getDocuments(filter), {
    ...options,
    staleTime: options?.staleTime || AWS_S3_FILE_SIGNED_URL_EXPIRY_TIME,
    cacheTime: options?.cacheTime || AWS_S3_FILE_SIGNED_URL_EXPIRY_TIME
  })
export const useDocument = (documentId: string) => useQuery([DOCUMENT, documentId], () => getDocumentById(documentId))
export const useDeleteDocument = () =>
  useDeleteMutation([DOCUMENT], (documentId: string) => deleteDocumentById(documentId), [
    DOCUMENTS,
    DOCUMENT,
    EMPLOYEES,
    USER,
    COMPANY,
    COMPANIES
  ])
export const useUploadTemplate = () =>
  useCreateMutation([TEMPLATES], (req: FormData) => uploadTemplate(req), [TEMPLATES])
export const useTemplates = (filter?: any) => useQuery([TEMPLATES], () => getTemplates(filter))
export const useTemplate = (templateId: string) => useQuery([TEMPLATE, templateId], () => getTemplateById(templateId))
export const useUpdateTemplate = (templateId: string) =>
  usePatchMutation([TEMPLATE, templateId], (req: FormData) => updateTemplateById(templateId, req), [
    TEMPLATE,
    TEMPLATES
  ])
export const useDeleteTemplate = () =>
  useDeleteMutation([TEMPLATE], (templateId: string) => deleteTemplateById(templateId), [TEMPLATES, TEMPLATE])
export const useForgotPassword = () => useMutation([], (req: { [key: string]: string }) => forgotPassword(req))
export const useResetPassword = (resetPasswordCode: string) =>
  usePatchMutation([resetPasswordCode], (req: { [key: string]: string }) => resetPassword(resetPasswordCode, req), [
    resetPasswordCode
  ])
export const useCreateNewsCard = () => useCreateMutation([NEWS_CARDS], (req) => createNewsCard(req), [NEWS_CARDS])
export const useDeleteNewsCard = () =>
  useDeleteMutation([NEWS_CARD], (newsCardId: string) => deleteNewsCardById(newsCardId), [NEWS_CARDS, NEWS_CARD])
export const useUpdateNewsCard = (newsCardId: string) =>
  usePatchMutation([NEWS_CARD, newsCardId], (req) => updateNewsCard(newsCardId, req), [
    NEWS_CARD,
    NEWS_CARDS,
    newsCardId
  ])
export const useNewsCards = (filter?: any) => useQuery([NEWS_CARDS], () => getNewsCards(filter))
export const useUploadTervelImage = () =>
  useCreateMutation([TERVEL_IMAGES], (req: FormData) => uploadTervelImage(req), [TERVEL_IMAGES])
export const useDeleteImage = () =>
  useDeleteMutation([TERVEL_IMAGES], (imageId: string) => deleteImageById(imageId), [TERVEL_IMAGES])
export const useTervelImages = (filter?: any) => useQuery([TERVEL_IMAGES], () => getTervelImages(filter))
export const useCreateBlogPost = () => useCreateMutation([BLOG_POSTS], (req) => createBlogPost(req), [BLOG_POSTS])
export const useBlogPosts = (filter?: any) => useQuery([BLOG_POSTS], () => getBlogPosts(filter))
export const useBlogPost = (blogPostId: string) => useQuery([BLOG_POST, blogPostId], () => getBlogPostById(blogPostId))
export const useDeleteBlogPost = () =>
  useDeleteMutation([BLOG_POSTS, BLOG_POST], (blogPostId: string) => deleteBlogPostById(blogPostId), [
    BLOG_POSTS,
    BLOG_POST
  ])
export const useUpdateBlogPost = (blogPostId: string) =>
  usePatchMutation([BLOG_POST, blogPostId], (req) => updateBlogPostById(blogPostId, req), [
    BLOG_POST,
    BLOG_POSTS,
    BLOG_POSTS_CATEGORIES,
    blogPostId
  ])
export const useCreateCategory = () =>
  useCreateMutation([BLOG_POSTS_CATEGORIES], (req) => createCategory(req), [BLOG_POSTS_CATEGORIES])
export const useUpdateCategory = (categoryId: string) =>
  usePatchMutation([BLOG_POSTS_CATEGORIES, BLOG_POST_CATEGORY, categoryId], (req) => updateCategory(categoryId, req), [
    BLOG_POSTS_CATEGORIES,
    BLOG_POST_CATEGORY,
    BLOG_POSTS,
    categoryId
  ])
export const useDeleteCategory = () =>
  useDeleteMutation(
    [BLOG_POSTS_CATEGORIES, BLOG_POST_CATEGORY],
    (categoryId: string) => deleteCategoryById(categoryId),
    [BLOG_POSTS_CATEGORIES, BLOG_POST_CATEGORY]
  )
export const useCategories = (filter?: any) => useQuery([BLOG_POSTS_CATEGORIES], () => getCategories(filter))
