import { FieldValues, UseFormSetError } from 'react-hook-form'

export const handleFieldValidationByErrorCode = (
  errorCode: number,
  setError: UseFormSetError<FieldValues>,
  message: string
) => {
  switch (errorCode) {
    case 0:
      return setError('email', {
        type: 'custom',
        message: message
      })

    default:
      return
  }
}
