import dayjs from 'dayjs'
import * as Yup from 'yup'
import { linkedinProfileRegex } from '../Constants'
import { UserType } from '../enums/UserType'
import { isUserCustomer, isUserEmployee } from './UsersHelper'

export const createRequiredStringYupValidation = (validationText: string) => Yup.string().required(validationText)

export const createStartingDateYupValidation = (validationText: string) =>
  Yup.mixed().when('role', {
    is: (role: UserType) => isUserEmployee(role) || isUserCustomer(role),
    then: Yup.string()
      .nullable()
      .required(validationText)
      .test('startingDate', validationText, (val) => Boolean(dayjs(val).isValid() && val))
  })

export const createConfirmPasswordYupValidation = (validationRequiredText: string, validationTextNotMatching: string) =>
  Yup.string()
    .oneOf([Yup.ref('password'), null], validationTextNotMatching)
    .required(validationRequiredText)

export const createRequiredDateYupValidation = (validationText: string, inputName: string) =>
  Yup.string()
    .required()
    .test(inputName, validationText, (val) => Boolean(dayjs(val).isValid() && val))

export const createNotRequiredDateYupValidation = (validationText: string, inputName: string) =>
  Yup.mixed()
    .notRequired()
    .test(inputName, validationText, (val) => dayjs(val).isValid() || !val)

export const createEmailYupValidation = (requiredText: string, invalidText?: string) =>
  Yup.string().email(invalidText).required(requiredText)

export const createLinkedInYupValidation = (validationMessage: string) =>
  Yup.string().matches(linkedinProfileRegex, { message: validationMessage, excludeEmptyString: true })
