import styled from 'styled-components'
import FigmaColors from '../figma/tokens/FigmaColors'
import SubmitButton, { Variant } from '../moesia/components/SubmitButton'
import { TextType } from '../moesia/interfaces/FigmaTypes'

type Props = {
  onClick: () => void
  isArchived: boolean
  variant?: Variant
  archiveTextKey: TextType
  activateTextKey: TextType
}

const ToggleArchiveButton: React.FC<Props> = ({ onClick, variant, isArchived, archiveTextKey, activateTextKey }) => (
  <StyledButton
    onClick={onClick}
    variant={variant ? variant : 'primary'}
    textKey={isArchived ? activateTextKey : archiveTextKey}
    $isShowingArchived={isArchived}
  />
)

export default ToggleArchiveButton

const StyledButton = styled(SubmitButton)<{ $isShowingArchived: boolean }>`
  div {
    background-color: ${({ $isShowingArchived }) =>
      $isShowingArchived ? FigmaColors.baseGreen : FigmaColors.lighterRed};
  }

  &:hover {
    div {
      background-color: ${({ $isShowingArchived }) =>
        $isShowingArchived ? FigmaColors.baseGreen : FigmaColors.lighterRed};
    }
  }
`
