import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import { SIDEBAR_WIDTH } from '../moesia/StrongHardCodedSizes'
import { Line } from './Line'
import Sidebar from './Sidebar'

type Props = {
  showHeading?: boolean
  pageHeading?: TextType
  pageSubheading?: TextType
  content?: React.ReactNode
}

const BackOfficeLayoutDesktop: React.FC<Props> = ({ showHeading, pageHeading, pageSubheading, content }) => (
  <InnerWrapper fullWidth direction="row">
    <Sidebar />
    <Page fullWidth>
      {showHeading && pageHeading && pageSubheading && (
        <StyledBox fullWidth fullPadding spacing={Spacings.big} gap={Spacings.min}>
          <TextContainer textKey={pageHeading} />
          <TextContainer textKey={pageSubheading} />
          <Line fullWidth />
        </StyledBox>
      )}

      <Content fullWidth left right align="center" spacing={Spacings.big}>
        {content}
      </Content>
    </Page>
  </InnerWrapper>
)

export default BackOfficeLayoutDesktop

const InnerWrapper = styled(StyledBox)`
  overflow-y: auto;
  overflow-x: hidden;
`

const Page = styled(StyledBox)`
  background: ${FigmaColors.backgroundGazelle};
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: ${SIDEBAR_WIDTH};
`
const Content = styled(StyledBox)`
  height: 100%;
`
