export const SV = 'sv'
export const BG = 'bg'
export const EN = 'en'
export const USERS = 'Users'
export const LOGIN = 'Login'
export const OVERVIEW = 'Overview'
export const COMPANIES = 'Companies'
export const EMPLOYEES = 'Employees'
export const TERVEL_IMAGES = 'Tervel Images'
export const TERVEL_NEWS_CARDS = 'Tervel News Cards'
export const TERVEL_BLOG_POSTS = 'Tervel Blog Posts'
export const TERVEL_BLOG_POST = 'Tervel Blog Post'
export const TEMPLATES = 'Templates'
export const DATE_ORDINAL_FORMAT = 'Do MMM YYYY'
export const MAX_FILE_SIZE_ACCEPTED = 5242880 //DO NOT CHANGE This is 5MB into bytes and is used to check uploaded file's size
export const AWS_S3_FILE_SIGNED_URL_EXPIRY_TIME = 3600000 // 1 hour in milliseconds
export const DELETED_COMPANY_NAME = 'Deleted company'
export const DELETED_USER_NAME = 'Deleted user'
export const linkedinProfileRegex = /^(https?:\/\/)(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+\/?$/
