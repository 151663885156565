import { useMemo } from 'react'
import { ImagesType } from '../enums/ImagesType'
import { getImageTypes } from '../helpers/ImageTypesHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import ImagesFilterDesktop from './ImagesFilterDesktop'
import ImagesFilterMobile from './ImagesFilterMobile'

type Props = {
  setFilterType: React.Dispatch<React.SetStateAction<ImagesType | null>>
  showFilterOptions: boolean
  filterType: ImagesType | null
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>
}

const ImagesFilterContainer: React.FC<Props> = ({
  showFilterOptions,
  setShowFilterOptions,
  filterType,
  setFilterType
}) => {
  const isMobile = useIsMobile()
  const filterOptions = useMemo(() => getImageTypes(true), [])

  return (
    <>
      {!isMobile && (
        <ImagesFilterDesktop
          filterType={filterType}
          setFilterType={setFilterType}
          filterOptions={filterOptions}
          showFilterOptions={showFilterOptions}
          setShowFilterOptions={setShowFilterOptions}
        />
      )}
      {isMobile && (
        <ImagesFilterMobile
          filterType={filterType}
          setFilterType={setFilterType}
          filterOptions={filterOptions}
          showFilterOptions={showFilterOptions}
          setShowFilterOptions={setShowFilterOptions}
        />
      )}
    </>
  )
}

export default ImagesFilterContainer
