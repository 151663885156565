import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import { Images } from '../figma/helpers/imageHelper'
import activeIcon from '../figma/images/activeIcon'
import archiveIcon from '../figma/images/archiveIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import ZIndex from '../figma/tokens/ZIndex'
import { Position } from '../helpers/AddEmployeeFormHelper'
import { CompanyOptionsType, RolesType } from '../helpers/UserTypesHelper'
import { Company } from '../interfaces/Dto'
import EditorController from '../moesia/components/EditorController'
import ImageContainer from '../moesia/components/ImageContainer'
import {
  AutoCompleteController,
  DatePickerController,
  SelectChipController
} from '../moesia/components/ReactHookComponents'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextFieldController from '../moesia/components/TextFieldController'
import AvatarInitials from './AvatarInitials'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'
import UserAdditionalProfileFields from './UserAdditionalProfileFields'

type Props = {
  open: boolean
  onClose: () => void
  handleSubmit: () => void
  control: Control<FieldValues, any>
  positionOptions: Position[]
  companies: Company[]
  companyOptions: CompanyOptionsType
  name: string
  roles: RolesType
  plan: JSON
  notes: JSON
  goals: JSON
  archived: boolean
  values: FieldValues
  isEmployeeOrCustomer: boolean
  shouldShowConfirmationModal: boolean
  handleClose: () => void
  isValuesChanged?: boolean
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  isNotRegularUser: boolean
}

const EditUserFormMobileView: React.FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  control,
  positionOptions,
  companies,
  companyOptions,
  name,
  roles,
  plan,
  notes,
  goals,
  archived,
  values,
  isEmployeeOrCustomer,
  shouldShowConfirmationModal,
  handleClose,
  isValuesChanged,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  isNotRegularUser
}) => (
  <>
    {shouldShowArchiveStatusChangeConfirmation && (
      <GenericConfirmationModal
        open
        onClose={handleShowArchiveStatusChangeConfirmation}
        onConfirm={onArchiveStatusChangeConfirm}
        confirmationTitle={!!archived ? Texts.ConfirmActivateEmployeeTitle : Texts.ConfirmArchiveEmployeeTitle}
        confirmationQuestion={
          !!archived ? Texts.ConfirmActivateEmplpyeeProfileQuestion : Texts.ConfirmArchiveEmployeeQuestion
        }
        confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
        confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
        smallSize
      />
    )}
    {shouldShowConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={onClose}
        onConfirm={handleSubmit}
        confirmationTitle={Texts.UnsavedChangesModalTitle}
        confirmationQuestion={Texts.UnsavedChangesModalQuestion}
        confirmButtonTextKey={Texts.SaveAndLeaveButtonText}
        cancelButtonTextKey={Texts.ConfirmLeaveChangesButtonText}
        cancelButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    <GenericPageModal
      open={open}
      onClose={handleClose}
      buttons={
        <ModalButtons
          primaryButton={
            <SubmitButton
              variant="primary"
              textKey={Texts.ApplyChangesButtonTextMobile}
              onClick={handleSubmit}
              disabled={!isValuesChanged}
            />
          }
          tertiaryButton={
            isNotRegularUser && (
              <MobileSubmitButton
                textKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ArchiveButtonText}
                buttonIcon={!!archived ? activeIcon : archiveIcon}
                textBackgroundColor={!!archived ? FigmaColors.green1 : FigmaColors.darkerRed}
                iconBackgroundColor={!!archived ? FigmaColors.green2 : FigmaColors.lighterRed}
                onClick={handleShowArchiveStatusChangeConfirmation}
              />
            )
          }
        />
      }
    >
      <StyledBox fullWidth gap={Spacings.big} align="center">
        <StyledBox fullWidth align="center" position="relative">
          <StyledImageContainer imageKey={Images.employeeDetailsMobileBackground} />
          <StyledGenericAvatar position="absolute">
            <AvatarInitials name={name} border />
          </StyledGenericAvatar>
        </StyledBox>
        <StyledBox fullWidth left right bottom spacing={Spacings.large} gap={Spacings.small}>
          <TextFieldController name="name" control={control} labelText="Name" />
          <TextFieldController name="email" control={control} labelText="Email" />
          <AutoCompleteController
            name="position"
            control={control}
            options={positionOptions}
            labelTextKey={Texts.AddEmployeePositionPlaceholder}
          />
          <SelectChipController
            name="companies"
            defaultValue={companies.map((company) => company._id)}
            control={control}
            options={companyOptions}
            labelText="Company"
          />
          <AutoCompleteController name="role" control={control} options={roles} labelText="User role" />
          {isEmployeeOrCustomer && <UserAdditionalProfileFields control={control} plan={plan} role={values.role} />}
          <EditorController name="goals" labelTextKey={Texts.GoalsPlaceholder} control={control} defaultValue={goals} />
          <EditorController name="notes" labelTextKey={Texts.NotesPlaceholder} control={control} defaultValue={notes} />
          <DatePickerController
            name="birthDate"
            control={control}
            labelTextKey={Texts.AddEmployeeBirthDatePlaceholder}
          />
        </StyledBox>
      </StyledBox>
    </GenericPageModal>
  </>
)

export default EditUserFormMobileView

const StyledImageContainer = styled(ImageContainer)`
  z-index: ${ZIndex.negative};
  display: flex;
  min-width: 100%;
  img {
    width: 100%;
  }
`
const StyledGenericAvatar = styled(StyledBox)`
  bottom: 10%;
`
