import React, { useContext } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import Spacings from 'src/figma/tokens/Spacings'
import { DETAILS_MAX_WIDTH, USER_DETAILS_WIDTH } from 'src/moesia/StrongHardCodedSizes'
import StyledBox from 'src/moesia/components/StyledBox'
import TextContainer from 'src/moesia/components/TextContainer'
import styled from 'styled-components'
import { UserType } from '../enums/UserType'
import invitationAcceptedIcon from '../figma/images/invitationAcceptedIcon'
import invitationPendingIcon from '../figma/images/invitationPendingIcon'
import remindButtonIcon from '../figma/images/remindButtonIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import { getEmployeeDetailsItems } from '../helpers/EmployeeDetailsHelper'
import { PageSubstate, PageSubstateContext } from '../helpers/PageSubstateHelper'
import { hasAccess } from '../helpers/UsersHelper'
import { User } from '../interfaces/Dto'
import AvatarInitials from './AvatarInitials'
import ConditionalDetailsFormsButtonDesktop from './ConditionalDetailsFormsButtonDesktop'
import EmployeeDetailsItemDesktop from './EmployeeDetailsItemDesktop'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import { Line } from './Line'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'
import ToggleArchiveButton from './ToggleArchiveButton'

type Props = {
  open: boolean
  onClose: () => void
  employee: User
  handleDeleteButton: () => void
  startingDateText: string
  positionAndCompany: string
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
  currentUserRole: UserType
  isNotRegularUser: boolean
  handleRemindButton: () => void
  isInvitationAccepted: boolean
}

const EmployeeDetailsDesktop: React.FC<Props> = ({
  open,
  onClose,
  employee,
  startingDateText,
  positionAndCompany,
  handleRemindButton,
  isInvitationAccepted,
  handleDeleteButton,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation,
  currentUserRole,
  isNotRegularUser
}) => {
  const { setSubstate } = useContext(PageSubstateContext)
  const { archived, invitationStatus } = employee
  const handleEditButton = () => {
    setSubstate(PageSubstate.EDIT)
  }

  const employeeDetailsItems = getEmployeeDetailsItems(employee, positionAndCompany)

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteEmployeeTitle}
          confirmationQuestion={Texts.ConfirmDeleteEmployeeQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateEmployeeTitle : Texts.ConfirmArchiveEmployeeTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateEmplpyeeProfileQuestion : Texts.ConfirmArchiveEmployeeQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
        />
      )}
      <GenericPageModal
        open={open}
        onClose={onClose}
        buttons={
          isNotRegularUser && (
            <ModalButtons
              primaryButton={
                <ConditionalDetailsFormsButtonDesktop
                  isArchived={!!archived}
                  onDeleteClick={handleShowDeleteConfirmation}
                  onEditClick={handleEditButton}
                />
              }
              tertiaryButton={
                <ToggleArchiveButton
                  activateTextKey={Texts.ActivateButtonTextModal}
                  archiveTextKey={Texts.ArchiveButtonTextModal}
                  onClick={handleShowArchiveStatusChangeConfirmation}
                  isArchived={archived}
                />
              }
            />
          )
        }
        overflowX="hidden"
      >
        <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
          <StyledBox fullWidth direction="row" gap={Spacings.medium}>
            <AvatarInitials name={employee.name} />
            <StyledBox fullWidth top spacing={Spacings.tiny} gap={Spacings.medium}>
              <StyledBox fullWidth gap={Spacings.minimum}>
                <TextContainer textKey={Texts.EmployeeDetailsName} text={employee.name} />
                <TextContainer textKey={Texts.EmployeeDetailsStartDate} text={startingDateText} />
                <Line fullWidth />
              </StyledBox>
              {!!employeeDetailsItems.length &&
                employeeDetailsItems.map(
                  ({ itemIcon, itemTitle, itemInfo, permissions }, index) =>
                    (!permissions || hasAccess(permissions, currentUserRole)) && (
                      <EmployeeDetailsItemDesktop
                        key={index}
                        itemIcon={itemIcon}
                        itemTitle={itemTitle}
                        itemInfo={itemInfo as JSX.Element | string}
                      />
                    )
                )}
              {!archived && (
                <EmployeeDetailsItemDesktop
                  itemIcon={isInvitationAccepted ? invitationAcceptedIcon : invitationPendingIcon}
                  itemTitle={Texts.EmployeeDetailsInvitationTitle}
                  itemInfo={invitationStatus}
                  itemButton={
                    !isInvitationAccepted && (
                      <MobileSubmitButton
                        textKey={Texts.RemindButtonText}
                        buttonIcon={remindButtonIcon}
                        textBackgroundColor={FigmaColors.green1}
                        iconBackgroundColor={FigmaColors.green2}
                        onClick={handleRemindButton}
                      />
                    )
                  }
                />
              )}
            </StyledBox>
          </StyledBox>
        </Wrapper>
      </GenericPageModal>
    </>
  )
}

export default EmployeeDetailsDesktop

const Wrapper = styled(StyledBox)`
  max-width: ${DETAILS_MAX_WIDTH};
  width: ${USER_DETAILS_WIDTH};
`

//TODO admin view with documents
