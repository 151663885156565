import { Texts } from '../figma/helpers/TextRepository'
import { TervelImage } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import { handleErrorMessage } from './NotificationsHelper'

export const handleExpandImage = (
  isExpandedImage: boolean,
  setIsExpandedImage: React.Dispatch<React.SetStateAction<boolean>>
) => setIsExpandedImage(!isExpandedImage)

export const handleDeleteImage = (deleteImage: any, imageId: string) => {
  deleteImage
    .mutateAsync(imageId)
    .then(() => successNotification(Texts.NotificationDeletedImageSuccessful))
    .catch((err: Error) => {
      handleErrorMessage(err)
    })
}

export const handleDeleteConfirmation = (
  showDeleteConfirmation: boolean,
  setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setShowDeleteConfirmation(!showDeleteConfirmation)
}

export const getCurrentImage = (imageID: string, images: TervelImage[]) =>
  images?.find((img: TervelImage) => img._id === imageID)

export const formatImageData = (imageFilesArray: any[], type: string) => {
  const imageFile = imageFilesArray.shift()
  const formData = new FormData()
  formData.append('image', imageFile)
  formData.append('type', type)
  return formData
}
