import { yupResolver } from '@hookform/resolvers/yup'
import { convertToRaw } from 'draft-js'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import { PageSubstate, PageSubstateContext } from 'src/helpers/PageSubstateHelper'
import { useCompanies, useUpdateUser } from 'src/helpers/QueryHooks'

import {
  getUserProfile,
  getUserRoles,
  handleUserArchiveStatus,
  hasAccess,
  isUserCustomer,
  isUserEmployee
} from 'src/helpers/UsersHelper'
import {
  createEmailYupValidation,
  createNotRequiredDateYupValidation,
  createRequiredStringYupValidation,
  createStartingDateYupValidation
} from 'src/helpers/ValidationHelper'
import useLanguage from 'src/hooks/useLanguage'
import { User } from 'src/interfaces/Dto'
import { useNotifications } from 'src/moesia/helpers/NotificationsHelper'
import * as Yup from 'yup'
import { UserType } from '../enums/UserType'
import { getPositionOptions } from '../helpers/AddEmployeeFormHelper'
import { getInitialWatchValues, getIsValuesChanged, hasData } from '../helpers/Common'
import { handleFormErrorCatch } from '../helpers/FormErrorCatchHandler'
import getCompanyOptions from '../helpers/getCompanyOptions'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'
import EditUserFormDesktopView from './EditUserFormDesktopView'
import EditUserFormMobileView from './EditUserFormMobileView'

type Props = {
  open: boolean
  onClose: () => void
  user: User
}

const EditUserFormContainer: React.FC<Props> = ({ open, onClose, user }) => {
  const { setSubstate } = useContext(PageSubstateContext)
  const { name, email, position, companies, role, notes, goals, birthDate, profile, archived } = user
  const { startingDate, plan } = profile ?? {}
  const isMobile = useIsMobile()
  const currentUserRole = useUser().role
  const isNotRegularUser = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const { successNotification } = useNotifications()
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [initialWatchValues, setInitialWatchValues] = useState<FieldValues | null>(null)
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)
  const updateUser = useUpdateUser(user._id)
  const language = useLanguage()
  const roles = useMemo(() => getUserRoles(), [])
  const { data: allCompanies } = useCompanies({ archived: false })
  const companyOptions = useMemo(() => getCompanyOptions(allCompanies), [allCompanies, allCompanies.length])
  const positionOptions = useMemo(() => getPositionOptions(), [])
  const defaultValues = useMemo(
    () => ({
      name: name,
      email: email,
      position: position,
      companies: companies.map((company) => company._id),
      role: role,
      plan: plan,
      notes: notes,
      goals: goals,
      birthDate: birthDate,
      startingDate: startingDate
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: createRequiredStringYupValidation(getText(Texts.AddUserNameErrorRequired, language)),
        email: createEmailYupValidation(
          getText(Texts.AddUserEmailErrorRequired, language),
          getText(Texts.AddUserEmailErrorValid)
        ),
        position: createRequiredStringYupValidation(getText(Texts.AddEmployeePositionErrorRequired, language)),
        companies: Yup.mixed().required(getText(Texts.AddUserCompanyErrorRequired, language)),
        role: createRequiredStringYupValidation(getText(Texts.AddUserRoleErrorRequired, language)),
        startingDate: createStartingDateYupValidation(getText(Texts.AddEmployeeStartDateErrorInvalid, language)),
        birthDate: createNotRequiredDateYupValidation(
          getText(Texts.AddEmployeeBirthDateErrorInvalid, language),
          'birthDate'
        )
      }),
    [language]
  )

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitSuccessful },
    setError
  } = useForm<FieldValues>({
    defaultValues,
    shouldUnregister: true,
    resolver: yupResolver(validationSchema)
  })
  const values = watch()
  const isEmployeeOrCustomer = isUserEmployee(values.role) || isUserCustomer(values.role)

  const handleSubmitButton = (data: { [key: string]: any }) => {
    const { email: _email, startingDate, plan, goals, notes, profile, ...restData } = data
    const userPlan = isUserEmployee(values.role) && JSON.stringify(convertToRaw(plan?.getCurrentContent()))
    const userGoals = JSON.stringify(convertToRaw(goals?.getCurrentContent()))
    const userNotes = JSON.stringify(convertToRaw(notes?.getCurrentContent()))
    const userProfile = getUserProfile(values.role)

    //Update the email only if it's different from the default one
    const updatedData = _email && _email !== email ? { ...restData, email: _email } : restData

    updatedData.profileModel = hasData(userProfile)
    updatedData.profile = {
      ...profile,
      startingDate: hasData(startingDate),
      plan: hasData(userPlan)
    }
    updatedData.goals = hasData(userGoals)
    updatedData.notes = hasData(userNotes)

    updateUser
      .mutateAsync(updatedData)
      .then(() => {
        setSubstate(PageSubstate.DEFAULT)
        successNotification(Texts.NotificationUpdateUserSuccessful)
        setIsCompleted(true)
      })
      .catch((err: Error) => {
        handleFormErrorCatch(err, setError, setSubstate)
      })
  }

  const isValuesChanged = useMemo(
    () => getIsValuesChanged(initialWatchValues, Object.values(watch())),
    [initialWatchValues, watch()]
  )

  const handleShowConfirmation = () => setShouldShowConfirmationModal(!shouldShowConfirmationModal)

  const handleClose = () => {
    if (isValuesChanged) {
      handleShowConfirmation()
      return
    }

    onClose()
  }

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(user, updateUser, onClose)

  useEffect(() => {
    if (isSubmitSuccessful && isCompleted) {
      reset({ defaultValues })
    }
  }, [isCompleted, isSubmitSuccessful])

  useEffect(() => {
    !initialWatchValues && setInitialWatchValues(getInitialWatchValues(Object.values(watch())))
  }, [initialWatchValues, watch()])

  return (
    <>
      {isMobile && (
        <EditUserFormMobileView
          open={open}
          onClose={onClose}
          handleSubmit={handleSubmit(handleSubmitButton)}
          control={control}
          positionOptions={positionOptions}
          companies={companies}
          companyOptions={companyOptions}
          name={name}
          roles={roles}
          plan={plan}
          notes={notes}
          goals={goals}
          archived={archived}
          values={values}
          isEmployeeOrCustomer={isEmployeeOrCustomer}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          isNotRegularUser={isNotRegularUser}
        />
      )}
      {!isMobile && (
        <EditUserFormDesktopView
          open={open}
          onClose={onClose}
          handleSubmit={handleSubmit(handleSubmitButton)}
          control={control}
          positionOptions={positionOptions}
          companies={companies}
          companyOptions={companyOptions}
          name={name}
          roles={roles}
          plan={plan}
          notes={notes}
          goals={goals}
          archived={archived}
          values={values}
          isEmployeeOrCustomer={isEmployeeOrCustomer}
          shouldShowConfirmationModal={shouldShowConfirmationModal}
          handleClose={handleClose}
          isValuesChanged={isValuesChanged}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          isNotRegularUser={isNotRegularUser}
        />
      )}
    </>
  )
}

export default EditUserFormContainer
