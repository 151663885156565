import MaterialTable, { MTableBodyRow, MTableToolbar } from '@material-table/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useMemo } from 'react'
import { User } from 'src/interfaces/Dto'
import styled from 'styled-components'
import { Texts, getText } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { generateUUID, sortElements } from '../helpers/Common'
import { PageSubstate } from '../helpers/PageSubstateHelper'
import { getTableExtraOptions } from '../helpers/TablesExtraOptionsHelper'
import { getUsersTableColumns } from '../helpers/UsersHelper'
import { getTableOptions } from '../helpers/getTableOptions'
import useLanguage from '../hooks/useLanguage'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import GenericConfirmationModal from './GenericConfirmationModal'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type Props = {
  users: User[]
  setSubstate: (state: PageSubstate) => void
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  onUserDelete: () => void
}

const UsersTableDesktopView: React.FC<Props> = ({
  users,
  setSubstate,
  setSelectedUser,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  onUserDelete
}) => {
  const language = useLanguage()
  const searchPlaceholder = getText(Texts.UsersSearchPlaceholder, language)
  const tableColumns = useMemo(() => getUsersTableColumns(language), [language])
  const extraOptions = useMemo(() => getTableExtraOptions(), [])

  return (
    <>
      {shouldShowDeleteConfirmationModal && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={onUserDelete}
          confirmationTitle={Texts.ConfirmDeleteUserTitle}
          confirmationQuestion={Texts.ConfirmDeleteUserQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
          confirmButtonColor={FigmaColors.lighterRed}
        />
      )}
      <Wrapper fullWidth alignSelf="center" align="flex-end" justify="center" left right spacing={Spacings.medium}>
        <TableWrapper>
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <StyledBox fullWidth direction="row" radius="rounded" align="center" justify="space-between">
                  <StyledBox direction="row" align="center" gap={Spacings.tiny}>
                    <MTableToolbar {...props} />
                    {!isShowingArchived && (
                      <SubmitButton
                        onClick={() => setSubstate(PageSubstate.CREATE)}
                        variant="primary"
                        textKey={Texts.AddUserButtonText}
                      />
                    )}
                  </StyledBox>
                  <ToggleArchiveButton
                    variant="primary"
                    onClick={handleShouldShowArchived}
                    archiveTextKey={Texts.ArchivedButtonText}
                    activateTextKey={Texts.ActivatedButtonText}
                    isArchived={isShowingArchived}
                  />
                </StyledBox>
              ),
              Row: (props) => <MTableBodyRow key={generateUUID()} {...props} />
            }}
            columns={!!tableColumns ? tableColumns : []}
            data={sortElements(users)}
            actions={[
              (user) => ({
                icon: () =>
                  isShowingArchived ? (
                    <DeleteIcon sx={{ color: FigmaColors.grayDark }} />
                  ) : (
                    <EditIcon sx={{ color: FigmaColors.grayDark }} />
                  ),
                tooltip: isShowingArchived
                  ? getText(Texts.DeleteUserText, language)
                  : getText(Texts.EditUserButtonText, language),
                onClick: () => {
                  setSelectedUser(user)
                  isShowingArchived ? handleShowDeleteConfirmation() : setSubstate(PageSubstate.EDIT)
                }
              })
            ]}
            onRowClick={(e, rowData) => {
              setSelectedUser(rowData)
              setSubstate(PageSubstate.DETAILS)
            }}
            localization={{
              toolbar: {
                searchPlaceholder: searchPlaceholder
              }
            }}
            options={getTableOptions({ extraOptions: extraOptions })}
          />
        </TableWrapper>
      </Wrapper>
    </>
  )
}
export default UsersTableDesktopView

const Wrapper = styled(StyledBox)`
  border-radius: ${BorderRadius.soft};
`
