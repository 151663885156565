import Carousel from '@itseasy21/react-elastic-carousel'
import { memo } from 'react'
import styled from 'styled-components'
import { PageSubstate } from '../../helpers/PageSubstateHelper'
import { NewsCardDto } from '../../interfaces/Dto'
import {
  TERVEL_CAROUSEL_LEFT_RIGHT_PADDING_MOBILE,
  TERVEL_CAROUSEL_TOP_BOTTOM_PADDING_MOBILE,
  TERVEL_DEFAULT_ITEMS_TO_SHOW_MOBILE
} from '../WeakHardCodedSizes'
import CarouselScrollArrowComponent from './CarouselScrollArrowComponent'
import NewsCardMobileView from './NewsCardMobileView'

type Props = {
  newsCards: NewsCardDto[]
  setSubstate: (state: PageSubstate) => void
  setSelectedNewsCard: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
  onDelete: (newsCardId: string) => void
}

const NewsCardsCarouselMobile: React.FC<Props> = ({ newsCards, setSubstate, setSelectedNewsCard, onDelete }) => (
  <StyledCarousel
    itemPadding={[TERVEL_CAROUSEL_LEFT_RIGHT_PADDING_MOBILE, TERVEL_CAROUSEL_TOP_BOTTOM_PADDING_MOBILE]}
    isRTL={false}
    itemsToShow={TERVEL_DEFAULT_ITEMS_TO_SHOW_MOBILE}
    pagination={false}
    disableArrowsOnEnd
    renderArrow={CarouselScrollArrowComponent}
  >
    {!!newsCards?.length &&
      newsCards.map((newsCard: NewsCardDto) => (
        <NewsCardMobileView key={newsCard._id} newsCard={newsCard} setSubstate={setSubstate} setSelectedNewsCard={setSelectedNewsCard} onDelete={onDelete} />
      ))}
  </StyledCarousel>
)

export default memo(NewsCardsCarouselMobile)

const StyledCarousel = styled(Carousel)`
  .rec-slider-container {
    margin: 0;
  }
`
