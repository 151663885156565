import { useMemo } from 'react'
import styled from 'styled-components'
import { BlogPostStatus } from '../enums/BlogPostStatus'
import { UserType } from '../enums/UserType'
import BorderRadius from '../figma/tokens/BorderRadius'
import Spacings from '../figma/tokens/Spacings'
import { getBlogPostStatusTextKey, getStatusColor } from '../helpers/BlogPostsHelper'
import { hasAccess } from '../helpers/UsersHelper'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { useUser } from './AuthProvider'

type Props = {
  handleShowStatusModal: () => void
  status: BlogPostStatus
}

const BlogPostStatusView: React.FC<Props> = ({ handleShowStatusModal, status }) => {
  const isMobile = useIsMobile()
  const currentUserRole = useUser().role
  const hasRights = hasAccess([UserType.ADMIN, UserType.MANAGER], currentUserRole)
  const statusState = useMemo(() => getBlogPostStatusTextKey(status), [status])
  const statusColor = useMemo(() => getStatusColor(status), [status])

  return (
    <Status position="absolute" fullWidth direction="row" align="center" gap={Spacings.minimum} $isMobile={isMobile}>
      <StyledBox
        onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          if (hasRights) {
            event.stopPropagation()
            handleShowStatusModal()
          }
        }}
      >
        <StatusCircle $statusColor={statusColor} $hasRights={hasRights} />
      </StyledBox>
      <TextContainer textKey={statusState} />
    </Status>
  )
}

export default BlogPostStatusView

const Status = styled(StyledBox)<{ $isMobile: boolean }>`
  top: ${({ $isMobile }) => ($isMobile ? `-${Spacings.medium}` : `-${Spacings.large}`)};
`

const StatusCircle = styled(StyledBox)<{ $statusColor: string; $hasRights: boolean }>`
  cursor: ${({ $hasRights }) => ($hasRights ? 'pointer' : 'not-allowed')};
  width: ${Spacings.min};
  height: ${Spacings.min};
  background-color: ${({ $statusColor }) => $statusColor && $statusColor};
  border-radius: ${BorderRadius.circle};
`
