import MaterialTable, { MTableToolbar } from '@material-table/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import React, { useContext, useMemo, useState } from 'react'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { getCompaniesDesktopColumns } from 'src/helpers/CompaniesTableColumnsHelper'
import { getTableExtraOptions } from 'src/helpers/TablesExtraOptionsHelper'
import { getTableOptions } from 'src/helpers/getTableOptions'
import useLanguage from 'src/hooks/useLanguage'
import { Company } from 'src/interfaces/Dto'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import styled from 'styled-components'
import { sortElements } from '../helpers/Common'
import { PageSubstate, PageSubstateContext, getCompaniesPageSubstate } from '../helpers/PageSubstateHelper'
import AddCompanyFormContainer from './AddCompanyFormContainer'
import GenericConfirmationModal from './GenericConfirmationModal'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type CompaniesTableDesktopProps = {
  companies: Company[]
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  selectedCompany?: Company | null
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company | null | undefined>>
  onDeleteCompany: () => void
}

const CompaniesTableDesktop: React.FC<CompaniesTableDesktopProps> = ({
  companies,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  selectedCompany,
  setSelectedCompany,
  onDeleteCompany
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const substateView = useMemo(
    () => getCompaniesPageSubstate(substate, setSubstate, selectedCompany, setSelectedCompany),
    [substate, setSubstate, selectedCompany]
  )
  const language = useLanguage()
  const searchPlaceholder = useMemo(() => getText(Texts.CompanySearchPlaceholder, language), [language])
  const columns = useMemo(() => getCompaniesDesktopColumns(language), [language])
  const extraOptions = useMemo(() => getTableExtraOptions(), [])

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && (
        <>
          {shouldShowDeleteConfirmationModal && (
            <GenericConfirmationModal
              open
              onClose={handleShowDeleteConfirmation}
              onConfirm={onDeleteCompany}
              confirmationTitle={Texts.ConfirmDeleteCompanyTitle}
              confirmationQuestion={Texts.ConfirmDeleteCompanyQuestion}
              confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
              confirmButtonColor={FigmaColors.lighterRed}
            />
          )}
          <Wrapper
            fullWidth
            alignSelf="center"
            gap={Spacings.medium}
            align="flex-end"
            justify="center"
            left
            right
            bottom
            spacing={Spacings.medium}
          >
            <AddCompanyFormContainer open={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <TableWrapper>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <StyledBox fullWidth direction="row" radius="rounded" align="center" justify="space-between">
                      <StyledBox direction="row" align="center" gap={Spacings.tiny}>
                        <MTableToolbar {...props} />
                        {!isShowingArchived && (
                          <SubmitButton
                            onClick={() => setIsModalOpen(true)}
                            variant="primary"
                            textKey={Texts.AddCompanyButtonText}
                          />
                        )}
                      </StyledBox>
                      <ToggleArchiveButton
                        variant="primary"
                        onClick={handleShouldShowArchived}
                        archiveTextKey={Texts.ArchivedButtonText}
                        activateTextKey={Texts.ActivatedButtonText}
                        isArchived={isShowingArchived}
                      />
                    </StyledBox>
                  )
                }}
                columns={columns}
                data={sortElements(companies)}
                actions={[
                  (company) => ({
                    icon: () =>
                      isShowingArchived ? (
                        <DeleteIcon sx={{ color: FigmaColors.grayDark }} />
                      ) : (
                        <EditIcon sx={{ color: FigmaColors.grayDark }} />
                      ),
                    tooltip: isShowingArchived
                      ? getText(Texts.DeleteCompanyText, language)
                      : getText(Texts.EditCompanyText, language),
                    onClick: () => {
                      setSelectedCompany(company)
                      isShowingArchived ? handleShowDeleteConfirmation() : setSubstate(PageSubstate.EDIT)
                    }
                  })
                ]}
                onRowClick={(e, rowData) => {
                  setSelectedCompany(rowData)
                  setSubstate(PageSubstate.DETAILS)
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: searchPlaceholder
                  }
                }}
                options={getTableOptions({ extraOptions: extraOptions })}
              />
            </TableWrapper>
          </Wrapper>
        </>
      )}
    </>
  )
}

export default CompaniesTableDesktop

const Wrapper = styled(StyledBox)`
  border-radius: ${BorderRadius.soft};
  .MuiIconButton-root {
    &:hover {
      border-radius: ${BorderRadius.circle};
    }
  }
`
