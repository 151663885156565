import React, { useMemo } from 'react'
import userMenuArrowLeft from 'src/figma/images/userMenuArrowLeft'
import Spacings from 'src/figma/tokens/Spacings'
import { generateUUID } from 'src/helpers/Common'
import { getLanguageMenuContent, getSelectedLanguage, isLanguageSelected, Language } from 'src/helpers/SidebarHelper'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import { useAppState } from './AuthProvider'
import MenuUserInformation, { UserInformationType } from './MenuUserInformation'
import SidebarLanguageMenuItem from './SidebarLanguageMenuItem'

type Props = {
  setIsOpenLanguageMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileLanguageMenu: React.FC<Props> = ({ setIsOpenLanguageMenu }) => {
  const languageMenuItems = useMemo(() => getLanguageMenuContent(), [])

  const { state, setState } = useAppState()

  const selectedLanguage = useMemo(() => getSelectedLanguage(state), [state])

  const onSelectLanguage = (language: Language) => {
    const newLanguage = { ...state, language }
    setState(newLanguage)
    setIsOpenLanguageMenu(false)
  }

  return (
    <>
      <StyledBox fullWidth justify="space-between" top={Spacings.huge}>
        <StyledBox fullWidth fullPadding spacing={Spacings.medium} justify="space-between">
          <StyledBox onClick={() => setIsOpenLanguageMenu(false)}>
            <SVGContainer SVG={userMenuArrowLeft} />
          </StyledBox>
          <StyledBox fullWidth gap={Spacings.min} top spacing={Spacings.small}>
            {!!languageMenuItems &&
              languageMenuItems.map(({ flag, textKey, value }) => {
                const isSelectedLanguage = isLanguageSelected(value, selectedLanguage)
                return (
                  <SidebarLanguageMenuItem
                    onClick={() => onSelectLanguage(value)}
                    key={generateUUID()}
                    flag={flag}
                    textKey={textKey}
                    isSelectedLanguage={isSelectedLanguage}
                  />
                )
              })}
          </StyledBox>
        </StyledBox>
        <MenuUserInformation iconType={UserInformationType.LOG_OUT} />
      </StyledBox>
    </>
  )
}

export default MobileLanguageMenu
