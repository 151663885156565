import { BaseSyntheticEvent, memo } from 'react'
import StyledLink from '../../components/StyledLink'
import { Texts } from '../../figma/helpers/TextRepository'
import Spacings from '../../figma/tokens/Spacings'
import StyledBox from './StyledBox'
import TextContainer from './TextContainer'

type Props = {
  textKey: string
  isBackSide?: boolean
  href?: string
}

const NewsCardsMobileDisplayContent = ({ textKey, isBackSide, href }: Props) => {
  const handlePropagation = (event: BaseSyntheticEvent) => {
    event.stopPropagation()
  }

  if (isBackSide && href) {
    return (
      <StyledLink onClick={handlePropagation} href={href} openInNewTab>
        <TextContainer textKey={Texts.BuildingOurWebsiteDesc} text={textKey} />
      </StyledLink>
    )
  }

  if (isBackSide && !href) {
    return (
      <>
        <StyledBox bottom spacing={Spacings.medium}>
          <TextContainer textKey={Texts.BuildingOurWebsiteDesc} text={textKey} />
        </StyledBox>
      </>
    )
  }

  return <TextContainer textKey={Texts.BuildingOurWebsiteTitle} text={textKey} />
}

export default memo(NewsCardsMobileDisplayContent)
