import { Control, FieldValues } from 'react-hook-form'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { BlogPostCategory } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import BlogPostCategoriesContent from './BlogPostCategoriesContent'
import BlogPostCategoryFormContainer from './BlogPostCategoryFormContainer'
import DevHarbourLoader from './DevHarbourLoader'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  onCreate: () => void
  onDelete: () => void
  onTrend: () => void
  control: Control<FieldValues, any>
  categories: BlogPostCategory[]
  selectedCategory: BlogPostCategory | null
  areCategoriesLoading: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmationModal: () => void
  shouldShowTrendingConfirmationModal: boolean
  handleShowTrendingConfirmationModal: () => void
  handleShowModal: () => void
  handleShowForm: () => void
  showForm: boolean
  showModal: boolean
}

const BlogPostCategoriesMobileView: React.FC<Props> = ({
  onCreate,
  onDelete,
  onTrend,
  control,
  categories,
  selectedCategory,
  areCategoriesLoading,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmationModal,
  shouldShowTrendingConfirmationModal,
  handleShowTrendingConfirmationModal,
  handleShowModal,
  handleShowForm,
  showForm,
  showModal
}) => (
  <StyledBox position="relative" fullWidth top={Spacings.tiny}>
    {shouldShowDeleteConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={handleShowDeleteConfirmationModal}
        onConfirm={onDelete}
        confirmationTitle={Texts.ConfirmDeleteCategoryTitle}
        confirmationQuestion={Texts.ConfirmDeleteCategoryQuestion}
        confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
        confirmButtonColor={FigmaColors.lighterRed}
        smallSize
      />
    )}
    {shouldShowTrendingConfirmationModal && (
      <GenericConfirmationModal
        open
        onClose={handleShowTrendingConfirmationModal}
        onConfirm={onTrend}
        confirmationTitle={Texts.TrendingCategoryTitle}
        confirmationQuestion={
          selectedCategory?.isTrending ? Texts.NotTrendingCategoryQuestion : Texts.TrendingCategoryQuestion
        }
        confirmButtonColor={selectedCategory?.isTrending ? FigmaColors.lighterRed : undefined}
        confirmButtonTextKey={Texts.ConfirmChangeStatusButtonText}
        smallSize
      />
    )}
    <CategoriesButton
      position="absolute"
      direction="row"
      align="center"
      justify="center"
      fullPadding
      spacing={Spacings.minimum}
      onClick={handleShowModal}
    >
      <TextContainer textKey={Texts.BlogPostCategoriesTextKey} />
    </CategoriesButton>
    {showModal && (
      <GenericPageModal
        open
        onClose={handleShowModal}
        buttons={
          <ModalButtons
            primaryButton={
              <SubmitButton
                variant="primary"
                textKey={Texts.AddCategoryButtonText}
                onClick={!showForm ? handleShowForm : onCreate}
              />
            }
          />
        }
      >
        <StyledBox fullWidth>
          {!showForm && areCategoriesLoading && <DevHarbourLoader />}
          {!showForm && !areCategoriesLoading && (
            <BlogPostCategoriesContent
              categories={categories}
              handleShowDeleteConfirmationModal={handleShowDeleteConfirmationModal}
              handleShowTrendingConfirmationModal={handleShowTrendingConfirmationModal}
            />
          )}
          {showForm && <BlogPostCategoryFormContainer handleShowForm={handleShowForm} control={control} />}
        </StyledBox>
      </GenericPageModal>
    )}
  </StyledBox>
)

export default BlogPostCategoriesMobileView

const CategoriesButton = styled(StyledBox)`
  display: flex;
  margin-top: -30px;
  right: 0;
  border-radius: 0 0 0 ${BorderRadius.soft};
  cursor: pointer;
  background-color: ${FigmaColors.bhBluePrimaryButton};

  &:hover {
    background: ${FigmaColors.blueDark};
  }
`
