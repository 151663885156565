import React, { useState } from 'react'
import { useUser } from '../components/AuthProvider'
import CompanyDetailsDesktop from '../components/CompanyDetailsDesktop'
import CompanyDetailsMobile from '../components/CompanyDetailsMobile'
import { UserType } from '../enums/UserType'
import { Texts } from '../figma/helpers/TextRepository'
import { Company } from '../interfaces/Dto'
import { successNotification } from '../moesia/helpers/NotificationsHelper'
import useIsMobile from '../moesia/hooks/useIsMobile'
import { handleErrorMessage } from './NotificationsHelper'
import { useDeleteCompany, useUpdateCompany } from './QueryHooks'
import { handleUserArchiveStatus, hasAccess } from './UsersHelper'

type Props = {
  open: boolean
  onClose: () => void
  company: Company
}

const CompanyDetails: React.FC<Props> = ({ open, onClose, company }) => {
  const isMobile = useIsMobile()
  const updateCompanyMutation = useUpdateCompany(company._id)
  const deleteCompanyMutation = useDeleteCompany()
  const [shouldShowArchiveStatusChangeConfirmation, setShouldShowArchiveStatusChangeConfirmation] = useState(false)
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] = useState(false)

  const handleShowArchiveStatusChangeConfirmation = () =>
    setShouldShowArchiveStatusChangeConfirmation(!shouldShowArchiveStatusChangeConfirmation)

  const onArchiveStatusChangeConfirm = () => handleUserArchiveStatus(company, updateCompanyMutation, onClose)

  const handleDeleteCompany = () => {
    deleteCompanyMutation
      .mutateAsync(company._id)
      .then(() => {
        onClose()
        successNotification(Texts.NotificationCompanyDeleted)
      })
      .catch((err: Error) => {
        handleErrorMessage(err)
      })
  }

  const handleShowDeleteConfirmation = () => setShouldShowDeleteConfirmation(!shouldShowDeleteConfirmation)

  return (
    <>
      {!isMobile && (
        <CompanyDetailsDesktop
          open={open}
          onClose={onClose}
          company={company}
          handleDeleteButton={handleDeleteCompany}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
        />
      )}
      {isMobile && (
        <CompanyDetailsMobile
          open={open}
          onClose={onClose}
          company={company}
          handleDeleteButton={handleDeleteCompany}
          shouldShowArchiveStatusChangeConfirmation={shouldShowArchiveStatusChangeConfirmation}
          handleShowArchiveStatusChangeConfirmation={handleShowArchiveStatusChangeConfirmation}
          onArchiveStatusChangeConfirm={onArchiveStatusChangeConfirm}
          shouldShowDeleteConfirmation={shouldShowDeleteConfirmation}
          handleShowDeleteConfirmation={handleShowDeleteConfirmation}
        />
      )}
    </>
  )
}

export default CompanyDetails
