import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from 'src/figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import styled from 'styled-components'

/**
 * @summary TableWrapper for MUI tables
 * @remarks
 * This table wrapper overrides the default behavior of material-table
 * Details panel is also styled for which we use .detailTableRow && .react-json-view
 * They both are styled, because some tables are using the react-json-view package
 */

const TableWrapper = ({ children, isMobile }: any) => (
  <TableContainer
    fullWidth
    $isMobile={isMobile}
    left={isMobile}
    right={isMobile}
    bottom={isMobile}
    spacing={Spacings.medium}
  >
    {children}
  </TableContainer>
)

const TableContainer = styled(StyledBox)<{ $isMobile: boolean }>`
  td,
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiInput-underline {
    &:after {
      border: none !important;
    }
    &:before {
      border: none !important;
    }
  }

  .MuiBox-root h6 {
    font-size: ${Spacings.large} !important;
  }

  & .MuiToolbar-root {
    padding: 0 !important;
  }

  & .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
  }

  .MuiTableBody-root {
    background-color: ${FigmaColors.white};
  }

  & .MuiTableCell-root {
    border: none;
    padding: ${Spacings.tiny};
  }

  .MuiIconButton-root {
    padding: ${Spacings.min};
  }

  & .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: inherit;
  }

  > div > div:not(:first-of-type) {
    border-radius: ${BorderRadius.soft} !important;
    box-shadow: ${Shadows.baseHeavy};
  }
`

export default TableWrapper
