import dayjs from 'dayjs'
import MUIRichTextEditor from 'mui-rte'
import { SVGProps } from 'react'
import { DATE_ORDINAL_FORMAT } from 'src/Constants'
import { Texts } from 'src/figma/helpers/TextRepository'
import employeeDetailsBirthDayIcon from 'src/figma/images/employeeDetailsBirthDayIcon'
import employeeDetailsGoalsIcon from 'src/figma/images/employeeDetailsGoalsIcon'
import employeeDetailsPlanIcon from 'src/figma/images/employeeDetailsPlanIcon'
import employeeDetailsPositionIcon from 'src/figma/images/employeeDetailsPositionIcon'
import employeeDetailsInfoIcon from 'src/figma/images/employeedetailsInfoIcon'
import CompanyDocumentsContainer from '../components/CompanyDocumentsContainer'
import { UserType } from '../enums/UserType'
import companyDetailsDocsIcon from '../figma/images/companyDetailsDocsIcon'
import { User } from '../interfaces/Dto'
import { TextType } from '../moesia/interfaces/FigmaTypes'

type DetailsItem = {
  itemIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  itemTitle: TextType
  itemInfo: JSX.Element | string
  permissions?: UserType[]
}

export const getEmployeeDetailsItems = (employee: User, positionAndCompanies: string): DetailsItem[] => {
  const {
    profile: { plan },
    goals,
    notes,
    birthDate,
    documents
  } = employee

  return [
    {
      itemIcon: employeeDetailsPositionIcon,
      itemTitle: Texts.EmployeeDetailsPositionTitle,
      itemInfo: positionAndCompanies
    },
    {
      itemIcon: employeeDetailsPlanIcon,
      itemTitle: Texts.EmployeeDetailsPlanTitle,
      itemInfo: plan && <MUIRichTextEditor toolbar={false} defaultValue={plan} readOnly />,
      permissions: [UserType.ADMIN, UserType.MANAGER]
    },
    {
      itemIcon: employeeDetailsGoalsIcon,
      itemTitle: Texts.EmployeeDetailsGoalsTitle,
      itemInfo: goals && <MUIRichTextEditor toolbar={false} defaultValue={goals} readOnly />,
      permissions: [UserType.ADMIN, UserType.MANAGER]
    },
    {
      itemIcon: employeeDetailsInfoIcon,
      itemTitle: Texts.EmployeeDetailsInfoTitle,
      itemInfo: notes && <MUIRichTextEditor toolbar={false} defaultValue={notes} readOnly />,
      permissions: [UserType.ADMIN, UserType.MANAGER]
    },
    {
      itemIcon: employeeDetailsBirthDayIcon,
      itemTitle: Texts.EmployeeDetailsBirthdayTitle,
      itemInfo: !!birthDate && dayjs(birthDate).format(DATE_ORDINAL_FORMAT)
    },
    {
      itemIcon: companyDetailsDocsIcon,
      itemTitle: Texts.CompanyDetailsDocsTitle,
      itemInfo: !!documents?.length ? <CompanyDocumentsContainer documents={documents} isDetails /> : '',
      permissions: [UserType.ADMIN, UserType.MANAGER]
    }
  ]
}
