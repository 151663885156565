type Props = {
  name: string
  file: File
  wikiUrl?: string
  customName?: string
}

export const createTemplateFormData = ({ name, file, wikiUrl, customName }: Props) => {
  const fileData = new FormData()
  const fileName = !!customName ? customName : file.name
  fileData.append('name', fileName)
  fileData.append(name, file)
  wikiUrl && fileData.append('wikiUrl', wikiUrl ?? '')
  return fileData
}
