import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const ToastProvider = styled(ToastContainer)`
  .Toastify__close-button--light {
    color: black;
    opacity: 1;
  }

  .success {
    color: black;
    border-left: 5px solid green;
    background-color: #90ee9084;
  }

  .error {
    color: black;
    border-left: 5px solid red;
    background-color: #ff000082;
  }

  .info {
    color: black;
    border-left: 5px solid blue;
    background-color: #0000ff7b;
  }

  .warning {
    color: black;
    border-left: 5px solid yellow;
    background-color: #ffff007e;
  }
`
