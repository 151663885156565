import React from 'react'
import StyledBox from 'src/moesia/components/StyledBox'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { FigmaImageType, TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'
import BackOfficeLayoutDesktop from './BackOfficeLayoutDesktop'
import BackOfficeLayoutMobile from './BackOfficeLayoutMobile'

type Props = {
  pageHeading?: TextType
  pageSubheading?: TextType
  children?: React.ReactNode
  showHeading?: boolean
  backgroundImage?: FigmaImageType
  fullBackgroundImage?: boolean
}

const BackOfficeLayout: React.FC<Props> = ({
  pageHeading,
  pageSubheading,
  children,
  showHeading,
  backgroundImage,
  fullBackgroundImage
}) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth>
      {!isMobile && (
        <BackOfficeLayoutDesktop
          showHeading={showHeading}
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          content={children}
        />
      )}
      {isMobile && (
        <BackOfficeLayoutMobile
          backgroundImage={backgroundImage}
          fullBackgroundImage={fullBackgroundImage}
          showHeading={showHeading}
          pageHeading={pageHeading}
          pageSubheading={pageSubheading}
          content={children}
        />
      )}
    </Wrapper>
  )
}

export default React.memo(BackOfficeLayout)

const Wrapper = styled(StyledBox)`
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
`
