import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'
import SubmitButton from '../moesia/components/SubmitButton'
import TextContainer from '../moesia/components/TextContainer'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import GenericPageModal from './GenericPageModal'
import ModalButtons from './ModalButtons'

type Props = {
  open: boolean
  onClose: () => void | void
  onModalClose?: () => void
  onConfirm: () => void | void
  confirmationTitle: TextType | string
  confirmationQuestion: TextType | string
  confirmButtonTextKey: TextType | string
  cancelButtonTextKey?: TextType | string
  confirmButtonColor?: string
  cancelButtonColor?: string
  buttonsBackground?: string
  smallSize?: boolean
}

const GenericConfirmationModal: React.FC<Props> = ({
  open,
  onClose,
  onModalClose,
  onConfirm,
  confirmationTitle,
  confirmationQuestion,
  confirmButtonTextKey,
  cancelButtonTextKey,
  confirmButtonColor,
  cancelButtonColor,
  buttonsBackground,
  smallSize
}) => {
  if (
    typeof confirmationTitle === 'string' ||
    typeof confirmationQuestion === 'string' ||
    typeof confirmButtonTextKey === 'string' ||
    typeof cancelButtonTextKey === 'string'
  )
    return null

  return (
    <GenericPageModal
      open={open}
      onClose={onModalClose}
      buttons={
        <ModalButtons
          primaryButton={
            <ConfirmButton
              variant="primary"
              textKey={confirmButtonTextKey}
              onClick={onConfirm}
              $confirmButtonColor={confirmButtonColor}
            />
          }
          secondaryButton={
            <CancelButton
              variant="secondary"
              textKey={cancelButtonTextKey ? cancelButtonTextKey : Texts.CancelButtonText}
              onClick={onClose}
              $cancelButtonColor={cancelButtonColor}
            />
          }
        />
      }
      buttonsBackground={buttonsBackground ? buttonsBackground : FigmaColors.lightStone}
      smallSize={smallSize}
    >
      <StyledBox gap={Spacings.small} fullPadding spacing={Spacings.large}>
        <TextContainer textKey={confirmationTitle} />
        <TextContainer textKey={confirmationQuestion} />
      </StyledBox>
    </GenericPageModal>
  )
}

export default GenericConfirmationModal

const ConfirmButton = styled(SubmitButton)<{ $confirmButtonColor?: string }>`
  div {
    background-color: ${({ $confirmButtonColor }) => $confirmButtonColor && $confirmButtonColor};
    &:hover {
      background-color: ${({ $confirmButtonColor }) => $confirmButtonColor && $confirmButtonColor};
    }
  }
`

const CancelButton = styled(SubmitButton)<{ $cancelButtonColor?: string }>`
  div {
    background-color: ${({ $cancelButtonColor }) => $cancelButtonColor && $cancelButtonColor};
    &:hover {
      background-color: ${({ $cancelButtonColor }) => $cancelButtonColor && $cancelButtonColor};
    }
  }
`
