import { useMemo } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import invitationAcceptedIcon from '../figma/images/invitationAcceptedIcon'
import invitationPendingIcon from '../figma/images/invitationPendingIcon'
import remindButtonIcon from '../figma/images/remindButtonIcon'
import FigmaColors from '../figma/tokens/FigmaColors'
import Spacings from '../figma/tokens/Spacings'
import { getUserDetailsInformation } from '../helpers/UsersHelper'
import { User } from '../interfaces/Dto'
import { DETAILS_MAX_WIDTH, USER_DETAILS_WIDTH } from '../moesia/StrongHardCodedSizes'
import StyledBox from '../moesia/components/StyledBox'
import TextContainer from '../moesia/components/TextContainer'
import AvatarInitials from './AvatarInitials'
import ConditionalDetailsFormsButtonDesktop from './ConditionalDetailsFormsButtonDesktop'
import DetailsInformationElement from './DetailsInformationElement'
import EmployeeDetailsItemDesktop from './EmployeeDetailsItemDesktop'
import GenericConfirmationModal from './GenericConfirmationModal'
import GenericPageModal from './GenericPageModal'
import { Line } from './Line'
import MobileSubmitButton from './MobileSubmitButton'
import ModalButtons from './ModalButtons'
import ToggleArchiveButton from './ToggleArchiveButton'

type Props = {
  open: boolean
  onClose: () => void
  handleEditButton: () => void
  handleDeleteButton: () => void
  user: User
  shouldShowArchiveStatusChangeConfirmation: boolean
  handleShowArchiveStatusChangeConfirmation: () => void
  onArchiveStatusChangeConfirm: () => void
  shouldShowDeleteConfirmation: boolean
  handleShowDeleteConfirmation: () => void
  handleRemindButton: () => void
  isInvitationAccepted: boolean
}

const UserDetailsDesktopView: React.FC<Props> = ({
  open,
  onClose,
  handleEditButton,
  handleDeleteButton,
  shouldShowArchiveStatusChangeConfirmation,
  handleShowArchiveStatusChangeConfirmation,
  onArchiveStatusChangeConfirm,
  shouldShowDeleteConfirmation,
  handleShowDeleteConfirmation,
  user,
  handleRemindButton,
  isInvitationAccepted
}) => {
  const { name, role, archived, invitationStatus } = user
  const userDetailsInformation = useMemo(() => getUserDetailsInformation(user), [user])

  return (
    <>
      {shouldShowDeleteConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowDeleteConfirmation}
          onConfirm={handleDeleteButton}
          confirmationTitle={Texts.ConfirmDeleteUserTitle}
          confirmationQuestion={Texts.ConfirmDeleteUserQuestion}
          confirmButtonTextKey={Texts.ConfirmDeleteButtonTextMobile}
          confirmButtonColor={FigmaColors.lighterRed}
        />
      )}
      {shouldShowArchiveStatusChangeConfirmation && (
        <GenericConfirmationModal
          open
          onClose={handleShowArchiveStatusChangeConfirmation}
          onConfirm={onArchiveStatusChangeConfirm}
          confirmationTitle={!!archived ? Texts.ConfirmActivateUserTitle : Texts.ConfirmArchiveUserTitle}
          confirmationQuestion={
            !!archived ? Texts.ConfirmActivateUserProfileQuestion : Texts.ConfirmArchiveUserQuestion
          }
          confirmButtonTextKey={!!archived ? Texts.ConfirmActivateButtonText : Texts.ConfirmArchiveButtonText}
          confirmButtonColor={!!archived ? FigmaColors.baseGreen : FigmaColors.lighterRed}
        />
      )}
      <GenericPageModal
        open={open}
        onClose={onClose}
        buttons={
          <ModalButtons
            primaryButton={
              <ConditionalDetailsFormsButtonDesktop
                isArchived={!!archived}
                onDeleteClick={handleShowDeleteConfirmation}
                onEditClick={handleEditButton}
              />
            }
            tertiaryButton={
              <ToggleArchiveButton
                activateTextKey={Texts.ActivateButtonTextModal}
                archiveTextKey={Texts.ArchiveButtonTextModal}
                onClick={handleShowArchiveStatusChangeConfirmation}
                isArchived={archived}
              />
            }
          />
        }
      >
        <Wrapper fullWidth fullPadding spacing={Spacings.medium}>
          <StyledBox fullWidth direction="row" left right bottom spacing={Spacings.medium} gap={Spacings.medium}>
            <AvatarInitials name={name} />
            <StyledBox fullWidth top spacing={Spacings.tiny} gap={Spacings.medium}>
              <StyledBox fullWidth gap={Spacings.minimum}>
                <TextContainer textKey={Texts.EmployeeDetailsName} text={name} />
                <TextContainer textKey={Texts.EmployeeDetailsPosition} text={role} />
                <Line fullWidth />
              </StyledBox>
              {!!userDetailsInformation &&
                userDetailsInformation.map(({ SVG, titleTextKey, dataTextKey, dataText }, idx) => (
                  <DetailsInformationElement
                    key={`${dataTextKey}-${idx}`}
                    SVG={SVG}
                    titleTextKey={titleTextKey}
                    dataTextKey={dataTextKey}
                    dataText={dataText}
                  />
                ))}
              {!archived && (
                <EmployeeDetailsItemDesktop
                  itemIcon={isInvitationAccepted ? invitationAcceptedIcon : invitationPendingIcon}
                  itemTitle={Texts.EmployeeDetailsInvitationTitle}
                  itemInfo={invitationStatus}
                  itemButton={
                    !isInvitationAccepted && (
                      <MobileSubmitButton
                        textKey={Texts.RemindButtonText}
                        buttonIcon={remindButtonIcon}
                        textBackgroundColor={FigmaColors.green1}
                        iconBackgroundColor={FigmaColors.green2}
                        onClick={handleRemindButton}
                      />
                    )
                  }
                />
              )}
            </StyledBox>
          </StyledBox>
        </Wrapper>
      </GenericPageModal>
    </>
  )
}

export default UserDetailsDesktopView

const Wrapper = styled(StyledBox)`
  overflow-y: scroll;
  max-width: ${DETAILS_MAX_WIDTH};
  width: ${USER_DETAILS_WIDTH};
`
