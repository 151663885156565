import MaterialTable, { Column, MTableToolbar, Options } from '@material-table/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { TableBodyProps } from '@mui/material/TableBody/TableBody'
import React, { useContext, useMemo } from 'react'
import { Texts, getText } from 'src/figma/helpers/TextRepository'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Spacings from 'src/figma/tokens/Spacings'
import { getTableOptions } from 'src/helpers/getTableOptions'
import StyledBox from 'src/moesia/components/StyledBox'
import SubmitButton from 'src/moesia/components/SubmitButton'
import styled from 'styled-components'
import { sortElements } from '../helpers/Common'
import { PageSubstate, PageSubstateContext, getEmployeesPageSubstate } from '../helpers/PageSubstateHelper'
import useLanguage from '../hooks/useLanguage'
import { User } from '../interfaces/Dto'
import GenericConfirmationModal from './GenericConfirmationModal'
import TableWrapper from './TableWrapper'
import ToggleArchiveButton from './ToggleArchiveButton'

type EmployeesTableProps = {
  employees: User[]
  extraOptions: Options<TableBodyProps>
  tableColumnsDesktop: Column<User>[]
  searchPlaceholder: string
  handleShouldShowArchived: () => void
  isShowingArchived: boolean
  shouldShowDeleteConfirmationModal: boolean
  handleShowDeleteConfirmation: () => void
  isNotRegularUser: boolean
  selectedEmployee: User | null
  setSelectedEmployee: React.Dispatch<React.SetStateAction<User | null>>
  onEmployeeDelete: () => void
}

const EmployeesTableDesktop: React.FC<EmployeesTableProps> = ({
  employees,
  extraOptions,
  tableColumnsDesktop,
  searchPlaceholder,
  handleShouldShowArchived,
  isShowingArchived,
  shouldShowDeleteConfirmationModal,
  handleShowDeleteConfirmation,
  isNotRegularUser,
  selectedEmployee,
  setSelectedEmployee,
  onEmployeeDelete
}) => {
  const { substate, setSubstate } = useContext(PageSubstateContext)
  const language = useLanguage()
  const substateView = useMemo(
    () => getEmployeesPageSubstate(substate, setSubstate, selectedEmployee, setSelectedEmployee),
    [substate, setSubstate, selectedEmployee]
  )

  return (
    <>
      {substateView}
      {substate !== PageSubstate.ERROR && (
        <>
          {shouldShowDeleteConfirmationModal && (
            <GenericConfirmationModal
              open
              onClose={handleShowDeleteConfirmation}
              onConfirm={onEmployeeDelete}
              confirmationTitle={Texts.ConfirmDeleteEmployeeTitle}
              confirmationQuestion={Texts.ConfirmDeleteEmployeeQuestion}
              confirmButtonTextKey={Texts.ConfirmDeleteButtonText}
              confirmButtonColor={FigmaColors.lighterRed}
            />
          )}
          <Wrapper
            fullWidth
            alignSelf="center"
            align="flex-end"
            justify="center"
            left
            right
            bottom
            spacing={Spacings.medium}
          >
            <TableWrapper>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <StyledBox fullWidth direction="row" radius="rounded" align="center" justify="space-between">
                      <StyledBox direction="row" align="center" gap={Spacings.tiny}>
                        <MTableToolbar {...props} />
                        {!isShowingArchived && (
                          <SubmitButton
                            onClick={() => setSubstate(PageSubstate.CREATE)}
                            variant="primary"
                            textKey={Texts.AddEmployeeButtonText}
                          />
                        )}
                      </StyledBox>
                      {isNotRegularUser && (
                        <ToggleArchiveButton
                          variant="primary"
                          onClick={handleShouldShowArchived}
                          archiveTextKey={Texts.ArchivedButtonText}
                          activateTextKey={Texts.ActivatedButtonText}
                          isArchived={isShowingArchived}
                        />
                      )}
                    </StyledBox>
                  )
                }}
                columns={!!tableColumnsDesktop ? tableColumnsDesktop : []}
                data={sortElements(employees)}
                actions={[
                  (employee) => ({
                    icon: () =>
                      isShowingArchived ? (
                        <DeleteIcon sx={{ color: FigmaColors.grayDark }} />
                      ) : (
                        <>{isNotRegularUser && <EditIcon sx={{ color: FigmaColors.grayDark }} />}</>
                      ),
                    tooltip: isShowingArchived
                      ? getText(Texts.DeleteEmployeeText, language)
                      : getText(Texts.EditEmployeeText, language),
                    onClick: () => {
                      setSelectedEmployee(employee as User)
                      isShowingArchived ? handleShowDeleteConfirmation() : setSubstate(PageSubstate.EDIT)
                    }
                  })
                ]}
                onRowClick={(e, rowData) => {
                  !!rowData && setSelectedEmployee(rowData as User)
                  setSubstate(PageSubstate.DETAILS)
                }}
                localization={{
                  toolbar: {
                    searchPlaceholder: searchPlaceholder
                  }
                }}
                options={getTableOptions({ extraOptions: extraOptions })}
              />
            </TableWrapper>
          </Wrapper>
        </>
      )}
    </>
  )
}

export default EmployeesTableDesktop

const Wrapper = styled(StyledBox)`
  border-radius: ${BorderRadius.soft};
`
