import styled from 'styled-components';
import { Texts } from '../figma/helpers/TextRepository';
import buttonAddIcon from '../figma/images/buttonAddIcon';
import FigmaColors from '../figma/tokens/FigmaColors';
import Spacings from '../figma/tokens/Spacings';
import { PageSubstate } from '../helpers/PageSubstateHelper';
import { NewsCardDto } from '../interfaces/Dto';
import NewsCardsCarouselMobile from '../moesia/components/NewsCardsCarouselMobile';
import StyledBox from '../moesia/components/StyledBox';
import MobileSubmitButton from './MobileSubmitButton';

type Props = {
  newsCards: NewsCardDto[]
  onAddNewsCardClick: () => void
  setSubstate: (state: PageSubstate) => void
  setSelectedNewsCard: React.Dispatch<React.SetStateAction<NewsCardDto | null>>
  onDelete: (newsCardId: string) => void
}

const NewsCardsSectionMobile: React.FC<Props> = ({
  newsCards,
  onAddNewsCardClick,
  setSubstate,
  setSelectedNewsCard,
  onDelete
}) => (
  <StyledBox fullWidth bottom={Spacings.huge}>
    <NewsCardsCarouselMobile
      newsCards={newsCards}
      setSubstate={setSubstate}
      setSelectedNewsCard={setSelectedNewsCard}
      onDelete={onDelete}
    />
    <ButtonWrapper fullWidth position="fixed" align="center">
      <MobileSubmitButton
        textKey={Texts.AddButtonText}
        buttonIcon={buttonAddIcon}
        textBackgroundColor={FigmaColors.bhBluePrimaryButton}
        iconBackgroundColor={FigmaColors.bhBlueMedium}
        onClick={onAddNewsCardClick}
      />
    </ButtonWrapper>
  </StyledBox>
)

export default NewsCardsSectionMobile

const ButtonWrapper = styled(StyledBox)`
  bottom: ${Spacings.tiny};
`
