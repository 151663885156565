import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import uploadDocumentsIcon from '../figma/images/uploadDocumentsIcon'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from '../figma/tokens/Spacings'
import { Template } from '../interfaces/Dto'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import {
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT,
  TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH
} from '../moesia/StrongHardCodedSizes'
import TemplateContainer from './TemplateContainer'

type Props = {
  templates: Template[]
  onAddTemplateClick?: () => void
  hasRightsToUploadTemplate: boolean
  hasRightsToDelete: boolean
  onDeleteTemplate: (templateId: string) => void
  setSelectedTemplate: React.Dispatch<React.SetStateAction<Template | null>>
}

const TemplatesSectionMobile: React.FC<Props> = ({
  templates,
  onAddTemplateClick,
  hasRightsToUploadTemplate,
  onDeleteTemplate,
  hasRightsToDelete,
  setSelectedTemplate
}) => (
  <>
    <StyledBox gap={Spacings.tiny} justify="flex-start" direction="row" flexWrap="wrap">
      {hasRightsToUploadTemplate && (
        <CreateTemplateButton onClick={onAddTemplateClick} gap={Spacings.tiny} align="center" justify="center">
          <SVGContainer SVG={uploadDocumentsIcon} />
          <TextContainer textKey={Texts.UploadTemplate} />
        </CreateTemplateButton>
      )}
      {!!templates &&
        templates.map((template) => (
          <TemplateContainer
            key={template._id}
            template={template}
            onDeleteTemplate={onDeleteTemplate}
            hasRightsToDelete={hasRightsToDelete}
            setSelectedTemplate={setSelectedTemplate}
          />
        ))}
    </StyledBox>
  </>
)

export default TemplatesSectionMobile

const CreateTemplateButton = styled(StyledBox)`
  height: ${TEMPLATES_DOCUMENT_CONTAINER_FIGMA_HEIGHT};
  width: ${TEMPLATES_DOCUMENT_CONTAINER_FIGMA_WIDTH};
  border: 1px dashed ${FigmaColors.grayLight};
  border-radius: ${BorderRadius.soft};
  box-shadow: ${Shadows.baseSoft};
`
