import ENTexts from '../langs/en.json'
import SVTexts from '../langs/sv.json'
import BGTexts from '../langs/bg.json'
import FigmaTexts from '../langs/figma.json'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import { Language } from 'src/moesia/interfaces/LanguageType'
import { BG, SV, EN } from '../../Constants'

import FigmaTextKeys from '../texts.json'

const VARIABLE = 'XXX'
type Printable = number | string | undefined

export const getText = (textKey: TextType, language: Language = 'default'): string => {
  switch (language) {
    case BG:
      return (BGTexts as any)[textKey.name] || ''
    case SV:
      return (SVTexts as any)[textKey.name] || ''
    case EN:
      return (ENTexts as any)[textKey.name] || ''
    default:
      return (FigmaTexts as any)[textKey.name] || ''
  }
} //TODO FIX BY LANGUAGE

export const Texts = FigmaTextKeys

export const formatText = (text: string, params: Printable[]): string => {
  const _params = params.map((p) => {
    if (isNaN(Number(p))) return p?.toString()
    return p ? p.toString() : ''
  })
  const splits = text.split(VARIABLE)

  return splits.reduce(
    (a, b, i) => ({ res: a.res + b + (i < splits.length - 1 ? _params[a.i] : '')?.toString(), i: i + 1 }),
    {
      res: '',
      i: 0
    }
  ).res
}
