import axios, { AxiosInstance } from 'axios'
import config from 'src/Config'

export const backofficeApi: AxiosInstance = axios.create({
  baseURL: config.baseUrl,

  headers: {
    'Content-Type': 'application/json'
  }
})

const COMPANY_ROUTE = '/company'
const AUTH_ROUTE = '/auth'
const OVERVIEW_ROUTE = '/overview'
const USER_ROUTE = '/user'
const TEMPLATE_ROUTE = '/template'
const DOCUMENT_ROUTE = '/document'
const NEWS_CARD_ROUTE = '/news-card'
const TERVEL_IMAGES_ROUTE = '/tervel-images'
const NEWS_ARTICLES_ROUTE = '/news-articles'
const NEWS_ARTICLES_CATEGORY_ROUTE = '/news-articles/category'

export const getCompanies = (filter: any) =>
  backofficeApi.get(`${COMPANY_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const createCompany = (data: any) => backofficeApi.post(`${COMPANY_ROUTE}/`, data).then(({ data }) => data)
export const getCompanyById = (companyId: string) =>
  backofficeApi.get(`${COMPANY_ROUTE}/${companyId}`).then(({ data }) => data)
export const deleteCompanyById = (companyId: string) =>
  backofficeApi.delete(`${COMPANY_ROUTE}/${companyId}`).then(({ data }) => data)
export const updateCompany = (companyId: string, data: any) =>
  backofficeApi.put(`${COMPANY_ROUTE}/${companyId}`, data).then(({ data }) => data)
export const signin = (data: any) => backofficeApi.post(`${AUTH_ROUTE}/signin`, data).then(({ data }) => data)
export const refreshToken = (data: any) =>
  backofficeApi.post(`${AUTH_ROUTE}/refreshtoken`, data).then(({ data }) => data)
export const getOverview = (filter: any) =>
  backofficeApi.get(`${OVERVIEW_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const getUsers = (filter: any) =>
  backofficeApi.get(`${USER_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const updateUser = (userId: string, data: any) =>
  backofficeApi.put(`${USER_ROUTE}/${userId}`, data).then(({ data }) => data)
export const createUser = (data: any) => backofficeApi.post(`${USER_ROUTE}/`, data).then(({ data }) => data)
export const deleteUserById = (userId: any) => backofficeApi.delete(`${USER_ROUTE}/${userId}`).then(({ data }) => data)
export const inviteUser = (userId: string, data: any) =>
  backofficeApi.post(`${USER_ROUTE}/${userId}/invite`, data).then(({ data }) => data)
export const getUserById = (userId: string) => backofficeApi.get(`${USER_ROUTE}/${userId}`).then(({ data }) => data)
export const uploadTemplate = (data: FormData) =>
  backofficeApi
    .post(`${TEMPLATE_ROUTE}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(({ data }) => data)
export const uploadDocument = (data: FormData & { userId?: string; companyId?: string }) =>
  backofficeApi
    .post(`${DOCUMENT_ROUTE}/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(({ data }) => data)
export const getDocuments = (filter: any) =>
  backofficeApi.get(`${DOCUMENT_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const getDocumentById = (documentId: string) =>
  backofficeApi.get(`${DOCUMENT_ROUTE}/${documentId}`).then(({ data }) => data)
export const deleteDocumentById = (documentId: string) =>
  backofficeApi.delete(`${DOCUMENT_ROUTE}/${documentId}`).then(({ data }) => data)
export const getTemplates = (filter: any) =>
  backofficeApi.get(`${TEMPLATE_ROUTE}`, { params: filter }).then(({ data }) => data)
export const getTemplateById = (templateId: string) =>
  backofficeApi.get(`${TEMPLATE_ROUTE}/${templateId}`).then(({ data }) => data)
export const deleteTemplateById = (templateId: string) =>
  backofficeApi.delete(`${TEMPLATE_ROUTE}/${templateId}`).then(({ data }) => data)
export const updateTemplateById = (templateId: string, data: FormData) =>
  backofficeApi.put(`${TEMPLATE_ROUTE}/${templateId}`, data).then(({ data }) => data)
export const forgotPassword = (data: { [key: string]: string }) =>
  backofficeApi.post(`${AUTH_ROUTE}/forgotpassword`, data).then(({ data }) => data)
export const resetPassword = (resetPasswordCode: string, data: { [key: string]: string }) =>
  backofficeApi.patch(`${AUTH_ROUTE}/resetpassword/${resetPasswordCode}`, data).then(({ data }) => data)
export const createNewsCard = (data: any) => backofficeApi.post(`${NEWS_CARD_ROUTE}/`, data).then(({ data }) => data)
export const deleteNewsCardById = (newsCardId: string) =>
  backofficeApi.delete(`${NEWS_CARD_ROUTE}/${newsCardId}`).then(({ data }) => data)
export const updateNewsCard = (newsCardId: string, data: any) =>
  backofficeApi.put(`${NEWS_CARD_ROUTE}/${newsCardId}`, data).then(({ data }) => data)
export const getNewsCards = (filter: any) =>
  backofficeApi.get(`${NEWS_CARD_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const uploadTervelImage = (data: FormData) =>
  backofficeApi
    .post(`${TERVEL_IMAGES_ROUTE}/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(({ data }) => data)
export const getTervelImages = (filter: any) =>
  backofficeApi.get(`${TERVEL_IMAGES_ROUTE}`, { params: filter }).then(({ data }) => data)
export const deleteImageById = (imageId: string) =>
  backofficeApi.delete(`${TERVEL_IMAGES_ROUTE}/${imageId}`).then(({ data }) => data)
export const createBlogPost = (data: any) =>
  backofficeApi.post(`${NEWS_ARTICLES_ROUTE}/`, data).then(({ data }) => data)
export const deleteBlogPostById = (blogPostId: string) =>
  backofficeApi.delete(`${NEWS_ARTICLES_ROUTE}/${blogPostId}`).then(({ data }) => data)
export const updateBlogPostById = (blogPostId: string, data: any) =>
  backofficeApi.put(`${NEWS_ARTICLES_ROUTE}/${blogPostId}`, data).then(({ data }) => data)
export const getBlogPosts = (filter: any) =>
  backofficeApi.get(`${NEWS_ARTICLES_ROUTE}/`, { params: filter }).then(({ data }) => data)
export const getBlogPostById = (blogPostId: string) =>
  backofficeApi.get(`${NEWS_ARTICLES_ROUTE}/${blogPostId}`).then(({ data }) => data)
export const createCategory = (data: any) =>
  backofficeApi.post(`${NEWS_ARTICLES_CATEGORY_ROUTE}/`, data).then(({ data }) => data)
export const updateCategory = (categoryId: string, data: any) =>
  backofficeApi.put(`${NEWS_ARTICLES_CATEGORY_ROUTE}/${categoryId}`, data).then(({ data }) => data)
export const deleteCategoryById = (categoryId: string) =>
  backofficeApi.delete(`${NEWS_ARTICLES_CATEGORY_ROUTE}/${categoryId}`).then(({ data }) => data)
export const getCategories = (filter: any) =>
  backofficeApi.get(`${NEWS_ARTICLES_CATEGORY_ROUTE}/`, { params: filter }).then(({ data }) => data)
