import { Control } from 'react-hook-form'
import { UserType } from '../enums/UserType'
import { Texts } from '../figma/helpers/TextRepository'
import { isUserEmployee } from '../helpers/UsersHelper'
import EditorController from '../moesia/components/EditorController'
import { DatePickerController } from '../moesia/components/ReactHookComponents'

type Props = {
  control: Control<any>
  plan?: JSON
  role: UserType.CUSTOMER | UserType.EMPLOYEE
}

const UserAdditionalProfileFields: React.FC<Props> = ({ control, plan, role }) => (
  <>
    <DatePickerController name="startingDate" control={control} labelTextKey={Texts.AddEmployeeStartDayPlaceholder} />
    {isUserEmployee(role) && (
      <EditorController name="plan" labelTextKey={Texts.PlansPlaceholder} control={control} defaultValue={plan} />
    )}
  </>
)

export default UserAdditionalProfileFields
